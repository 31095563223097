import classNames from "classnames"
import Checkbox from "core/Checkbox"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import {
    ScrollAreaCorner,
    ScrollAreaRoot,
    ScrollAreaScrollbar,
    ScrollAreaThumb,
    ScrollAreaViewPort,
} from "core/scrollComponents"
import EventPayment from "models/events/eventPayment"
import { PaymentItem } from "models/payment_item"
import { nanoid } from "nanoid"
import { useEffect, useState } from "react"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { paymentStore } from "stores/paymentStore"
import { rootStore } from "stores/rootStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectPayment = ({ isOpen, setIsOpen }: Props) => {
    const { paymentItems, paymentCategories, units } = useSnapshot(rootStore)
    const { editingPayments, currentPaymentName: currentSection } = useSnapshot(paymentStore)
    const [selectedCategory, setSelectedCategory] = useState(
        paymentCategories?.[0]?.name || ""
    )

    const [selectedItems, setSelectedItems] = useState<EventPayment[]>([])

    useEffect(() => {
        setSelectedItems(editingPayments.map((e) => ({ ...e })))
    }, [editingPayments, isOpen])

    const handleDone = () => {
        paymentStore.editingPayments = [...selectedItems.map((e) => ({ ...e }))]
        setIsOpen(false)
        saveDraft()
    }

    const onSelect = (paymentItem: PaymentItem) => {
        //mỗi lần select là key sẽ thay đổi
        if (selectedNames.includes(paymentItem.name)) {
            setSelectedItems((s) => s.filter((e) => e.name != paymentItem.name))
        } else {
            setSelectedItems([
                ...selectedItems,
                {
                    eventId: eventDetailStore.currentEvent.id,
                    id: 0,
                    name: paymentItem.name,
                    category: selectedCategory,
                    createdAt: new Date().getTime(),
                    free: false,
                    note: "",
                    price: paymentItem.price,
                    quantity: 1,
                    section: currentSection,
                    unit:
                        units.find((e) => e.id === paymentItem.unitId)?.name ||
                        "",
                    key: nanoid(),
                },
            ])
        }
    }

    const currentCategory = paymentCategories.find(
        (e) => e.name === selectedCategory
    )
    const viewItems = selectedItems.filter(
        (e) =>
            e.category === currentCategory?.name && e.section === currentSection
    )
    const selectedNames = viewItems.map((e) => e.name)
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[600px] mt-[5vh] bg-white">
                    <DialogTitle>Thêm hạng mục</DialogTitle>

                    <div className="flex items-stretch">
                        <TempateContainer className="flex-none">
                            {paymentCategories.map((s) => (
                                <TemplateName
                                    key={s.id}
                                    onClick={() => {
                                        setSelectedCategory(s.name)
                                    }}
                                    className={classNames({
                                        selected: s.name === selectedCategory,
                                    })}
                                >
                                    {s.name}{" "}
                                    {selectedItems.filter(
                                        (e) =>
                                            e.category === s.name &&
                                            e.section === currentSection
                                    ).length > 0 && (
                                        <span className="text-[#aaa] text-13 ml-1">
                                            (
                                            {
                                                selectedItems.filter(
                                                    (e) => e.category === s.name
                                                ).length
                                            }
                                            )
                                        </span>
                                    )}
                                </TemplateName>
                            ))}
                        </TempateContainer>

                        <ScrollAreaRoot
                            className="flex-1 min-w-0"
                            style={{
                                height: `calc(86vh - 120px)`,
                            }}
                        >
                            <ScrollAreaViewPort className="bg-[#fff]">
                                <TodoContainer>
                                    {paymentItems
                                        .filter(
                                            (e) =>
                                                e.typeId === currentCategory?.id
                                        )
                                        .map((e) => (
                                            <StyledMenuItem
                                                key={e.id}
                                                onClick={() => onSelect(e)}
                                                className={classNames(
                                                    "flex items-center pl-10 pr-4 py-1.5",
                                                    {
                                                        selected:
                                                            selectedNames.includes(
                                                                e.name
                                                            ),
                                                    }
                                                )}
                                            >
                                                {e.name}
                                                <Checkbox
                                                    className="ml-auto"
                                                    value={selectedNames.includes(
                                                        e.name
                                                    )}
                                                />
                                            </StyledMenuItem>
                                        ))}
                                </TodoContainer>
                            </ScrollAreaViewPort>
                            <ScrollAreaScrollbar orientation="vertical">
                                <ScrollAreaThumb />
                            </ScrollAreaScrollbar>
                            <ScrollAreaScrollbar orientation="horizontal">
                                <ScrollAreaThumb />
                            </ScrollAreaScrollbar>
                            <ScrollAreaCorner />
                        </ScrollAreaRoot>
                    </div>
                    <div className="flex items-center h-14 border-t border-gray-200 px-6  gap-10">
                        <Button
                            outline
                            className="border-none shadow-none"
                            onClick={() => setIsOpen(false)}
                        >
                            Hủy
                        </Button>
                        <Button
                            className="ml-auto text-md w-24"
                            onClick={handleDone}
                        >
                            Xong
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default SelectPayment

const TempateContainer = styled.div`
    box-shadow: 0px 2px 5px #ccc;
    z-index: 2;
`

const TodoContainer = styled.div`
    padding: 8px 16px;
`

const StyledMenuItem = styled.div`
    user-select: none;
    font-size: 14px;
    color: #222;

    &.selected {
        font-weight: 500;
        color: black;
        background-color: #efefef;
    }
    &:hover {
        background-color: #ededed;
        cursor: default;
    }
`
const TemplateName = styled.div`
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    height: 32px;
    border-left: 3px solid transparent;
    padding-left: 12px;
    width: 182px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    &:hover {
        background-color: #f5f6f7;
        cursor: pointer;
    }
    &.selected {
        border-left: 3px solid var(--blue);
        background-color: var(--light-blue);
        color: var(--blue);
    }
`
