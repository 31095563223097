import { cellBorderColor } from "core/cells/config"
import styled from "styled-components"

export const ORDER_TABLE_HEADER_HEIGHT = 40

const ChecklistTableHeader = () => {
    return (
        <ChecklistTableHeaderWrap
            className="card-shadow"
        >
            <div className="justify-center">&nbsp;</div>
            <div className="justify-start uppercase">Hạng mục</div>
            <div className="uppercase">Ghi chú</div>
            <div>&nbsp;</div>
        </ChecklistTableHeaderWrap>
    )
}

export default ChecklistTableHeader

const ChecklistTableHeaderWrap = styled.div`
    height: ${ORDER_TABLE_HEADER_HEIGHT}px;
    background-color: #f1f1f1;
    position: sticky; 
    top: 54px;
    z-index: 8;
    flex-shrink: 0;
    font-size: 12px;
    display: grid;
    grid-template-columns: 56px  1fr 2fr 40px;
    border-left: 1px solid #e9e9e9;
    border-top: 1px solid #e9e9e9;
    div {
        display: flex;
        align-items: center;
        padding: 8px 8px;
        font-weight: bold;
        border-right: 1px solid ${cellBorderColor};
    }
`
