import { Page } from "core/styledComponents"
import TabsProvider from "core/tabs/TabsProvider"
import AppHeader from "modules/header/AppHeader"
import { useEffect, useState } from "react"
import { getEventDetailFull, useEventDetailFull } from "services/eventService"
import styled from "styled-components"
import EventDetailLeft from "./EventDetailLeft/EventDetailLeft"
import EventDetailRight from "./EventDetailRight/EventDetailRight"

import SavePanel from "./SavePanel/SavePanel"
import { useSnapshot } from "valtio"
import { eventDetailStore } from "stores/eventDetailStore"
import { dialogStore } from "stores/dialogStore"
import { extractEventId } from "services/utils"
import { EventItem } from "models/events/event"
import { getDraft } from "services/draftService"
import { eventMenuStore } from "stores/eventMenuStore"
import { todoStore } from "stores/todoStore"
import { paymentStore } from "stores/paymentStore"
import { attachmentStore } from "stores/attachmentStore"
import { useParams } from "react-router"

const EventDetail = () => {
    let { eventId } = useParams()
    const [loading, setLoading] = useState(false)
    const { currentEvent } = useSnapshot(eventDetailStore)
    const { showEventPanel } = useSnapshot(dialogStore)
    useEffect(() => {
        document.title = currentEvent.title
    }, [currentEvent])

    useEffect(() => {
        if (eventId) {
            ;(async () => {
                setLoading(true)
                var ev = await getEventDetailFull(Number(eventId))

                var eventItem = new EventItem(ev)

                var draft = await getDraft(ev.id)
                if (draft) {
                    eventDetailStore.initEventDraft(draft)
                    eventDetailStore.currentEvent = eventItem
                } else {
                    eventDetailStore.initEventBasicInfo(eventItem)
                }

                eventMenuStore.initMenusAndOrderNames(
                    (draft?.menus || eventItem.menus || []).map((e) => ({
                        ...e,
                    })),
                    draft?.orderNames || ev.orderNames || []
                )
                //init todos
                todoStore.initTodosAndChecklists(
                    (draft?.todos || eventItem.todos || []).map((e) => ({
                        ...e,
                    })),
                    ev.checklistNames || []
                )
                //init payments
                paymentStore.initEventPaymentsAndSections(
                    (draft?.payments || eventItem.payments || []).map((e) => ({
                        ...e,
                        free: !!e.free,
                    }))
                )
                //init attachments
                attachmentStore.attachments = (eventItem.attachments || []).map(
                    (e) => ({
                        ...e,
                    })
                )

                eventDetailStore.isFetchedEvent = true
                setLoading(false)
            })()
        }
        return () => {
            eventDetailStore.isFetchedEvent = false
        }
    }, [eventId])

    // useEffect(() => {
    //     eventDetailStore.refreshCount++
    //     console.log("refresh count: " + eventDetailStore.refreshCount)
    // }, [eventId])

    return (
        <TabsProvider>
            <Page>
                <AppHeader />
                <div className="flex items-stretch">
                    <Left>
                        <EventDetailLeft />
                    </Left>
                    <Right>
                        <EventDetailRight />
                    </Right>
                    {/* đảm bảo event đã đc load rồi thì mới render SavePanel */}
                    {!loading && <SavePanel />}
                </div>
            </Page>
        </TabsProvider>
    )
}

export default EventDetail

const Left = styled.div`
    width: 300px;
    height: calc(100vh - 54px);
    background-color: #fff;
    box-shadow: 0 1px 5px #ccc;
    z-index: 10;
    @media (max-width: 1200px) {
        width: 250px;
    }
`

const Right = styled.div`
    width: calc(100vw - 300px);
    height: calc(100vh - 54px);
    background-color: #f4f6f8;
    overflow-y: auto;
    @media (max-width: 1200px) {
        width: calc(100vw - 250px);
    }
`
