import {
    FloatingOverlay,
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import { useState } from "react"
import styled from "styled-components"
import classNames from "classnames"
import { useClickOutside } from "core/hooks"
import IconCaretDown from "common/icons/IconCaretDown"

type Props = {
    value: string
    onSelect: (value: string) => void
}

const MonthPicker = ({ value, onSelect }: Props) => {
    const [showDropdown, setShowDropdown] = useState(false)

    const { refs, floatingStyles, update } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "bottom-end",
                    "top-start",
                    "top-end",
                ],
            }),
            offset(10),
        ],
    })
    const handleSelectMonth = (d: Date) => {
        onSelect(`${d.getMonth() + 1}.${d.getFullYear()}`)
    }
    const handleClickDropdown = () => { }

    const handleClickOutside = () => {
        setShowDropdown(false)
    }
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)
    var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    return (
        <FilterWrap
            ref={refs.setReference}
            onClick={() => setShowDropdown((s) => !s)}
            className={classNames(
                "inline-flex select-none whitespace-nowrap cursor-pointer items-center px-3.5 py-1 rounded-[3px] font-semibold text-sm text-link",
            )}
        >
            Tháng {value}
            <IconCaretDown className="text-[8px]  ml-2 text-[#768893]" />
            {showDropdown && (
                <FloatingPortal>
                    <DropdownMenu
                        className="shadow-menu rounded-lg py-2 z-[99]"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        onClick={handleClickDropdown}
                    >
                        <div className="text-center py-3 font-semibold">2023</div>
                        <MonthWrap className="grid text-sm">
                            {
                                arr.map(e => (
                                    <div key={e}
                                        onClick={() => onSelect(`${e}.2023`)}
                                    >Tháng {e}</div>
                                ))
                            }

                        </MonthWrap>

                    </DropdownMenu>
                </FloatingPortal>
            )}
        </FilterWrap>
    )
}

export default MonthPicker

const FilterWrap = styled.div`
    &.active {
        background-color: #eef8ff;
    }
`

const DropdownMenu = styled.div`
    width: 400px;
    padding: 8px;
    background-color: #fff;
    div {
        font-size: 14px;
    }

   
`
const MonthWrap = styled.div`
    grid-template-columns: 1fr 1fr 1fr;
    div {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        &:hover {
            background-color: #eee;
        }

    }
`
