import { proxy } from "valtio"
import { ResultItem } from "../models/types"
import {
    getRecentItems,
    saveRecentItems,
} from "../services/globalSearchService"

export const globalSearchStore = proxy({
    recentItems: <ResultItem[]>[],
    async loadRecentItems() {
        const items = await getRecentItems()
        this.recentItems = items
    },
    async addItem(item: ResultItem) {
        if (!this.recentItems.length) {
            await this.loadRecentItems()
        }

        //thay thế item cũ nếu có
        this.recentItems = this.recentItems.filter((e) => e.id != item.id)
        this.recentItems.push(item)
        //lưu lại
        saveRecentItems(this.recentItems)
    },
    async removeItem(id: number) {
        let oldLength = this.recentItems.length
        if (!oldLength) {
            await this.loadRecentItems()
        }
        //thay thế item cũ nếu có
        this.recentItems = this.recentItems.filter((e) => e.id != id)
        if (this.recentItems.length != oldLength) {
            saveRecentItems([...this.recentItems])
        }
    },
})
