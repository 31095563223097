import { styled } from "styled-components"
import {
    useFloating,
    autoPlacement,
    offset,
    FloatingPortal,
} from "@floating-ui/react"
import { useClickOutside } from "core/hooks"
import classNames from "classnames"
import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { extractOrderName, parseMealTableSetup, parseMealTableSetupWithDefault } from "utils/TableSetupUtils"
import { useSnapshot } from "valtio"
import SelectTablePopup from "./SelectTablePopup/SelectTablePopup"

const TableEstimateSimple = () => {
    const { tableSetup } = useSnapshot(eventDetailStore)
    const mealTableSetups = parseMealTableSetupWithDefault(tableSetup)
    const { orderNames } = useSnapshot(eventMenuStore)

    const [isOpen, setIsOpen] = useState(false)

    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [autoPlacement(), offset(40)],
    })

    const handleClickOutside = () => {
        setIsOpen(false)
    }
    useClickOutside(handleClickOutside, refs.floating, refs.domReference)
    return (
        <>
            {orderNames.length > 0 &&
                orderNames.length === mealTableSetups.length && (
                    <Wrap className="pl-2">
                        {mealTableSetups.map((e, index) => (
                            <div className="mb-5" key={index}>
                                <div className="text-[#77889A] pl-2 font-medium">
                                    {extractOrderName(orderNames[index]) ||
                                        "Mặc định"}
                                </div>
                                <div className="mt-1">
                                    <Div>
                                        <SelectTablePopup index={index} />
                                    </Div>
                                </div>
                            </div>
                        ))}
                    </Wrap>
                )}
        </>
    )
}

export default TableEstimateSimple

const Wrap = styled.div`
    border-left: 3px solid #eee;
    padding-left: 20px;
`
const Div = styled.div`
    border-radius: 3px;
    padding: 4px 16px;
    height: 38px;
    display: flex;
    align-items: center;
    border: 1px solid var(--input-border);
    background-color: var(--input-bg-color);
   
`
