import { nanoid } from "nanoid"
import { useEffect } from "react"
import { rootStore } from "stores/rootStore"
import { useSnapshot } from "valtio"
import API from "./api"

import { eventDetailStore } from "stores/eventDetailStore"
import { paymentStore } from "stores/paymentStore"
import {
    calculatePaymentChanges,
    calculatePaymentNameChanges,
} from "utils/PaymentUtils"

export const usePaymentChangeCount = () => {
    const { currentEvent, isFetchedEvent } = useSnapshot(eventDetailStore)
    const { editingPayments } = useSnapshot(paymentStore)
    if (!isFetchedEvent) return 0

    const paymentChanges = calculatePaymentChanges(
        currentEvent.payments,
        editingPayments
    )

    return paymentChanges.changeCount
}

export const usePaymentNameChangeCount = () => {
    const { currentEvent, isFetchedEvent } = useSnapshot(eventDetailStore)
    const { paymentNames: currentSectionNames } = useSnapshot(paymentStore)
    if (!isFetchedEvent) return 0

    const nameChanges = calculatePaymentNameChanges(
        [...currentEvent.paymentNames],
        [...currentSectionNames]
    )

    return nameChanges.changeCount
}

const usePayments = () => {
    const { paymentItems } = useSnapshot(rootStore)
    useEffect(() => {}, [])
}

const getAllPayments = async (): Promise<PaymentItem[]> => {
    var res = await API.get("/payment-items")
    return res.data.data.map((e: any) => ({ ...e, key: nanoid() }))
}

const PaymentService = {}

export default PaymentService
