import { useEffect, useState } from "react"
import { Input } from "core/styledComponents"
import { useSnapshot } from "valtio"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import { eventMenuStore } from "stores/eventMenuStore"
import CloseButton from "core/button/CloseButton"
import { parseOrderName } from "services/orderUtils"
import { saveDraft } from "services/draftService"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DialogRenameOrder = ({ isOpen, setIsOpen }: Props) => {
    const { currentOrderName } = useSnapshot(eventMenuStore)

    const [name, setName] = useState("")
    useEffect(() => {
        setName(parseOrderName(currentOrderName).name)
    }, [currentOrderName])

    const handleRename = async () => {
        eventMenuStore.renameOrder(name);
        saveDraft()
        setIsOpen(false)
    }
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleRename()
        }
    }

    return (
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[25vh] bg-white">
                <div className=" pt-6 pb-6 px-14 flex-col flex items-center relative">
                    <CloseButton
                        className="absolute top-0 right-0 mt-3 mr-3 text-gray-500"
                        onClick={() => setIsOpen(false)}
                    />
                    <div className="text-base font-semibold text-primary mb-5 text-center">
                        Đổi tên thực đơn
                    </div>
                    <Input
                        className="text-md"
                        placeholder={`Ví dụ: "Bữa trưa"`}
                        value={name}
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        onClick={handleRename}
                        className="mt-4  w-full "
                        disabled={!name || !name.trim()}
                    >
                        Xong
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default DialogRenameOrder
