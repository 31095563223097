import { useEffect, useState } from "react"
import { Input } from "core/styledComponents"
import { useSnapshot } from "valtio"
import { Button } from "core/button/Button"
import { todoStore } from "stores/todoStore"
import Dialog from "core/dialog/Dialog"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DialogRenameChecklist = ({ isOpen, setIsOpen }: Props) => {
    const { checklistNames, currentChecklist } = useSnapshot(todoStore)
    const [name, setName] = useState("")
    useEffect(() => {
        setName(currentChecklist)
    }, [currentChecklist])

    const handleRename = async () => {
        todoStore.editingTodos = todoStore.editingTodos.map((e) => {
            if (e.checklist === currentChecklist) {
                e.checklist = name
            }
            return e
        })

        var indexOfOldChecklist = todoStore.checklistNames.indexOf(
            todoStore.currentChecklist
        )
        todoStore.currentChecklist = name
        if (indexOfOldChecklist != -1) {
            todoStore.checklistNames[indexOfOldChecklist] = name
        }
        setIsOpen(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleRename()
        }
    }

    return (
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[25vh] bg-white">
                <div className=" pt-6 pb-6 px-14 flex-col flex items-center relative">
                    <span
                        className="icon-close text-[10px] absolute top-0 right-0 mt-4 mr-4 text-gray-500"
                        onClick={() => setIsOpen(false)}
                    />
                    <div className="text-base font-semibold text-primary mb-5 text-center">
                        Đổi tên check list
                    </div>
                    <Input
                        className="text-md"
                        placeholder="Tên check list"
                        value={name}
                        autoFocus={true}
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Button
                        onClick={handleRename}
                        className="mt-4  w-full "
                        disabled={
                            !name ||
                            !name.trim() ||
                            checklistNames.includes(name.trim())
                        }
                    >
                        Xong
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default DialogRenameChecklist
