import classNames from "classnames"
import { Page } from "core/styledComponents"
import {
    addMonths,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isSameDay,
    isSameMonth,
    isToday,
    startOfMonth,
} from "date-fns"
import { EventItem, EventLite } from "models/events/event"
import EventDetailPanel from "modules/EventDetail/EventDetailPanel/EventDetailPanel"
import AppHeader from "modules/header/AppHeader"
import { useCallback, useEffect, useState } from "react"
import EventService from "services/eventService"
import {
    getMondayInThePast,
    getSundayInFuture,
    parseDateFromNumber,
    parseTimelines,
} from "services/utils"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import EventCard from "./EventCard"
import IconChevronLeft from "common/icons/IconChevronLeft"
import IconChevronRight from "common/icons/IconChevronRight"

type EventGroup = {
    events: EventLite[]
    date: Date
}

const EventsMonthView = () => {
    const { eventTypes } = useSnapshot(rootStore)
    const [currentMonth, setCurrentMonth] = useState(new Date())

    let monday = getMondayInThePast(startOfMonth(currentMonth))
    var dayEnd = getSundayInFuture(endOfMonth(currentMonth))
    const [eventLites, setEventLites] = useState<EventLite[]>([])

    useEffect(() => {
        ;(async () => {
            var res = await EventService.getEventLiteByMonth({
                month: currentMonth.getMonth() + 1,
                year: currentMonth.getFullYear(),
            })
            setEventLites(res)
        })()
    }, [currentMonth])

    const prevMonth = useCallback(() => {
        var prevMonth = addMonths(currentMonth, -1)
        setCurrentMonth(prevMonth)
    }, [currentMonth])
    const nextMonth = useCallback(() => {
        var nextMonth = addMonths(currentMonth, 1)
        setCurrentMonth(nextMonth)
    }, [currentMonth])

    let days = eachDayOfInterval({
        start: monday,
        end: dayEnd,
    })

    return (
        <>
            <Page>
                <AppHeader />
                <div>
                    <div className="flex items-center justify-center my-5 text-xl font-bold">
                        <IconChevronLeft
                            width={24}
                            height={24}
                            onClick={prevMonth}
                            className="mr-8  cursor-pointer  text-gray-300 hover:text-link"
                        />

                        <div className=" w-[100px] flex items-center">
                            Tháng {currentMonth.getMonth() + 1}
                        </div>
                        <div className="text-[#cdcdcd]">
                            {currentMonth.getFullYear()}
                        </div>
                        <IconChevronRight
                            width={24}
                            height={24}
                            onClick={nextMonth}
                            className="ml-8 cursor-pointer  text-gray-300 hover:text-link"
                        />
                    </div>
                    <div>
                        <WeekdayHeader className="shadow-bottom relative grid grid-cols-7 h-8 items-stretch text-13 font-semibold leading-6 text-center text-[#77889A]">
                            <div>THỨ 2</div>
                            <div>THỨ 3</div>
                            <div>THỨ 4</div>
                            <div>THỨ 5</div>
                            <div>THỨ 6</div>
                            <div>THỨ 7</div>
                            <div>CHỦ NHẬT</div>
                        </WeekdayHeader>
                        <MonthViewBody className="grid grid-cols-7 ">
                            {days.map((day, dayIdx) => {
                                return (
                                    <div
                                        key={day.toString()}
                                        className={classNames(
                                            dayIdx === 0 &&
                                                colStartClasses[
                                                    getDay(day) - 1
                                                ],
                                            "py-1",
                                            "day-item relative",
                                            !isSameMonth(day, currentMonth) &&
                                                "bg-[#f5f7f9]"
                                        )}
                                    >
                                        <span
                                            className={classNames(
                                                " font-medium",
                                                isSameMonth(
                                                    day,
                                                    currentMonth
                                                ) &&
                                                    !isToday(day) &&
                                                    "text-secondary",
                                                !isSameMonth(
                                                    day,
                                                    currentMonth
                                                ) && "text-[#C2C2C2]",
                                                isToday(day) &&
                                                    "bg-blue text-white font-semibold",
                                                "ml-3 text-sm flex h-6 my-1 w-6 cursor-default items-center justify-center rounded-full"
                                            )}
                                        >
                                            <time
                                                dateTime={format(
                                                    day,
                                                    "yyyy-MM-dd"
                                                )}
                                            >
                                                {format(day, "d")}
                                            </time>
                                        </span>
                                        <div>
                                            {eventLites
                                                .filter((e) =>
                                                    isSameDay(
                                                        parseDateFromNumber(
                                                            e.startTime
                                                        ),
                                                        day
                                                    )
                                                )
                                                .map((e) => (
                                                    <EventCard
                                                        key={e.id}
                                                        color={
                                                            eventTypes.find(
                                                                (c) =>
                                                                    c.id ===
                                                                    parseTimelines(
                                                                        e.timeline,
                                                                        e.id
                                                                    )?.[0]
                                                                        .typeId
                                                            )?.color || "#fff"
                                                        }
                                                        eventLite={e}
                                                    />
                                                ))}
                                        </div>
                                        {/* <AddIcon className="add flex items-center justify-center w-4 h-4 rounded-full bg-[#2379EA]">
                                            <span className="icon-plus text-[8px] text-white" />
                                        </AddIcon> */}
                                    </div>
                                )
                            })}
                        </MonthViewBody>
                    </div>
                </div>
            </Page>
            <EventDetailPanel />
        </>
    )
}

export default EventsMonthView

const WeekdayHeader = styled.div`
    border-right: 1px solid #e7e7e7;
    div {
        border-left: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        display: flex;
        align-items: center;
        padding-left: 12px;
        font-weight: 600;
    }
`

const AddIcon = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0;
`

const MonthViewBody = styled.div`
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    div.day-item {
        border-left: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        min-height: 120px;
        overflow: hidden;
        &.is-selected {
            border: 1px solid red;
        }
        &:hover {
            .add {
                opacity: 1;
                transition: opacity 0.25s ease;
            }
        }
    }
`
let colStartClasses = [
    "",
    "col-start-2",
    "col-start-3",
    "col-start-4",
    "col-start-5",
    "col-start-6",
    "col-start-7",
]
