import styled from "styled-components"

export const ORDER_TABLE_HEADER_HEIGHT = 32

const OrderTableHeader = () => {
    return (
        <OrderTableHeaderWrap
            className="shadow-bottom"
        >
            <div className="justify-center">STT</div>
            <div className="justify-center">TÊN MÓN</div>
            <div className="justify-center">ĐỊNH LƯỢNG</div>
            <div className="justify-center">SIZE</div>
            <div className="justify-center">GHI CHÚ</div>
            <div className="justify-center">ĐƠN GIÁ</div>
            <div>&nbsp;</div>

        </OrderTableHeaderWrap>
    )
}

export default OrderTableHeader

const OrderTableHeaderWrap = styled.div`
    height: ${ORDER_TABLE_HEADER_HEIGHT}px;
    background-color: #f5f5f5;
    position: relative;
    position: sticky;
    top: 54px;

    border: 1px solid #E9E9E9;
    border-bottom: none;
    flex-shrink: 0;
    z-index: 8;
    /* padding-right: 16px; */
    font-size: 12px;
    font-weight: bold;
    display: grid;
    grid-template-columns: 36px 5fr 5fr 72px 5fr 120px 40px;
    div {
        display: flex;
        align-items: center;
        color: #343434;
        padding: 8px 8px;
        border-right: 1px solid #eaeaea;
        &:last-child {
            border-right: none;
        }
    }
`
