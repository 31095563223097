import { Button } from "core/button/Button"
import DatePicker from "core/datepicker/DatePicker"
import Tab from "core/tabs/Tab"
import TabHeaders from "core/tabs/TabHeaders"
import TabPanel from "core/tabs/TabPanel"
import { useCallback, useContext, useEffect, useState } from "react"
import { styled } from "styled-components"
import ScheduleDetail from "../../ScheduleDetail"
import TimePicker from "modules/timeline_tab/TimePicker"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import { usePlaces } from "services/placeService"
import { useEventTypes } from "services/eventTypeService"
import { EventItem } from "models/events/event"
import EventService from "services/eventService"
import { tabsContext } from "core/tabs/tabContext"
import ScheduleChangeCount from "./TimelineChangeCount/ScheduleChangeCount"

const EventTimeline = ({ eventId }: { eventId: number }) => {
    const tabGroupName = "event_schedule"
    const { selectedDate } = useSnapshot(eventDetailStore)
    const places = usePlaces()
    const evenTypes = useEventTypes()
    const [otherEvents, setOtherEvents] = useState<EventItem[]>([])

    const { activeTabs } = useContext(tabsContext)
    const activeIndex =
        activeTabs.find((e) => e.name === tabGroupName)?.index || -1

    const onSelectDate = useCallback((d: Date) => {
        eventDetailStore.selectedDate = new Date(d.getTime())
    }, [])

    useEffect(() => {
        ;(async () => {
            var res = await EventService.getEventsByStartDate(selectedDate)
            setOtherEvents(res.filter((e) => e.id !== eventId))
        })()
    }, [selectedDate, eventId])

    return (
        <div className="card-shadow px-5 py-5 rounded-md bg-white mb-4">
            <div className="flex items-center">
                <div className="text-base font-semibold">Lịch sự kiện <ScheduleChangeCount /></div>
            </div>
            <div className="mt-5 flex items-center">
                <div className="w-20 text-sm">Ngày:</div>
                <DatePicker
                    selectedDate={selectedDate}
                    onSelect={onSelectDate}
                />
                <div className="text-sm text-[#818181] ml-20">
                    ({selectedDate.getLunarDate(true)} Âm lịch)
                </div>
            </div>
            <TabHeaders className="flex items-center mt-5" group={tabGroupName}>
                <TabItem index={0}>Lịch chi tiết</TabItem>
                <TabItem index={1}>Timeline</TabItem>
            </TabHeaders>
            <hr />
            <TabPanel index={0} group={tabGroupName}>
                <div className="max-w-3xl">
                    {/* <Row className="header text-13 text-secondary font-medium pt-4 pb-3">
                        <div className="pl-8">Sảnh</div>
                        <div>Bắt đầu</div>
                        <div>Kết thúc</div>
                        <div>Loại hình</div>
                    </Row> */}
                    <ScheduleDetail />
                </div>
            </TabPanel>
            <TabPanel index={1} group={tabGroupName}>
                {activeIndex == 1 && places.length && evenTypes.length && (
                    <TimePicker
                        eventTypes={evenTypes}
                        places={places}
                        otherEvents={otherEvents}
                    />
                )}
            </TabPanel>
        </div>
    )
}

export default EventTimeline

const Row = styled.div`
    display: grid;
    grid-template-columns: 5fr 3fr 3fr 5fr;
    .header {
        div {
            font-size: 13px;
            color: var(--text-secondary);
        }
    }
`

const TabItem = styled(Tab)`
    display: flex;
    justify-content: center;
    border-bottom: 3px solid transparent;
    padding: 12px 8px;
    font-size: 14px;
    margin: 0 16px;
    cursor: pointer;
    font-weight: 500;
    &.active {
        border-bottom: 3px solid var(--link);
        color: var(--link);
    }
`
