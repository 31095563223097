import classNames from "classnames"
import IconCaretDown from "common/icons/IconCaretDown"
import MultiSelect from "core/select/MultiSelect"
import { useEffect, useMemo, useState } from "react"
import { rootStore } from "stores/rootStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"

type Props = {
    customerValues: readonly string[]
    onChange: (s: string[]) => void
}

const CustomerFilter = ({ customerValues, onChange }: Props) => {
    const { customers } = useSnapshot(rootStore)
    const [selectedItems, setSelectedItems] = useState<string[]>([
        ...customerValues,
    ])
    const handleChange = (newValues: string[]) => {
        setSelectedItems(newValues)
    }

    const renderOption = ({
        label,
        value,
        addr,
    }: {
        label: string
        value: number
        addr: string
    }) => {
        return (
            <div className="text-sm">
                <div>{label}</div>
                <div className="text-[#aaa]">{addr}</div>
            </div>
        )
    }

    useEffect(() => {
        setSelectedItems([...customerValues])
    }, [customerValues])

    const options = useMemo(
        () =>
            customers
                .map((e) => ({ label: e.name, value: e.id, addr: e.addr }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [customers]
    )

    const handleOpenChange = (boolValue: boolean) => {
        if (!boolValue) {
            onChange(selectedItems)
        }
    }

    const handleClear = () => {
        setSelectedItems([])
    }

    const renderFooter = () => {
        return (
            <Footer className="px-5 flex items-center justify-between py-3">
                <div
                    onClick={handleClear}
                    className={classNames(
                        "text-13 font-medium hover:underline cursor-pointer",
                        {
                            "text-link": selectedItems.length,
                            "text-[#ccc] pointer-events-none":
                                !selectedItems.length,
                        }
                    )}
                >
                    Xóa lựa chọn{" "}
                    {selectedItems.length ? `(${selectedItems.length})` : ``}
                </div>
            </Footer>
        )
    }

    return (
        <Wrap
            className={classNames("inline-flex ", {
                active: customerValues.length > 0,
            })}
        >
            <MultiSelect
                className="px-3 py-1 flex items-center"
                values={selectedItems}
                searchable
                groupSelectedItems
                footer={renderFooter()}
                placeholder="Tìm kiếm khách hàng"
                options={options}
                onOpenChange={handleOpenChange}
                onChange={handleChange}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value.toString()}
                renderOption={renderOption}
            >
                <span className="text-sm font-semibold text-link">
                    Khách hàng
                </span>
                <IconCaretDown
                    className={classNames("text-[8px] text-[#868686] ml-2")}
                />
            </MultiSelect>
        </Wrap>
    )
}

export default CustomerFilter

const Wrap = styled.div`
    border-radius: 3px;
    &.active {
        background-color: #eef8ff;
    }

    .menu-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }
`
const Footer = styled.div`
    border-top: 1px solid #e7e7e7;
`
