import { EventItem } from "models/events/event"
import { useEffect, useRef, useState } from "react"
import EventService from "services/eventService"
import { eventFilterStore } from "stores/eventFilterStore"
import { useSnapshot } from "valtio"
import { useDebounce } from "use-debounce"
import EventTableBody from "./EventTableBody"
import { styled } from "styled-components"
import { rootStore } from "stores/rootStore"
import { Input } from "core/styledComponents"
import Paginator from "core/pagination/Paginator"
import IconSearch from "common/icons/IconSearch"

const EventTable = () => {
    const {
        dateValue,
        dateCreated,
        statusValues,
        customerValues,
        eventTypeValues,
        placeValues,
        sortBy,
        sortDir,
        itemCount,
        views,
        refreshCount,
    } = useSnapshot(eventFilterStore)
    const [events, setEvents] = useState<EventItem[]>([])

    const { places, eventTypes } = useSnapshot(rootStore)
    const [inputValue, setInputValue] = useState("")
    const [searchTerm] = useDebounce(inputValue, 500)
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        if (!views.length) return
        ;(async () => {
            setOffset(0)
            const response = await EventService.queryEvent({
                customers: [...customerValues],
                date: dateValue,
                dateCreated: dateCreated,
                eventTypes: [...eventTypeValues],
                statuses: [...statusValues],
                places: [...placeValues],
                sortBy,
                sortDir,
                limit,
                offset: 0,
                searchTerm,
            })
            setEvents(response.data.map((e) => new EventItem(e)))
            eventFilterStore.itemCount = response.count
        })()
    }, [
        dateValue,
        dateCreated,
        statusValues.join(","),
        customerValues.join(","),
        eventTypeValues.join(","),
        placeValues.join(","),
        sortBy,
        sortDir,
        searchTerm,
        views.length,
        refreshCount,
    ])

    const onPrevClick = async () => {
        let nextOffset = Math.max(0, offset - limit)
        setOffset(nextOffset)
        const response = await EventService.queryEvent({
            customers: [...customerValues],
            date: dateValue,
            dateCreated: dateCreated,
            eventTypes: [...eventTypeValues],
            statuses: [...statusValues],
            places: [...placeValues],
            sortBy,
            sortDir,
            limit,
            offset: nextOffset,
            searchTerm,
        })
        setEvents(response.data.map((e) => new EventItem(e)))
    }

    const onNextClick = async () => {
        let nextOffset = offset + limit
        if (nextOffset < itemCount) {
            setOffset(nextOffset)
            const response = await EventService.queryEvent({
                customers: [...customerValues],
                date: dateValue,
                dateCreated: dateCreated,
                eventTypes: [...eventTypeValues],
                statuses: [...statusValues],
                places: [...placeValues],
                sortBy,
                sortDir,
                limit,
                offset: nextOffset,
                searchTerm,
            })
            setEvents(response.data.map((e) => new EventItem(e)))
        }
    }
    return (
        <div className="px-8">
            <div className="flex mb-2">
                <SearchWrap>
                    <IconSearch className="absolute top-[8px] left-[12px] text-md text-[#ccc]"/>
                    <Input
                        className="text-13 "
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Tìm kiếm sự kiện"
                    />
                </SearchWrap>
                {itemCount > 0 && (
                    <Paginator
                        className="ml-auto"
                        itemCount={itemCount}
                        limit={limit}
                        offset={offset}
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                    />
                )}
            </div>
            <EventTableBody
                events={events}
                places={places}
                eventTypes={eventTypes}
            />
        </div>
    )
}

export default EventTable

const SearchWrap = styled.div`
    position: relative;
    border-radius: 96px;
    input {
        height: 32px;
        border-radius: 99px !important;
        text-indent: 34px;
        width: 200px;
        color: #333;
    }
`

const PaginationWrap = styled.div`
    border: 1px solid #e7e7e7;
    border-radius: 4px;
`
const PaginatorLeft = styled.div`
    width: 36px;
    border-right: 1px solid #ddd;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    background-color: #fff;
    cursor: pointer;
    span {
        color: #bebebe;
    }
    &:hover {
        background-color: #fafafa;
        span {
            color: #aaa;
        }
    }
`
const PaginatorRight = styled.div`
    width: 36px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
        color: #bebebe;
    }
    border-radius: 0 4px 4px 0;
    background-color: #fff;
    &:hover {
        background-color: #fafafa;
        span {
            color: #aaa;
        }
    }
`
