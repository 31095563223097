import classNames from "classnames"
import IconCaretDown from "common/icons/IconCaretDown"
import MultiSelect from "core/select/MultiSelect"
import { EventType } from "models/eventType"
import { useEffect, useState } from "react"
import EventTypeService from "services/eventTypeService"
import { styled } from "styled-components"

type Props = {
    values: readonly string[]
    onChange: (values: string[]) => void
}

const EventTypeFilter = ({ values, onChange }: Props) => {
    const [eventTypes, setEventTypes] = useState<EventType[]>([])
    const [selectedItems, setSelectedItems] = useState<string[]>([...values])
    const handleChange = (newValues: string[]) => {
        setSelectedItems(newValues)
    }
    useEffect(() => {
        ;(async () => {
            const response = await EventTypeService.getAll()
            setEventTypes(response)
        })()
    }, [])

    useEffect(() => {
        setSelectedItems([...values])
    }, [values])

    const handleOpenChange = (boolValue: boolean) => {
        if (!boolValue) {
            onChange(selectedItems)
        }
    }

    const handleClear = () => {
        setSelectedItems([])
    }

    const renderFooter = () => {
        return (
            <Footer className="px-5 flex items-center justify-between py-3">
                <div
                    onClick={handleClear}
                    className={classNames(
                        "text-13 font-medium hover:underline cursor-pointer",
                        {
                            "text-link": selectedItems.length,
                            "text-[#ccc] pointer-events-none":
                                !selectedItems.length,
                        }
                    )}
                >
                    Xóa lựa chọn{" "}
                    {selectedItems.length ? `(${selectedItems.length})` : ``}
                </div>
            </Footer>
        )
    }

    return (
        <Wrap
            className={classNames("inline-flex ", {
                active: values.length > 0,
            })}
        >
            <MultiSelect
                className="px-3 py-1 flex items-center"
                menuHeight={290}
                values={selectedItems}
                options={eventTypes}
                footer={renderFooter()}
                onOpenChange={handleOpenChange}
                onChange={handleChange}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id.toString()}
                // renderNoOption={renderNoOption}
            >
                <span className="text-sm font-semibold text-link">
                    Loại sự kiện
                </span>
                <IconCaretDown
                    className={classNames("text-[8px] text-[#868686] ml-2")}
                />
            </MultiSelect>
        </Wrap>
    )
}

export default EventTypeFilter

const Wrap = styled.div`
    border-radius: 3px;
    &.active {
        background-color: #eef8ff;
    }
`
const Footer = styled.div`
    border-top: 1px solid #e7e7e7;
`
