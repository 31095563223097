import { useState } from "react"
import { useOrderChangeCount } from "services/eventService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import OrderChanges from "../OrderChanges/OrderChanges"

const OrderChangeCount = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { currentEvent } = useSnapshot(eventDetailStore)

    const changeCount = useOrderChangeCount()
    if (currentEvent.id == 0) return null

    return (
        <>
            {changeCount > 0 && (
                <div
                    onClick={() => setIsOpen(true)}
                    className=" text-[orangered] font-normal text-13 ml-3 hover:underline cursor-pointer"
                >
                    +{changeCount}
                </div>
            )}
            {changeCount > 0 && (
                <OrderChanges isOpen={isOpen} setIsOpen={setIsOpen} />
            )}
        </>
    )
}

export default OrderChangeCount
