import { useState } from "react"
import { useChecklistNoteChangeCount } from "services/eventService"
import ChecklistNoteChange from "../ChecklistNoteChange/ChecklistNoteChange"

const ChecklistNoteChangeCount = () => {
    const [isOpen, setIsOpen] = useState(false)
    const changeCount = useChecklistNoteChangeCount()
    return (
        <>
            {changeCount > 0 && (
                <span
                    onClick={() => setIsOpen(true)}
                    className="text-[orangered] text-13 font-normal ml-2 cursor-pointer hover:underline"
                >
                    +1
                </span>
            )}
            <ChecklistNoteChange isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default ChecklistNoteChangeCount
