import { useEffect, useState } from "react"
import { styled, css } from "styled-components"
import ViewService from "./viewService"
import EventFilters from "./EventFilters"
import AddView from "./components/AddView"
import { useSnapshot } from "valtio"
import { eventFilterStore } from "stores/eventFilterStore"
import classNames from "classnames"
import RenameViewDialog from "./components/RenameViewDialog"
import IconClose from "common/icons/IconClose"

const EventViews = () => {
    const { views, currentViewName } = useSnapshot(eventFilterStore)

    useEffect(() => {
        eventFilterStore.initData()
    }, [])
    const [showRenameDialog, setShowRenameDialog] = useState(false)

    return (
        <Wrap>
            <ViewsWrap>
                <PadLeft />
                {views
                    .filter((e) => !e.isHidden)
                    .map((e, index) => (
                        <Tab
                            onClick={() => {
                                if (e.name != currentViewName) {
                                    eventFilterStore.changeCurrentView(e.name)
                                }
                            }}
                            onDoubleClick={() => {
                                if (e.name == currentViewName) {
                                    setShowRenameDialog(true)
                                }
                            }}
                            key={e.name}
                            active={e.name == currentViewName}
                        >
                            {e.name}
                            {views.filter(e => !e.isHidden).length > 1 && (
                                <CloseButton
                                    onClick={() =>
                                        eventFilterStore.toggleViewVisibility(
                                            e.name
                                        )
                                    }
                                    className={classNames(
                                        "close-button h-6 w-6 p-1 rounded-full  items-center justify-center  ml-auto",
                                        { active: e.name == currentViewName }
                                    )}
                                >
                                    <IconClose width={18} height={18}className="text-[#5D7482]" />
                                </CloseButton>
                            )}
                        </Tab>
                    ))}

                <Right>
                    <AddView className="ml-6" />
                </Right>
            </ViewsWrap>
            <RenameViewDialog
                isOpen={showRenameDialog}
                setIsOpen={setShowRenameDialog}
            />
            <EventFilters />
        </Wrap>
    )
}

export default EventViews

const Wrap = styled.div``

const ViewsWrap = styled.div`
    position: relative;
    display: flex;
`
const PadLeft = styled.div`
    width: 50px;
    flex: none;
    flex-shrink: 0;
    border-bottom: 1px solid #e7e7e7;
    height: 48px;
`

const Right = styled.div`
    display: flex;
    align-items: center;
    flex: none;
    flex-shrink: 0;
    width: 320px;
    height: 48px;
    border-bottom: 1px solid #e7e7e7;
`

const Tab = styled.div<{ active?: boolean }>`
    background-color: #f8f8f9;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    ${(props) =>
        props.active &&
        css`
            background-color: #fff;
            border-bottom: none;
            cursor: default;
        `}
    color: #33475b;
    font-size: 14px;
    height: 48px;
    padding: 0 18px;
    flex: 1;
    display: flex;
    align-items: center;
    & + div {
        border-left: none;
    }
    &:hover {
        .close-button {
            display: flex;
        }
    }
`
const CloseButton = styled.div`
    &:hover {
        background-color: #eee;
    }
    display: none;
    &.active {
        display: flex;
    }
`
