import { styled } from "styled-components"
import DateFilter from "./DateFilter"
import { useSnapshot } from "valtio"
import { eventFilterStore } from "stores/eventFilterStore"
import { useCallback, useEffect, useState } from "react"
import CustomerFilter from "./CustomerFilter"
import StatusFilter from "./StatusFilter"
import EventTypeFilter from "./EventTypeFilter"
import { ViewItem } from "models/view"
import SaveViewButton from "./SaveViewButton"
import ResetViewButton from "./ResetViewButton"
import PlaceFilter from "./PlaceFilter"
import DateInThePastFilter from "./DateInThePastFilter"

type Props = {
    viewItem: ViewItem
}
const EventFilters = () => {
    const {
        dateValue,
        dateCreated,
        customerValues,
        eventTypeValues,
        placeValues,
        statusValues,
        canSave,
    } = useSnapshot(eventFilterStore)
    useEffect(() => {}, [])

    const handleChangePlace = useCallback((s: string[]) => {
        eventFilterStore.placeValues = s
    }, [])

    return (
        <FiltersWrap className="flex items-center py-6 pl-14 gap-3">
            <DateFilter
                value={dateValue}
                onSelect={(value: string) => {
                    eventFilterStore.dateValue = value
                }}
            />
            <CustomerFilter
                customerValues={customerValues}
                onChange={(s) => {
                    eventFilterStore.customerValues = s
                }}
            />
            <StatusFilter
                statuses={statusValues}
                onChange={(s) => {
                    eventFilterStore.statusValues = s
                }}
            />
            <PlaceFilter onChange={handleChangePlace} values={placeValues} />
            <EventTypeFilter
                onChange={(s) => {
                    eventFilterStore.eventTypeValues = s
                }}
                values={eventTypeValues}
            />
            <DateInThePastFilter
                label="Ngày tạo"
                value={dateCreated}
                onSelect={(value: string) => {
                    eventFilterStore.dateCreated = value
                }}
            />
            <div className="ml-auto  mr-8">
                {canSave ? (
                    <SaveViewButton
                        canSave={true}
                        warningText={
                            canSave
                                ? `View được tạo bởi bạn và có thể chỉnh sửa, cập nhật.`
                                : `View mặc định, không thể chỉnh sửa, cập nhật`
                        }
                    />
                ) : (
                    <ResetViewButton />
                )}
            </div>
        </FiltersWrap>
    )
}

export default EventFilters

const FiltersWrap = styled.div``
