import classNames from "classnames"
import IconCaretDown from "common/icons/IconCaretDown"
import MultiSelect from "core/select/MultiSelect"
import { useEffect, useMemo, useState } from "react"
import { EventStatus } from "services/constants"
import { styled } from "styled-components"

type Props = {
    statuses: readonly string[]
    onChange: (s: string[]) => void
}

const StatusFilter = ({ statuses, onChange }: Props) => {
    const [selectedItems, setSelectedItems] = useState<string[]>([...statuses])
    const handleChange = (newValues: string[]) => {
        setSelectedItems(newValues)
    }
    const options = useMemo(
        () => [
            {
                label: "Tham khảo",
                value: EventStatus.UNCONFIRM,
            },
            {
                label: "Đã cọc, chưa chốt",
                value: EventStatus.INPROGRESS,
            },
            {
                label: "Đã chốt",
                value: EventStatus.CONFIRMED,
            },
            {
                label: "Đã hủy",
                value: EventStatus.CANCEL,
            },
        ],
        []
    )

    useEffect(() => {
        setSelectedItems([...statuses])
    }, [statuses])

    const handleOpenChange = (boolValue: boolean) => {
        if (!boolValue) {
            onChange(selectedItems)
        }
    }

    const handleClear = () => {
        setSelectedItems([])
    }

    const renderFooter = () => {
        return (
            <Footer className="px-5 flex items-center justify-between py-3">
                <div
                    onClick={handleClear}
                    className={classNames(
                        "text-13 font-medium hover:underline cursor-pointer",
                        {
                            "text-link": selectedItems.length,
                            "text-[#ccc] pointer-events-none":
                                !selectedItems.length,
                        }
                    )}
                >
                    Xóa lựa chọn{" "}
                    {selectedItems.length ? `(${selectedItems.length})` : ``}
                </div>
            </Footer>
        )
    }

    return (
        <Wrap
            className={classNames("inline-flex ", {
                active: statuses.length > 0,
            })}
        >
            <MultiSelect
                className="px-3 py-1 flex items-center"
                values={selectedItems}
                options={options}
                onChange={handleChange}
                onOpenChange={handleOpenChange}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value.toString()}
                footer={renderFooter()}
            >
                <span className="text-sm font-semibold text-link">
                    Trạng thái
                </span>
                <IconCaretDown
                    className={classNames("text-[8px]  text-[#868686] ml-2")}
                />
            </MultiSelect>
        </Wrap>
    )
}

export default StatusFilter

const Wrap = styled.div`
    border-radius: 3px;
    &.active {
        background-color: #eef8ff;
    }
`

const Footer = styled.div`
    border-top: 1px solid #e7e7e7;
`
