import { autoPlacement, autoUpdate, offset, useFloating } from "@floating-ui/react"
import { useCallback, useEffect, useState } from "react"
import { styled } from "styled-components"
import DatePickerInner from "./DatePickerInner"
import { DateCount } from "core/types"
import { Input } from "core/styledComponents"
import { useClickOutside } from "core/hooks"
import IconDate from "common/icons/IconDate"

type Props = {
    selectedDate: Date
    onSelect: (d: Date) => void
    onMonthViewChange?: (d: Date) => void
    dateCounts: DateCount[]
}

const DateInput = ({
    selectedDate,
    onSelect,
    onMonthViewChange,
    dateCounts,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState(selectedDate.formatDMY())

    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "bottom-end",
                    "top-start",
                    "top-end",
                ],
            }),
            offset(10),
        ],
    })

    useEffect(() => {
        setInputValue(selectedDate.formatDMY())
    }, [selectedDate])

    const handleClose = useCallback(() => {
        // setIsOpen(false)
    }, [])
    const openDatePicker = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()
            setIsOpen(true)
        },
        []
    )

    const handleSelect = useCallback(
        (d: Date) => {
            setIsOpen(false)
            onSelect(d)
            setInputValue(d.formatDMY())
        },
        [onSelect]
    )

    const handleBlur = () => {
        if (isValidDate(inputValue)) {
            var [d, m, y] = inputValue.split("/")
            var date = new Date(+y, Number(m) - 1, +d)
            onSelect(date)
        } else {
            setInputValue(selectedDate.formatDMY())
        }
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var newValue = e.target.value
        setInputValue(newValue)
    }

    const isValidDate = useCallback((dateString: string) => {
        var isValid = true
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
            isValid = false
        } else {
            var [d, m, y] = dateString.split("/")
            var date = new Date(+y, Number(m) - 1, +d)
            if (
                date.getDate() !== Number(d) ||
                date.getMonth() !== Number(m) - 1 ||
                date.getFullYear() !== Number(y)
            ) {
                isValid = false
            }
        }

        return isValid
    }, [])

    const handleViewMonthChange = useCallback((d: Date) => {
        onMonthViewChange && onMonthViewChange(d)
    }, [])

    const handleClickOutside = useCallback(() => {
        setIsOpen(false)
    }, [])
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)

    return (
        <>
            <Wrapper
                className="inline-flex items-center relative"
                ref={refs.setReference}
                onClick={openDatePicker}
            >
                <IconDate className="icon-date" />
                <Input
                    onMouseDown={(e) => e.stopPropagation()}
                    placeholder="__ / __ / __"
                    value={inputValue}
                    onChange={onInputChange}
                    onBlur={handleBlur}
                />
            </Wrapper>
            {isOpen && (
                <div
                    className="shadow-menu z-10 bg-white rounded-[3px] p-3"
                    onClick={(e) => e.stopPropagation()}
                    ref={refs.setFloating}
                    style={floatingStyles}
                >
                    <DatePickerInner
                        selectedDate={selectedDate}
                        onSelect={handleSelect}
                        dateCounts={dateCounts}
                        onViewMonthChange={handleViewMonthChange}
                    />
                </div>
            )}
        </>
    )
}

export default DateInput

const Wrapper = styled.div`
    .icon-date {
        position: absolute;
        left: 14px;
        top: 12px;
    }

    input {
        text-indent: 36px;
    }
    width: 100%;
`
