import { isToday } from "date-fns"
import { EventActivityItem } from "models/eventActivity"
import { useEffect, useState } from "react"
import { useEventActivity } from "services/activityService"
import { ActivityType } from "services/constants"
import styled from "styled-components"
import ActivityDetail from "./ActivityDetail"
import { useSnapshot } from "valtio"
import { rootStore } from "stores/rootStore"

type ActivityGroup = {
    activities: EventActivityItem[]
    date: Date
}

const EventActivity = ({ eventId }: { eventId: number }) => {
    const { loading, eventActivities } = useEventActivity(eventId)
    const { places, eventTypes } = useSnapshot(rootStore)

    const [activityGroups, setActivityGroups] = useState<ActivityGroup[]>([])
    useEffect(() => {
     
        const times = eventActivities.map((e) => e.updatedAt)
        times.sort((a, b) => b - a)
        var groups: ActivityGroup[] = [
            ...new Set(
                eventActivities.map((e) => new Date(e.updatedAt).formatDMY())
            ),
        ].map((e) => {
            var activities = eventActivities.filter(
                (activity) => new Date(activity.updatedAt).formatDMY() === e
            )
            var [d, m, y] = e.split("/")
           
            return {
                activities,
                date: new Date(+y, Number(m) - 1, +d),
            }
        })
        setActivityGroups(groups)
    }, [eventActivities])
    return (
        <Wrap className="px-10 pb-10 pt-2">
            {activityGroups.map((g, index) => (
                <div key={index} className=" mt-10 mb-3 ">
                    <div className="text-sm  ml-5 mb-3 pt-3 font-semibold ">
                        {isToday(g.date) ? "Hôm nay" : g.date.formatDMY()}
                    </div>
                    <div className="flex flex-col gap-2 card-shadow rounded bg-white ">
                        {g.activities.map((activity) => (
                            <ActivityDetail
                                places={[...places]}
                                eventTypes={[...eventTypes]}
                                activity={activity}
                                key={activity.id}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </Wrap>
    )
}

export default EventActivity

const Wrap = styled.div``
