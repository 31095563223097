import EventMenu from "models/events/eventMenu"
import { getPureOrderName } from "services/utils"

export type OrderChangeItem = {
    orderName: string
    updatedItems: string[]
    addedItems: string[]
    removedItems: string[]
}

export const calculateEventMenusChangeCount = (
    oldItems: readonly EventMenu[],
    newItems: readonly EventMenu[]
) => {
    var changes = calculateEventMenusChanges(oldItems, newItems)
    if (!changes.changed) {
        return 0
    }

    return changes.changes.reduce(
        (acc, cur) =>
            acc +
            cur.addedItems.length +
            cur.updatedItems.length +
            cur.removedItems.length,
        0
    )
}
export const calculateEventMenusChanges = (
    oldItems: readonly EventMenu[],
    newItems: readonly EventMenu[]
) => {
    var orders: {
        [key: string]: {
            updatedItems: string[]
            addedItems: string[]
            removedItems: string[]
        }
    } = {}

    const _oldItems = oldItems.filter((e) => e.menu)
    const _newItems = newItems.filter((e) => e.menu)

    //tất cả order đều đc đẩy lên
    let changed = false
    for (var newItem of _newItems) {
        let orderName = newItem.orderName
        if (!orders[orderName]) {
            orders[orderName] = {
                updatedItems: [],
                addedItems: [],
                removedItems: [],
            }
        }
        let found = oldItems.find(
            (m) => m.menu == newItem.menu && m.orderName === newItem.orderName
        )
        if (found) {
            var isSame =
                found.groupName == newItem.groupName &&
                found.amount == newItem.amount &&
                found.price == newItem.price &&
                found.menu == newItem.menu &&
                found.size == newItem.size &&
                found.note == newItem.note
            if (!isSame) {
                changed = true
                orders[orderName].updatedItems.push(newItem.menu)
            }
        } else {
            changed = true
            orders[orderName].addedItems.push(newItem.menu)
        }
    }

    //tìm những món bị bỏ
    for (var oldItem of _oldItems) {
        let orderName = oldItem.orderName as string
        if (!orders[orderName]) {
            orders[orderName] = {
                updatedItems: [],
                addedItems: [],
                removedItems: [],
            }
        }
        let found = newItems.find(
            (m) => m.menu == oldItem.menu && m.orderName === oldItem.orderName
        )
        if (!found) {
            changed = true
            orders[orderName].removedItems.push(oldItem.menu)
        }
    }
    const changes: OrderChangeItem[] = []
    let orderNamesInCurrentItems = [...new Set(newItems.map(e => e.orderName))]
    for (let orderName in orders) {
        //xử lý xóa những orders "bị xóa" bằng cách kiểm tra order đó có mặt trong list items hiện tại ko
        if(orderNamesInCurrentItems.includes(getPureOrderName(orderName))) {
            changes.push({
                orderName,
                addedItems: orders[orderName].addedItems,
                removedItems: orders[orderName].removedItems,
                updatedItems: orders[orderName].updatedItems,
            })
        }
    }
    
    return {
        changed,
        changes,
    }
}

const checkIsOrderChanged = (
    editingMenus: EventMenu[],
    defaultMenus: EventMenu[]
) => {
    if (editingMenus.length != defaultMenus.length) {
        return true
    }
    for (var item of editingMenus) {
        var found = defaultMenus.find(
            (e) =>
                e.orderName === item.orderName &&
                e.amount === item.amount &&
                e.menu === item.menu &&
                e.price === item.price &&
                e.note === item.note &&
                e.size === item.size
        )
        if (!found) {
            return true
        }
    }
    return false
}

const OrderUtils = {
    checkIsOrderChanged,
}

export default OrderUtils
