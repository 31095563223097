import { eventMenuStore } from "stores/eventMenuStore"
import styled from "styled-components"
import { useReactToPrint } from "react-to-print"
import { useEffect, useRef, useState } from "react"
import { useSnapshot } from "valtio"
import { eventDetailStore } from "stores/eventDetailStore"
import Dialog from "core/dialog/Dialog"
import { Input, TextArea } from "core/styledComponents"
import { Button } from "core/button/Button"
import SelectPlace from "modules/common/SelectPlace"
import { rootStore } from "stores/rootStore"
import { PlaceItem } from "models/place"
import EventMenu from "models/events/eventMenu"
import TableSetupUtils from "utils/TableSetupUtils"
import IconPrint from "common/icons/IconPrint"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DialogPrintOrder = ({ isOpen, setIsOpen }: Props) => {
    const { places } = useSnapshot(rootStore)
    const [placeId, setPlaceId] = useState(0)
    const [place, setPlace] = useState("")
    const [title, setTitle] = useState("ORDER - TIỆC")
    const [totalPrice, setTotalPrice] = useState("")
    const [price, setPrice] = useState("")

    const { editingMenus, currentOrderName } = useSnapshot(eventMenuStore)
    const { editingAppointments, customerName, selectedDate, tableSetup } =
        useSnapshot(eventDetailStore)

    const [cusName, setCusName] = useState(customerName)
    const [startTime, setStartTime] = useState("")
    
    useEffect(() => {
        setCusName(customerName)
    }, [customerName])

    useEffect(() => {
        var price = editingMenus
            .filter((e) => e.orderName === currentOrderName)
            .reduce((acc, cur) => acc + cur.price, 0)
            .formatCurrency()
        setTotalPrice(price)
    }, [editingMenus])

    useEffect(() => {
        setPrice(`${totalPrice} (Chưa bao gồm VAT và chưa bao gồm đồ uống)`)
    },[totalPrice])

    const printContainerRef = useRef<HTMLDivElement>(null)
    const openPrint = useReactToPrint({
        content: () => printContainerRef.current,
        fonts: [
            {
                family: "Times New Roman",
                source: "/fonts/Times New Roman Bold.ttf",
                style: "normal",
                weight: "bold",
            },
            {
                family: "Times New Roman",
                source: "/fonts/times new roman bold italic.ttf",
                style: "italic",
                weight: "bold",
            },
            {
                family: "Times New Roman",
                source: "/fonts/times new roman italic.ttf.ttf",
                style: "italic",
                weight: "normal",
            },
        ],
    })

    const handleChangePlace = () => {}

    var currentPlaces = editingAppointments
        .map((e) => places.find((p) => p.id === e.placeId))
        .filter((item) => item) as readonly PlaceItem[]

    let _placeId = placeId || currentPlaces[0]?.id

    useEffect(() => {
        let ap = editingAppointments.find((e) => e.placeId === _placeId)
        if (ap) {
            setStartTime(`${ap.hour.padZero()}h:${ap.min.padZero()}`)
        }
    }, [_placeId, editingAppointments])
    let placeName = places.find((e) => e.id === _placeId)?.name || ""

    useEffect(() => {
        setPlace(placeName)
    },[placeName])

    let menus: EventMenu[] = [
        ...editingMenus.filter(
            (e) => e.groupName && e.groupName.toLowerCase().includes("khai vị")
        ),
        ...editingMenus.filter(
            (e) =>
                e.groupName && e.groupName.toLowerCase().includes("món chính")
        ),
        ...editingMenus.filter(
            (e) =>
                e.groupName && e.groupName.toLowerCase().includes("tráng miệng")
        ),
    ]

    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen} closeOnClickBackdrop={false}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg max-h-[820px]  w-[950px] mt-10 bg-white">
                    <div className="flex ">
                        <PreviewWindow className="preview-window">
                            <div>Preview</div>
                            <PrintWrap
                                ref={printContainerRef}
                                className="page-a4"
                            >
                                <style type="text/css" media="print">
                                    {
                                        "\
            @page { size: portrait; }\
            "
                                    }
                                </style>
                                <div className="flex items-center font-bold">
                                    <div className="w-[330px]">
                                        Công ty TNHH Thăng Long
                                    </div>
                                    <div className="w-[160px]">
                                        Bộ phận yêu cầu:
                                    </div>
                                    <div>Phòng Kinh Doanh</div>
                                </div>
                                <div className="flex items-center  font-bold">
                                    <div className="w-[330px]">
                                        Trung tâm tổ chức sự kiện Âu Cơ
                                    </div>
                                    <div className="w-[160px]">
                                        Bộ phận tiếp nhận:
                                    </div>
                                    <div>Bếp, P.Kế toán</div>
                                </div>
                                <div className="text-center font-bold mt-8 mb-4 text-xl">
                                    {title}
                                </div>
                                <div className="font-bold">
                                    Khách hàng: {cusName}
                                </div>
                                <div className="font-bold">
                                    Địa điểm: {place}
                                </div>
                                <div className="font-bold">
                                    Thời gian tiệc: {startTime} ngày{" "}
                                    {selectedDate.formatDMY()}
                                </div>
                                <div className="font-bold">
                                    Số lượng khách:{" "}
                                    {TableSetupUtils.formatTableSetup(
                                        tableSetup
                                    )}
                                </div>
                                <div className="font-bold">
                                    Đơn giá: {price}
                                </div>
                                <TableWrap className="mt-6">
                                    <Row className="header">
                                        <div>STT</div>
                                        <div>THỰC ĐƠN</div>
                                        <div>ĐỊNH LƯỢNG</div>
                                    </Row>
                                    {menus.map((e, index) => (
                                        <Row key={e.id}>
                                            <div className="text-center">
                                                {index + 1}
                                            </div>
                                            <div>{e.menu}</div>
                                            <div>{e.amount}</div>
                                        </Row>
                                    ))}
                                    <Row>
                                        <div className="text-center">
                                            {menus.length + 1}
                                        </div>
                                        <div>
                                            Đồ uống:{" "}
                                            {editingMenus
                                                .filter(
                                                    (e) =>
                                                        e.groupName &&
                                                        e.groupName
                                                            .toLowerCase()
                                                            .includes("đồ uống")
                                                )
                                                .map((item, index2) => (
                                                    <>
                                                        {index2 > 0 && (
                                                            <span>, </span>
                                                        )}
                                                        <span>
                                                            {item.amount}{" "}
                                                            {item.menu}
                                                        </span>
                                                    </>
                                                ))}
                                        </div>
                                        <div></div>
                                    </Row>
                                </TableWrap>

                                <div className="flex items-center mt-4">
                                    <div className="w-[200px] font-bold ml-20">
                                        Người lập
                                    </div>
                                    <div className="text-right ml-auto mr-20 font-bold ">
                                        Trưởng bộ phận
                                    </div>
                                </div>
                            </PrintWrap>
                        </PreviewWindow>
                        <div className="flex-1 h-20">
                            <div className="px-6 pt-3 ">
                                <div className="text-xl font-semibold mb-3">
                                    In thực đơn nhà bếp
                                </div>
                            </div>
                            <hr />

                            <div className="flex items-center gap-10 mx-10 mt-5">
                                <div className="flex-[2] text-sm text-right">
                                    Tiêu đề
                                </div>

                                <div className="flex-[3] text-sm">
                                    <Input
                                        placeholder="Nhập tiêu đề"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-10 mx-10 mt-5">
                                <div className="flex-[2] text-sm text-right">
                                    Khách hàng
                                </div>

                                <div className="flex-[3] text-sm">
                                    <Input
                                        placeholder="Nhập tên khách hàng"
                                        value={cusName}
                                        onChange={(e) =>
                                            setCusName(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-10 mx-10 mt-5">
                                <div className="flex-[2] text-sm text-right">
                                    Thời gian tiệc
                                </div>

                                <div className="flex-[3] text-sm">
                                    <Input
                                        value={startTime}
                                        placeholder="Giờ ăn tiệc"
                                        onChange={(e) =>
                                            setStartTime(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-10 mx-10 my-2 pt-3 text-sm">
                                <div className="flex-[2] text-sm text-right">
                                    Địa điểm
                                </div>
                                <div className="flex-[3]">
                                <Input
                                        value={place}
                                        placeholder="Địa điểm"
                                        onChange={(e) =>
                                            setPlace(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-10 mx-10 my-2 pt-3">
                                <div className="flex-[2] text-sm text-right">
                                    Đơn giá
                                </div>
                                <div className="flex-[3] text-sm">
                                    <Input
                                        value={price}
                                        placeholder="Đơn giá"
                                        onChange={(e) =>
                                            setPrice(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <hr className="mt-8" />
                            <div className="flex justify-center gap-4 py-5">
                                <Button
                                    className="w-[100px]"
                                    onClick={openPrint}
                                >
                                    <IconPrint className="text-xs mx-2" />
                                    In
                                </Button>
                                <Button
                                    className="w-[100px]"
                                    outline={true}
                                    onClick={() => setIsOpen(false)}
                                >
                                    Đóng
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default DialogPrintOrder

const PreviewWindow = styled.div`
    width: 550px;
    max-height: 95vh;
    overflow: hidden;
    padding-left: 20px;
    overflow: hidden;
`

const PrintWrap = styled.div`
    /* display: none; */
    transform: scale(0.65);
    transform-origin: top left;
    flex-shrink: 0;
    @media print {
        display: block;
        transform: none;
    }

    * {
        font-family: "Times New Roman", sans-serif;
    }
`

const TableWrap = styled.div`
    border-right: 1px solid black;
    border-bottom: 1px solid black;
`
const Row = styled.div`
    box-sizing: border-box;
    min-height: 32px;
    display: grid;
    grid-template-columns: 70px 1fr 1fr;
    div {
        border-top: 1px solid black;
        padding: 6px 12px;
        border-left: 1px solid black;
    }
    &.header {
        font-weight: 700;
        text-align: center;
    }
`
