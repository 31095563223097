import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import { useCallback, useState } from "react"
import styled from "styled-components"
import classNames from "classnames"
import { useClickOutside } from "core/hooks"
import CancelNoteDialog from "./components/CancelNoteDialog"
import IconCaretDown from "common/icons/IconCaretDown"
import IconTrash from "common/icons/IconTrash"
import IconCancel from "common/icons/IconCancel"
import IconCopy from "common/icons/IconCopy"

type Props = {
    className?: string
}

const EventDetailActions = ({ className }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [showCancelDialog, setShowCancelDialog] = useState(false)
    const { refs, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-end",
                    "top-start",
                    "top-end",
                    "right-end",
                ],
            }),
            ,
            offset(10),
        ],
    })

    const handleCancel = () => {
        setShowCancelDialog(true)
    }

    const handleClickOutside = useCallback(() => {
        setIsOpen(false)
    }, [])
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)
    return (
        <>
            <div
                ref={refs.setReference}
                onClick={() => setIsOpen((s) => !s)}
                className={classNames(
                    "flex items-center select-none cursor-pointer text-link font-semibold",
                    className
                )}
            >
                Thao tác
                <IconCaretDown className=" ml-2 text-[#868686] text-[8px]" />
            </div>
            {isOpen && (
                <FloatingPortal>
                    <DropdownMenu
                        className="shadow-menu rounded-lg py-2 z-[100]"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        onClick={() => setIsOpen(false)}
                    >
                        <div className="menu-item">
                            <IconCopy className="mr-4 text-base"/>
                            Nhân bản sự kiện
                        </div>
                        <div className="menu-item" onClick={handleCancel}>
                            <IconCancel className="mr-4 text-base" />
                            Hủy sự kiện
                        </div>
                        <div className="menu-item text-red-500">
                            <IconTrash className="mr-4 text-base"/>
                            Xóa sự kiện
                        </div>
                    </DropdownMenu>
                </FloatingPortal>
            )}
            <CancelNoteDialog
                isOpen={showCancelDialog}
                setIsOpen={setShowCancelDialog}
            />
        </>
    )
}

export default EventDetailActions

const DropdownMenu = styled.div`
    width: 250px;
    background-color: #fff;
    overflow: hidden;

    .menu-item {
        font-size: 14px;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
        span {
            width: 32px;
            display: inline-flex;
        }
    }
`
