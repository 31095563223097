import { ReactNode, useContext, useEffect } from "react";
import { tabHeadersContext, tabsContext } from "./tabContext";

import { Box, PolymorphicComponentProps } from "core/polymorphic";

interface TabHeadersProps {
    group?: string;
    className?: string;
    defaultIndex?: number;
    children: ReactNode
}

const TabHeaders = (props: TabHeadersProps) => {
    const { onChange } = useContext(tabsContext);
    const { defaultIndex, group, children , className } = props;
    useEffect(() => {
        onChange(defaultIndex || 0, group || "");
    }, [defaultIndex, group]);

    return (
        <tabHeadersContext.Provider
            value={{
                name: group || "",
            }}
        >
            <div className={className} >
                {children}
            </div>
        </tabHeadersContext.Provider>
    );
};

export default TabHeaders;
