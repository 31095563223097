import { useEffect, useState } from "react"
import { Input } from "core/styledComponents"
import { useSnapshot } from "valtio"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import { paymentStore } from "stores/paymentStore"
import CloseButton from "core/button/CloseButton"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DialogRenamePaymentSection = ({ isOpen, setIsOpen }: Props) => {
    const { paymentNames: currentSectionNames, currentPaymentName: currentSection } = useSnapshot(paymentStore)
    const [name, setName] = useState("")
    useEffect(() => {
        setName(currentSection)
    }, [currentSection])

    const handleRename = async () => {
        paymentStore.editingPayments = paymentStore.editingPayments.map((e) => {
            if (e.section === currentSection) {
                e.section = name
            }
            return e
        })

        var indexOfOldChecklist = currentSectionNames.indexOf(
            paymentStore.currentPaymentName
        )
        paymentStore.currentPaymentName = name
        if (indexOfOldChecklist != -1) {
            paymentStore.paymentNames[indexOfOldChecklist] = name
        }
        setIsOpen(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleRename()
        }
    }

    return (
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[25vh] bg-white">
                <div className=" pt-6 pb-6 px-14 flex-col flex items-center relative">
                    <CloseButton
                        className="absolute top-0 right-0 mt-3 mr-3 text-gray-500"
                        onClick={() => setIsOpen(false)}
                    />

                    <div className="text-base font-semibold text-primary mb-5 text-center">
                        Đổi tên mục thanh toán
                    </div>
                    <Input
                        className="text-md"
                        placeholder={`Ví dụ: "Bữa tối"`}
                        value={name}
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        onClick={handleRename}
                        className="mt-4  w-full "
                        disabled={
                            !name ||
                            !name.trim() ||
                            currentSectionNames.includes(name.trim())
                        }
                    >
                        Xong
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default DialogRenamePaymentSection
