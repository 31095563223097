import { moveDownAtIndex } from "core/cells/utils"
import EventPayment from "models/events/eventPayment"
import { PaymentItem } from "models/payment_item"
import { UnitItem } from "models/unit"
import { nanoid } from "nanoid"
import { proxy } from "valtio"
import { eventDetailStore } from "./eventDetailStore"
import { rootStore } from "./rootStore"

const PAYMENT_COLUMNS = ["name", "unit", "note", "quantity", "free", "price"]

export const paymentStore = proxy({
    activeCol: "",
    lastActiveColumn: "",
    activeKey: "",
    lastActiveKey: "",

    editingPayments: <EventPayment[]>[],
    paymentNames: <string[]>[],
    currentPaymentName: "",
    initPaymentSections: () => {
        var sectionNames = eventDetailStore.currentEvent.payments
            .map((e) => e.section)
            .unique()

        if (!sectionNames.length) {
            sectionNames = [""]
        }
        paymentStore.paymentNames = sectionNames
        paymentStore.currentPaymentName = sectionNames[0]
    },
    initEventPaymentsAndSections: (eventPayments: EventPayment[]) => {
        paymentStore.editingPayments = eventPayments
        var sectionNames = eventPayments.map((e) => e.section).unique()
        if (!sectionNames.length) {
            sectionNames = [""]
        }
        paymentStore.paymentNames = sectionNames
        paymentStore.currentPaymentName = sectionNames[0]
    },
    resetPayments: () => {
        paymentStore.editingPayments =
            eventDetailStore.currentEvent.payments.map((e) => ({ ...e }))
        paymentStore.initPaymentSections()
    },
    initEmpty: () => {
        paymentStore.editingPayments = []
        paymentStore.paymentNames = [""]
        paymentStore.currentPaymentName = ""
    },
    removeItem: (key: string) => {
        var removeItem = paymentStore.editingPayments.find(
            (e) =>
                e.key === key && e.section === paymentStore.currentPaymentName
        )
        paymentStore.editingPayments = paymentStore.editingPayments.filter(
            (e) => e != removeItem
        )
    },
    addRow(data: EventPayment | null) {
        const currentEditingItems = this.editingPayments.filter(
            (e) => e.section === this.currentPaymentName
        )
        const index = data
            ? this.editingPayments.findIndex((e) => e.key === data.key)
            : 0
        const category = data?.category || ""
        const categories = currentEditingItems.map((e) => e.category).unique()

        if (data) {
            this.editingPayments.splice(index + 1, 0, {
                category,
                createdAt: new Date().getTime(),
                eventId: eventDetailStore.currentEvent.id,
                free: false,
                id: 0,
                name: "",
                note: "",
                price: 0,
                quantity: 0,
                section: this.currentPaymentName,
                unit: "",
                key: nanoid(),
            })
        } else {
            this.editingPayments.push({
                category: categories[categories.length - 1],
                createdAt: new Date().getTime(),
                eventId: eventDetailStore.currentEvent.id,
                free: false,
                id: 0,
                name: "",
                note: "",
                price: 0,
                quantity: 0,
                section: this.currentPaymentName,
                unit: "",
                key: nanoid(),
            })
        }
        setTimeout(() => {
            if (document.activeElement) {
                moveDownAtIndex(document.activeElement, 1)
            }
        })
    },
    deleteCell: (data: EventPayment, colKey: string, cb?: () => void) => {
        var currentItems = paymentStore.editingPayments.filter(
            (e) => e.section === paymentStore.currentPaymentName
        )
        var item = currentItems.find(
            (e) =>
                e.key === data.key &&
                e.section === paymentStore.currentPaymentName
        )
        if (item) {
            if (colKey === "unit") {
                item.unit = ""
            }
            if (colKey === "free") {
                item.free = false
            }
            if (colKey === "note") {
                item.note = ""
            }
            if (colKey === "quantity") {
                item.quantity = 0
            }
            if (colKey === "price") {
                item.price = 0
            }
            if (
                ["unit", "free", "note", "quantity", "price"].includes(colKey)
            ) {
                cb && cb()
            }
        }
    },
    updateUnit: (data: EventPayment, option: UnitItem, cb?: () => void) => {
        var currentItems = paymentStore.editingPayments.filter(
            (e) => e.section === paymentStore.currentPaymentName
        )
        var item = currentItems.find(
            (e) =>
                e.key === data.key &&
                e.section === paymentStore.currentPaymentName
        )
        if (item) {
            item.unit = option.name
            cb && cb()
        }
    },
    updateTextCell: (
        data: EventPayment,
        colKey: string,
        value: string,
        cb?: () => void
    ) => {
        var currentItems = paymentStore.editingPayments.filter(
            (e) => e.section === paymentStore.currentPaymentName
        )
        var item = currentItems.find(
            (e) =>
                e.key === data.key &&
                e.section === paymentStore.currentPaymentName
        )
        if (item) {
            if (colKey === "note") {
                item.note = value
                cb && cb()
            }
            if (colKey === "price") {
                item.price = Math.round(Number(value))
                cb && cb()
            }
            if (colKey === "quantity") {
                item.quantity = Number(value)
                cb && cb()
            }
        }
    },
    updateBooleanCell: (
        data: EventPayment,
        colKey: string,
        cb?: () => void
    ) => {
        var currentItems = paymentStore.editingPayments.filter(
            (e) => e.section === paymentStore.currentPaymentName
        )
        var item = currentItems.find(
            (e) =>
                e.key === data.key &&
                e.section === paymentStore.currentPaymentName
        )
        if (item) {
            if (colKey === "free") {
                item.free = !item.free
                cb && cb()
            }
        }
    },
    //@ts-ignore
    changePayment: ({
        category,
        cellData,
        colKey,
        option,
        cb,
    }: {
        cellData: EventPayment
        colKey: string
        option: PaymentItem
        category: string
        cb?: () => void
    }) => {
        var _unit = rootStore.units.find((e) => e.id === option.unitId)
        var index = paymentStore.editingPayments.findIndex(
            (e) =>
                e.key === cellData.key &&
                e.section === paymentStore.currentPaymentName
        )
        if (index != -1) {
            // giữ key cũ
            var key = paymentStore.editingPayments[index].key
            paymentStore.editingPayments[index] = {
                eventId: cellData.eventId,
                id: 0,
                name: option.name,
                category,
                free: false,
                createdAt: new Date().getTime(),
                note: "",
                price: option.price,
                quantity: 1,
                section: paymentStore.currentPaymentName,
                unit: _unit?.name || "",
                key,
            }
            cb && cb()
        }
    },
})

const getSelectedItem = () => {
    var item = paymentStore.editingPayments.find(
        (e) =>
            e.key === paymentStore.lastActiveKey &&
            e.section === paymentStore.currentPaymentName
    )
    return item
}

const moveLeft = () => {
    let index = PAYMENT_COLUMNS.indexOf(paymentStore.activeCol)
    index--
    if (index >= 0) {
        paymentStore.activeCol = PAYMENT_COLUMNS[index]
    }
}
const moveRight = () => {
    let index = PAYMENT_COLUMNS.indexOf(paymentStore.activeCol)
    index++
    if (index < PAYMENT_COLUMNS.length) {
        paymentStore.activeCol = PAYMENT_COLUMNS[index]
    }
}

export const PaymentStore = {
    getSelectedItem,
    moveLeft,
    moveRight,
}
