import { todoStore } from "stores/todoStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import ChecklistTopActions from "./ChecklistTopActions"
import classNames from "classnames"

const ChecklistTableTop = () => {
    const { checklistNames, currentChecklist } = useSnapshot(todoStore)
    const editMode = true
    return (
        <ChecklistTableTopWrap className="bg-white flex items-center gap-4">
            {checklistNames.map((e) => (
                <ChecklistName
                    key={e}
                    onClick={() => (todoStore.currentChecklist = e)}
                    className={classNames(
                        "h-7 px-3 flex items-center rounded-full font-medium text-sm cursor-default",
                        {
                            "bg-lightblue text-link active":
                                e === currentChecklist,
                        }
                    )}
                >
                    {e || "Mặc định"}
                </ChecklistName>
            ))}
   
            <div className="ml-auto">{editMode && <ChecklistTopActions />}</div>
        </ChecklistTableTopWrap>
    )
}

export default ChecklistTableTop

const ChecklistTableTopWrap = styled.div`
    background-color: #fff;

    height: 44px;
    font-size: 13px;
`
const ChecklistName = styled.div`
    &:not(.active) {
        &:hover {
            background-color: #f1f1f1;
        }
    }
`
