import classNames from "classnames"
import {
    FloatingOverlay,
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import { useState } from "react"
import { styled } from "styled-components"
import { useClickOutside } from "core/hooks"
import AddViewDialog from "./AddViewDialog"
import { useSnapshot } from "valtio"
import { eventFilterStore } from "stores/eventFilterStore"
import { defaultViews } from "../viewService"
import IconPlus from "common/icons/IconPlus"

type Props = {
    className?: string
}

const AddView = ({ className }: Props) => {
    const { views } = useSnapshot(eventFilterStore)
    const [showDropdown, setShowDropdown] = useState(false)
    const [isOpenAddView, setIsOpenAddView] = useState(false)
    const { refs, floatingStyles, update } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [
            autoPlacement({
                allowedPlacements: ["bottom-end", "bottom-start", "bottom"],
            }),
            offset(10),
        ],
    })
    const handleClickOutside = () => {
        setShowDropdown(false)
    }
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)
    const addView = (name: string) => {
        eventFilterStore.toggleViewVisibility(name, true)
        setShowDropdown(false)
    }

    const handleSave = () => {}

    return (
        <>
            <div
                onClick={() => setShowDropdown((s) => !s)}
                ref={refs.setReference}
                className={classNames(
                    "flex items-center text-sm select-none text-link font-semibold cursor-pointer",
                    className
                )}
            >
                <IconPlus className="text-[#7C98B6] text-[22px] mr-2" />
                Thêm view
            </div>

            {showDropdown && (
                <Dropdown
                    onClick={() => setShowDropdown(false)}
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className="shadow-menu bg-white flex flex-col rounded-lg overflow-hidden z-[100]"
                >
                    <div className="text-[11px] uppercase select-none font-semibold text-[#B9B9B9] pl-6 h-8 mt-4">
                        View mặc định
                    </div>
                    {defaultViews.map((e) => (
                        <div
                            className="menu-item"
                            onClick={() => addView(e.name)}
                        >
                            {e.name}
                        </div>
                    ))}
                    <hr className="mt-3 mx-6" />
                    <div className="text-[11px] uppercase select-none font-semibold text-[#B9B9B9] pl-6 h-8 mt-4">
                        View người dùng tạo
                    </div>
                    {views
                        .filter((e) => !e.isDefault)
                        .map((e) => (
                            <div
                                className="menu-item"
                                onClick={() => addView(e.name)}
                            >
                                {e.name}
                            </div>
                        ))}
                    <div className="h-10" />
                    <hr className="mt-auto " />
                    <div
                        className="py-3 px-4 cursor-pointer  text-link flex items-center text-13 font-semibold "
                        onClick={handleSave}
                    >
                        <IconPlus className="text-xl mr-3" />
                        <div
                            className="hover:underline"
                            onClick={() => setIsOpenAddView(true)}
                        >
                            Tạo mới view
                        </div>
                    </div>
                </Dropdown>
            )}

            <AddViewDialog
                isOpen={isOpenAddView}
                setIsOpen={setIsOpenAddView}
            />
        </>
    )
}

export default AddView

const Dropdown = styled.div`
    min-width: 370px;
    background-color: #fff;
    min-height: 250px;

    .menu-item {
        padding: 0 32px;
        margin: 0 4px;
        display: flex;
        align-items: center;
        font-size: 13px;
        height: 40px;
        border-radius: 4px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
    }
`
