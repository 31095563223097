import { AppointmentItem } from "models/events/appointment"
import { parseColor } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"

type Props = {
    appointment: AppointmentItem
    scrollTop: number
    bottom: number
    bottomPinMouseDown: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void
    bottomPinDragStart: (
        e: React.DragEvent<HTMLDivElement>
    ) => void
}
const BottomPin = ({
    scrollTop,
    bottom,
    bottomPinMouseDown,
    bottomPinDragStart,
    appointment,
}: Props) => {
    return (
        <>
            <BottomPinWrap
                onDragStart={bottomPinDragStart}
                onMouseDown={bottomPinMouseDown}
                //colWidth*0.8 là dịch sang bên phải 0.8 bề rộng của colWidth
                style={{
                    transform: `translateY(${-scrollTop + 30 - 8 + bottom}px)`,
                    backgroundColor: `${appointment.color}3F`,
                }}
            >
                <div
                    style={{
                        backgroundColor: appointment.color,
                    }}
                />
            </BottomPinWrap>
        </>
    )
}

export default BottomPin

const BottomPinWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    position: absolute;
    right: 20px;
    div {
        width: 10px;
        height: 10px;
        border-radius: 96px;
    }
`
