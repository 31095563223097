import classNames from "classnames"
import IconCaretDown from "common/icons/IconCaretDown"

type CaretDownProps = {
    className?: string
}

const CaretDown = ({ className }: CaretDownProps) => {
    return <IconCaretDown className={classNames("text-[9px] mr-3 text-[#868686]", className)} />
}
export default CaretDown
