export const parseOrderName = (orderName: string) => {

    if (orderName.includes("^")) {
        return {
            name: orderName.split("^")[0],
            index: +orderName.split("^")[1],
        }
    }
    return {
        name: orderName,
        index: 10,
    }
}
