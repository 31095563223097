import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import { saveDraft } from "services/draftService"
import { formatGuestEstimate } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const GuestEstimateChange = ({ isOpen, setIsOpen }: Props) => {
    const { guestFrom, guestTo, currentEvent } = useSnapshot(eventDetailStore)

    const cancelChanges = () => {
        eventDetailStore.guestFrom = currentEvent.guestFrom
        eventDetailStore.guestTo = currentEvent.guestTo
        // clearTableSetupDraft(currentEvent.id)
        saveDraft()
        setIsOpen(false)
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg   w-[600px] pb-5 mt-[25vh] bg-white">
                    <DialogTitle>Thay đổi khách dự kiến</DialogTitle>
                    <div className="px-12 pb-7 pt-5 text-[15px] flex flex-wrap justify-center">
                        <div>
                            <span className="font-medium">
                                {formatGuestEstimate(
                                    currentEvent.guestFrom,
                                    currentEvent.guestTo
                                ) || 0}
                            </span>
                            &nbsp;
                            <span className="text-grey">khách</span>
                        </div>
                        <div className="flex flex-wrap items-center">
                            <span className="text-[#aaa] mx-2">→</span>
                            <span className="font-medium">
                                {formatGuestEstimate(guestFrom, guestTo) || "0"}
                            </span>
                            &nbsp;<span className="text-grey">khách</span>
                        </div>
                    </div>
                    <div className="justify-center flex ">
                        <Button outline onClick={cancelChanges}>
                            Hủy thay đổi
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default GuestEstimateChange
