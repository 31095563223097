import classNames from "classnames"
import { matchSorter } from "match-sorter"
import EventMenu from "models/events/eventMenu"
import { MenuItem } from "models/menu_item"
import { nanoid } from "nanoid"
import { useCallback, useEffect, useRef, useState } from "react"
import Select, { ActionMeta, MultiValue, SelectInstance } from "react-select"
import { DialogName } from "services/constants"
import { stripAccents } from "services/utils"
import { closeDialog } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { rootStore } from "stores/rootStore"
import { styled } from "styled-components"
import { IconSelect } from "core/styledComponents"
import { useSnapshot } from "valtio"
import Dialog from "core/dialog/Dialog"
import CloseButton from "core/button/CloseButton"
import { parseOrderName } from "services/orderUtils"
import { saveDraft } from "services/draftService"
import { recentListStore } from "modules/recent_list/recentListStore"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DialogSelectMenu = ({ isOpen, setIsOpen }: Props) => {
    const { menus, menuTypes } = useSnapshot(rootStore)
    const { editingMenus, currentOrderName } = useSnapshot(eventMenuStore)
    const { currentEvent } = useSnapshot(eventDetailStore)
    //@ts-ignore
    const [search, setSearch] = useState("")
    const [currentTab, setCurrentTab] = useState(0)

    const [selectedMenus, setSelectedMenus] = useState<MenuItem[]>([])
    const [viewMenus, setViewMenus] = useState<MenuItem[]>([])

    useEffect(() => {
        if (!search) {
            setViewMenus(menus.filter((e) => e.typeId === currentTab))
        } else {
            var result = matchSorter(
                menus
                    .filter((e) => e.typeId === currentTab)
                    .map((e) => ({ ...e, name_en: stripAccents(e.name) })),
                search,
                { keys: ["name_en"], keepDiacritics: false }
            )
            setViewMenus(result)
        }
    }, [search, currentTab])

    useEffect(() => {
        if (menuTypes.length) {
            setCurrentTab(menuTypes[0].id)
        }
    }, [menuTypes])

    useEffect(() => {
        setViewMenus(menus.map((e) => e))
    }, [menus])

    const getOptionValue = useCallback((option: MenuItem) => {
        return option.id.toString()
    }, [])

    const getOptionLabel = useCallback((option: MenuItem) => {
        return option.name
    }, [])

    const onChange = (
        newValue: MultiValue<MenuItem>,
        actionMeta: ActionMeta<MenuItem>
    ) => {
        // setSelectedMenus(newValue.filter((e) => e.typeId == currentTab))
        // setSelectedMenus(e.values)
        // console.log(selectedOptions)
        if (actionMeta.action === "remove-value") {
            eventMenuStore.editingMenus = eventMenuStore.editingMenus.filter(
                (e) =>
                    e.orderName === parseOrderName(currentOrderName).name &&
                    e.menu != actionMeta.removedValue.name
            )
        } else if (actionMeta.action === "select-option") {
            const foundMenu = menus.find(
                (e) => e.name === actionMeta.option?.name
            )
            if (foundMenu) {
                var menuType = rootStore.menuTypes.find(
                    (e) => e.id === foundMenu.typeId
                )
                var eventMenu: EventMenu = {
                    id: 0,
                    amount: "",
                    groupName: menuType?.name || "",
                    note: "",
                    eventId: currentEvent.id,
                    menu: foundMenu.name,
                    orderName: parseOrderName(currentOrderName).name,
                    price: 0,
                    size: "",
                    key: nanoid(),
                }

                var _index = editingMenus.findIndex(
                    (e) =>
                        e.menu === foundMenu.name &&
                        e.orderName === parseOrderName(currentOrderName).name
                )
                if (_index == -1) {
                    eventMenuStore.editingMenus.push(eventMenu)
                }
            }
        } else if (actionMeta.action === "clear") {
            var currentTypeName =
                menuTypes.find((e) => e.id == currentTab)?.name || ""
            eventMenuStore.editingMenus = eventMenuStore.editingMenus.filter(
                (e) =>
                    e.orderName === parseOrderName(currentOrderName).name &&
                    e.groupName != currentTypeName
            )
        }
        saveDraft()
    }
    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Escape") {
                closeDialog(DialogName.SELECT_MENU)
            }
        },
        []
    )

    useEffect(() => {
        const value = editingMenus
            .map((e) => {
                var menu = menus.find(
                    (item) =>
                        item.name === e.menu &&
                        e.orderName === parseOrderName(currentOrderName).name
                )
                return menu
            })
            .filter((e) => e) as MenuItem[]
        setSelectedMenus(value)
    }, [editingMenus, currentOrderName])
    const selectRef = useRef<SelectInstance<MenuItem> | null>(null)

    const selectedMenusByType = selectedMenus.filter(
        (e) => e.typeId === currentTab
    )

    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[600px] mt-[25vh] bg-white">
                    <div className="text-[13px] h-">
                        <div
                            className="font-semibold text-lg relative  leading-6 text-gray-900  px-6 py-3 border-b border-td"
                            onMouseDown={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                        >
                            Chọn thực đơn
                            <CloseButton
                                className="absolute top-3 right-3"
                                onClick={() => setIsOpen(false)}
                            />
                        </div>
                        <div className="mt-3" style={{}}>
                            <TabsWrapper>
                                {menuTypes.map((e) => (
                                    /* Use the `selected` state to conditionally style the selected tab. */
                                    <TabItem
                                        key={e.id}
                                        onMouseDown={(ev) =>
                                            ev.preventDefault()
                                        }
                                        onClick={() => setCurrentTab(e.id)}
                                        className={classNames({
                                            active: currentTab == e.id,
                                        })}
                                    >
                                        {e.name} (
                                        {
                                            selectedMenus.filter(
                                                (item) => item.typeId === e.id
                                            ).length
                                        }
                                        )
                                    </TabItem>
                                ))}
                            </TabsWrapper>
                            <Select
                                ref={selectRef}
                                classNamePrefix={"react-select"}
                                value={selectedMenusByType}
                                className="mt-3 px-4 pb-4"
                                components={{
                                    DropdownIndicator: IconSelect,
                                }}
                                options={viewMenus}
                                getOptionLabel={getOptionLabel}
                                getOptionValue={getOptionValue}
                                // onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                // menuIsOpen={true}
                                defaultMenuIsOpen
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                    }),
                                    menu: (base, props) => ({
                                        ...base,
                                        fontSize: "14px",
                                    }),
                                }}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                                menuShouldScrollIntoView={false}
                                //@ts-ignore
                                onChange={onChange}
                                placeholder="Chọn món"
                                noOptionsMessage={() => "Không có kết quả"}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default DialogSelectMenu

const TabsWrapper = styled.div`
    border-bottom: 1px solid #eee;
    padding-top: 8px;
    padding-left: 24px;
`

const TabItem = styled.div`
    display: inline-flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin: 0 16px;
    padding: 0 4px;
    height: 36px;
    border-bottom: 2px solid transparent;
    cursor: default;
    &.active {
        border-bottom: 2px solid var(--link);
        color: var(--link);
    }
`
