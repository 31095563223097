import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import classNames from "classnames"
import IconChevronDown from "common/icons/IconChevronDown"
import IconChevronUp from "common/icons/IconChevronUp"
import { Button } from "core/button/Button"
import { useClickOutside } from "core/hooks"
import { Input } from "core/styledComponents"
import { TableCount } from "models/table_count"
import { useEffect, useRef, useState } from "react"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { styled } from "styled-components"
import {
    parseMealTableSetup,
    parseMealTableSetupWithDefault,
    parseMealTableSetupWithDefault2,
} from "utils/TableSetupUtils"
import { useSnapshot } from "valtio"

const SelectTablePopup = ({ index }: { index: number }) => {
    const { tableSetup } = useSnapshot(eventDetailStore)
    const mealTableSetups = parseMealTableSetupWithDefault2(tableSetup)
    const [tableCounts, setTableCounts] = useState(mealTableSetups[index])
    const [clickedIndex, setClickedIndex] = useState(-1)
    // const { orderNames } = useSnapshot(eventMenuStore)

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isOpen) {
            setTableCounts(mealTableSetups[index])
        }
    }, [isOpen])

    const handleOk = () => {
        var mealTblSetups = parseMealTableSetup(tableSetup)
        mealTblSetups[index] = tableCounts
        var newTableSetup = mealTblSetups
            .map((tblCounts) =>
                tblCounts.map((item) => `${item.count}b${item.index}`).join(".")
            )
            .join("|")
        eventDetailStore.tableSetup = newTableSetup
        eventMenuStore.updateTableType()
        saveDraft()
        setIsOpen(false)
    }

    const handleTableChange = (val: number, index: number) => {
        var found = tableCounts.find((item) => item.index === index)
        let newTableCounts: TableCount[]
        if (found) {
            newTableCounts = tableCounts.map((e) =>
                e.index == index ? { index, count: val } : e
            )
        } else {
            newTableCounts = [...tableCounts, { count: val, index }]
        }
        setTableCounts(newTableCounts)
    }
    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: ["left", "right", "top", "bottom"],
            }),
            ,
            offset(40),
        ],
    })

    const handleClickOutside = () => {
        setIsOpen(false)
    }
    useClickOutside(handleClickOutside, refs.floating, refs.domReference)
    return (
        <>
            <div ref={refs.setReference}>
                {mealTableSetups[index].map((tblCount, index) => (
                    <span key={index}>
                        {index > 0 && <span>, </span>}
                        <span
                            onClick={() => {
                                setIsOpen(true)
                                setClickedIndex(tblCount.index)
                            }}
                            className="text-link hover:underline cursor-pointer underline  decoration-dotted"
                        >
                            {`${tblCount.count} bàn ${tblCount.index}`}
                        </span>
                    </span>
                ))}
            </div>
            {isOpen && (
                <FloatingPortal>
                    <GuestInputWrap
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className="shadow-menu bg-white rounded-lg px-5 py-4  z-20"
                    >
                        <Table>
                            <thead className="text-primary">
                                <td>Số lượng</td>
                                <td>Bàn</td>
                            </thead>
                            <tbody>
                                {[12, 11, 10, 9, 8, 7, 6, 5].map((e) => (
                                    <TableRow
                                        key={e}
                                        initialFocus={clickedIndex === e}
                                        onEnter={handleOk}
                                        onChange={handleTableChange}
                                        count={
                                            tableCounts.find(
                                                (item) => item.index == e
                                            )?.count || 0
                                        }
                                        index={e}
                                    />
                                ))}
                            </tbody>
                        </Table>
                        <hr className="mt-5 -mx-5" />
                        <div className="pt-3 flex text-center justify-between">
                            <Button
                                outline
                                onClick={() => setIsOpen(false)}
                                className="border-none text-secondary font-normal"
                            >
                                Hủy
                            </Button>
                            <Button className="ml-5 w-24" onClick={handleOk}>
                                OK
                            </Button>
                        </div>
                    </GuestInputWrap>
                </FloatingPortal>
            )}
        </>
    )
}

export default SelectTablePopup

const GuestInputWrap = styled.div`
    width: 300px;
`
const Table = styled.table`
    thead td {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        padding: 8px 16px;
    }
    tbody td {
        font-size: 14px;
        &:last-child {
            text-align: center;
            width: 80px;
        }
    }
`
const TableRow = ({
    index,
    count,
    onChange,
    onEnter,
    initialFocus,
}: {
    index: number
    count: number
    onEnter: () => void
    initialFocus: boolean
    onChange: (val: number, index: number) => void
}) => {
    const [hasFocus, setHasFocus] = useState(false)
    const [mouseEnter, setMouseEnter] = useState(false)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        if (/\d+/.test(val)) {
            onChange(+val, index)
        }
    }
    useEffect(() => {
        if (initialFocus) {
            inputRef.current?.focus()
        }
    }, [])
    const inputRef = useRef<HTMLInputElement>(null)

    const timerRef = useRef(0)
    const handleMouseEnter = () => {
        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(() => {
            setMouseEnter(true)
        }, 450)
    }

    const handleMouseLeave = () => {
        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(() => {
            setMouseEnter(false)
        }, 250)
    }

    const handleIncrease = () => {
        onChange(count + 1, index)
        inputRef?.current?.focus()
    }
    const handleDecrease = () => {
        onChange(Math.max(0, count - 1), index)
        inputRef?.current?.focus()
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "ArrowDown") {
            e.preventDefault()
            onChange(Math.max(0, count - 1), index)
        }
        if (e.key === "ArrowUp") {
            e.preventDefault()
            onChange(count + 1, index)
        }
        if (e.key === "Enter") {
            onEnter()
        }
        if (e.key === "Escape") {
            inputRef.current?.blur()
        }
    }

    return (
        <>
            <tr>
                <td>
                    <Wrap
                        className={classNames("relative", {
                            "mouse-enter": mouseEnter,
                        })}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Input
                            ref={inputRef}
                            onFocus={() => setMouseEnter(true)}
                            onBlur={() => setMouseEnter(false)}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            className="text-right pr-8"
                            placeholder={
                                (hasFocus || mouseEnter) && !count ? "0" : ""
                            }
                            value={count == 0 ? "" : count}
                        />
                        <div className="vertical-line" />
                        <div className="horizontal-line" />
                        <IconChevronUp
                            onClick={handleIncrease}
                            className="text-xs up"
                            onMouseDown={(e) => e.preventDefault()}
                        />
                        <IconChevronDown
                            onClick={handleDecrease}
                            className="text-xs down"
                            onMouseDown={(e) => e.preventDefault()}
                        />
                        {/* <IconPlus
                            className="text-lg"
                            onMouseDown={(e) => e.preventDefault()}
                        />
                        <IconMinus
                            className="text-lg left-10"
                            onMouseDown={(e) => e.preventDefault()}
                        /> */}
                    </Wrap>
                </td>
                <td
                    className={classNames({
                        "text-link font-medium": count > 0,
                        "text-[#aaa]": count == 0,
                    })}
                >
                    bàn {index}
                </td>
            </tr>
        </>
    )
}

const Wrap = styled.div`
    svg {
        opacity: 0;
        position: absolute;
        right: 6px;
        cursor: pointer;
        fill: #7e8fa1;
        &.up {
            top: 6px;
        }
        &.down {
            top: 24px;
        }
        &:hover {
            fill: #1d2f43;
        }
    }
    .horizontal-line {
        opacity: 0;
        position: absolute;
        right: 1px;
        top: 20px;
        width: 23px;
        height: 1px;
        border-bottom: 1px solid #e0e0e0;
    }
    .vertical-line {
        opacity: 0;
        height: 38px;
        border-left: 1px solid #e0e0e0;
        position: absolute;
        top: 1px;
        right: 24px;
    }
    &.mouse-enter {
        svg {
            opacity: 1;
        }
        .vertical-line,
        .horizontal-line {
            opacity: 1;
        }
    }
`
