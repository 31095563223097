import OrderTableBody from "modules/EventDetail/OrderTab/OrderTableBody"
import OrderTableHeader from "modules/EventDetail/OrderTab/OrderTableHeader"
import OrderTableTop from "modules/EventDetail/OrderTab/OrderTableTop"
import { useEffect } from "react"
import { styled } from "styled-components"
import OrderHeader from "./OrderHeader/OrderHeader"
import { OrderTableInfo } from "./OrderTableInfo"

const OrderTab = () => {
    useEffect(() => {}, [])
    return (
        <Wrap className="px-5 py-4 rounded-md card-shadow">
            <OrderHeader />

            <OrderTableTop />
            <OrderTableInfo />
            <OrderTableHeader />
            <OrderTableBody />
        </Wrap>
    )
}

export default OrderTab

const Wrap = styled.div`
    background-color: #fff;
`
