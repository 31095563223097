import styled from "styled-components"
import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import { useCallback, useState } from "react"
import { useClickOutside } from "core/hooks"
import { useNavigate } from "react-router"
import { useSnapshot } from "valtio"
import { rootStore } from "stores/rootStore"
import { CLOUD_FRONT_URL } from "services/constants"

const UserProfileMenu = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { currentUser } = useSnapshot(rootStore)
    const navigate = useNavigate()
    const { refs, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-end",
                    "top-start",
                    "top-end",
                    "right-end",
                ],
            }),
            ,
            offset(10),
        ],
    })

    const handleClickOutside = useCallback(() => {
        setIsOpen(false)
    }, [])
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)

    const handleLogout = () => {
        navigate("/login")
    }
    return (
        <>
            <UserProfileMenuWrap
                className="flex items-center"
                ref={refs.setReference}
                onClick={() => setIsOpen((s) => !s)}
            >
                <Avatar className="w-8 h-8 flex items-center justify-center rounded-full overflow-hidden">
                    <img
                        src={
                            currentUser?.photoID
                                ? `${CLOUD_FRONT_URL}/${currentUser.photoID}`
                                : "/images/avatar-default.webp"
                        }
                    />
                </Avatar>
                <span className="icon-chevron-thin-down text-[5px] ml-2" />
            </UserProfileMenuWrap>

            {isOpen && (
                <FloatingPortal>
                    <DropdownMenu
                        className="shadow-menu rounded-lg py-2 z-[100]"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        onClick={() => setIsOpen(false)}
                    >
                        <div className="menu-item" onClick={handleLogout}>
                            <span className="icon-logout text-xs" />
                            Đăng xuất
                        </div>
                        {/* <div className="menu-item" onClick={handleCancel}>
                            <span className="icon-cancel text-xs" />
                            Hủy sự kiện
                        </div>
                        <div className="menu-item text-red-500">
                            <span className="icon-trash text-xs" />
                            Xóa sự kiện
                        </div> */}
                    </DropdownMenu>
                </FloatingPortal>
            )}
        </>
    )
}

export default UserProfileMenu

const UserProfileMenuWrap = styled.div`
    margin-left: 40px;
`
const Avatar = styled.div``
const DropdownMenu = styled.div`
    width: 250px;
    background-color: #fff;
    overflow: hidden;

    .menu-item {
        font-size: 14px;
        padding: 8px 20px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
        span {
            width: 32px;
            display: inline-flex;
        }
    }
`
