const isSameMonth = (d1: Date, d2: Date) => {
    return (
        d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
    )
}
const isSameDay = (d1: Date, d2: Date) => {
    return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
    )
}

const getMondayInThePast = (d: Date) => {
    const clone = new Date(d)
    const day = clone.getDay(),
        diff = clone.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
    return new Date(clone.setDate(diff))
}

const getSundayInFuture = (d: Date) => {
    const clone = new Date(d)
    const day = clone.getDay()
    if (day === 0) {
        return clone
    }

    const diff = 7 - day
    clone.setDate(clone.getDate() + diff)
    return clone
}

const DateUtils = {
    getMondayInThePast,
    getSundayInFuture,
    isSameDay,
    isSameMonth,
}

export default DateUtils
