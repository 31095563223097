import { ReactNode, useState } from "react"
import { CellContext } from "./context"

type Props = {
    children: ReactNode
}
const CellProvider = ({ children }: Props) => {
    const [colKey, setColKey] = useState('')
    const [data, setData] = useState<any>(null)
    return (
        <CellContext.Provider value={{ colKey, data: data, setData, setColKey }}>
            {children}
        </CellContext.Provider>
    )
}

export default CellProvider