import classNames from "classnames"
import Checkbox from "core/Checkbox"
import Container from "core/Container/Container"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import { TextButton } from "core/styledComponents"
import EventTodo from "models/events/eventTodo"
import { Todo } from "models/todo"
import SelectTemplate from "modules/checklist/components/SelectTemplate"
import { nanoid } from "nanoid"
import { useEffect, useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { rootStore } from "stores/rootStore"
import { todoStore } from "stores/todoStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DialogSelectTodo = ({ isOpen, setIsOpen }: Props) => {
    const { todos, todoSections, checklistTemplates } = useSnapshot(rootStore)
    const { editingTodos, currentChecklist } = useSnapshot(todoStore)
    const [selectedTemplateId, setSelectedTemplateId] = useState(
        checklistTemplates?.[0]?.id || 0
    )

    useEffect(() => {
        /**
         *    lấy ra template name từ  editingTodos(đã lọc theo currentChecklist)
         *      và so sánh với template của hệ thống dựa trên name
         *    Nếu tìm thấy thì lấy item đó (của hệ thống có tên trùng), nếu ko lấy checklistTemplates[0]
         */
        if (isOpen) {
            var currentTemplate =
                checklistTemplates.find(
                    (e) =>
                        e.name.trim().toLowerCase() ===
                        editingTodos
                            ?.filter(
                                (item) =>
                                    item.name &&
                                    item.checklist.trim().toLowerCase() ==
                                        currentChecklist.trim().toLowerCase()
                            )?.[0]
                            ?.template.trim()
                            .toLowerCase()
                ) || checklistTemplates[0]
            if (currentTemplate) {
                setSelectedTemplateId(currentTemplate.id)
            }
        }
    }, [checklistTemplates, isOpen])

    const [editingItems, setEditingItems] = useState<EventTodo[]>([])

    const handleSelectTemplate = (id: number) => {
        setSelectedTemplateId(id)
    }

    useEffect(() => {
        if (isOpen) {
            setEditingItems(editingTodos.map((e) => ({ ...e })))
        }
    }, [editingTodos, isOpen])

    const handleDone = () => {
        /**
         *   Khi ấn done thì :
         *    - Các todos của những checklist khác giữ nguyên
         *    - Các todos của currentChecklist thì chỉ lấy những item có template là template hiện tại
         */
        if (checklistTemplates.length) {
            const otherItems = editingItems.filter(
                (e) => e.checklist != currentChecklist
            )

            const currentTemplate = getCurrentTemplate()
            const currentItems = editingItems.filter(
                (e) =>
                    e.checklist.trim().toLowerCase() ===
                        currentChecklist.trim().toLowerCase() &&
                    e.template.trim().toLowerCase() ===
                        currentTemplate.trim().toLowerCase()
            )
            todoStore.editingTodos = [...otherItems, ...currentItems].filter(
                (e) => e.name
            )
        }

        setIsOpen(false)
    }

    const getCurrentTemplate = () => {
        var currentTemplate =
            checklistTemplates.find((e) => e.id === selectedTemplateId) ||
            checklistTemplates[0]
        return currentTemplate?.name ?? ""
    }

    const onSelect = (todo: Todo, sectionName: string) => {
        var index = editingItems.findIndex(
            (item) =>
                item.name.trim().toLowerCase() ===
                    todo.name.trim().toLowerCase() &&
                item.section.trim().toLowerCase() ===
                    sectionName.trim().toLowerCase() &&
                item.checklist.trim().toLowerCase() ===
                    currentChecklist.trim().toLowerCase()
        )
        if (index !== -1) {
            setEditingItems((s) => s.filter((item, idx) => idx !== index))
        } else {
            var currentTemplate = getCurrentTemplate()
            setEditingItems((s) => [
                ...s,
                {
                    id: 0,
                    checklist: currentChecklist,
                    detail: "",
                    eventId: eventDetailStore.currentEvent.id,
                    key: nanoid(),
                    name: todo.name,
                    section: sectionName,
                    template: currentTemplate,
                },
            ])
        }
    }

    const sections = todoSections.filter(
        (e) => e.templateId === selectedTemplateId
    )

    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[500px] mt-[5vh] bg-white">
                    <div>
                        <DialogTitle>Chọn hạng mục</DialogTitle>

                        <div className="flex px-5 py-4 text-sm shadow-bottom mb-3">
                            <div className="text-secondary mr-2">Template:</div>

                            <SelectTemplate
                                id={selectedTemplateId}
                                name={
                                    checklistTemplates.find(
                                        (e) => e.id === selectedTemplateId
                                    )?.name || ""
                                }
                                onChange={handleSelectTemplate}
                            />
                        </div>
                        <div className="flex items-stretch">
                            <Container className="flex-1 min-w-0 h-[calc(75vh-120px)]">
                                {sections.map((s) => (
                                    <TodoContainer key={s.id}>
                                        <SectionName>{s.name}</SectionName>
                                        <div>
                                            {todos
                                                .filter(
                                                    (e) => e.sectionId === s.id
                                                )
                                                .map((e) => (
                                                    <StyledMenuItem
                                                        key={e.id}
                                                        onClick={() =>
                                                            onSelect(e, s.name)
                                                        }
                                                        className={classNames(
                                                            "flex text-sm items-center pl-10 pr-4 py-1.5",
                                                            {
                                                                selected:
                                                                    editingItems.find(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.name
                                                                                .trim()
                                                                                .toLowerCase() ===
                                                                                e.name
                                                                                    .trim()
                                                                                    .toLowerCase() &&
                                                                            item.template
                                                                                .trim()
                                                                                .toLowerCase() ===
                                                                                getCurrentTemplate()
                                                                                    .trim()
                                                                                    .toLowerCase() &&
                                                                            item.section
                                                                                .trim()
                                                                                .toLowerCase() ===
                                                                                s.name
                                                                                    .trim()
                                                                                    .toLowerCase() &&
                                                                            item.checklist
                                                                                .trim()
                                                                                .toLowerCase() ===
                                                                                currentChecklist
                                                                                    .trim()
                                                                                    .toLowerCase()
                                                                    ) != null,
                                                            }
                                                        )}
                                                    >
                                                        {e.name}
                                                        <Checkbox
                                                            className="ml-auto"
                                                            value={
                                                                editingItems.find(
                                                                    (item) =>
                                                                        item.name
                                                                            .trim()
                                                                            .toLowerCase() ===
                                                                            e.name
                                                                                .trim()
                                                                                .toLowerCase() &&
                                                                        item.template
                                                                            .trim()
                                                                            .toLowerCase() ===
                                                                            getCurrentTemplate()
                                                                                .trim()
                                                                                .toLowerCase() &&
                                                                        item.section
                                                                            .trim()
                                                                            .toLowerCase() ===
                                                                            s.name
                                                                                .trim()
                                                                                .toLowerCase() &&
                                                                        item.checklist
                                                                            .trim()
                                                                            .toLowerCase() ===
                                                                            currentChecklist
                                                                                .trim()
                                                                                .toLowerCase()
                                                                ) != null
                                                            }
                                                        />
                                                    </StyledMenuItem>
                                                ))}
                                        </div>
                                    </TodoContainer>
                                ))}
                            </Container>
                        </div>
                        <div className="flex items-center h-14 border-t border-gray-200 px-6  gap-10">
                            <Button
                                outline
                                className="border-none shadow-none"
                                onClick={() => setIsOpen(false)}
                            >
                                Hủy
                            </Button>
                            <Button
                                className="ml-auto text-md w-24"
                                onClick={handleDone}
                            >
                                Xong
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default DialogSelectTodo

const TempateContainer = styled.div`
    box-shadow: 0px 2px 5px #ccc;
    z-index: 2;
`

const TodoContainer = styled.div`
    padding: 8px 16px;
`

const SectionName = styled.div`
    color: #333;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
`

const StyledMenuItem = styled.div`
    user-select: none;
    color: #222;

    &.selected {
        font-weight: 500;
        color: black;
        background-color: #efefef;
    }
    &:hover {
        background-color: #ededed;
        cursor: default;
    }
`
const TemplateName = styled.div`
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    height: 32px;
    border-left: 3px solid transparent;
    padding-left: 12px;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    &:hover {
        background-color: #f5f6f7;
        cursor: pointer;
    }
    &.selected {
        border-left: 3px solid var(--blue);
        background-color: var(--light-blue);
        color: var(--blue);
    }
`
