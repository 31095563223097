import classNames from "classnames"
import { Button } from "core/button/Button"
import { useEffect, useRef, useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import EventNoteChangeCount from "./EventNoteChangeCount/EventNoteChangeCount"

const EventNote = () => {
    const { eventNote, currentEvent } = useSnapshot(eventDetailStore)
    const [focus, setFocus] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const textareRef = useRef<HTMLTextAreaElement>(null)

    const [text, setText] = useState(eventNote)
    useEffect(() => {
        setText(eventNote)
    }, [eventNote])

    const handleDone = async () => {
        eventDetailStore.eventNote = text
        // saveEventNoteDraft(text, currentEvent.id)
        saveDraft()
        setEditMode(false)
    }

    const handleCancel = () => {
        setEditMode(false)
        setText(eventNote)
    }

    const handleFocus = () => {
        setFocus(true)
    }

    return (
        <Wrap className={classNames({ focus: focus, readonly: !editMode })}>
            <div className="pt-3 text-md bg-white rounded-md mb-3  mt-2 shadow">
                <div className={classNames("text-base font-semibold pl-5 mt-1")}>
                    Ghi chú <EventNoteChangeCount />
                </div>
                <div className={classNames("px-5 pb-5 pt-3")}>
                    <EditorWrap
                        tabIndex={0}
                        className={classNames({
                            active: editMode,
                            "is-blank": !text,
                        })}
                        data-placeholder="Ghi chú"
                        onClick={() => {
                            if (!editMode) {
                                setEditMode(true)
                                setTimeout(() => {
                                    textareRef.current?.focus()
                                })
                            }
                        }}
                    >
                        <StyledTextArea
                            minRows={editMode ? 5 : 1}
                            value={text}
                            ref={textareRef}
                            onFocus={handleFocus}
                            onBlur={() => setFocus(false)}
                            className={classNames("text-md")}
                            onChange={(e) => {
                                setText(e.target.value)
                            }}
                        />
                        {!editMode && (
                            <span className="icon-edit pencil text-xs" />
                        )}
                    </EditorWrap>
                    {editMode && (
                        <div className="flex items-center gap-3 mt-5 action-buttons">
                            <Button size="sm" onClick={handleDone}>
                                Xong
                            </Button>
                            <Button size="sm" outline onClick={handleCancel}>
                                Hủy
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Wrap>
    )
}

export default EventNote

const StyledTextArea = styled(TextareaAutosize)`
    border-color: transparent;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding-top: 4px;
    width: 100%;
`

const Wrap = styled.div`
    &.focus {
        .ql-toolbar {
            opacity: 1;
            pointer-events: all;
        }
    }
    &.readonly {
        textarea {
            pointer-events: none;
            resize: none;
        }
    }
`

const EditorWrap = styled.div`
    border-radius: 4px;
    padding: 8px 15px;
    padding-bottom: 4px;
    position: relative;
    outline: 2px solid transparent;
    transition: outline 0.15s ease;
    border: 1px solid transparent;
    &.is-blank:before {
        color: rgba(0, 0, 0, 0.6);
        content: attr(data-placeholder);
        font-size: 13px;
        font-style: italic;
        left: 15px;
        top: 12px;
        pointer-events: none;
        position: absolute;
        right: 15px;
        font-family: Helvetica, Arial, sans-serif;
    }
    &:not(.active) {
        border: 1px solid #f5e8bc;
        background-color: #fff9e5;
    }
    &:hover {
        cursor: pointer;
        border: 1px solid #ffd866;
        .pencil {
            opacity: 1;
        }
    }

    transition: all 0.25s ease;

    &.active {
        padding-bottom: 10px;
        outline: 2px solid var(--link);
        &:hover {
            cursor: auto;
            border: 1px solid transparent;
            background-color: #fff;
        }
    }
    .pencil {
        position: absolute;
        color: orange;
        top: 6px;
        right: 10px;
        opacity: 0;
        pointer-events: none;
    }
`
