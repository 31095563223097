import { FloatingPortal, autoPlacement, offset, useFloating } from '@floating-ui/react';
import classNames from "classnames";
import Spinner from '../loader/Spinner';
import { useCallback, useContext, useRef, useState } from "react";
import { styled } from "styled-components";
import { moveDown, moveLeft, moveRight, moveUp } from './utils';
import { cellBorderColor } from './config';
import { stripAccents } from '../utils';
import { CellContext, ICellContext } from './context';
import React from 'react';

export interface Props<T, K> {
    data: T,
    colKey: string
    getOptions: (data: T) => Promise<K[]>
    getOptionLabel: (option: K) => string
    getOptionValue: (option: K) => string | number
    getCellLabel: (data: T) => string
    onUpdateValue: (data: T, option: K) => void
    onDelete: (data: T, colKey: string) => void

}
const SelectCellAsync = <T extends object, K extends object>({ data, colKey, getOptions, getOptionLabel, getOptionValue, getCellLabel, onUpdateValue, onDelete }: React.PropsWithChildren<Props<T, K>>) => {

    const [showDropdown, setShowDropdown] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [options, setOptions] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const { setData, setColKey } = useContext<ICellContext<T>>(CellContext)

    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const handleFocus = () => {
        setInputValue('')
        setShowDropdown(false)
        setData(data)
        setColKey(colKey)
    }

    /**
     *      Khi textarea bị blur thì ẩn dropdown và ẩn highlight ô bị blur
     */
    const handleBlur = () => {
        setInputValue('')
        setShowDropdown(false)
        setData(null)
    }

    /**
     *      Khi menu unit xổ ra, ấn lên xuống thì cập nhật textarea value, (chưa cập nhật vào store)
     */
    const goDownAndUpdateUnit = () => {
        if (options.length > 0) {
            let _item = getFirstItemMatch(inputValue)

            let index = options.map(e => getOptionLabel(e)).indexOf(getOptionLabel(_item) || '')
            if (index === -1) {
                index = 0
            } else {
                index++;
                if (index == options.length) {
                    index = 0
                }
            }
            setInputValue(getOptionLabel(options[index]))
        }
    }

    /**
     *      Khi menu unit xổ ra, ấn lên xuống thì cũng nhật textarea value, (chưa cập nhật vào store)
     */
    const goUpAndUpdateUnit = () => {
        if (options.length > 0) {
            let item = getFirstItemMatch(inputValue)

            let index = options.map(e => getOptionLabel(e)).indexOf(getOptionLabel(item) || '')
            if (index === -1) {
                index = options.length - 1
            } else {
                index--;
                if (index == options.length) {
                    index = 0
                }
            }
            setInputValue(getOptionLabel(options[index]))
        }
    }

    /**
     * 
    *       Khi menu unit xổ ra, click vào 1 item thì update lại unit ở ô đang focus
    */
    const onClickMenuItem = (option: any) => {
        onUpdateValue(data, option)
    }

    const loadOptionsIfNeeded = () => {
        if (!isDataLoaded) {
            setLoading(true)
            getOptions(data).then((value) => {
                setOptions(value)
                setIsDataLoaded(true)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }


    const editCell = () => {
        textAreaRef.current?.focus()

        setShowDropdown(true)
        setInputValue(`${getCellLabel(data)}`)
        setTimeout(() => {
            textAreaRef.current?.select()
        }, 20)
        loadOptionsIfNeeded()
    }

    //khi double click vào ô thì hiện dropdown
    const onDoubleClick = () => {
        if (!showDropdown) {
            editCell()
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value
        setShowDropdown(true)
        setInputValue(value)
        loadOptionsIfNeeded()
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation()
        if (e.key === 'Escape') {
            // paymentStore.activeKey = ''
            setInputValue('')
            setShowDropdown(false)

        } else if (e.key === 'Enter') {
            e.preventDefault()
            if (!showDropdown) {
                setShowDropdown(true)
                editCell()
            } else {
                let option = getFirstItemMatch(inputValue)
                setInputValue('')
                setShowDropdown(false)
            }
        } else if (e.key === 'Delete' || e.key === 'Backspace') {
            onDelete(data, colKey)
        }
        else if (e.key === 'ArrowRight') {
            moveRight(textAreaRef.current)
        } else if (e.key === 'ArrowLeft') {
            moveLeft(textAreaRef.current)
        } else if (e.key === 'ArrowDown') {
            if (showDropdown) {
                e.preventDefault()
                goDownAndUpdateUnit()

            } else {
                setInputValue('')
                setShowDropdown(false)
                moveDown(textAreaRef.current)
            }
        } else if (e.key === 'ArrowUp') {
            if (showDropdown) {
                e.preventDefault()
                goUpAndUpdateUnit()
            } else {

                moveUp(textAreaRef.current)
            }
        } else if (e.key === 'Tab') {
            e.preventDefault()
            moveRight(textAreaRef.current)
        }
    }
    const { refs, floatingStyles } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [
            autoPlacement(),
            offset(10)
        ]
    })

    const getFirstItemMatch = useCallback((searchText: string) => {
        const item = options.find(e => stripAccents(getOptionLabel(e)).toLowerCase().startsWith(stripAccents(searchText).toLowerCase()))
        return item
    }, [options])
    const item = getFirstItemMatch(inputValue)
    return (
        <>
            <Wrap
                ref={refs.setReference}
                onDoubleClick={onDoubleClick}
                className={
                    classNames(
                        'relative flex justify-center')
                }
            >{getCellLabel(data)}

                <textarea
                    ref={textAreaRef}
                    value={inputValue}
                    onBlur={handleBlur}
                    className={classNames({ 'show-cursor': showDropdown })}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                />

            </Wrap>
            {
                showDropdown && (

                    <FloatingPortal>
                        <DropdownMenu
                            className="shadow-menu rounded-lg py-2"
                            ref={refs.setFloating}
                            style={floatingStyles}
                        >
                            {
                                loading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        {
                                            options.map(e => (
                                                <div
                                                    onMouseDown={() => onClickMenuItem(e)}
                                                    className={
                                                        classNames(
                                                            'menu-item',
                                                            { active: item ? getOptionLabel(e) === getOptionLabel(item) : false }
                                                        )
                                                    }
                                                    key={getOptionValue(e)}

                                                >{getOptionLabel(e)}</div>
                                            ))
                                        }
                                        {
                                            options.length === 0 && (
                                                <div className='text-xs text-gray-200'>Không có dữ liệu</div>
                                            )
                                        }
                                    </>
                                )
                            }
                        </DropdownMenu>
                    </FloatingPortal>
                )
            }
        </>
    )
}

export default React.memo(SelectCellAsync) as typeof SelectCellAsync

const Wrap = styled.div`
    outline: none;
    display: flex;
    align-items: center;
    padding: 8px;
    white-space: pre-wrap;
    min-width: 0;
    font-size: 14px;
    border-right: 1px solid ${cellBorderColor};
    &:last-child {
        padding: 4px 0;
    }
    &:focus-within {
        outline: 2px solid royalblue;
        background-color: #fff;
        z-index: 1;
    }

    textarea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: transparent;
        padding: 8px;
        outline: none;
        background-color: transparent;
        resize: none;
        caret-color: transparent;
        cursor: default;
        &.show-cursor {
            background-color: white;
            color: var(--text-primary);
            caret-color: var(--text-primary);
        }
    }
`

const DropdownMenu = styled.div`
    width: 180px;
    background-color: #fff;
    overflow: hidden;
    div {
        font-size: 14px;
        padding: 4px 16px;        
    }

    .menu-item {
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
    }
`