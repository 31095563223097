import Skeleton from "core/skeleton/Skeleton"

const EventDetailLeftSkeleton = () => {
    return (
        <>
            <Skeleton />
            <Skeleton height={56} />
            <Skeleton count={2} />
            <hr className="my-5" />
            <Skeleton />
            <Skeleton count={10} />
        </>
    )
}

export default EventDetailLeftSkeleton
