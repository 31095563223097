import { Timeline } from "models/timeline"
import { getEndHour } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"

const ScheduleDetail = () => {
    const { places, eventTypes } = useSnapshot(rootStore)
    const { editingAppointments } = useSnapshot(eventDetailStore)
    return (
        <Table>
            <thead className=" text-13 text-secondary font-medium">
                <tr>
                    <td>Sảnh</td>
                    <td>Bắt đầu</td>
                    <td>Kết thúc</td>
                    <td>Loại hình</td>
                </tr>
            </thead>
            <tbody>
                {editingAppointments.map((e) => (
                    <tr className="text-sm" key={e.key}>
                        <td className="flex items-center cell">
                            <div
                                className="rounded-full w-2 h-2 mr-3"
                                style={{
                                    backgroundColor: `${
                                        eventTypes.find(
                                            (item) => item.id == e.typeId
                                        )?.color
                                    }`,
                                }}
                            />
                            {places.find((item) => item.id == e.placeId)?.name}
                        </td>
                        <td className="cell">
                            {e.hour.padZero()}:{e.min.padZero()}
                        </td>
                        <td className="cell">
                            {getEndHour(e.hour, e.min, e.duration)[0].padZero()}
                            :
                            {getEndHour(e.hour, e.min, e.duration)[1].padZero()}
                        </td>
                        <td className="cell">
                            {
                                eventTypes.find((item) => item.id == e.typeId)
                                    ?.name
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}
const Table = styled.table`
    margin-top: 20px;
    border-right:  1px solid #e0e0e0;
    border-bottom:  1px solid #e0e0e0;

    td {
        padding: 8px 12px;
        border-left: 1px solid #e0e0e0;
        border-top:  1px solid #e0e0e0;
        background-color: #fff;
        &:nth-child(2), &:nth-child(3) {
            text-align: center;
        }
    }
    thead td {
        background-color: #f3f3f3;
        text-align: center;
        min-width: 120px;
        &:first-child,&:last-child  {
            min-width: 160px;
        }
    }
`


export default ScheduleDetail
