import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import { styled } from "styled-components"
import {
    useFloating,
    autoPlacement,
    offset,
    FloatingPortal,
} from "@floating-ui/react"
import { useClickOutside } from "core/hooks"
import { Button } from "core/button/Button"
import { Input } from "core/styledComponents"
import EmptyContent from "core/EmptyContent"
import TableReserveChangeCount2 from "./TableReserveChangeCount2"
import TableReserveChange from "modules/EventDetail/GeneralTab/InputTableReserve/TableReserveChangeCount/TableReserveChange/TableReserveChange"

type Props = {
    tableReserve: number
    onDone: (value: number) => void
}
const SelectTableReserve = ({ tableReserve, onDone }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenChangeCount, setIsOpenChangeCount] = useState(false)
    const [active, setActive] = useState(false)

    const fromRef = useRef<HTMLInputElement>(null)

    const [from, setFrom] = useState("")

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                fromRef?.current?.focus()
                fromRef?.current?.select()
            }, 10)
        }
    }, [isOpen])

    useEffect(() => {
        setFrom(tableReserve ? `${tableReserve}` : "")
    }, [tableReserve])

    const handleChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value

        const num = Number(val) || 0
        if (!num) {
            setFrom("")
        } else {
            setFrom(val)
        }
    }

    const handleKeydown = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            setIsOpen(false)
        }
        if (e.key === "Enter") {
            setIsOpen(false)
            onDone(Number(from) || 0)
        }
    }
    const handleDone = () => {
        onDone(Number(from))
        setIsOpen(false)
    }

    const handleMousEnter = () => {
        setActive(true)
    }

    const handleMouseLeave = () => {
        setActive(false)
    }

    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [autoPlacement(), offset(40)],
    })

    const isEmpty = !tableReserve

    const handleClickOutside = () => {
        setIsOpen(false)
    }
    useClickOutside(handleClickOutside, refs.floating, refs.domReference)
    return (
        <>
            <Wrap
                ref={refs.setReference}
                onMouseDown={(e) => {
                    e.preventDefault()
                }}
                className={classNames(
                    "relative mt-7  select-none cursor-pointer",
                    { active: active || isOpen }
                )}
                onMouseEnter={handleMousEnter}
                onMouseLeave={handleMouseLeave}
                tabIndex={0}
                onClick={() => setIsOpen((s) => !s)}
            >
                <Label
                    className={classNames("text-13 text-secondary label mb-1")}
                >
                    Bàn dự phòng
                </Label>
                {isEmpty && !isOpen && !active && (
                    <EmptyContent className="text-[#555]" />
                )}
                <span className="font-medium text-sm">
                    {tableReserve || ""}
                </span>
                &nbsp;
                {active && !isOpen && <EditIcon className="icon-pen" />}
                <TableReserveChangeCount2
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsOpenChangeCount(true)
                    }}
                    onMouseEnter={(e) => {
                        setActive(false)
                    }}
                />
            </Wrap>
            <TableReserveChange
                isOpen={isOpenChangeCount}
                setIsOpen={setIsOpenChangeCount}
            />
            {isOpen && (
                <FloatingPortal>
                    <GuestInputWrap
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className="shadow-menu bg-white rounded-lg px-8 py-4  z-20"
                    >
                        {/* <InputGuestFrom /> */}
                        <div className="text-sm font-medium">Bàn dự phòng</div>
                        <div className="flex items-center mt-[18px] text-13">
                            <Input
                                placeholder={"0"}
                                value={from}
                                ref={fromRef}
                                onChange={handleChangeFrom}
                                onKeyDown={handleKeydown}
                                autoFocus
                                className="w-[90px] ml-5"
                            />
                        </div>
                        <div className="flex items-center mt-7">
                            <Button
                                className="mr-4"
                                size="sm"
                                onClick={handleDone}
                            >
                                Xong
                            </Button>
                            <Button
                                outline
                                size="sm"
                                onClick={() => setIsOpen(false)}
                            >
                                Hủy
                            </Button>
                        </div>
                        <Arrow />
                    </GuestInputWrap>
                </FloatingPortal>
            )}
        </>
    )
}

export default SelectTableReserve

const Wrap = styled.div`
    border-bottom: 1px solid transparent;
    &.active {
        border-bottom: 1px solid var(--link);
    }
`
const EditIcon = styled.span`
    position: absolute;
    font-size: 12px;
    color: var(--link);
    top: 22px;
    right: 20px;
`

const GuestInputWrap = styled.div`
    width: 370px;
    height: 170px;
`

const Arrow = styled.div`
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    border-top: 1px solid var(--border-input);
    border-left: 1px solid var(--border-input);
    transform: translateX(-10px) translateY(-100%) rotate(-45deg);
`

const Label = styled.div`
    transition: top 0.25s ease-out;
`
