import { MenuSize } from "models/menu_size"
import API from "./api"
import { CommonResponse } from "models/response"
import { useEffect, useState } from "react"
import { EventType } from "models/eventType"
import store from "store2"
import { parseColor } from "./utils"
const KEY = `PLACE_ITEMS`
const ALL_ITEMS = `${KEY}_ALL`

const getAll = async (): Promise<EventType[]> => {
    var res = await API.get(`/event-types`)
    return res.data.data
}

export const useEventTypes = () => {
    const [eventTypes, setEventTypes] = useState<EventType[]>(
        store.get(ALL_ITEMS) || []
    )
    useEffect(() => {
        ;(async () => {
            var items = await getAll()
            setEventTypes(
                items.map((e) => ({ ...e, color: parseColor(e.color) }))
            )
            store.set(ALL_ITEMS, items)
        })()
    }, [])
    return eventTypes
}

const createMenuSize = async (menuSize: MenuSize): Promise<CommonResponse> => {
    var response = await API.post<CommonResponse>("/menu-sizes", {
        menuId: menuSize.menuId,
        name: menuSize.name,
        amount: menuSize.amount,
        price: menuSize.price,
    })
    return response.data
}
const updateMenuSize = async (menuSize: MenuSize): Promise<CommonResponse> => {
    var response = await API.put<CommonResponse>("/menu-sizes", {
        id: menuSize.id,
        name: menuSize.name,
        amount: menuSize.amount,
        price: menuSize.price,
    })
    return response.data
}

const EventTypeService = {
    getAll,
    createMenuSize,
    updateMenuSize,
}

export default EventTypeService
