import { parseColor } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import { COL_MARGIN, YAXIS_WIDTH } from "./TimePicker"
import * as Tooltip from "@radix-ui/react-tooltip"
import IconCheckmarkThin from "common/icons/IconCheckmarkThin"

type Props = {
    scrollLeft: number
    scrollTop: number
    top: number
    colIndex: number
    colWidth: number
}
const CheckButton = ({
    scrollLeft,
    scrollTop,
    top,
    colIndex,
    colWidth,
}: Props) => {
    const { eventTypes } = useSnapshot(rootStore)
    const { editingKey } = useSnapshot(eventDetailStore)
    const editingAppointment = eventDetailStore.editingAppointments.find(
        (e) => e.key === editingKey
    )
    const getColor = (typeID: number) => {
        var color = eventTypes.find((e) => e.id === typeID)?.color || ""
        return parseColor(color)
    }
    const onMouseDown = () => {
        eventDetailStore.toggleViewMode()
    }
    return (
        <>
            {!!editingAppointment && (
                <Tooltip.Provider>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <CheckButtonWrap
                                onMouseDown={onMouseDown}
                                style={{
                                    transform: `translate(${
                                        -scrollLeft +
                                        colWidth * 0.75 -
                                        COL_MARGIN * 2 +
                                        colWidth * colIndex +
                                        COL_MARGIN / 2 +
                                        YAXIS_WIDTH
                                    }px, ${-scrollTop + 30 - 8 + top}px)`,
                                    backgroundColor: `#fff`,
                                }}
                            >
                                <IconCheckmarkThin
                                    style={{
                                        color: getColor(
                                            editingAppointment.typeId
                                        ),
                                    }}
                                />
                            </CheckButtonWrap>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-[#fff] select-none rounded-[4px] bg-[rgba(0,0,0,1)] px-[12px] py-[6px] text-[13px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
                                sideOffset={5}
                            >
                                Xong
                                <Tooltip.Arrow className="fill-[rgba(0,0,0,1)]" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
            )}
        </>
    )
}

export default CheckButton

const CheckButtonWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 99px;
    position: absolute;
    span {
        &:hover {
            cursor: pointer;
        }
    }
    div {
        width: 10px;
        height: 10px;
        border-radius: 96px;
    }
`
