import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import classNames from "classnames"
import { useCallback, useContext, useRef, useState } from "react"
import { stripAccents } from "../utils"
import { styled } from "styled-components"
import { moveDown, moveLeft, moveRight, moveUp } from "./utils"
import { cellBorderColor } from "./config"
import { CellContext } from "./context"
import React from "react"

export interface Props<T, K> {
    data: T
    colKey: string
    options: K[]
    getOptionLabel: (option: K) => string
    getOptionValue: (option: K) => string | number
    getCellLabel: (data: T) => string
    onUpdateValue: (data: T, option: K) => void
    onDelete: (data: T, colKey: string) => void
}
const SelectCell = <T extends object, K extends object>({
    data,
    colKey,
    options,
    getOptionLabel,
    getOptionValue,
    getCellLabel,
    onUpdateValue,
    onDelete,
}: React.PropsWithChildren<Props<T, K>>) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [inputValue, setInputValue] = useState("")

    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const { setColKey, setData } = useContext(CellContext)

    const handleFocus = () => {
        setInputValue("")
        setShowDropdown(false)
        setColKey(colKey)
        setData(data)
    }

    /**
     *      Khi textarea bị blur thì ẩn dropdown và ẩn highlight ô bị blur
     */
    const handleBlur = () => {
        setInputValue("")
        setShowDropdown(false)
        setData(null)
    }

    /**
     *      Khi menu unit xổ ra, ấn lên xuống thì cập nhật textarea value, (chưa cập nhật vào store)
     */
    const goDownAndUpdateUnit = () => {
        if (options.length > 0) {
            let _item = getFirstItemMatch(inputValue)

            let index = _item
                ? options
                      .map((e) => getOptionLabel(e))
                      .indexOf(getOptionLabel(_item) || "")
                : -1
            if (index === -1) {
                index = 0
            } else {
                index++
                if (index == options.length) {
                    index = 0
                }
            }
            setInputValue(getOptionLabel(options[index]))
        }
    }

    const saveCellContent = () => {
        let option = getFirstItemMatch(inputValue)
        if (option) {
            onUpdateValue(data, option)
        }
    }

    /**
     *      Khi menu unit xổ ra, ấn lên xuống thì cũng nhật textarea value, (chưa cập nhật vào store)
     */
    const goUpAndUpdateUnit = () => {
        if (options.length > 0) {
            let item = getFirstItemMatch(inputValue)

            let index = item
                ? options
                      .map((e) => getOptionLabel(e))
                      .indexOf(getOptionLabel(item) || "")
                : -1
            if (index === -1) {
                index = options.length - 1
            } else {
                index--
                if (index == options.length) {
                    index = 0
                }
            }
            setInputValue(getOptionLabel(options[index]))
        }
    }

    /**
     *
     *       Khi menu unit xổ ra, click vào 1 item thì update lại unit ở ô đang focus
     */
    const onClickMenuItem = (option: any) => {
        onUpdateValue(data, option)
    }

    const editCell = () => {
        textAreaRef.current?.focus()

        setShowDropdown(true)
        setInputValue(`${getCellLabel(data)}`)
        setTimeout(() => {
            textAreaRef.current?.select()
        }, 20)
    }

    //khi double click vào ô thì hiện dropdown
    const onDoubleClick = () => {
        if (!showDropdown) {
            editCell()
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value
        setShowDropdown(true)
        setInputValue(value)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation()
        if (e.key === "Escape") {
            // paymentStore.activeKey = ''
            setInputValue("")
            setShowDropdown(false)
        } else if (e.key === "Enter") {
            e.preventDefault()
            if (!showDropdown) {
                setShowDropdown(true)
                editCell()
            } else {
                saveCellContent()
                setInputValue("")
                setShowDropdown(false)
            }
        } else if (e.key === "Delete" || e.key === "Backspace") {
            onDelete(data, colKey)
        } else if (e.key === "ArrowRight") {
            moveRight(textAreaRef.current)
        } else if (e.key === "ArrowLeft") {
            moveLeft(textAreaRef.current)
        } else if (e.key === "ArrowDown") {
            if (showDropdown) {
                e.preventDefault()
                goDownAndUpdateUnit()
            } else {
                setInputValue("")
                setShowDropdown(false)
                moveDown(textAreaRef.current)
            }
        } else if (e.key === "ArrowUp") {
            if (showDropdown) {
                e.preventDefault()
                goUpAndUpdateUnit()
            } else {
                moveUp(textAreaRef.current)
            }
        } else if (e.key === "Tab") {
            e.preventDefault()
            moveRight(textAreaRef.current)
        }
    }
    const { refs, floatingStyles } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [autoPlacement(), offset(10)],
    })

    const getFirstItemMatch = useCallback(
        (searchText: string) => {
            const item = options.find(
                (e) =>
                    stripAccents(getOptionLabel(e))
                        .toLowerCase()
                        .startsWith(stripAccents(searchText).toLowerCase()) ||
                    stripAccents(getOptionLabel(e))
                        .toLowerCase()
                        .includes(` ${stripAccents(searchText).toLowerCase()}`)
            )
            return item
        },
        [options]
    )
    const item = getFirstItemMatch(inputValue)
    return (
        <>
            <Wrap
                ref={refs.setReference}
                onDoubleClick={onDoubleClick}
                className={classNames("relative flex justify-center")}
            >
                {getCellLabel(data)}

                <textarea
                    ref={textAreaRef}
                    value={inputValue}
                    onBlur={handleBlur}
                    className={classNames({ "show-cursor": showDropdown })}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                />
            </Wrap>
            {showDropdown && (
                <FloatingPortal>
                    <DropdownMenu
                        className="shadow-menu rounded-lg py-2 z-[100]"
                        ref={refs.setFloating}
                        style={floatingStyles}
                    >
                        {options.map((e) => (
                            <div
                                onMouseDown={() => onClickMenuItem(e)}
                                className={classNames("menu-item", {
                                    active: item
                                        ? getOptionLabel(e) ===
                                          getOptionLabel(item)
                                        : false,
                                })}
                                key={getOptionValue(e)}
                            >
                                {getOptionLabel(e)}
                            </div>
                        ))}
                    </DropdownMenu>
                </FloatingPortal>
            )}
        </>
    )
}

export default React.memo(SelectCell) as typeof SelectCell

const Wrap = styled.div`
    outline: none;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    white-space: pre-wrap;
    min-width: 0;
    font-size: 14px;
    border-right: 1px solid ${cellBorderColor};
    &:last-child {
        padding: 8px 0;
    }
    &:focus-within {
        outline: 2px solid royalblue;
        background-color: #fff;
        z-index: 1;
    }

    textarea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: transparent;
        padding: 4px 8px;
        outline: none;
        background-color: transparent;
        resize: none;
        caret-color: transparent;
        cursor: default;
        &.show-cursor {
            background-color: white;
            color: var(--text-primary);
            caret-color: var(--text-primary);
        }
    }
`

const DropdownMenu = styled.div`
    width: 180px;
    background-color: #fff;
    overflow: hidden;
    div {
        font-size: 14px;
        padding: 4px 16px;
    }

    .menu-item {
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
    }
`
