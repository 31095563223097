import { EventItem, EventLite } from "models/events/event"
import { useCallback } from "react"
import { parseDateFromNumber } from "services/utils"
import { dialogStore } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import styled from "styled-components"

type Props = {
    eventLite: EventLite
    color: string
}

const EventCard = ({ eventLite, color }: Props) => {
    const previewEvent = useCallback(() => {
        let eventItem: EventItem = EventItem.generateEmpty()
        eventItem.id = eventLite.id
        eventItem.title = eventLite.title
        eventItem.startTime = eventLite.startTime
        eventItem.timeline = eventLite.timeline
        eventItem.startDate = parseDateFromNumber(eventItem.startTime)

        eventDetailStore.initEventBasicInfo(eventItem)
        dialogStore.showEventPanel = true
        if (
            eventItem.id == eventDetailStore.currentEvent.id &&
            eventItem.id !== 0
        ) {
            //xử lý trường hợp ví dụ vào view 1 event có id = 7, xong ra, vào view lại đúng event này thì cần refresh lại dữ liệu
            //vì mỗi lần ra thì dữ liệu bị reset
            eventDetailStore.refreshCount++
        }
    }, [eventLite])

    return (
        <Wrap
            onClick={previewEvent}
            style={{
                backgroundColor: color,
            }}
        >
            {eventLite.title || "Không có tiêu đề"}
        </Wrap>
    )
}

export default EventCard

const Wrap = styled.div`
    cursor: default;
    display: block;
    background-color: #0e60d1;
    color: white;
    border-radius: 2px;
    text-align: left;
    padding: 1px 4px;
    margin: 1px 2px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
        color: #fff;
        text-decoration: underline;
    }
`
