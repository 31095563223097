import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { getDatabase, onValue, ref } from "firebase/database"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Input } from "core/styledComponents"
import { Button } from "core/button/Button"
import { getMessaging, getToken } from "firebase/messaging"
import { VAPID_KEY } from "services/firebase"
import NotificationService from "services/notificationService"
import { ToastContainer, Zoom } from "react-toastify"

const Login = () => {
    useEffect(() => {}, [])
    const [userAndCompany, setUserAndCompany] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        ;(async () => {
            await getAuth().signOut()
        })()
    }, [])

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
                handleLogin()
            }
        },
        [userAndCompany, password]
    )

    const handleLogin = async () => {
        var [companyCode, userName] = userAndCompany.split("/")
        if (userName && companyCode) {
            var auth = getAuth()
            try {
                setLoading(true)
                await signInWithEmailAndPassword(
                    auth,
                    `${userName}_${companyCode}_user@gmail.com`,
                    password
                )
                // const messaging = getMessaging()
                // const token = await getToken(messaging, {
                //     vapidKey: VAPID_KEY,
                // })

                // console.log({ token })
                navigate("/")
            } catch (error: any) {
                setLoading(false)
                if (error.code && error.code == "auth/wrong-password") {
                    NotificationService.error("Sai mật khẩu")
                }
            }
        }
    }
    return (
        <>
            <div className="max-w-xl mx-auto mt-[25vh]">
                <div className="flex items-center gap-4 mb-4">
                    <div className="flex-1">Tên đăng nhập</div>
                    <div className="flex-1">
                        <Input
                            placeholder="username"
                            value={userAndCompany}
                            onChange={(e) => setUserAndCompany(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <div className="flex-1"> Mật khẩu</div>
                    <div className="flex-1">
                        <Input
                            placeholder="password"
                            type="password"
                            value={password}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-center mt-4">
                    <Button
                        loading={loading}
                        className="primary"
                        onClick={handleLogin}
                    >
                        Đăng nhập
                    </Button>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={4000}
                transition={Zoom}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}

export default Login
