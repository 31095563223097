import { Page } from "core/styledComponents"
import AppHeader from "modules/header/AppHeader"
import { NavLink } from "react-router-dom"
import FeedbackReportHeader from "./FeedbackReportHeader"
import FeedbackReportTableBody from "./FeedbackReportTableBody"
import { useEffect, useState } from "react"
import { FeedbackReportItem } from "models/feedbackReportItem"
import CustomerReportService from "services/customerReportService"
import MonthPicker from "core/datepicker/MonthPicker"

const FeedbackReport = () => {
    const [items, setItems] = useState<FeedbackReportItem[]>([])
    const [month, setMonth] = useState(`${new Date().getMonth() + 1}.${new Date().getFullYear()}`)

    useEffect(() => {
        (async () => {

            var { count, items } = await CustomerReportService.getFeedbackReport(month)
            setItems(items)
        })()
    }, [month])
    return (
        <Page>
            <AppHeader />
            <div className="flex items-center my-5 mx-12">
                <div className="flex items-center text-sm">
                    <NavLink
                        className={"text-link hover:underline"}
                        to={`/bao-cao`}
                    >
                        Báo cáo
                    </NavLink>
                    <span className="mx-1">/</span>
                    <NavLink
                        className={"text-link hover:underline"}
                        to={`/bao-cao`}
                    >
                        Feedback khách hàng
                    </NavLink>
                </div>
            </div>
            <hr />
            <div className="flex items-center mx-20 mt-10">
                <MonthPicker
                    value={month}
                    onSelect={(s: string) => {
                        setMonth(s)
                    }}
                />
            </div>
            <div className="mx-20 pt-5" >
                <FeedbackReportTableBody
                    items={items}
                />
            </div>
        </Page>
    )
}

export default FeedbackReport
