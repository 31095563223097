import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import classNames from "classnames"
import EmptyContent from "core/EmptyContent"
import { Button } from "core/button/Button"
import { useClickOutside } from "core/hooks"
import { NumericInput } from "core/styledComponents"
import { useEffect, useState } from "react"
import { NumberFormatValues } from "react-number-format"
import { saveDepositDraft } from "services/depositDraft"
import { formatDeposit } from "services/utils"
import { styled } from "styled-components"
import DepositChangeCount2 from "./DepositChangeCount2"
import DepositChange from "modules/EventDetail/GeneralTab/InputDeposit/DepositChangeCount/DepositChange/DepositChange"

type Props = {
    deposit: number
    onDone: (deposit: number) => void
}
const SelectDeposit = ({ deposit, onDone }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenChange, setIsOpenChange] = useState(false)
    const [active, setActive] = useState(false)

    const [value, setValue] = useState(deposit)

    useEffect(() => {
        setValue(deposit)
    }, [deposit])

    const handleKeydown = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            setIsOpen(false)
        }
        if (e.key === "Enter") {
            handleDone()
        }
    }
    const handleDone = () => {
        setIsOpen(false)
        onDone && onDone(value)
    }

    const handleMousEnter = () => {
        setActive(true)
    }

    const handleMouseLeave = () => {
        setActive(false)
    }

    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: ["right"],
            }),
            offset(40),
        ],
    })

    const handleChange = (values: NumberFormatValues) => {
        const _value = values.floatValue
        setValue(_value || 0)
    }

    const handleClickOutside = () => {
        setIsOpen(false)
    }
    useClickOutside(handleClickOutside, refs.floating, refs.domReference)

    const isEmpty = !deposit
    return (
        <>
            <Wrap
                ref={refs.setReference}
                onMouseDown={(e) => {
                    e.preventDefault()
                }}
                className={classNames(
                    "relative mt-7  select-none cursor-pointer",
                    { active: active || isOpen }
                )}
                onMouseEnter={handleMousEnter}
                onMouseLeave={handleMouseLeave}
                tabIndex={0}
                onClick={() => setIsOpen((s) => !s)}
            >
                <Label
                    className={classNames("text-13 text-secondary label mb-1 ")}
                >
                    Đặt cọc
                </Label>
                {isEmpty && !isOpen && !active && (
                    <EmptyContent className="text-[#555]" />
                )}
                <span className="font-medium text-sm">
                    {deposit ? formatDeposit(deposit) : null}
                </span>
                &nbsp;
                {active && !isOpen && <EditIcon className="icon-pen" />}
                <DepositChangeCount2
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsOpenChange(true)
                    }}
                    onMouseEnter={(e) => {
                        setActive(false)
                    }}
                />
            </Wrap>
            <DepositChange isOpen={isOpenChange} setIsOpen={setIsOpenChange} />
            {isOpen && (
                <FloatingPortal>
                    <TableInputWrap
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className="shadow-menu bg-white rounded-lg px-8 py-4  z-20"
                    >
                        <div className="text-sm font-medium">Đặt cọc</div>

                        <div className="flex items-center mt-[18px] text-13">
                            <NumericInput
                                placeholder="0"
                                value={value || ""}
                                autoFocus
                                onKeyDown={handleKeydown}
                                allowLeadingZeros
                                thousandSeparator=","
                                onValueChange={handleChange}
                            />
                        </div>

                        <div className="flex items-center mt-7">
                            <Button
                                className="mr-4"
                                size="sm"
                                onClick={handleDone}
                            >
                                Xong
                            </Button>
                            <Button
                                outline
                                size="sm"
                                onClick={() => setIsOpen(false)}
                            >
                                Hủy
                            </Button>
                        </div>
                        <Arrow />
                    </TableInputWrap>
                </FloatingPortal>
            )}
        </>
    )
}

export default SelectDeposit

const Wrap = styled.div`
    border-bottom: 1px solid transparent;
    &.active {
        border-bottom: 1px solid var(--link);
    }
`

const EditIcon = styled.span`
    position: absolute;
    font-size: 12px;
    color: var(--link);
    top: 22px;
    right: 20px;
`

const Arrow = styled.div`
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    border-top: 1px solid var(--border-input);
    border-left: 1px solid var(--border-input);
    transform: translateX(-10px) translateY(-100%) rotate(-45deg);
`

const Label = styled.div`
    transition: top 0.25s ease-out;
`

const TableInputWrap = styled.div`
    outline: none;
    font-size: 13px;
    width: 350px;
`
