import { ReactNode, useContext } from "react";
import { tabHeadersContext, tabsContext } from "./tabContext";
import classNames from "classnames";

type TabProps = {
    index: number;
    className?: string
    children: ReactNode
};


function Tab({
    className,
    children,
    index,
}: TabProps) {
    const { activeTabs, onChange } = useContext(tabsContext);
    const { name } = useContext(tabHeadersContext);
    const isActive = !!activeTabs.find(
        (e) => e.index == index && e.name == name
    );
 
    return (
        <div
            onClick={() => onChange(index, name)}
            className={classNames(className, {
                'active': isActive,
            })}
        >{children}</div>
    );
}

export default Tab;
