import { useGeneralChangeCount } from "services/eventService"

const GeneralChangeCount = () => {
    // const {
    //     title,
    //     status,
    //     deposit,
    //     tableReserve,
    //     tableSetup,
    //     customerId,
    //     guestFrom,
    //     guestTo,
    //     eventNote,
    //     currentEvent,
    // } = useSnapshot(eventDetailStore)
    // const dateChange = useDateChanges()
    // const timelineChange = useTimelineChanges()
    // const titleChange = title !== currentEvent.title ? 1 : 0
    // const statusChange = status !== currentEvent.status ? 1 : 0
    // const depositChange = (deposit || 0) !== (currentEvent.deposit || 0) ? 1 : 0
    // const tableSetupChange = checkTableSetupChange(
    //     tableSetup,
    //     currentEvent.tableSetup
    // )
    //     ? 1
    //     : 0
    // const tableReserveChange =
    //     (tableReserve || 0) !== (currentEvent.tableReserve || 0) ? 1 : 0

    // const guestChange =
    //     formatGuestEstimate(guestFrom, guestTo) !=
    //     formatGuestEstimate(currentEvent.guestFrom, currentEvent.guestTo)
    //         ? 1
    //         : 0
    // const customerChange =
    //     (customerId || 0) !== (currentEvent.customerId || 0) ? 1 : 0
    // const noteChange = eventNote !== currentEvent.note ? 1 : 0

    // const totalChangeCount =
    //     dateChange +
    //     timelineChange +
    //     titleChange +
    //     statusChange +
    //     depositChange +
    //     guestChange +
    //     customerChange +
    //     tableSetupChange +
    //     tableReserveChange +
    //     noteChange
    const totalChangeCount= useGeneralChangeCount()
    if (!totalChangeCount) return null
    return (
        <span className="text-[#999] text-xs font-medium ml-2">
            +{totalChangeCount}
        </span>
    )
}

export default GeneralChangeCount
