const getQuillContent = (s: string) => {
    if(!s) return ""
    
}


const getPlainText = (quillContent: string) => {
    if (!quillContent) {
        return "";
    }
    if (quillContent.indexOf("{") == -1) {
        return quillContent;
    }

    var plainText = "";
    var delta = JSON.parse(quillContent);
    if (Array.isArray(delta)) {
        for (var part of delta) {
            plainText += part.insert;
        }
    } else if (delta.ops && Array.isArray(delta.ops)) {
        for (var part of delta.ops) {
            plainText += part.insert;
        }
    }

    //đoạn này xử lý loại bỏ ký tự xuống dòng (mặc định bị tự động gắn ký tự này ở cuối khi dùng Quill nên cần bỏ đi)
    if (plainText.endsWith("\n")) {
        plainText = plainText.substring(0, plainText.length - 1);
    }
    return plainText;
};

const QuillService = {
    getPlainText
}

export default QuillService