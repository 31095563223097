import { styled } from "styled-components";

const PlaceButton = styled.div<{ color: string }>`
    border-radius: 99px;
    min-width: 0;
    color: white;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    height: 28px;
    font-size: 13px;
    font-weight: 500;
    margin: 0 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: ${props => props.color}
`

export default PlaceButton