import { useState } from "react"
import { useTimelineChangeCount } from "services/eventService"
import { formatGuestEstimate } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import TimelineChangePopup from "./TimelineChange/TimelineChangePopup"

const TimelineChangeCount = () => {
    const { currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    const changed = useTimelineChangeCount()

    if (currentEvent.id == 0 || !changed) return null
    return (
        <>
            <span
                onClick={() => setIsOpen(true)}
                className="text-[orangered] text-13 font-normal ml-2 cursor-pointer hover:underline absolute right-[-32px] top-3"
            >
                +1
            </span>
            <TimelineChangePopup isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default TimelineChangeCount
