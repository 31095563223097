// icon:check | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
import * as React from "react"

function IconCheckmarkThin(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M20.61 5.207a1 1 0 01.183 1.403l-10 13a1 1 0 01-1.5.097l-5-5a1 1 0 011.414-1.414l4.195 4.195L19.207 5.39a1 1 0 011.403-.183z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default IconCheckmarkThin
