import * as React from "react"

function IconDate(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 16 18"
            {...props}
        >
            <path
                fill="#000"
                d="M13 2.5h3v15H0v-15h3v-1c0-.41.15-.76.44-1.06C3.73.15 4.09 0 4.5 0c.41 0 .77.15 1.06.44.29.3.44.65.44 1.06v1h4v-1c0-.41.15-.76.44-1.06.29-.29.65-.44 1.06-.44.41 0 .77.15 1.06.44.29.3.44.65.44 1.06v1zm-9-1V4a.491.491 0 00.5.5A.491.491 0 005 4V1.5c0-.14-.05-.26-.15-.35A.454.454 0 004.5 1c-.14 0-.26.05-.35.15-.1.09-.15.21-.15.35zm7 0V4c0 .14.05.26.14.36.1.09.22.14.36.14.14 0 .26-.05.36-.14.09-.1.14-.22.14-.36V1.5a.491.491 0 00-.5-.5.491.491 0 00-.5.5zm4 15v-10H1v10h14zm-10-9v2H3v-2h2zm2 0h2v2H7v-2zm4 2v-2h2v2h-2zm-6 1v2H3v-2h2zm2 0h2v2H7v-2zm4 2v-2h2v2h-2zm-6 1v2H3v-2h2zm4 2H7v-2h2v2zm4 0h-2v-2h2v2z"
            ></path>
        </svg>
    )
}

export default IconDate
