import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import DepositChange from "./DepositChange/DepositChange"

const DepositChangeCount = () => {
    const { deposit, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    const depositChange = (deposit || 0) != (currentEvent.deposit || 0)

    if (currentEvent.id == 0 || !depositChange) return null
    return (
        <>
            <span
                onClick={() => setIsOpen(true)}
                className="text-[orangered] text-13 font-normal cursor-pointer hover:underline absolute right-[-32px] top-2"
            >
                +1
            </span>
            <DepositChange isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default DepositChangeCount
