import { useState } from "react"
import { useDateChanges, useTimelineChangeCount } from "services/eventService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import DateChangePopup from "./DateChange/DateChangePopup"

const DateChangeCount = () => {
    const { eventNote, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    const dateChange = useDateChanges()

    if (currentEvent.id == 0 || !dateChange) return null
    return (
        <>
            <span
                onClick={() => setIsOpen(true)}
                className="text-[orangered] text-13 font-normal ml-2 cursor-pointer hover:underline"
            >
                +1
            </span>
            <DateChangePopup isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default DateChangeCount
