import classNames from "classnames"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import TextareaAutosize from "react-textarea-autosize"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EventNoteChange = ({ isOpen, setIsOpen }: Props) => {
    const { eventNote, currentEvent } = useSnapshot(eventDetailStore)

    const cancelChanges = () => {
        eventDetailStore.eventNote = currentEvent.note || ""
        // clearEventNoteDraft(currentEvent.id)
        saveDraft()
        setIsOpen(false)
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg min-h-[250px]  w-[550px] pb-5 mt-[25vh] bg-white">
                    <DialogTitle>Ghi chú </DialogTitle>
                    <div className="px-12 pb-7 pt-5">
                        <div className="text-sm  font-normal mb-2">
                            Giá trị cũ:
                        </div>
                        <EditorWrap
                            tabIndex={0}
                            className={classNames({
                                "is-blank": !currentEvent.note,
                            })}
                            data-placeholder="Ghi chú"
                        >
                            <StyledTextArea
                                value={currentEvent.note}
                                className={classNames("text-md")}
                            />
                        </EditorWrap>

                        <div className="text-sm font-normal mb-2 mt-7">
                            Giá trị mới:
                        </div>
                        <EditorWrap
                            tabIndex={0}
                            className={classNames({
                                "is-blank": !eventNote,
                            })}
                        >
                            <StyledTextArea
                                value={eventNote}
                                className={classNames("text-md")}
                            />
                        </EditorWrap>
                    </div>
                    <div className="justify-center flex ">
                        <Button outline onClick={cancelChanges}>
                            Hủy thay đổi
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default EventNoteChange

const StyledTextArea = styled(TextareaAutosize)`
    border-color: transparent;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding-top: 4px;
    width: 100%;
    resize: none;
`

const EditorWrap = styled.div`
    border-radius: 4px;
    padding: 8px 15px;
    padding-bottom: 4px;
    position: relative;
    outline: 2px solid transparent;
    transition: outline 0.15s ease;
    border: 1px solid transparent;
    pointer-events: none;
 
    &:not(.active) {
        border: 1px solid #f5e8bc;
        background-color: #fff9e5;
    }
`
