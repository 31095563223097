import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import { useCustomer } from "services/customerService"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomerChange = ({ isOpen, setIsOpen }: Props) => {
    const { customerId, customerName } =
        useSnapshot(eventDetailStore)
    useCustomer(customerId)

    const cancelChanges = () => {
        eventDetailStore.customerId =
            eventDetailStore.currentEvent.customerId || 0
        eventDetailStore.customerName =
            eventDetailStore.currentEvent.customerName || ""
        saveDraft()
        setIsOpen(false)
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg   w-[500px] pb-5 mt-[25vh] bg-white">
                    <DialogTitle>Thay đổi</DialogTitle>
                    <div className="px-12 pb-7 pt-5 text-[15px] text-center">
                        <span>
                            {eventDetailStore.currentEvent.customerName || ""}
                        </span>
                        <span className="text-[#aaa] mx-2">→</span>
                        <span>{customerName || ""}</span>{" "}
                    </div>
                    <div className="justify-center flex ">
                        <Button outline onClick={cancelChanges}>
                            Hủy thay đổi
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default CustomerChange
