import {
    FloatingPortal,
    autoPlacement,
    autoUpdate,
    offset,
    useFloating,
} from "@floating-ui/react"
import classNames from "classnames"
import EmptyContent from "core/EmptyContent"
import { Button } from "core/button/Button"
import { useClickOutside } from "core/hooks"
import { Input, NumericInput } from "core/styledComponents"
import TitleChange from "modules/EventDetail/GeneralTab/TitleChangeCount/TitleChange/TitleChange"
import { useEffect, useState } from "react"
import { NumberFormatValues } from "react-number-format"
import { formatDeposit } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"

type Props = {
    title: string
    onDone: (title: string) => void
}
const EventTitle = () => {
    const { currentEvent, title } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)
    const [active, setActive] = useState(false)
    const [isOpenChange, setIsOpenChange] = useState(false)

    const [value, setValue] = useState(title)

    useEffect(() => {
        setValue(title)
    }, [title])

    const handleKeydown = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            setIsOpen(false)
        }
        if (e.key === "Enter") {
            handleDone()
        }
    }
    const handleDone = () => {
        setIsOpen(false)
        eventDetailStore.title = value
    }

    const handleCancel = () => {
        setValue(eventDetailStore.title)
        setIsOpen(false)
    }
    const handleMousEnter = () => {
        setActive(true)
    }

    const handleMouseLeave = () => {
        setActive(false)
    }

    const { refs, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ["right"],
            }),
            offset(20),
        ],
    })

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }

    const handleClickOutside = () => {
        setIsOpen(false)
    }
    useClickOutside(handleClickOutside, refs.floating, refs.domReference)

    const changed = title != currentEvent.title
    return (
        <>
            <Wrap
                ref={refs.setReference}
                onMouseDown={(e) => {
                    e.preventDefault()
                }}
                className={classNames("relative select-none", {
                    active: active || isOpen,
                })}
                tabIndex={0}
                onClick={() => setIsOpen((s) => !s)}
            >
                <div
                    className={classNames(
                        "text-xl mt-5 font-semibold pl-5 pr-10  cursor-pointer",
                        currentEvent.isCancel
                            ? "text-[#e84b4b]"
                            : "text-primary "
                    )}
                    onMouseEnter={handleMousEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {currentEvent.isCancel ? "[Hủy]" : ""}
                    {title || "Chưa có tiêu đề"}
                    {active && !isOpen ? (
                        <EditIcon className="icon-pen ml-2" />
                    ) : (
                        <EditIcon className="icon-pen ml-2 opacity-0 pointer-events-none" />
                    )}
                </div>
                &nbsp;
                {changed && (
                    <span
                        onClick={(ev) => {
                            ev.stopPropagation()
                            setIsOpenChange(true)
                        }}
                        className="change-count hover:underline cursor-pointer ml-1"
                    >
                        +1
                    </span>
                )}
            </Wrap>
            <TitleChange isOpen={isOpenChange} setIsOpen={setIsOpenChange} />
            {isOpen && (
                <FloatingPortal>
                    <TableInputWrap
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className="shadow-menu bg-white rounded-lg px-8 py-4  z-20"
                    >
                        <div className="text-sm font-medium">
                            Tiêu đề sự kiện
                        </div>

                        <div className="flex items-center mt-[18px] text-13">
                            <Input
                                value={value}
                                onChange={handleInputChange}
                                // onBlur={handleBlur}
                                autoFocus
                                onKeyDown={handleKeydown}
                                placeholder={`Tối đa 100 ký tự`}
                            />
                        </div>

                        <div className="flex items-center mt-7">
                            <Button
                                className="mr-4"
                                size="sm"
                                onClick={handleDone}
                            >
                                Xong
                            </Button>
                            <Button outline size="sm" onClick={handleCancel}>
                                Hủy
                            </Button>
                        </div>
                        <Arrow />
                    </TableInputWrap>
                </FloatingPortal>
            )}
        </>
    )
}

export default EventTitle

const Wrap = styled.div`
    .change-count {
        position: absolute;
        font-size: 12px;
        color: red;
        top: 8px;
        right: 12px;
    }
`

const EditIcon = styled.span`
    /* position: absolute; */
    font-size: 12px;
    color: var(--link);
    /* top: 8px; */
    /* right: 20px; */
`

const Arrow = styled.div`
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    border-top: 1px solid var(--border-input);
    border-left: 1px solid var(--border-input);
    transform: translateX(-10px) translateY(-100%) rotate(-45deg);
`

const TableInputWrap = styled.div`
    outline: none;
    font-size: 13px;
    width: 450px;
`
