import {
    useFloating,
    autoPlacement,
    offset,
    FloatingOverlay,
    FloatingPortal,
} from "@floating-ui/react"
import classNames from "classnames"
import IconCheck from "common/icons/IconCheck"
import IconChevronRight from "common/icons/IconChevronRight"
import { useClickOutside } from "core/hooks"
import { useCallback, useState } from "react"
import { saveDraft } from "services/draftService"
import { parseOrderName } from "services/orderUtils"
import { extractTableType } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { parseMealTableSetupWithDefault } from "utils/TableSetupUtils"
import { useSnapshot } from "valtio"

export const OrderUnitPrice = () => {
    const { editingMenus, orderNames, currentOrderName } =
        useSnapshot(eventMenuStore)
    const { tableSetup } = useSnapshot(eventDetailStore)
    const meals = parseMealTableSetupWithDefault(tableSetup)
    const index = orderNames.indexOf(currentOrderName)
    const tableCounts = index == -1 ? meals[0] : meals[index]

    const [isOpen, setIsOpen] = useState(false)

    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: ["top-start", "top-end"],
            }),
            offset(10),
        ],
    })
    const handleClickOutside = useCallback(() => {
        setIsOpen(false)
    }, [])
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)

    const handleClickOpenPopup = () => {
        if (tableCounts.length) {
            setIsOpen(true)
        }
    }

    const handleChangeType = (type: number) => {
        setIsOpen(false)
        eventMenuStore.changeTableType(type)
        saveDraft()
    }

    const currentType = extractTableType(currentOrderName)
    const totalPrice = editingMenus
        .filter((e) => e.orderName === parseOrderName(currentOrderName).name)
        .reduce((acc, cur) => acc + cur.price, 0)
    return (
        <>
            <div
                onClick={handleClickOpenPopup}
                ref={refs.setReference}
                className={classNames(
                    " font-semibold text-red-500 text-sm w-24 text-right mr-8 relative",
                    {
                        "hover:underline cursor-pointer":
                            tableCounts.length > 1,
                    }
                )}
            >
                {Math.round(totalPrice / currentType).formatCurrency()}
                {tableCounts.length > 1 && (
                    <IconChevronRight className="text-xl absolute right-[-20px] top-0" />
                )}
            </div>
            {isOpen && (
                <FloatingPortal>
                    <FloatingOverlay
                        onClick={() => setIsOpen(false)}
                        className="z-[100]"
                    >
                        <div
                            className="shadow-menu bg-white rounded-[3px] text-sm w-[280px] overflow-hidden"
                            onClick={(e) => e.stopPropagation()}
                            ref={refs.setFloating}
                            style={floatingStyles}
                        >
                            {tableCounts.map((tblCount, idx) => (
                                <>
                                    {idx > 0 && <hr />}
                                    <div
                                        onClick={() =>
                                            handleChangeType(tblCount.index)
                                        }
                                        className="flex items-center h-10 pr-5 hover:cursor-pointer hover:bg-slate-50"
                                    >
                                        <div className="w-10 text-center">
                                            {currentType == tblCount.index && (
                                                <IconCheck className="text-link" />
                                            )}
                                        </div>
                                        <div className="font-medium">
                                            Bàn {tblCount.index}
                                        </div>
                                        <div className="ml-auto text-secondary">
                                            {Math.round(
                                                totalPrice / tblCount.index
                                            ).formatCurrency()}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </FloatingOverlay>
                </FloatingPortal>
            )}
        </>
    )
}
