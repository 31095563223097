import LoadingSkeleton, {
    SkeletonTheme as LoadingSkeletonTheme,
} from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

type Props = React.ComponentProps<typeof LoadingSkeleton>

const Skeleton = (props: Props) => {
    return <LoadingSkeleton {...props} />
}

export default Skeleton

export const SkeletonTheme = LoadingSkeletonTheme
