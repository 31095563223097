// icon:edit | Feathericons https://feathericons.com/ | Cole Bemis
import * as React from "react"

function IconEdit(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                fill="#000"
                d="M5.033 16.183l3.164-1.291c.242-.097.369-.185.525-.337l9.215-8.96c.921-.893.915-1.965-.009-2.876L15.864.685C14.93-.22 13.837-.23 12.916.663L3.695 9.619a1.239 1.239 0 00-.346.5l-1.344 3.096c-.22.506-.158 1.014.23 1.393l1.374 1.35c.378.372.897.447 1.424.225zm-.184-1.636c-.181.062-.312.06-.47-.095l-.604-.59c-.159-.155-.16-.291-.09-.451l1.166-2.66 9.124-8.87c.256-.235.545-.249.784-.015l1.954 1.926c.239.242.225.524-.024.768l-9.122 8.87-2.718 1.117zm-3.714 2.13h1.243c.293 0 .406-.053.615-.217l.958-.723-1.469-1.459L.83 15.964c-.299.3-.154.713.305.713zm2.655-5.97l4.023 3.95 1.026-.995-4.023-3.957-1.026 1.002zm7.972-7.741l4.02 3.947 1.03-.984-4.024-3.955-1.026.992zM.83 20h18.332a.844.844 0 00.838-.828.832.832 0 00-.838-.817H.83c-.458 0-.83.37-.83.817A.84.84 0 00.83 20z"
            ></path>
        </svg>
    )
}

export default IconEdit
