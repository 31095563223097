// @ts-ignore
import { CalendarVietnamese } from "date-chinese"

export const stripAccents = (str: string) => {
    return str.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd').replace(/Đ/g, 'D');
}


export const isSameMonth = (d1: Date, d2: Date) => {
    return (
        d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
    )
}
export const isSameDay = (d1: Date, d2: Date) => {
    return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
    )
}


export const getLunarDate = (d: Date) => {
    const cal = new CalendarVietnamese()
    cal.fromGregorian(d.getFullYear(), d.getMonth() + 1, d.getDate())
    let [, , month, , day] = cal.get()
    let gyear = cal.yearFromEpochCycle()
    return {
        year: gyear,
        month,
        day,
    }
}