import { PaymentChangeItem } from "models/events/PaymentChangeItem"
import EventPayment from "models/events/eventPayment"
import { equalIgnoreCase } from "services/utils"

export const calculatePaymentNameChanges = (
    oldNames: string[],
    newNames: string[]
) => {
    let addedItems: string[] = []
    let removedItems: string[] = []

    for (let item of oldNames) {
        let found = newNames.includes(item)
        if (!found) {
            removedItems.push(item)
        }
    }

    for (let item of newNames) {
        if (!oldNames.includes(item)) {
            addedItems.push(item)
        }
    }
    return {
        addedItems,
        removedItems,
        changeCount: addedItems.length + removedItems.length,
        changed: addedItems.length + removedItems.length > 0,
    }
}

export const calculatePaymentChanges = (
    oldItems: readonly EventPayment[],
    newItems: readonly EventPayment[]
) => {
    var sections: {
        [key: string]: {
            updatedItems: string[]
            addedItems: string[]
            removedItems: string[]
        }
    } = {}

    const _oldItems = oldItems.filter((e) => e.name)
    const _newItems = newItems.filter((e) => e.name)

    //tất cả order đều đc đẩy lên
    let changed = false
    for (var newItem of _newItems) {
        let sectionName = newItem.section
        if (!sections[sectionName]) {
            sections[sectionName] = {
                updatedItems: [],
                addedItems: [],
                removedItems: [],
            }
        }
        let found = oldItems.find(
            (m) =>
                equalIgnoreCase(m.name, newItem.name) &&
                m.section == newItem.section &&
                equalIgnoreCase(m.category, newItem.category)
        )
        if (found) {
            var isSame =
                found.note == newItem.note &&
                found.price == newItem.price &&
                !!found.free == !!newItem.free &&
                found.quantity == newItem.quantity &&
                found.unit == newItem.unit
            if (!isSame) {
                changed = true
                sections[sectionName].updatedItems.push(newItem.name)
            }
        } else {
            changed = true
            sections[sectionName].addedItems.push(newItem.name)
        }
    }

    //tìm những món bị bỏ
    for (var oldItem of _oldItems) {
        let sectionName = oldItem.section as string
        if (!sections[sectionName]) {
            sections[sectionName] = {
                updatedItems: [],
                addedItems: [],
                removedItems: [],
            }
        }
        let found = newItems.find(
            (m) =>
                equalIgnoreCase(m.name, oldItem.name) &&
                m.section == oldItem.section &&
                equalIgnoreCase(m.category, oldItem.category)
        )
        if (!found) {
            changed = true
            sections[sectionName].removedItems.push(oldItem.name)
        }
    }
    const changes: PaymentChangeItem[] = []
    let sectionNamesInCurrentItems = [
        ...new Set(newItems.map((e) => e.section)),
    ]

    let changeCount = 0
    for (let sectionName in sections) {
        //xử lý xóa những checklist "bị xóa" bằng cách kiểm tra checklist đó có mặt trong list items hiện tại ko
        if (sectionNamesInCurrentItems.includes(sectionName)) {
            const items = sections[sectionName]
            changes.push({
                sectionName,
                addedItems: items.addedItems,
                removedItems: items.removedItems,
                updatedItems: items.updatedItems,
            })
            changeCount +=
                items.addedItems.length +
                items.removedItems.length +
                items.updatedItems.length
        }
    }

    const oldNames = oldItems.map((e) => e.section)
    const newNames = newItems.map((e) => e.section)
    const sectionNameChanges = calculatePaymentNameChanges(oldNames, newNames)

    return {
        changed,
        changes,
        changeCount,
        sectionNameChanges,
    }
}

export const checkPaymentChanged = (
    editingPayments: EventPayment[],
    defaultPayments: EventPayment[]
) => {
    if (editingPayments.length != defaultPayments.length) {
        return true
    }

    for (var item of editingPayments) {
        var found = defaultPayments.find(
            (e) =>
                equalIgnoreCase(e.name, item.name) &&
                e.section === item.section &&
                e.unit === item.unit &&
                e.price === item.price &&
                e.quantity === item.quantity &&
                equalIgnoreCase(e.category, item.category) &&
                e.note === item.note &&
                !!e.free === !!item.free
        )
        if (!found) {
            return true
        }
    }
    return false
}
const PaymentUtils = {
    calculatePaymentChange: checkPaymentChanged,
}

export default PaymentUtils
