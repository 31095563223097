import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import {
    parseMealTableSetupWithDefault,
} from "utils/TableSetupUtils"
import { useSnapshot } from "valtio"

export const OrderTableInfo = () => {
    const { tableSetup } = useSnapshot(eventDetailStore)
    const meals = parseMealTableSetupWithDefault(tableSetup)
    const { currentOrderName, orderNames } = useSnapshot(eventMenuStore)
    const index = orderNames.indexOf(currentOrderName)
    const tableCounts = index == -1 ? meals[0] : meals[index]
    return (
        <div className=" mb-3 mr-3 mt-1 text-sm flex justify-end items-center">
            <span className="text-secondary">Bàn:&nbsp;</span>
            {tableCounts.map((tblCount, idx) => (
                <span key={idx}>
                    {idx > 0 && <span className="text-secondary">,&nbsp;</span>}
                    <span className="font-medium"> {tblCount.count}</span>
                    &nbsp;
                    {tblCount.count > 0 && (
                        <span className="text-grey">bàn {tblCount.index}</span>
                    )}
                </span>
            ))}
        </div>
    )
}
