// icon:undo | Lucide https://lucide.dev/ | Lucide
import * as React from "react"

function IconUndo(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="10"
            fill="none"
            viewBox="0 0 18 10"
            {...props}
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M2 3.988C3.768 1.596 6.478 0 9 0c2.59 0 4.729.987 6.278 2.626 1.535 1.624 2.448 3.84 2.716 6.264a1 1 0 01-1.988.22c-.23-2.076-1-3.86-2.182-5.11C12.656 2.762 11.044 2 9 2 6.987 2 4.465 3.562 3.068 6H6a1 1 0 010 2H1a1 1 0 01-1-1V2a1 1 0 012 0v1.988z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default IconUndo
