import styled from "styled-components"
import { cellBorderColor } from "core/cells/config"

export const ORDER_TABLE_HEADER_HEIGHT = 32

const PaymentTableHeader = () => {
    return (
        <PaymentTableHeaderWrap
            className="card-shadow"
        >
            <div className="justify-center">&nbsp;</div>
            <div className="justify-center uppercase">Hạng mục</div>
            <div className="justify-center text-center  uppercase">Đơn vị tính</div>
            <div className="justify-center uppercase">Ghi chú</div>
            <div className="justify-end uppercase text-center">Số lượng</div>
            <div className="justify-center uppercase">Miễn phí</div>
            <div className="text-center justify-center uppercase">Đơn giá</div>
            <div>&nbsp;</div>
        </PaymentTableHeaderWrap>
    )
}

export default PaymentTableHeader

const PaymentTableHeaderWrap = styled.div`
    height: ${ORDER_TABLE_HEADER_HEIGHT}px;
    background-color: #f1f1f1;
    position: sticky; 
    top: 54px;
    z-index: 8;
    flex-shrink: 0;
    font-size: 12px;
    border-left: 3px solid transparent;
    /* padding-right: 16px; */
    display: grid;
    grid-template-columns: 36px  minmax(200px, 2fr) 100px 3fr  100px 90px  120px 40px;
    div {
        display: flex;
        align-items: center;
        padding: 0 8px;
        font-weight: bold;
        border-right: 1px solid ${cellBorderColor};
    }
`
