import classNames from "classnames"
import get from "get-value"
import React, { useCallback, useContext, useRef, useState } from "react"
import { styled } from "styled-components"
import { moveDown, moveDownAtIndex, moveLeft, moveRight, moveUp } from "./utils"
import { cellBorderColor } from "./config"
import { CellContext, ICellContext } from "./context"

export interface Props<T> {
    data: T
    colKey: string
    onUpdateValue: (data: T, colKey: string, value: string) => void
    onDelete: (data: T, colKey: string) => void
    onMoveDownAtLastRow?: () => void
    onTabAtLastRowCell?: () => void
}

const TextCell = <T extends object>({
    data,
    colKey,
    onDelete,
    onUpdateValue,
    onMoveDownAtLastRow,
    onTabAtLastRowCell,
}: React.PropsWithChildren<Props<T>>) => {
    const [showCursor, setShowCursor] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const { setData, setColKey } = useContext<ICellContext<T>>(CellContext)
    const onMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    const handleFocus = () => {
        setInputValue("")
        setShowCursor(false)
        setData(data)
        setColKey(colKey)
    }

    const handleBlur = () => {
        if (showCursor) {
            saveCellContent()
        } else {
            setInputValue("")
            setShowCursor(false)
        }
        setData(null)
    }

    const editCellContent = useCallback((content: string) => {
        setShowCursor(true)
        console.log(`[${content}]`)
        setInputValue(content)
    }, [])

    const saveCellContent = (removeEnter?: boolean) => {
        let value = textAreaRef?.current?.value || ""
        //cần loại bỏ ký tự xuống dòng do thao tác enter gây ra
        if (removeEnter && value.endsWith("\n")) {
            value = value.substring(0, value.length - 1)
        }
        onUpdateValue(data, colKey, value)
        setInputValue("")
        setShowCursor(false)
    }

    const handleDoubleClick = () => {
        if (textAreaRef.current && !showCursor) {
            editCellContent(get(data, colKey))
        }
    }

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const value = e.target.value
            //focus và update value textarea
            setShowCursor(true)
            setInputValue(value)
        },
        []
    )

    const blurCellButStillFocus = useCallback(() => {
        setShowCursor(false)
        setInputValue("")
    }, [])

    const deleteCellContent = () => {
        setInputValue("")
        onDelete(data, colKey)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            blurCellButStillFocus()
        } else if (e.key === "Backspace" || e.key === "Delete") {
            if (!showCursor) {
                deleteCellContent()
            }
        } else if (e.key === "Enter") {
            if (!e.shiftKey) {
                e.preventDefault()
                if (textAreaRef.current && showCursor) {
                    saveCellContent(true)
                    const canMoveDown = moveDown(textAreaRef.current)
                    if (!canMoveDown) {
                        onMoveDownAtLastRow && onMoveDownAtLastRow()
                    }
                } else {
                    e.stopPropagation()
                    editCellContent(get(data, colKey))
                }
            }
        } else if (e.key === "ArrowRight") {
            if (!showCursor) {
                moveRight(textAreaRef.current)
            }
        } else if (e.key === "ArrowLeft") {
            if (!showCursor) {
                moveLeft(textAreaRef.current)
            }
        } else if (e.key === "ArrowDown") {
            if (showCursor) {
                onUpdateValue(data, colKey, textAreaRef.current?.value || "")
            }
            moveDown(textAreaRef.current)
        } else if (e.key === "ArrowUp") {
            if (showCursor) {
                onUpdateValue(data, colKey, textAreaRef.current?.value || "")
            }
            moveUp(textAreaRef.current)
        } else if (e.key === "Tab") {
            e.preventDefault()
            if (showCursor) {
                saveCellContent()
            }
            const canMove = moveRight(textAreaRef.current)
            if (!canMove) {
                if (document.activeElement) {
                    const canMoveDown = moveDownAtIndex(
                        document.activeElement,
                        1
                    )
                    if (!canMoveDown) {
                        onTabAtLastRowCell && onTabAtLastRowCell()
                    }
                }
            }
        }
    }

    return (
        <Wrap className={classNames("relative")} onMouseDown={onMouseDown}>
            {get(data, colKey) + ""}
            <textarea
                onFocus={handleFocus}
                onDoubleClick={handleDoubleClick}
                className={classNames({ "show-textarea": showCursor })}
                value={inputValue}
                ref={textAreaRef}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
            />
        </Wrap>
    )
}

export default React.memo(TextCell) as typeof TextCell

const Wrap = styled.div`
    outline: none;
    white-space: pre;
    display: flex;
    align-items: start;
    padding: 8px;
    white-space: pre-wrap;
    min-width: 0;
    font-size: 14px;
    border-right: 1px solid ${cellBorderColor};
    &:last-child {
        padding: 4px 0;
    }
    &:focus-within {
        outline: 2px solid royalblue;
        background-color: #fff;
        z-index: 1;
    }

    textarea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: transparent;
        padding: 8px;
        outline: none;
        background-color: transparent;
        resize: none;
        caret-color: transparent;
        &.show-textarea {
            background-color: white;
            color: var(--text-primary);
            caret-color: var(--text-primary);
        }
    }
`
