import { eventDetailStore } from "stores/eventDetailStore"
import { todoStore } from "stores/todoStore"
import {
    calculateChecklistNameChanges,
    calculateTodoChanges,
} from "utils/TodoUtils"
import { useSnapshot } from "valtio"

export const useChecklistChangeCount = () => {
    const { currentEvent, isFetchedEvent } = useSnapshot(eventDetailStore)
    const { editingTodos } = useSnapshot(todoStore)
    if (!isFetchedEvent || currentEvent.id == 0) return 0

    const todoChanges = calculateTodoChanges(currentEvent.todos, editingTodos)
    return todoChanges.changeCount
}

export const useChecklistNameChangeCount = () => {
    const { currentEvent, isFetchedEvent } = useSnapshot(eventDetailStore)
    const { checklistNames } = useSnapshot(todoStore)
    if (!isFetchedEvent || currentEvent.id == 0) return 0

    const nameChanges = calculateChecklistNameChanges(
        [...currentEvent.checklistNames],
        [...checklistNames]
    )

    return nameChanges.changeCount
}
