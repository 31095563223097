import EventMenu from "models/events/eventMenu"
import { proxy } from "valtio"
import { eventDetailStore } from "./eventDetailStore"
import set from "set-value"
import { rootStore } from "./rootStore"
import { MenuItem } from "models/menu_item"
import { MenuSize } from "models/menu_size"
import { parseOrderName } from "services/orderUtils"
import EventMenuDraftService from "services/eventMenuDraftService"
import { extractTableType, getPureOrderName } from "services/utils"
import { saveDraft } from "services/draftService"
import { nanoid } from "nanoid"
import { moveDownAtIndex } from "core/cells/utils"
import { parseMealTableSetup, parseTableCount } from "utils/TableSetupUtils"

export const eventMenuStore = proxy({
    activeCol: "",
    lastActiveCol: "",
    activeKey: "",
    lastActiveKey: "",

    editingMenus: <EventMenu[]>[],

    orderNames: <string[]>[],
    currentOrderName: "",
    focusCell: (data: EventMenu, colKey: string) => {
        eventMenuStore.activeKey = data.key
        eventMenuStore.lastActiveKey = data.key
        eventMenuStore.activeCol = colKey
        eventMenuStore.lastActiveCol = colKey
    },
    blurCell: (data: EventMenu) => {
        eventMenuStore.activeKey = ""
    },
    updateCell: (
        data: EventMenu,
        colKey: string,
        value: string,
        cb?: () => void
    ) => {
        var found = eventMenuStore.editingMenus.find(
            (e) =>
                e.key === data.key &&
                e.orderName ===
                    parseOrderName(eventMenuStore.currentOrderName).name
        )

        if (found) {
            if (colKey == "price") {
                set(found, colKey, Math.round(Number(value) || 0))
            } else {
                set(found, colKey, value)
            }
            cb && cb()
            // EventMenuDraftService.saveEventMenus(
            //     eventMenuStore.editingMenus,
            //     eventDetailStore.currentEvent.id
            // )
        }
    },
    deleteCell: (menu: EventMenu, colKey: string, cb?: () => void) => {
        var index = eventMenuStore.editingMenus.findIndex(
            (e) =>
                e.key === menu.key &&
                e.orderName ===
                    parseOrderName(eventMenuStore.currentOrderName).name
        )
        if (index >= 0) {
            if (colKey == "amount") {
                eventMenuStore.editingMenus[index].amount = ""
            }
            if (colKey === "note") {
                eventMenuStore.editingMenus[index].note = ""
            }
            if (colKey === "price") {
                eventMenuStore.editingMenus[index].price = 0
            }
            //force update editingMenus
            if (["amount", "note", "price"].includes(colKey)) {
                eventMenuStore.editingMenus = eventMenuStore.editingMenus.map(
                    (e) => ({ ...e })
                )
                cb && cb()
            }
        }
    },

    changeTableType: (type: number) => {
        var currentName = parseOrderName(eventMenuStore.currentOrderName).name
        eventMenuStore.orderNames = eventMenuStore.orderNames.map((e) => {
            if (e == eventMenuStore.currentOrderName) {
                return `${currentName}^${type}`
            }
            return e
        })
        eventMenuStore.currentOrderName = `${currentName}^${type}`
    },
    selectCell: (key: string, colKey: string) => {
        eventMenuStore.activeKey = key
        eventMenuStore.lastActiveKey = key
        eventMenuStore.activeCol = colKey
        eventMenuStore.lastActiveCol = colKey
    },
    initOrderNames: () => {
        var orderNames = eventDetailStore.currentEvent.orderNames
            .map((e) => e)
            .unique()
        if (!orderNames.length) {
            orderNames = [""]
        }
        eventMenuStore.orderNames = orderNames
        eventMenuStore.currentOrderName = orderNames[0]
    },
    updateTableType() {
        //logic cập nhật lại orderName khi tableSetup thay đổi
        let currentTableSetup = eventDetailStore.tableSetup;
        const meals = currentTableSetup.split("|").map((e) => parseTableCount(e))
        for(let orderName of this.orderNames) {
            let index = this.orderNames.indexOf(orderName)
            let type = extractTableType(orderName)
            let tableCounts = meals[index]
            let types = (tableCounts.map(e => e.index)).unique()
            if(!types.includes(type)) {
                this.orderNames[index] = `${getPureOrderName(this.orderNames[index])}^${types[0]}`
                this.currentOrderName = this.orderNames[0]
            }
        }

    },
    initMenusAndOrderNames: (eventMenus: EventMenu[], orderNames: string[]) => {
        eventMenuStore.editingMenus = eventMenus
        if (!orderNames.length) {
            orderNames = [""]
        }
        eventMenuStore.orderNames = orderNames.map((e) => e)
        eventMenuStore.currentOrderName = orderNames[0]
    },
    deleteOrder(index: number) {
        if (index <= this.orderNames.length - 1 && this.orderNames.length > 1) {
            let orderName = this.orderNames[index]
            let pureName = getPureOrderName(orderName)
            this.editingMenus = this.editingMenus.filter(
                (menu) => menu.orderName != pureName
            )
            this.orderNames.splice(index, 1)
            this.currentOrderName = this.orderNames[0]
        }
    },
    resetEventMenus() {
        this.editingMenus = eventDetailStore.currentEvent.menus.map((e) => ({
            ...e,
        }))
        this.initOrderNames()
        // EventMenuDraftService.discardEventMenus(
        //     eventDetailStore.currentEvent.id
        // )
        // EventMenuDraftService.discardOrderNames(
        //     eventDetailStore.currentEvent.id
        // )
    },
    initEmpty: () => {
        eventMenuStore.editingMenus = []
        eventMenuStore.orderNames = [""]
        eventMenuStore.currentOrderName = ""
    },
    duplicateOrder: () => {
        var orderName = parseOrderName(eventMenuStore.currentOrderName).name
        var type = parseOrderName(eventMenuStore.currentOrderName).index
        var newName = `${orderName || "Mặc định"}-copy`
        var menus = eventMenuStore.editingMenus
            .filter((e) => e.orderName === orderName)
            .map((e) => ({
                ...e,
                orderName: newName,
            }))
        eventMenuStore.addOrderWithMenus(`${newName}^${type}`, menus)

        // EventMenuDraftService.saveOrderNames(
        //     eventMenuStore.orderNames,
        //     eventDetailStore.currentEvent.id
        // )
        // EventMenuDraftService.saveEventMenus(
        //     eventMenuStore.editingMenus,
        //     eventDetailStore.currentEvent.id
        // )
    },
    addEmptyOrder: (newOrder: string) => {
        newOrder = newOrder.trim()
        var newName = `${newOrder}^10`
        if (!eventMenuStore.orderNames.includes(newName)) {
            eventMenuStore.orderNames.push(newName)
            eventMenuStore.currentOrderName = newName
            eventDetailStore.addEmptyTableSetup()
        }
    },
    addOrderWithMenus: (orderName: string, menus: EventMenu[]) => {
        eventMenuStore.editingMenus = eventMenuStore.editingMenus.filter(
            (e) => e.orderName != orderName
        )
        for (let menu of menus) {
            eventMenuStore.editingMenus.push(menu)
        }
        if (!eventMenuStore.orderNames.includes(orderName)) {
            eventMenuStore.orderNames.push(orderName)

            const type = extractTableType(orderName)
            eventDetailStore.addEmptyTableSetup(type)
        }
        eventMenuStore.currentOrderName = orderName

        // EventMenuDraftService.saveOrderNames(
        //     eventMenuStore.orderNames,
        //     eventDetailStore.currentEvent.id
        // )
        // EventMenuDraftService.saveEventMenus(
        //     eventMenuStore.editingMenus,
        //     eventDetailStore.currentEvent.id
        // )
    },
    renameOrder: (newName: string) => {
        var currentName = getPureOrderName(eventMenuStore.currentOrderName)
        var index = parseOrderName(eventMenuStore.currentOrderName).index
        eventMenuStore.editingMenus = eventMenuStore.editingMenus.map((e) => {
            if (e.orderName === currentName) {
                e.orderName = newName
            }
            return e
        })
        // EventMenuDraftService.saveEventMenus(
        //     eventMenuStore.editingMenus,
        //     eventDetailStore.currentEvent.id
        // )
        eventMenuStore.orderNames = eventMenuStore.orderNames.map((e) => {
            if (e == eventMenuStore.currentOrderName) {
                return `${newName}^${index}`
            }
            return e
        })
        // EventMenuDraftService.saveOrderNames(
        //     eventMenuStore.orderNames,
        //     eventDetailStore.currentEvent.id
        // )
        eventMenuStore.currentOrderName = `${newName}^${index}`
    },
    addRow(data: EventMenu | null) {
        const currentEditingItems = this.editingMenus.filter(
            (e) => e.orderName === parseOrderName(this.currentOrderName).name
        )
        const categories = currentEditingItems.map((e) => e.groupName).unique()
        const index = data
            ? this.editingMenus.findIndex((e) => e.key === data.key)
            : 0
        const groupName = data?.groupName || ""

        if (data) {
            this.editingMenus.splice(index + 1, 0, {
                groupName,
                amount: "",
                menu: "",
                orderName: parseOrderName(this.currentOrderName).name,
                size: "",
                eventId: eventDetailStore.currentEvent.id,
                id: 0,
                note: "",
                price: 0,
                key: nanoid(),
            })
            setTimeout(() => {
                moveDownAtIndex(document.activeElement, 1)
            })
        } else {
            this.editingMenus.push({
                groupName: categories[categories.length - 1],
                amount: "",
                menu: "",
                orderName: parseOrderName(this.currentOrderName).name,
                size: "",
                eventId: eventDetailStore.currentEvent.id,
                id: 0,
                note: "",
                price: 0,
                key: nanoid(),
            })
        }
    },
    removeItem: (key: string) => {
        var removeItem = eventMenuStore.editingMenus.find(
            (e) =>
                e.key === key &&
                e.orderName ===
                    parseOrderName(eventMenuStore.currentOrderName).name
        )
        eventMenuStore.editingMenus = eventMenuStore.editingMenus.filter(
            (e) => e != removeItem
        )
        // EventMenuDraftService.saveEventMenus(
        //     eventMenuStore.editingMenus,
        //     eventDetailStore.currentEvent.id
        // )
    },
    //@ts-ignore
    changeMenu: ({
        category,
        cellData,
        colKey,
        option,
        cb,
    }: {
        cellData: EventMenu
        colKey: string
        option: MenuItem
        category: string
        cb?: () => void
    }) => {
        var groupName =
            rootStore.menuTypes.find((e) => e.id === option.typeId)?.name || ""

        //nếu chọn lại món cũ thì ko làm gì cả
        if (cellData.menu == option.name && category === groupName) {
            return
        }

        //nếu chọn món khác thì reset lại các giá trị amount, note, price, size ...
        var index = eventMenuStore.editingMenus.findIndex(
            (e) =>
                e.key === cellData.key &&
                e.orderName ===
                    parseOrderName(eventMenuStore.currentOrderName).name
        )
        if (index != -1) {
            // giữ key cũ
            var key = eventMenuStore.editingMenus[index].key
            eventMenuStore.editingMenus[index] = {
                id: 0,
                amount: "",
                groupName,
                note: "",
                eventId: cellData.eventId,
                menu: option.name,
                orderName: parseOrderName(eventMenuStore.currentOrderName).name,
                price: 0,
                size: "",
                key,
            }
            cb && cb()
        }
    },
    deleteSize: (data: EventMenu, cb?: () => void) => {
        var item = eventMenuStore.editingMenus.find(
            (e) =>
                e.key === data.key &&
                e.orderName ===
                    parseOrderName(eventMenuStore.currentOrderName).name
        )
        if (item) {
            item.size = ""
            cb && cb()
        }
    },
    updateSize: (data: EventMenu, option: MenuSize, cb?: () => void) => {
        var item = eventMenuStore.editingMenus.find(
            (e) =>
                e.key === data.key &&
                e.orderName ===
                    parseOrderName(eventMenuStore.currentOrderName).name
        )
        if (item) {
            item.size = option.name
            item.amount = option.amount
            item.price = option.price

            cb && cb()
        }
    },
})
