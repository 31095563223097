import {
    FloatingOverlay,
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import { useState } from "react"
import styled from "styled-components"
import { defaultDateValues } from "./viewConstants"
import classNames from "classnames"
import MonthSelect from "./components/MonthSelect"
import { DateFilterValue } from "./viewService"
import { useClickOutside } from "core/hooks"
import IconCheckmarkThin from "common/icons/IconCheckmarkThin"
import IconCaretDown from "common/icons/IconCaretDown"
import IconChevronRight from "common/icons/IconChevronRight"

type Props = {
    value: string
    onSelect: (value: string) => void
}

//filter  dành cho start_time
const DateFilter = ({ value, onSelect }: Props) => {
    const [showDropdown, setShowDropdown] = useState(false)

    const { refs, floatingStyles, update } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [autoPlacement(), offset(10)],
    })
    const handleSelectMonth = (d: Date) => {
        onSelect(`${d.getMonth() + 1}.${d.getFullYear()}`)
    }
    const handleClickDropdown = () => {}
    const isMonth = /^\d{1,2}\.\d{4}$/.test(value)
    const isNextDay = /^\d+$/.test(value)
    const isLastDay = /^-\d+$/.test(value)

    const handleClickOutside = () => {
        setShowDropdown(false)
    }
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)
    return (
        <FilterWrap
            ref={refs.setReference}
            onClick={() => setShowDropdown((s) => !s)}
            className={classNames(
                "inline-flex select-none whitespace-nowrap cursor-pointer items-center px-3.5 py-1 rounded-[3px] font-semibold text-sm text-link",
                { active: value != DateFilterValue.ALL }
            )}
        >
            Ngày diễn ra
            <IconCaretDown className="text-[8px]  ml-2 text-[#768893]" />
            {showDropdown && (
                <FloatingPortal>
                    <DropdownMenu
                        className="shadow-menu rounded-lg py-2 z-[99]"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        onClick={handleClickDropdown}
                    >
                        {defaultDateValues.map((e, index) => (
                            <div
                                key={`${index}-${e.value}`}
                                className={classNames("menu-item", {
                                    "font-medium text-link": value == e.value,
                                })}
                                onClick={() => onSelect(e.value)}
                            >
                                {value == e.value ? (
                                    <IconCheckmarkThin className="text-link mr-2 font-medium" />
                                ) : (
                                    <span className="w-[22px]">&nbsp;</span>
                                )}
                                {e.label}
                            </div>
                        ))}
                        {isNextDay && (
                            <div className="menu-item text-link font-medium">
                                <IconCheckmarkThin className="text-link mr-2" />
                                {value} ngày tới
                            </div>
                        )}
                        {isLastDay && (
                            <div className="menu-item text-link font-medium">
                                <IconCheckmarkThin className="text-link mr-2" />
                                {Math.abs(Number(value))} ngày gần đây
                            </div>
                        )}
                        {isMonth && (
                            <div className="menu-item text-link font-medium">
                                <IconCheckmarkThin className="text-link mr-2" />
                                Tháng {value.split(".")[0]} -&nbsp;
                                {value.split(".")[1]}
                            </div>
                        )}
                        <hr className="mx-4 my-3" />
                        <MonthMenu className="menu-item relative">
                            <span className="w-[22px]" />
                            Tháng
                            <MonthSelectWrap className="shadow-menu month-select">
                                <MonthSelect onSelect={handleSelectMonth} />
                            </MonthSelectWrap>
                            <IconChevronRight className="ml-auto text-base text-gray-400" />
                        </MonthMenu>

                        <NextDayMenu className="menu-item relative">
                            <span className="w-[22px]" />
                            Ngày tới
                            <NextDayWrap className="shadow-menu  next-day-select">
                                <div onClick={() => onSelect(`10`)}>
                                    10 ngày tới
                                </div>
                                <div onClick={() => onSelect(`30`)}>
                                    30 ngày tới
                                </div>
                                <div onClick={() => onSelect(`60`)}>
                                    60 ngày tới
                                </div>
                            </NextDayWrap>
                            <IconChevronRight className="ml-auto text-base text-gray-400" />
                        </NextDayMenu>
                        <LastDayMenu className="menu-item relative">
                            <span className="w-[22px]" />
                            Ngày gần đây
                            <LastDayWrap className="shadow-menu  last-day-select">
                                <div onClick={() => onSelect(`-10`)}>
                                    10 ngày gần đây
                                </div>
                                <div onClick={() => onSelect(`-30`)}>
                                    30 ngày gần đây
                                </div>
                                <div onClick={() => onSelect(`-60`)}>
                                    60 ngày gần đây
                                </div>
                            </LastDayWrap>
                            <IconChevronRight className="ml-auto text-base text-gray-400" />
                        </LastDayMenu>
                        <div className="menu-item">
                            <span className="w-[22px]" />
                            Chọn ngày
                            <IconChevronRight className="ml-auto text-base  text-gray-400" />
                        </div>
                    </DropdownMenu>
                </FloatingPortal>
            )}
        </FilterWrap>
    )
}

export default DateFilter

const FilterWrap = styled.div`
    &.active {
        background-color: #eef8ff;
    }
`

const DropdownMenu = styled.div`
    min-width: 240px;
    padding: 4px;
    background-color: #fff;
    div {
        font-size: 14px;
    }

    .menu-item {
        padding: 0 12px;
        display: flex;
        user-select: none;
        align-items: center;
        height: 40px;
        border-radius: 4px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
    }

    .category-item {
        padding: 8px 16px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
    }
`

const MonthMenu = styled.div`
    &:hover {
        .month-select {
            display: block;
        }
    }
`
const LastDayMenu = styled.div`
    &:hover {
        .last-day-select {
            display: block;
        }
    }
`
const NextDayMenu = styled.div`
    &:hover {
        .next-day-select {
            display: block;
        }
    }
`
const MonthSelectWrap = styled.div`
    position: absolute;
    display: none;
    z-index: 10;
    left: calc(100% - 12px);
    width: 250px;
    padding: 4px;
    border-radius: 4px;
    top: -160px;
    background-color: #fff;
    div {
        padding: 12px 16px;
        &:hover {
            background-color: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;
        }
    }
`
const LastDayWrap = styled.div`
    position: absolute;
    display: none;
    z-index: 10;
    left: calc(100% - 12px);
    width: 200px;
    padding: 4px;
    border-radius: 4px;
    top: -60px;
    background-color: #fff;
    div {
        padding: 12px 16px;
        &:hover {
            background-color: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;
        }
    }
`
const NextDayWrap = styled.div`
    position: absolute;
    display: none;
    z-index: 10;
    left: calc(100% - 12px);
    width: 200px;
    padding: 4px;
    border-radius: 4px;
    top: -60px;
    background-color: #fff;
    div {
        padding: 12px 16px;
        &:hover {
            background-color: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;
        }
    }
`
