export type IUser = {
    id: number
    userName: string
    displayName: string
    deviceId: string
    deviceName: string
    active: boolean
    permissions: number[];
    photoID: string
    roleId: number


}

class User {
    constructor(data: IUser) {
        this.active = data.active;
        this.displayName = data.displayName;
        this.userName = data.userName;
        this.permissions = data.permissions;
        this.roleId = data.roleId;
        this.photoID = data.photoID;
    }
    active: boolean;
    userName: string
    displayName: string
    permissions: number[]
    photoID: string
    roleId: number

    static generateEmpty() {
        return new User({
            active: false,
            id: 0,
            deviceId: '',
            deviceName: '',
            displayName: '',
            permissions: [],
            photoID: '',
            roleId: 0,
            userName: ''

        })
    }
}

export default User;
