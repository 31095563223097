import { Page } from "core/styledComponents"
import AppHeader from "modules/header/AppHeader"
import { NavLink } from "react-router-dom"
import { AppRoutes } from "services/constants"

const Customers = () => {
    return (
        <Page>
            <AppHeader />
            <div className="flex items-center my-5 mx-12">
                <div className="text-xl font-bold text-link">Khách hàng</div>
            </div>
          
        </Page>
    )
}

export default Customers
