// @ts-ignore
import { CalendarVietnamese } from "date-chinese"
import { differenceInDays, startOfDay } from "date-fns"
import { TableCount } from "models/table_count"
import { Timeline } from "models/timeline"
import { nanoid } from "nanoid"
import { Quill } from "react-quill"
import { format } from "timeago.js"
import { EventStatus } from "./constants"

export const parseColor = (s: string) => {
    if (s.length === 9) {
        return `#${s.substring(3, 9)}`
    }
    return s
}

export const getBase64Image = (img: any) => {
    var canvas = document.createElement("canvas")
    canvas.width = img.width
    canvas.height = img.height
    var ctx = canvas.getContext("2d")
    ctx?.drawImage(img, 0, 0)
    var dataURL = canvas.toDataURL("image/png")
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
}

export const getQuillContent = (s: string) => {
    let div = document.createElement("div")
    let quill = new Quill(div)
    if (!s) return ""
    quill.setContents(JSON.parse(s))
    if (quill.getText() === "\n") return ""
    const content = JSON.stringify(quill.getContents())
    return content
}

export const compareQuillContent = (a: string, b: string) => {
    if (!a && !b) {
        return true
    }

    return getQuillContent(a) == getQuillContent(b)
}

export const extractEventId = () => {
    const pathname = window.location.pathname
    var index = pathname.lastIndexOf("/")
    if (index != -1) {
        var eventId = pathname.substring(index + 1, pathname.length)
        return Number(eventId) || 0
    }
    return 0
}

export const extractModule = () => {
    const pathname = window.location.pathname

    return pathname
}

//chỉ dành để parse startTime của event
export const parseDateFromNumber = (n: number) => {
    var str = `${n}`
    if (str.length < 10) return new Date()

    var y = `20${+str.substring(0, 2)}`
    var m = +str.substring(2, 4)
    var d = +str.substring(4, 6)
    return new Date(+y, m - 1, d)
}

export const parseStartTime = (startTime: number) => {
    //start time có dạng yymmddhhmm (10 số)
    var year = Number("20" + `${startTime}`.substring(0, 2))
    var month = +`${startTime}`.substring(2, 4) - 1
    var day = +`${startTime}`.substring(4, 6)
    var hour = +`${startTime}`.substring(6, 8)
    var min = +`${startTime}`.substring(8, 10)
    var date = new Date(year, month, day, hour, min, 0, 0)
    return date
}

export const parseTimelines = (timeline: string, eventId: number) => {
    if (!timeline) {
        return []
    }

    var list = <Timeline[]>[]
    for (var str of timeline.split("|")) {
        var arr = str.split(",")
        if (arr.length != 4) {
            return []
        }
        var hhmm = arr[0]
        var duration = +arr[1]
        var typeId = +arr[2]
        var placeId = +arr[3]
        var hour = +hhmm.split(":")[0]
        var min = +hhmm.split(":")[1]

        list.push(
            new Timeline({
                key: nanoid(), //generate phía client và chỉ dùng phía client
                duration,
                eventId,
                hour,
                min,
                placeId,
                typeId,
            })
        )
    }
    list.sort((a, b) => a.hour * 60 + a.min - b.hour * 60 - b.min)
    return list
}

export const formatGuestEstimate = (guestFrom: number, guestTo: number) => {
    if (guestFrom >= guestTo) {
        return guestFrom == 0 ? "" : `${guestFrom}`
    }
    return `${guestFrom} - ${guestTo}`
}

export const formatStatus = (status: number) => {
    if (status === EventStatus.CONFIRMED) {
        return "Đã chốt"
    }
    if (status === EventStatus.INPROGRESS) {
        return "Đã cọc, chưa chốt"
    }

    return "Tham khảo"
}

export const encodeTimelines = (timelines: readonly Timeline[]) => {
    var arr = timelines.map((e) => e)
    arr.sort((a, b) => a.hour * 60 + a.min - b.hour * 60 - b.min)
    var timeline = arr
        .map((e) => `${e.hour}:${e.min},${e.duration},${e.typeId},${e.placeId}`)
        .join("|")
    return timeline
}

export const formatDeposit = (deposit: number) => {
    if (!deposit) return ""
    if (deposit % 1000000 == 0) {
        return `${deposit / 1000000} triệu`
    }
    return deposit.formatCurrency()
}

export const getEndHour = (
    hour: number,
    min: number,
    durationInMinutes: number
) => {
    const extraMin = (durationInMinutes + min) % 60
    const extraHour = Math.floor((durationInMinutes + min) / 60)
    return [hour + extraHour, extraMin]
}

export const stripAccents = (str: string) => {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/đ/g, "d")
        .replace(/Đ/g, "D")
}

export const removeSpaces = (str: string) => str.replace(/\s/g, "")

export const removeCommas = (str: string) => str.replaceAll(",", "")

export const formatCurrency = (num: number | undefined, seperator?: string) => {
    if (num === undefined) return null
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, seperator || ",")
}

export const getLunarDate = (d: Date) => {
    const cal = new CalendarVietnamese()
    cal.fromGregorian(d.getFullYear(), d.getMonth() + 1, d.getDate())
    let [, , month, , day] = cal.get()
    let gyear = cal.yearFromEpochCycle()
    return {
        year: gyear,
        month,
        day,
    }
}

export const dateDiffIgnoreTime = (d1: Date, d2: Date) => {
    var _d1 = startOfDay(d1)
    var _d2 = startOfDay(d2)

    var diff = differenceInDays(_d1, _d2)
    return diff
}

export const daysAgo = (d: Date) => {
    const date = new Date(d.getTime())
    date.setHours(0, 0, 0, 0)

    var today = new Date()
    today.setHours(0, 0, 0, 0)

    if (isSameDay(d, new Date())) {
        return "Hôm nay"
    }

    var diff = differenceInDays(date, today)
    if (diff === -1) {
        return "Hôm qua"
    }

    if (diff === 1) {
        return "Ngày mai"
    }

    if (diff > 0) {
        return `${diff} ngày tới`
    }

    return format(date, "vi")
}

export const isSameMonth = (d1: Date, d2: Date) => {
    return (
        d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
    )
}
export const isSameDay = (d1: Date, d2: Date) => {
    return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
    )
}

export const getMondayInThePast = (d: Date) => {
    const clone = new Date(d)
    const day = clone.getDay(),
        diff = clone.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
    return new Date(clone.setDate(diff))
}

export const getSundayInFuture = (d: Date) => {
    const clone = new Date(d)
    const day = clone.getDay()
    if (day === 0) {
        return clone
    }

    const diff = 7 - day
    clone.setDate(clone.getDate() + diff)
    return clone
}

// ==============================
// Mobile Device Detector
// ==============================

export function isMobileDevice() {
    try {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    } catch (e) {
        return false
    }
}

// ==============================
// Scroll Helpers
// ==============================

export function isDocumentElement(
    el: HTMLElement | typeof window
): el is typeof window {
    return [document.documentElement, document.body, window].indexOf(el) > -1
}

export function scrollIntoView(
    menuEl: HTMLElement,
    focusedEl: HTMLElement
): void {
    const menuRect = menuEl.getBoundingClientRect()
    const focusedRect = focusedEl.getBoundingClientRect()
    const overScroll = focusedEl.offsetHeight / 3

    if (focusedRect.bottom + overScroll > menuRect.bottom) {
        scrollTo(
            menuEl,
            Math.min(
                focusedEl.offsetTop +
                    focusedEl.clientHeight -
                    menuEl.offsetHeight +
                    overScroll,
                menuEl.scrollHeight
            )
        )
    } else if (focusedRect.top - overScroll < menuRect.top) {
        scrollTo(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0))
    }
}

export function scrollTo(el: HTMLElement | typeof window, top: number): void {
    // with a scroll distance, we perform scroll on the element
    if (isDocumentElement(el)) {
        window.scrollTo(0, top)
        return
    }

    el.scrollTop = top
}

export const calculateOrderNamesChange = (
    oldNames: string[],
    newNames: string[]
) => {
    oldNames = oldNames.map((e) => (e.includes("^") ? e : `${e}^10`))
    newNames = newNames.map((e) => (e.includes("^") ? e : `${e}^10`))

    let changed = oldNames.join("|") !== newNames.join("|")
    let nameChanged =
        oldNames.map((e) => e.split("^")[0]).join("|") !==
        newNames.map((e) => e.split("^")[0]).join("|")
    let details = `${oldNames.join("|")}$${newNames.join("|")}`

    let addedItems: string[] = []
    let updatedItems: string[] = []
    let removedItems: string[] = []
    let remainItems: string[] = [] //items không thay đổi

    for (let item of oldNames) {
        let found = newNames.find(
            (e) => getPureOrderName(e) == getPureOrderName(item)
        )
        if (found) {
            //khác tableType thì là update, giống cả tableType thì có nghĩa là ko có thay đổi
            if (extractTableType(found) != extractTableType(item)) {
                updatedItems.push(found)
            } else {
                remainItems.push(found)
            }
        } else {
            removedItems.push(item)
        }
    }

    for (let item of newNames) {
        let found = oldNames.find(
            (e) => getPureOrderName(e) == getPureOrderName(item)
        )
        if (!found) {
            addedItems.push(item)
        }
    }

    return {
        changed,
        nameChanged,
        details,
        items: { addedItems, updatedItems, removedItems, remainItems },
    }
}

export const extractTableType = (s: string) => {
    let type = s.split("^")?.[1] || 10
    return +type
}

export const getPureOrderName = (s: string) =>
    s.includes("^") ? s.split("^")[0] : s

/**
 *      kiểm tra danh sách order thực sự có thay đổi về table count ko
 *      Ví dụ nếu thêm hoặc xóa 1 order với table count rỗng thì coi như ko thay đổi
 *
 */
export const calculateOrderTableCountChanges = ({
    tableSetupChangeDetails,
    orderNameChangeDetails,
}: {
    tableSetupChangeDetails: string
    orderNameChangeDetails: string
}) => {
    let arr0 = orderNameChangeDetails.split("$").map((e) => e.split("|"))
    let oldOrderNames = arr0[0]
    let newOrderNames = arr0[1]

    let orderNameChanges = calculateOrderNamesChange(
        oldOrderNames,
        newOrderNames
    )

    let arr = tableSetupChangeDetails.split("$")
    let oldMealTableSetup = arr[0]
        .split("|")
        .map((e) => parseTableCountWithDefault(e))
    let newMealTableSetup = arr[1]
        .split("|")
        .map((e) => parseTableCountWithDefault(e))

    let changedItems: {
        orderName: string
        oldTableCounts: TableCount[]
        newTableCounts: TableCount[]
    }[] = []

    //trường hợp thêm mới order, kiểm tra order đó có table count rỗng hay ko
    for (let orderName of orderNameChanges.items.addedItems) {
        let index = newOrderNames.findIndex(
            (e) => getPureOrderName(e) == getPureOrderName(orderName)
        )
        if (index != -1 && index <= newMealTableSetup.length - 1) {
            let mealTableSetup = newMealTableSetup[index]
            let hasTableCount =
                mealTableSetup.filter((e) => e.count > 0).length > 0
            if (hasTableCount) {
                changedItems.push({
                    orderName,
                    oldTableCounts: [],
                    newTableCounts: mealTableSetup.filter((e) => e.count > 0),
                })
            }
        }
    }
    //trường hợp xóa order, kiểm tra order đó có table count rỗng hay ko
    for (let orderName of orderNameChanges.items.removedItems) {
        let index = oldOrderNames.findIndex(
            (e) => getPureOrderName(e) == getPureOrderName(orderName)
        )
        if (index != -1 && index <= oldMealTableSetup.length - 1) {
            let mealTableSetup = oldMealTableSetup[index]
            let hasTableCount =
                mealTableSetup.filter((e) => e.count > 0).length > 0
            if (hasTableCount) {
                //chỉ trả về changedItems cho trường hợp remainItems và updatedItems
                changedItems.push({
                    orderName,
                    oldTableCounts: mealTableSetup.filter((e) => e.count > 0),
                    newTableCounts: [],
                })
            }
        }
    }

    var updateAndRemainItems = [
        ...orderNameChanges.items.updatedItems,
        ...orderNameChanges.items.remainItems,
    ]

    //kiểm tra table count có thay đổi giữa cũ và mới ko

    for (let orderName of updateAndRemainItems) {
        let indexInOld = oldOrderNames.findIndex(
            (e) => getPureOrderName(e) == getPureOrderName(orderName)
        )
        let oldMeal = oldMealTableSetup[indexInOld]

        let indexInNew = newOrderNames.findIndex(
            (e) => getPureOrderName(e) == getPureOrderName(orderName)
        )
        let newMeal = newMealTableSetup[indexInNew]

        let changed = checkTableCountsChanged(oldMeal, newMeal)
        if (changed) {
            changedItems.push({
                orderName,
                oldTableCounts: oldMeal,
                newTableCounts: newMeal,
            })
        }
    }

    return {
        changed: changedItems.length > 0,
        changedItems,
    }
}

export const containsIgnoreCase = (list: string[], s: string) => {
    return (
        list.find(
            (e) =>
                `${e}`.trim().toLocaleLowerCase() ==
                `${s}`.trim().toLocaleLowerCase()
        ) != null
    )
}

export const uniqueIgnoreCase = (list: string[]) => {
    let arr = [...list].unique()

    return arr.map((e) =>
        list.find(
            (item) =>
                item.trim().toLocaleLowerCase() === e.trim().toLocaleLowerCase()
        )
    ) as string[]
}

export const parseTableCountWithDefault = (
    tableSetupFragment: string
): TableCount[] => {
    if (!tableSetupFragment || !tableSetupFragment.trim()) {
        return [{ count: 0, index: 10 }]
    }
    var list = []
    for (var item of tableSetupFragment.split(".")) {
        var index = Number(item.split("b")?.[1]) || 0
        var count = Number(item.split("b")?.[0]) || 0
        if (index && count)
            list.push({
                index,
                count,
            })
    }
    list.sort((a, b) => a.index - b.index)
    return list
}

const checkTableCountsChanged = (
    oldValue: TableCount[],
    newValue: TableCount[]
) => {
    oldValue = oldValue.filter((e) => e.count > 0)

    newValue = newValue.filter((e) => e.count > 0)
    oldValue.sort((e) => e.index)
    newValue.sort((e) => e.index)

    if (oldValue.length != newValue.length) return true
    for (let i = 0; i < oldValue.length; i++) {
        if (
            oldValue[i].count !== newValue[i].count ||
            oldValue[i].index !== newValue[i].index
        ) {
            return true
        }
    }
    return false
}
export const equalIgnoreCase = (s1: string, s2: string) => {
    return (
        (s1 || "").trim().toLocaleLowerCase() ==
        (s2 || "").trim().toLocaleLowerCase()
    )
}
