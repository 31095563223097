import classNames from "classnames"
import IconCheck from "common/icons/IconCheck"
import { useEffect, useState } from "react"
import styled from "styled-components"

type Props = {
    className?: string
    value: boolean
    onChange?: (val: boolean) => void
}
const Checkbox = ({ value, onChange, className }: Props) => {
    const [checked, setChecked] = useState(value)

    useEffect(() => {
        setChecked(value)
    }, [value])

    const handleClick = () => {
        onChange && onChange(!checked)
    }
    return (
        <CheckboxWrap
            className={classNames(className)}
            onClick={handleClick}
        >
            {
                !!checked && (
                   <IconCheck  className="icon-check" />
                )
            }
        </CheckboxWrap>
    )
}

const CheckboxWrap = styled.div`
    display: inline-flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    /* font-size: 9px; */
    border: 1px solid #ccc;
    .icon-check {
        color: var(--link);
    }
    width: 22px;
    height: 22px;
    border-radius: 4px;
`

export default Checkbox