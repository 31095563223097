import { EventStatus } from "services/constants"
import { FilterType, FilterTypeValue, FilterValue } from "./filterTypes"
import { ViewItem } from "models/view"

export const DateFilterValue = {
    ALL: "",
    THIS_MONTH: "THIS_MONTH",
    LAST_MONTH: "LAST_MONTH",
    TODAY: "TODAY",
    YESTERDAY: "YESTERDAY",
    ON_GOING: "ON_GOING",
    AFTER_TODAY: "AFTER_TODAY",
}

export const CustomerValue = {
    ALL: "",
    CUSTOMER: "CUSTOMER",
    CUSTOMER_GROUP: "CUSTOMER_GROUP",
}

export const defaultViews: ViewItem[] = [
    {
        isDefault: true,
        name: "Tất cả",
        customers: [],
        date: DateFilterValue.ALL,
        dateCreated: DateFilterValue.ALL,
        deposit: "",
        statuses: [],
        places: [],
        eventTypes: [],
    },
    {
        isDefault: true,
        name: "Hôm nay",
        customers: [],
        date: DateFilterValue.TODAY,
        dateCreated: DateFilterValue.ALL,
        deposit: "",
        statuses: [],
        places: [],
        eventTypes: [],
    },
]

const ViewService = {
    getAllViews(): ViewItem[] {
        return defaultViews
    },
}

export default ViewService
