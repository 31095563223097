import { useState } from "react"
import { useDateChanges, useTimelineChangeCount } from "services/eventService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import ScheduleChanges from "./ScheduleChange/ScheduleChange"

const ScheduleChangeCount = () => {
    const { eventNote, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    const dateChange = useDateChanges()
    const timelineChange = useTimelineChangeCount()
    const changeCount = dateChange + timelineChange

    if (currentEvent.id == 0 || !changeCount) return null
    return (
        <>
            <span
                onClick={() => setIsOpen(true)}
                className="text-[orangered] text-13 font-normal ml-2 cursor-pointer hover:underline"
            >
                (+{changeCount})
            </span>
            <ScheduleChanges isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* <EventNoteChange isOpen={isOpen} setIsOpen={setIsOpen} /> */}
        </>
    )
}

export default ScheduleChangeCount
