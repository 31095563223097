import IconClose from "common/icons/IconClose"
import { ClearIndicatorProps } from "react-select"

export const ClearIcon = (props: ClearIndicatorProps<any>) => {
    const { innerProps } = props
    return (
        <div
            onMouseDown={(e) => e.preventDefault()}
            className="w-5 h-5 flex items-center justify-center rounded-full hover:bg-[#eee] text-[#ccc] hover:text-[#999] mr-3"
            {...innerProps}
        >
            <IconClose className="text-base" />
        </div>
    )
}
