import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import EventNoteChange from "./EventNoteChange/EventNoteChange"

const EventNoteChangeCount = () => {
    const { eventNote, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)
    if (currentEvent.id == 0) return null
    return (
        <>
            {currentEvent.note != eventNote && (
                <span
                    onClick={() => setIsOpen(true)}
                    className="text-[orangered] text-13 font-normal cursor-pointer hover:underline"
                >
                    +1
                </span>
            )}
            <EventNoteChange isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default EventNoteChangeCount
