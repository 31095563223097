import * as React from "react"

function IconBookOpen(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="23"
            fill="none"
            viewBox="0 0 27 23"
            {...props}
        >
            <path
                fill="#000"
                d="M0 20.019c0 1.066.705 1.557 1.544 1.557.44 0 .797-.234 1.314-.542 1.283-.855 2.811-1.363 4.362-1.351a6.562 6.562 0 014.52 1.825c.551.509.987.69 1.484.69.485 0 .931-.181 1.475-.69a6.604 6.604 0 014.527-1.825c1.551-.012 3.07.496 4.353 1.351.519.308.873.542 1.324.542.829 0 1.534-.49 1.534-1.557V4.114c0-.233-.027-.435-.162-.649C25.179 1.548 22.438 0 19.177 0c-2.502 0-4.716.969-5.953 2.372C11.986.97 9.761 0 7.271 0 3.999 0 1.257 1.548.161 3.465a1.202 1.202 0 00-.161.65v15.904zm2.296-1.294V4.458c.928-1.293 2.902-2.162 4.975-2.162 2.125 0 3.995.878 4.8 2.22V18.9c-1.192-.875-2.966-1.514-4.8-1.514-1.927 0-3.74.517-4.975 1.338zm12.07.176V4.515c.805-1.34 2.684-2.22 4.811-2.22 2.064 0 4.035.87 4.964 2.163v14.267c-1.236-.82-3.046-1.338-4.964-1.338-1.845 0-3.62.639-4.81 1.514z"
            ></path>
        </svg>
    )
}

export default IconBookOpen
