import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import classNames from "classnames"
import Checkbox from "core/Checkbox"
import { useClickOutside } from "core/hooks"
import { stripAccents } from "core/utils"
import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { styled } from "styled-components"

const MENU_ITEM_HEIGHT = 40

export interface Props<T> {
    values: readonly string[]
    children: ReactNode
    options: T[]
    groupSelectedItems?: boolean
    menuHeight?: number
    className?: string
    getOptionLabel: (option: T) => string
    getOptionValue: (option: T) => string
    onChange: (values: string[]) => void
    searchable?: boolean
    placeholder?: string
    renderOption?: (option: T) => ReactNode
    renderNoOption?: (s: string) => ReactNode
    isMenuOpen?: boolean
    onOpenChange?: (isOpen: boolean) => void
    footer?: ReactNode
}

const MultiSelect = <T extends object>({
    options,
    groupSelectedItems,
    className,
    isMenuOpen,
    onOpenChange,
    placeholder,
    menuHeight,
    renderOption,
    renderNoOption,
    getOptionLabel,
    getOptionValue,
    onChange,
    values,
    searchable,
    children,
    footer,
}: React.PropsWithChildren<Props<T>>) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const menuRef = useRef<HTMLDivElement>(null)

    //sử dụng biến này để xử lý tình huống jump xuống đáy hoặc lên đầu danh sách mà ko bị ảnh hưởng bởi mouseOver
    const ignorePointerRef = useRef(false)
    const [searchText, setSearchText] = useState("")
    const [index, setIndex] = useState(0)
    const showDropdownRef = useRef(false)
    const [selectedValues, setSelectedValues] = useState<string[]>(
        [...values] || []
    )
    const [selectedValuesTop, setSelectedValuesTop] = useState<string[]>(
        [...values] || []
    )
    const [selectedItemsTop, setSelectedItemsTop] = useState<T[]>([])
    const selectedValuesRef = useRef<string[]>([])

    useEffect(() => {
        if (isMenuOpen !== undefined) {
            setShowDropdown(!!isMenuOpen)
        }
    }, [isMenuOpen])

    const { refs, floatingStyles, update } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "bottom-end",
                    "top-start",
                    "top-end",
                ],
            }),
            offset(10),
        ],
    })

    const matchSearchTerm = useCallback((item: T, search: string) => {
        return (
            stripAccents(getOptionLabel(item))
                .toLowerCase()
                .startsWith(stripAccents(search.trim()).toLowerCase()) ||
            stripAccents(getOptionLabel(item).toLowerCase()).includes(
                ` ${stripAccents(search.trim().toLowerCase())}`
            )
        )
    }, [])

    const getCurrentOptions = (text: string) => {
        if (!text) return options
        return options.filter((e) => matchSearchTerm(e, text))
    }
    const _options = searchable ? getCurrentOptions(searchText) : options

    const handleContainerClick = () => {
        showDropdownRef.current = !showDropdown
        setShowDropdown((s) => !s)
    }

    const handleClickDropdown = () => {}

    const handleContainerKeyDown = (e: React.KeyboardEvent) => {
        if (!searchable) {
            if (e.key === "Enter") {
                e.preventDefault()
                handleEnter()
            }
            if (e.key == "ArrowDown") {
                e.preventDefault()
                handleArrowDown()
            }
            if (e.key == "ArrowUp") {
                e.preventDefault()

                handleArrowUp()
            }

            if (e.key === "Escape") {
                e.preventDefault()
                setShowDropdown(false)
                showDropdownRef.current = false
                // handleSelectOption(getOptionValue(options[index]))
            }
        }
    }

    const onClickSelectedItem = (e: T) => {
        if (selectedValues.includes(getOptionValue(e) + "")) {
            var newValues = selectedValues.filter(
                (item) => item != getOptionValue(e)
            )
            setSelectedValues(newValues)
            onChange && onChange(newValues)
        } else {
            var newValues = [...selectedValues, getOptionValue(e)]
            setSelectedValues(newValues)
            onChange && onChange(newValues)
        }
    }

    const safeJump = (el: HTMLDivElement, scrollTop: number) => {
        ignorePointerRef.current = true
        setTimeout(() => {
            el.scrollTop = scrollTop
            // ignorePointerRef.current = false
        })
    }

    useEffect(() => {
        selectedValuesRef.current = selectedValues
    }, [selectedValues])

    useEffect(() => {
        setSelectedValues([...values])
    }, [values])

    const handleSearchInputBlur = () => {}
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key == "Escape") {
            setShowDropdown(false)
        }
        if (e.key == "ArrowDown") {
            e.preventDefault()
            handleArrowDown()
        }
        if (e.key == "ArrowUp") {
            e.preventDefault()
            handleArrowUp()
        }

        if (e.key === "Enter") {
            e.preventDefault()
            handleEnter()
        }
    }

    const handleArrowUp = () => {
        var newIndex = index - 1
        if (newIndex < 0) {
            newIndex = _options.length - 1
        }
        setIndex(newIndex)
        if (menuRef.current) {
            if (newIndex == _options.length - 1) {
                var child = menuRef.current.childNodes.item(
                    newIndex
                ) as HTMLDivElement
                if (child) {
                    safeJump(menuRef.current, child.offsetTop - 240)
                }
            } else {
                var maxScrollTop = MENU_ITEM_HEIGHT * newIndex
            
                if (menuRef.current.scrollTop > maxScrollTop) {
                    safeJump(menuRef.current, maxScrollTop)
                }
            }
        }
    }
    const handleArrowDown = () => {
        var newIndex = index + 1

        if (newIndex > _options.length - 1) {
            newIndex = 0
        }
        setIndex(newIndex)
        if (menuRef.current) {
            if (newIndex == 0) {
                safeJump(menuRef.current, 0)
            } else {
                var child = menuRef.current.childNodes.item(
                    newIndex
                ) as HTMLDivElement
                if (child) {
                    var minScrollTop = child.offsetTop - 160
                    if (menuRef.current.scrollTop < minScrollTop) {
                        safeJump(menuRef.current, minScrollTop)
                    }
                }
            }
        }
    }

    const handleEnter = () => {
        var list = groupSelectedItems
            ? [
                  ..._options.filter((e, index) =>
                      selectedValuesTop.includes(getOptionValue(e))
                  ),
                  ..._options.filter(
                      (e, index) =>
                          index < 40 &&
                          !selectedValuesTop.includes(getOptionValue(e))
                  ),
              ]
            : _options
        handleSelectOption(list[index])
    }

    useEffect(() => {
        if (showDropdown && !searchText) {
        } else {
            setIndex(0)
        }

        if (!showDropdown) {
            setSearchText("")
            setIndex(0)
        } else {
        }
    }, [showDropdown, values, searchText])
    useEffect(() => {
        onOpenChange && onOpenChange(showDropdown)
    }, [showDropdown])

    useEffect(() => {
        setSelectedValuesTop(selectedValuesRef.current)
        const items = options.filter((e) =>
            selectedValuesRef.current.includes(getOptionValue(e))
        )
        setSelectedItemsTop(items.filter((e) => matchSearchTerm(e, searchText)))
        if (menuRef.current) {
            menuRef.current.scrollTop = 0
        }
    }, [searchText, options])

    useEffect(() => {
        if (!showDropdown) {
            setSelectedValuesTop([...selectedValues])
            const items = options.filter((e) =>
                selectedValues.includes(getOptionValue(e))
            )
            setSelectedItemsTop(items)
        }
    }, [showDropdown, selectedValues, options])

    const handleMouseOverOption = (e: T) => {
        if (ignorePointerRef.current) return
        const _index = !groupSelectedItems
            ? _options.findIndex(
                  (item) => getOptionValue(item) === getOptionValue(e)
              )
            : [
                  ..._options.filter((e, index) =>
                      selectedValuesTop.includes(getOptionValue(e))
                  ),
                  ..._options.filter(
                      (e, index) =>
                          index < 40 &&
                          !selectedValuesTop.includes(getOptionValue(e))
                  ),
              ].findIndex((item) => getOptionValue(e) === getOptionValue(item))
        setIndex(_index)
    }

    const handleSelectOption = (e: T) => {
        if (!selectedValues.includes(getOptionValue(e))) {
            var newValues = [...selectedValues, getOptionValue(e)]
            setSelectedValues(newValues)
            onChange && onChange(newValues)
        } else {
            var newValues = [
                ...selectedValues.filter((item) => item != getOptionValue(e)),
            ]
            setSelectedValues(newValues)
            onChange && onChange(newValues)
        }

        // setShowDropdown(false)
    }
    const handleClickOutside = () => {
        setShowDropdown(false)
        setIndex(0)
        setSearchText("")
    }
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)

    return (
        <>
            <Wrap
                tabIndex={0}
                ref={refs.setReference}
                className={classNames(
                    "relative inline-flex justify-start select-none cursor-pointer",
                    className
                )}
                onClick={handleContainerClick}
                onKeyDown={handleContainerKeyDown}
            >
                {children}
            </Wrap>
            {showDropdown && (
                <FloatingPortal>
                    <div
                        ref={refs.setFloating}
                        onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        style={floatingStyles}
                        className="shadow-menu bg-white rounded-lg overflow-hidden z-20"
                    >
                        {searchable && (
                            <div className="flex items-center">
                                <InputSearch
                                    onKeyDown={handleKeyDown}
                                    autoFocus
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onBlur={handleSearchInputBlur}
                                    className="outline-none flex-1 px-4"
                                    value={searchText}
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                    placeholder={placeholder}
                                />
                            </div>
                        )}

                        <DropdownMenu
                            className={classNames(
                                " py-2 overflow-y-auto relative"
                            )}
                            height={menuHeight}
                            ref={menuRef}
                            onMouseDown={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                            onMouseMove={() =>
                                (ignorePointerRef.current = false)
                            }
                            onClick={handleClickDropdown}
                        >
                            {groupSelectedItems && (
                                <SelectedItems>
                                    {selectedItemsTop.map((e, _index) => (
                                        <div
                                            className={classNames(
                                                "menu-item text-sm",
                                                {
                                                    selected: _index == index,
                                                }
                                            )}
                                            key={getOptionValue(e)}
                                            onMouseDown={(ev) => {
                                                ev.preventDefault()
                                                ev.stopPropagation()
                                                onClickSelectedItem(e)
                                            }}
                                            onMouseOver={() =>
                                                handleMouseOverOption(e)
                                            }
                                        >
                                            <Checkbox
                                                value={selectedValues.includes(
                                                    getOptionValue(e)
                                                )}
                                                className=" mr-3 flex-shrink-0"
                                            />
                                            {renderOption
                                                ? renderOption(e)
                                                : getOptionLabel(e)}
                                        </div>
                                    ))}
                                </SelectedItems>
                            )}

                            {_options
                                .filter((e, index) =>
                                    !groupSelectedItems
                                        ? true
                                        : index < 40 &&
                                          !selectedValuesTop.includes(
                                              getOptionValue(e)
                                          )
                                )
                                .map((e, _index) => (
                                    <div
                                        className={classNames("menu-item", {
                                            selected: groupSelectedItems
                                                ? _index ==
                                                  index -
                                                      selectedItemsTop.length
                                                : _index === index,
                                        })}
                                        onMouseOver={() =>
                                            handleMouseOverOption(e)
                                        }
                                        onMouseDown={() =>
                                            handleSelectOption(e)
                                        }
                                        key={getOptionValue(e)}
                                    >
                                        <Checkbox
                                            value={selectedValues.includes(
                                                getOptionValue(e)
                                            )}
                                            className="mr-3 flex-shrink-0"
                                        />
                                        {renderOption
                                            ? renderOption(e)
                                            : getOptionLabel(e)}
                                    </div>
                                ))}
                            {_options.length == 0 &&
                                (renderNoOption ? (
                                    renderNoOption(searchText)
                                ) : (
                                    <div
                                        onMouseDown={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        className="menu-item text-gray-400 cursor-default bg-white"
                                    >
                                        Không tìm thấy kết quả
                                    </div>
                                ))}
                            {_options.length > 40 && (
                                <div
                                    onMouseDown={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    className="menu-item text-gray-400 cursor-default bg-white border-t border-[#ededed]"
                                >
                                    Còn {_options.length - 40} kết quả, gõ tiếp
                                    để lọc
                                </div>
                            )}
                        </DropdownMenu>
                        {footer}
                    </div>
                </FloatingPortal>
            )}
        </>
    )
}

export default MultiSelect

const Wrap = styled.div`
    outline: none;
`

const InputSearch = styled.input`
    font-size: 14px;
    border-bottom: 1px solid #ececec;
    width: 100%;
    padding: 8px 0;
`

const SelectedItems = styled.div`
    position: relative;
    overflow-y: auto;
    z-index: 10;
    .menu-item {
        padding: 0 12px;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        display: flex;
        align-items: center;
        /* height: ${MENU_ITEM_HEIGHT}px; */
        &:hover {
            cursor: pointer;
        }
    }
    border-bottom: 1px solid #e7e7e7;
`

const DropdownMenu = styled.div<{ height?: number }>`
    background-color: #fff;
    max-height: ${(props) => `${props.height || 250}px`};

    min-width: 240px;
    .menu-item {
        padding: 8px 12px;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        display: flex;
        align-items: center;
        min-height: 40px;
        /* height: ${MENU_ITEM_HEIGHT}px; */
        &:hover {
            cursor: pointer;
        }
        &.selected {
            background-color: #f5f5f5;
        }
    }
`
