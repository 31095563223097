import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import { COL_MARGIN, YAXIS_WIDTH } from "./TimePicker"
import { eventDetailStore } from "stores/eventDetailStore"
import classNames from "classnames"

type Props = {
    colWidth: number
    scrollLeft: number
    scrollTop: number
}

const Columns = ({ colWidth, scrollLeft, scrollTop }: Props) => {

    const { places } = useSnapshot(rootStore)
    const { editingAppointments } = useSnapshot(eventDetailStore)
    return (
        <>
            {
                places.map((e, index) => (

                    <Column
                        key={`col-${e.id}`}
                        className={classNames({ active: editingAppointments.find(item => item.placeId == e.id) })}
                        style={{
                            width: `${colWidth}px`,
                            transform: `translate(${-scrollLeft}px, ${-scrollTop}px)`,
                            marginLeft: `${index * colWidth - COL_MARGIN / 2 + YAXIS_WIDTH}px`,
                        }}
                    >
                    </Column>
                ))
            }
        </>
    )
}

export default Columns

const Column = styled.div`
    border-radius: 4px;
    pointer-events: none;
    height: 1080px;
    position: absolute;
    &.active {
        background-color: rgba(200,200,200,0.2);
    }
    /* &:hover {
        background-color: rgba(200,200,200,0.2);
    } */
    border-left: 1px solid #ededed;

      
`
