type ITimeline = {
    key?: string
    eventId: number
    duration: number
    typeId: number
    placeId: number
    hour: number
    min: number
}

export class Timeline {
    constructor(data: ITimeline) {
        this.key = data.key
        this.eventId = data.eventId;
        this.hour = data.hour;
        this.min = data.min;
        this.placeId = data.placeId;
        this.typeId = data.typeId;
        this.duration = data.duration;
    }
    key?: string   //key  generate ra ở local và chỉ sử dụng ở local
    eventId: number
    duration: number
    typeId: number
    placeId: number
    hour: number
    min: number

}

