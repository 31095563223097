import styled from "styled-components"
import { START_HOUR } from "./TimePickerSingle"

type Props = {
    bottom: number
    scrollTop: number
}

const EndTimeLabel = ({ scrollTop, bottom }: Props) => {
    let endHour = Math.floor(bottom / 60) + START_HOUR
    let endMin = Math.round((bottom - (endHour - START_HOUR) * 60) / 15) * 15
    if (endMin == 60) {
        endMin = 0
        endHour += 1
    }

    return (
        <EndTimeLabelWrap
            style={{
                transform: `translateY(${-scrollTop + 30 + Math.round(bottom / 15) * 15 - 9}px)`,
            }}
        >{`${endHour.padZero()}:${endMin.padZero()}`}</EndTimeLabelWrap>
    )
}

export default EndTimeLabel


const EndTimeLabelWrap = styled.div`
    font-size: 12px;
    cursor: default;
    user-select: none;
    color: var(--link);
    position: absolute;
    top: 0;
    z-index: 2;
    left: 6px;
`