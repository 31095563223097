import classNames from "classnames"
import ReactQuill from "core/ReactQuill"
import { Button } from "core/button/Button"
import { useEffect, useRef, useState } from "react"
import { quillFormat, quillModules } from "services/constants"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import OrderNoteChangeCount from "./OrderNoteChangeCount/OrderNoteChangeCount"

const OrderNote = () => {
    const { orderNote, currentEvent } = useSnapshot(eventDetailStore)
    const [focus, setFocus] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [textContent, setTextContent] = useState(orderNote)

    const quillRef = useRef<ReactQuill | null>(null)
    // @ts-ignore
    const handleChange = (
        value: string,
        delta: any,
        source: any,
        editor: any
    ) => {
        // eventDetailStore.orderNote = JSON.stringify(editor.getContents())
        setTextContent(JSON.stringify(editor.getContents()))
    }

    useEffect(() => {
        setTextContent(orderNote || "")
    }, [orderNote])

    const handleFocus = () => {
        setFocus(true)
    }

    const handleDone = async () => {
        eventDetailStore.orderNote = textContent
        // saveOrderNoteDraft(textContent, currentEvent.id)
        saveDraft()
        setEditMode(false)
    }

    useEffect(() => {
        if (editMode) {
            setTimeout(() => {
                quillRef?.current?.focus()
            }, 50)
        }
    }, [editMode])

    const handleCancel = () => {
        setEditMode(false)
        setTextContent(eventDetailStore.orderNote || "")
        // eventDetailStore.orderNote =
        //     eventDetailStore.currentEvent.orderNote || ""
    }

    return (
        <Wrap className={classNames({ focus: focus, readonly: !editMode })}>
            <div className="pt-3 text-md bg-white rounded-md mb-3  mt-2 shadow">
                <div
                    className={classNames(
                        "text-base flex items-center font-bold pl-5 mt-1"
                    )}
                >
                    Ghi chú thực đơn
                    {currentEvent.orderNote != orderNote && (
                        <OrderNoteChangeCount />
                    )}
                </div>
                <div className={classNames("px-5 pb-5 pt-3")}>
                    <EditorWrap
                        tabIndex={0}
                        className={classNames({ active: editMode })}
                        onClick={() => {
                            if (!editMode) {
                                setEditMode(true)
                            }
                        }}
                    >
                        <ReactQuill
                            theme="snow"
                            value={textContent ? JSON.parse(textContent) : ""}
                            modules={quillModules}
                            formats={quillFormat}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            readOnly={!editMode}
                            onBlur={() => setFocus(false)}
                            ref={(el) => {
                                quillRef.current = el
                            }}
                            placeholder="Ghi chú"
                        />
                        {!editMode && (
                            <span className="icon-edit pencil text-xs" />
                        )}
                    </EditorWrap>
                    {editMode && (
                        <div className="flex items-center gap-3 mt-5 action-buttons">
                            <Button onClick={handleDone}>Xong</Button>
                            <Button outline onClick={handleCancel}>
                                Hủy
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Wrap>
    )
}

export default OrderNote

const Wrap = styled.div`
    .quill {
        padding: 0;
        position: relative;
        .ql-toolbar {
            padding-left: 0;
        }
        .ql-picker-options {
            z-index: 11 !important;
        }
    }
    .ql-toolbar {
        position: absolute;
        bottom: 0;
        width: 100%;
        transform: translateY(100%);
    }
    .ql-editor {
        min-height: 80px;
    }

    &.focus {
        .ql-toolbar {
            opacity: 1;
            pointer-events: all;
        }
    }
    &.readonly {
        .ql-toolbar {
            display: none;
        }
        .quill {
            pointer-events: none;
        }
        .ql-editor {
            min-height: 0px;
        }
    }
    .ql-container {
        padding: 4px 8px;
    }
    .ql-editor.ql-blank::before {
        left: 7px;
    }
`

const EditorWrap = styled.div`
    border-radius: 4px;
    padding: 8px;
    position: relative;
    outline: 2px solid transparent;
    transition: outline 0.15s ease;
    border: 1px solid transparent;
    &:not(.active) {
        border: 1px solid #f5e8bc;
        background-color: #fff9e5;
    }
    &:hover {
        cursor: pointer;
        border: 1px solid #ffd866;
        .pencil {
            opacity: 1;
        }
    }

    &.active {
        padding-bottom: 40px;
        outline: 2px solid var(--link);
        &:hover {
            cursor: auto;
            border: 1px solid transparent;
            background-color: #fff;
        }
    }
    .pencil {
        position: absolute;
        color: orange;
        top: 6px;
        right: 10px;
        opacity: 0;
        pointer-events: none;
    }
`
