import classNames from "classnames"
import { ChevronLeftButton, ChevronRightButton } from "core/styledComponents"
import {
    addDays,
    addMonths,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isToday,
    startOfMonth,
    subDays,
    subMonths,
} from "date-fns"
import { DateCount } from "core/types"
import React, { useEffect, useState } from "react"
import { getLunarDate, isSameDay, isSameMonth } from "core/utils"
import { styled } from "styled-components"

type Props = {
    selectedDate?: Date
    onSelect?: (d: Date) => void
    dateCounts: DateCount[]
    onViewMonthChange: (d: Date) => void
}

const DatePickerInner = ({
    selectedDate,
    onSelect,
    onViewMonthChange,
    dateCounts: eventDates,
}: Props) => {
    const [viewMonth, setViewMonth] = useState(selectedDate ?? new Date())

    let firstDayCurrentMonth = startOfMonth(viewMonth)
    var dayEnd = endOfMonth(firstDayCurrentMonth)

    let days = eachDayOfInterval({
        start: firstDayCurrentMonth,
        end: dayEnd,
    })

    var daysPadLeft = []

    for (var i = 1; i < (firstDayCurrentMonth.getDay() || 7); i++) {
        daysPadLeft.push(
            subDays(
                firstDayCurrentMonth,
                (firstDayCurrentMonth.getDay() || 7) - i
            )
        )
    }

    var daysPadRight = []
    for (var i = 1; i <= 7 - (dayEnd.getDay() || 7); i++) {
        daysPadRight.push(addDays(dayEnd, i))
    }

    days = [...daysPadLeft, ...days, ...daysPadRight]
    useEffect(() => {
        setViewMonth(selectedDate || new Date())
    }, [selectedDate])

    // useEffect(() => {
    //     console.log('-------3333')
    //     ;(async () => {
    //         const from = days[0].toYMDNumber()
    //         const to = days[days.length - 1].toYMDNumber()
    //         var data = await StatisticService.countByDate(from, to)
    //         setEventDates(data)
    //     })()
    // }, [days[0].toYMDNumber(), days[days.length - 1].toYMDNumber()])

    const prevMonth = () => {
        var newValue = subMonths(viewMonth, 1)
        setViewMonth(newValue)
        onViewMonthChange(newValue)
    }

    const nextMonth = () => {
        var newValue = addMonths(viewMonth, 1)
        setViewMonth(newValue)
        onViewMonthChange(newValue)
    }

    const countSameDayEvents = (date: Date) => {
        var year = date.getFullYear().toString().substring(2, 4)
        var month = (date.getMonth() + 1).padZero()
        const day = date.getDate().padZero()
        var item = eventDates.find((e) => e.date == +`${year}${month}${day}`)
        return item?.count || 0
    }

    return (
        <>
            <MonthHeader>
                <div className="grid grid-cols-3 mt-2 mb-5 select-none">
                    <div>&nbsp;</div>
                    <div className="text-[17px] flex items-center font-bold text-center">
                        <div className=" w-24">
                            Tháng {viewMonth.getMonth() + 1}{" "}
                        </div>
                        <span className="text-[#C9C9C9]">
                            {viewMonth.getFullYear()}
                        </span>
                    </div>
                    <div className="mr-4 flex items-center gap-3 justify-end">
                        <ChevronLeftButton
                            className="cursor-pointer"
                            onClick={prevMonth}
                        />
                        <ChevronRightButton
                            className="cursor-pointer"
                            onClick={nextMonth}
                        />
                    </div>
                </div>
            </MonthHeader>
            <WeekdayHeader className="grid grid-cols-7 font-semibold text-xs mb-3 eading-6 text-center text-[#77889A] select-none">
                <div>T2</div>
                <div>T3</div>
                <div>T4</div>
                <div>T5</div>
                <div>T6</div>
                <div>T7</div>
                <div>CN</div>
            </WeekdayHeader>
            <MonthViewBody className="grid grid-cols-7 select-none">
                {days.map((day, dayIdx) => {
                    var lunarDate = getLunarDate(day)
                    var _isSameDay = selectedDate
                        ? isSameDay(day, selectedDate)
                        : false
                    var _isSameMonth = isSameMonth(day, firstDayCurrentMonth)
                    return (
                        <div
                            onClick={() => {
                                onSelect && onSelect(day)
                            }}
                            key={day.toString()}
                            className={classNames(
                                "relative flex flex-col",
                                dayIdx === 0 &&
                                    colStartClasses[getDay(day) - 1],
                                "py-1",
                                "day-item",
                                _isSameDay && "is-selected"
                            )}
                        >
                            <span
                                className={classNames(
                                    "date-num font-semibold",

                                    _isSameDay && "text-white font-semibold",
                                    !_isSameDay &&
                                        !_isSameDay &&
                                        !isToday(day) &&
                                        _isSameMonth &&
                                        "text-[#596671]",
                                    !_isSameDay &&
                                        !isToday(day) &&
                                        !_isSameMonth &&
                                        "text-[#C2C2C2]",

                                    "mx-auto text-md flex  items-center justify-center rounded"
                                )}
                            >
                                <time dateTime={format(day, "yyyy-MM-dd")}>
                                    {format(day, "d")}
                                </time>
                            </span>
                            {!_isSameDay && (
                                <Dots count={countSameDayEvents(day)} />
                            )}
                            <LunarDate
                                className={classNames(
                                    "text-center text-[10px] ",
                                    lunarDate.day == 1
                                        ? "text-red-500"
                                        : "text-gray-400"
                                )}
                            >
                                {lunarDate.day == 1
                                    ? `${lunarDate.day}/${lunarDate.month}`
                                    : lunarDate.day}
                            </LunarDate>
                        </div>
                    )
                })}
            </MonthViewBody>
        </>
    )
}

export default React.memo(DatePickerInner)

const Dots = ({ count }: { count: number }) => {
    if (!count) {
        return (
            <DotsWrap className="flex items-center justify-center opacity-0">
                <Dot />
            </DotsWrap>
        )
    }
    var list = Array(count).fill(0)
    if (count > 4) {
        list = Array(3).fill(0)
        return (
            <DotsWrap className="flex items-center justify-center ">
                {list.map((e, index) => (
                    <Dot key={index} />
                ))}
                <span className="icon-plus text-[6px]" />
            </DotsWrap>
        )
    }

    return (
        <DotsWrap className="flex items-center justify-center ">
            {list.map((e, index) => (
                <Dot key={index} />
            ))}
        </DotsWrap>
    )
}

const WeekdayHeader = styled.div`
    div {
    }
`

const MonthHeader = styled.div``

const MonthViewBody = styled.div`
    div.day-item {
        overflow: hidden;
        cursor: pointer;
        height: 64px;
        font-size: 13px;
        &:hover {
            background-color: #ededed;
            border-radius: 4px;
        }
        .date-num {
            border-radius: 4px;
            width: 32px;
            height: 32px;
        }
        &.is-selected {
            .date-num {
                background-color: var(--blue) !important;
            }
        }
    }
`
const DotsWrap = styled.div`
    column-gap: 2px;
    margin-top: -4px;
`
const Dot = styled.div`
    width: 4px;
    height: 4px;
    background-color: var(--blue);
    border-radius: 9px;
`

const LunarDate = styled.div`
    position: absolute;
    bottom: 8px;
    left: 50%;
    cursor: default;
    transform: translateX(-50%);
`

let colStartClasses = [
    "",
    "col-start-2",
    "col-start-3",
    "col-start-4",
    "col-start-5",
    "col-start-6",
    "col-start-7",
]
