import { useCallback, useEffect, useRef, useState } from "react"
import { Input } from "core/styledComponents"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import CloseButton from "core/button/CloseButton"
import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import styled from "styled-components"
import { useClickOutside } from "core/hooks"
import EventService from "services/eventService"
import { EventItem } from "models/events/event"
import IconClose from "common/icons/IconClose"
import EventMenu from "models/events/eventMenu"
import Checkbox from "core/Checkbox"
import { parseOrderName } from "services/orderUtils"
import MenuListPopup from "./MenuListPopup/MenuListPopup"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onDone: (orderName: string, eventMenus: EventMenu[]) => void
}

const DialogSearchEvent = ({ isOpen, setIsOpen, onDone }: Props) => {
    const [searchText, setSearchText] = useState("")
    const [events, setEvents] = useState<EventItem[]>([])
    const [eventItem, setEventItem] = useState<EventItem>()
    const [loading, setLoading] = useState(false)
    const [eventMenus, setEventMenus] = useState<EventMenu[]>([])
    const [selectedMenus, setSelectedMenus] = useState<EventMenu[]>([])
    const [selectedOrder, setSelectedOrder] = useState<string>()
    const [showMenuList, setShowMenuList] = useState(false)

    useEffect(() => {
        if (eventItem && isOpen) {
            ;(async () => {
                var data = await EventService.getEventMenus(eventItem.id)
                console.log(data)
                setEventMenus(data)
            })()
        }
    }, [eventItem, isOpen])

    const handleChange = (s: string) => {
        setSearchText(s)
        doSearch(s)
    }

    useEffect(() => {
        if (!isOpen) {
            setSearchText("")
            setEvents([])
            setEventItem(undefined)
            setEventMenus([])
            setSelectedOrder(undefined)
        }
    }, [isOpen])

    const timerRef = useRef(0)

    const doSearch = (query: string) => {
        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(async () => {
            setLoading(true)
            var data = await EventService.searchEvent(query)
            setLoading(false)
            setEvents(data)
        }, 800)
    }

    const handleSelect = (e: EventItem) => {
        setEventItem(e)
        setEvents([])
        setSearchText("")
    }

    const handleClear = () => {
        setEventItem(undefined)
        setSearchText("")
        setEventMenus([])
        setEvents([])
    }

    const handleClickOrder = (name: string) => {
        if (selectedOrder === name) {
            setSelectedOrder(undefined)
        } else {
            setSelectedOrder(name)
        }
    }
    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key == "Enter" && searchText) {
            }
        },
        [searchText]
    )

    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: ["bottom-start"],
            }),
            offset(10),
        ],
    })
    const handleClickOutside = useCallback(() => {
        setIsOpen(false)
    }, [])
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)

    var orderNames = eventItem ? eventItem.orderNames : []

    const handleDone = () => {
        if (selectedOrder !== undefined) {
            var orderName = parseOrderName(selectedOrder).name
            var selectedMenus = eventMenus.filter(
                (e) => e.orderName === orderName
            )
            onDone(selectedOrder, selectedMenus)
        }
    }

    const handleShowMenus = () => {
        if (selectedOrder !== undefined) {
            var orderName = parseOrderName(selectedOrder).name
            var menuList = eventMenus.filter((e) => e.orderName === orderName)
            setSelectedMenus(menuList)
            setShowMenuList(true)
        }
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px]  rounded-lg  w-[500px] mt-[15vh] bg-white">
                    <div className=" pt-6 pb-3 px-14 flex-col flex items-center relative">
                        <CloseButton
                            onClick={() => setIsOpen(false)}
                            className="absolute top-2 right-3"
                        />
                        <div className="text-xl font-semibold text-primary mb-5 text-center">
                            Sao chép thực đơn
                        </div>
                        <div className="w-full">
                            {/* <div className="mb-1">Tìm kiếm sự kiện</div> */}
                            <div ref={refs.setReference} className="w-full">
                                <div className="text-md mb-1 text-[#333] font-medium">
                                    Sao chép từ sự kiện:
                                </div>
                                <div className="relative">
                                    <Input
                                        className="text-md"
                                        placeholder={
                                            eventItem != null
                                                ? ""
                                                : "Gõ tiêu đề để tìm kiếm"
                                        }
                                        autoFocus={true}
                                        value={searchText}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) =>
                                            handleChange(e.target.value)
                                        }
                                    />
                                    {eventItem != null && !searchText && (
                                        <PlaceholderText className="selected-event absolute top-0 mt-[8px] ml-[13px] left-0 pointer-events-none">
                                            {eventItem.title}
                                        </PlaceholderText>
                                    )}
                                    {(eventItem != null ||
                                        searchText.length > 0) &&
                                        !loading && (
                                            <div
                                                onMouseDown={(ev) =>
                                                    ev.preventDefault()
                                                }
                                                onClick={handleClear}
                                                className="w-4 h-4 absolute rounded-full cursor-pointer hover:bg-slate-400 bg-[#dddddd] right-2 top-2.5 flex items-center justify-center"
                                            >
                                                <IconClose className="text-white text-[12px]" />
                                            </div>
                                        )}
                                </div>
                            </div>
                            {events.length > 0 && (
                                <FloatingPortal>
                                    <DropdownMenu
                                        className="shadow-menu rounded-lg py-2 z-[100]"
                                        ref={refs.setFloating}
                                        style={floatingStyles}
                                    >
                                        {events.map((e) => (
                                            <div
                                                key={e.id}
                                                className="menu-item"
                                                onClick={() => handleSelect(e)}
                                            >
                                                {e.title} -{" "}
                                                {e.startDate.formatDMY()}
                                                {/* <span className="text-[#7d7e8b]">
                                                    
                                                </span> */}
                                            </div>
                                        ))}
                                    </DropdownMenu>
                                </FloatingPortal>
                            )}
                        </div>
                        {eventItem != null && (
                            <div className="w-full mt-4 mb-2">
                                <div className="text-md mb-1 text-[#333] font-medium">
                                    Thực đơn:
                                </div>
                            </div>
                        )}

                        {eventItem != null && !orderNames.length && (
                            <div className="text-[#ccc] text-sm mb-4">
                                Không có dữ liệu
                            </div>
                        )}

                        <div className="w-full">
                            {orderNames.map((name) => (
                                <div
                                    className="flex items-center mb-4"
                                    onClick={() => handleClickOrder(name)}
                                >
                                    <Checkbox
                                        value={selectedOrder === name}
                                        className="mr-3"
                                    />{" "}
                                    {parseOrderName(name).name
                                        ? parseOrderName(name).name
                                        : "Mặc định"}
                                </div>
                            ))}
                        </div>
                        <hr className="w-full mt-3" />
                        <div className="w-full flex justify-between items-center mt-3 ">
                            {orderNames.length > 0 && (
                                <>
                                    {selectedOrder !== undefined && (
                                        <Button
                                            onClick={handleShowMenus}
                                            outline
                                            className="text-sm border-none text-link hover:underline"
                                        >
                                            Xem thực đơn
                                        </Button>
                                    )}
                                    <Button
                                        className="text-sm w-24"
                                        // size="lg"
                                        onClick={handleDone}
                                        disabled={selectedOrder === undefined}
                                    >
                                        Xong
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <MenuListPopup
                isOpen={showMenuList}
                setIsOpen={setShowMenuList}
                menus={selectedMenus}
            />
        </>
    )
}

export default DialogSearchEvent

const DropdownMenu = styled.div`
    width: 400px;
    max-height: 250px;
    overflow-y: auto;
    background-color: #fff;

    .menu-item {
        font-size: 14px;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
        span {
            width: 32px;
            display: inline-flex;
        }
    }
`
const PlaceholderText = styled.div`
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`
