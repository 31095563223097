import { SIDEBAR_WIDTH } from "services/constants"
import { parseColor } from "services/utils"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import PlaceButton from "./PlaceButton"
import { YAXIS_WIDTH } from "./TimePicker"
import { PlaceItem } from "models/place"

type Props = {
    places: PlaceItem[]
}

//@ts-ignore
const PlacesContainer = ({ places }: Props) => {
    return (
        <PlacesWrapper
            style={{
                // transform: `translateX(-${scrollLeft}px)`,
                paddingLeft: `${YAXIS_WIDTH}px`,
                minWidth: 0
            }}
            className="  h-10 flex items-center overflow-hidden">
            {
                places.map(e => (
                    <PlaceButton
                        key={e.id}
                        color={parseColor(e.color)}
                        className="flex-1"
                    >
                        <div style={{
                            // maxWidth: 60,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {e.name}
                        </div>
                    </PlaceButton>
                ))
            }
            <div className="w-5">&nbsp;</div>
        </PlacesWrapper>
    )
}

export default PlacesContainer


const PlacesWrapper = styled.div`
    overflow: hidden;
    background-color: #fff;
    position: sticky;
    top: 54px;
    z-index: 8;
    border-bottom: 1px solid #ededed;
`

