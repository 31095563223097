import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import TimelineChangeHistory from "modules/EventDetail/GeneralTab/TimelineChangeCount/TimelineChange/TimelineChangeHistory"
import { saveDraft } from "services/draftService"
import { useDateChanges, useTimelineChangeCount } from "services/eventService"
import { usePlaces } from "services/placeService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ScheduleChanges = ({ isOpen, setIsOpen }: Props) => {
    const { selectedDate, currentEvent, editingAppointments } =
        useSnapshot(eventDetailStore)
    const places = usePlaces()
    const dateChange = useDateChanges()

    const cancelChanges = () => {
        eventDetailStore.selectedDate = new Date(
            currentEvent.startDate.getTime()
        )
        eventDetailStore.editingAppointments = currentEvent.parseTimelines()
        saveDraft()
        // clearTimelineDraft(currentEvent.id)
        // clearDateDraft(currentEvent.id)
        setIsOpen(false)
    }
    const timelineChange = useTimelineChangeCount()
    if (currentEvent.id == 0) return null
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg   w-[550px] pb-3 mt-[25vh] bg-white">
                    <DialogTitle>Thay đổi lịch tiệc</DialogTitle>
                    <div className="px-12 pb-2 pt-5 text-sm">
                        {dateChange > 0 && (
                            <>
                                <div>Ngày:</div>
                                <div className="px-12 pb-5 pt-3 text-[15px] text-center">
                                    <span>
                                        {currentEvent.startDate.formatDMY()}
                                    </span>{" "}
                                    <span className="text-[#aaa] mx-2">→</span>
                                    <span>{selectedDate.formatDMY()}</span>
                                </div>
                            </>
                        )}
                        {timelineChange > 0 && (
                            <>
                                <div className="mb-4">Giờ & địa điểm:</div>
                                <TimelineChangeHistory
                                    newTimelines={[...editingAppointments]}
                                    oldTimelines={currentEvent.parseTimelines()}
                                    places={places}
                                />
                            </>
                        )}
                    </div>
                    <hr />
                    <div className="justify-center flex pt-3">
                        <Button outline onClick={cancelChanges}>
                            Hủy thay đổi
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ScheduleChanges
