import { NumericInput } from "core/styledComponents"
import { useEffect, useRef, useState } from "react"
import { NumberFormatValues } from "react-number-format"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import TableReserveChangeCount from "./TableReserveChangeCount/TableReserveChangeCount"
import { saveTableReserveDraft } from "services/tableReserveDraft"
import { saveDraft } from "services/draftService"

const InputTableReserve = () => {
    const { tableReserve, currentEvent } = useSnapshot(eventDetailStore)
    const [value, setValue] = useState(tableReserve)
    const timerRef = useRef(0)
    useEffect(() => {
        setValue(tableReserve)
    }, [tableReserve])

    const handleChange = (values: NumberFormatValues) => {
        const _value = values.floatValue
        setValue(_value || 0)
        eventDetailStore.tableReserve = _value || 0
        // saveTableReserveDraft(_value || 0, currentEvent.id)
        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(() => {
            saveDraft()
        }, 500)
    }

    const handleBlur = () => {
        window.clearTimeout(timerRef.current)
    }
    return (
        <NumericInput
            placeholder="0"
            value={value || ""}
            autoFocus
            onBlur={handleBlur}
            allowLeadingZeros
            thousandSeparator=","
            onValueChange={handleChange}
        />
    )
}

export default InputTableReserve
