
import * as ReactTooltip from '@radix-ui/react-tooltip';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode,
    content: string
    placement?: "top" | "left" | "right" | "bottom"
}
const Tooltip = ({ children, content, placement }: Props) => {
    return (
        <>
            <ReactTooltip.Provider delayDuration={250}>
                <ReactTooltip.Root>
                    <ReactTooltip.Trigger asChild>
                        {children}
                    </ReactTooltip.Trigger>
                    <ReactTooltip.Portal
                    >
                        <ReactTooltip.Content
                            side={placement}
                            className="z-[1000] data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-[#fff] select-none rounded-[4px] bg-[rgba(0,0,0,1)] px-[12px] py-[6px] text-[13px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
                            sideOffset={5}
                        >
                            {content}
                            <ReactTooltip.Arrow className="fill-[rgba(0,0,0,1)]" />
                        </ReactTooltip.Content>
                    </ReactTooltip.Portal>
                </ReactTooltip.Root>
            </ReactTooltip.Provider>
        </>
    )
}

export default Tooltip