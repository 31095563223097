import { useState } from "react"
import { useDateChanges, useTimelineChangeCount } from "services/eventService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import CustomerChange from "./CustomerChange/CustomerChange"
import classNames from "classnames"

const CustomerChangeCount = ({ className }: { className?: string }) => {
    const { customerId, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    if (
        currentEvent.id == 0 ||
        (customerId || 0) == (currentEvent.customerId || 0)
    )
        return null
    return (
        <>
            <span
                onClick={() => setIsOpen(true)}
                className={classNames(
                    "text-[orangered] text-13 font-normal cursor-pointer hover:underline",
                    className
                )}
            >
                +1
            </span>
            <CustomerChange isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default CustomerChangeCount
