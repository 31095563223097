import classNames from "classnames"
import { useCallback } from "react"
import Select, { ClearIndicatorProps } from "react-select"

import { PlaceItem } from "models/place"
import { rootStore } from "stores/rootStore"
import { useSnapshot } from "valtio"
import { EventType } from "models/eventType"
import { useEventTypes } from "services/eventTypeService"
import CaretDown from "../../../../common/CaretDown"
import { ClearIcon } from "../../../../common/ClearIcon"

type Props = {
    className?: string
    isClearable?: boolean
    typeId: number
    onChange: (typeId: number) => void
    onFocus?: () => void
}
const SelectEventType = ({
    className,
    typeId,
    onChange,
    isClearable,
    onFocus,
}: Props) => {
    const { eventTypes } = useSnapshot(rootStore)
    const getOptionValue = useCallback((option: EventType) => {
        return option.id.toString()
    }, [])

    const getOptionLabel = useCallback((option: EventType) => {
        return option.name
    }, [])

    const handleChange = (option: EventType | null) => {
        onChange(option?.id || 0)
    }
    const value = eventTypes.find((e) => e.id === typeId) || null
    return (
        <>
            <Select
                classNamePrefix={"react-select"}
                className={classNames(className)}
                components={{
                    DropdownIndicator: CaretDown,
                    ClearIndicator: ClearIcon,
                }}
                value={value}
                options={eventTypes}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                isMulti={false}
                onFocus={onFocus}
                isClearable={isClearable}
                onChange={handleChange}
                placeholder="Chọn loại hình"
                noOptionsMessage={() => "Không có kết quả"}
            />
        </>
    )
}

export default SelectEventType
