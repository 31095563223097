import * as Popover from "@radix-ui/react-popover"
import classNames from "classnames"
import { useState } from "react"
import { DialogName } from "services/constants"
import { openDialog } from "stores/dialogStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { paymentStore } from "stores/paymentStore"
import styled from "styled-components"
import { DropdownMenu } from "core/styledComponents"
import { useSnapshot } from "valtio"
import AddPaymentSection from "./AddPaymentSection"
import DialogRenamePaymentSection from "modules/dialogs/DialogRenamePaymentSection"
import IconDotsVertical from "common/icons/IconDotsVertical"

const PaymentMoreButton = () => {
    const [open, setOpen] = useState(false)
    const [isOpenRename, setIsOpenRename] = useState(false)
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const { currentPaymentName: currentSection, paymentNames: currentSectionNames } = useSnapshot(paymentStore)

    const handleDelete = async () => {
        if (currentSectionNames.length > 0) {
            paymentStore.editingPayments = paymentStore.editingPayments.filter(
                (e) => e.section !== currentSection
            )
            paymentStore.paymentNames = currentSectionNames.filter(
                (e) => e !== currentSection
            )
            if (currentSectionNames.length) {
                eventMenuStore.currentOrderName = currentSectionNames[0]
            }
        }
    }

    return (
        <>
            <Popover.Root open={open} onOpenChange={setOpen}>
                <Popover.Trigger className="" asChild>
                    <Div
                        className={classNames(
                            "flex items-center justify-center",
                            { active: open }
                        )}
                    >
                        <IconDotsVertical width={20} height={20} />
                    </Div>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        align="end"
                        className="card-shadow bg-white p-1 outline-none rounded-lg z-30"
                        sideOffset={5}
                    >
                        <DropdownMenu onClick={() => setIsOpenAdd(true)}>
                            Thêm mục thanh toán
                            <span className="icon-plus text-xs ml-auto" />
                        </DropdownMenu>
                        <DropdownMenu onClick={() => setIsOpenRename(true)}>
                            Đổi tên mục thanh toán
                            <span className="icon-plus text-xs ml-auto" />
                        </DropdownMenu>
                        <DropdownMenu
                            className="text-red-500"
                            onClick={handleDelete}
                            disabled={currentSectionNames.length < 1}
                        >
                            Xóa mục thanh toán
                            <span className="icon-trash text-xs ml-auto" />
                        </DropdownMenu>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
            <AddPaymentSection isOpen={isOpenAdd} setIsOpen={setIsOpenAdd} />
            <DialogRenamePaymentSection
                isOpen={isOpenRename}
                setIsOpen={setIsOpenRename}
            />
        </>
    )
}

export default PaymentMoreButton

const Div = styled.div`
    width: 38px;
    height: 38px;
    &:hover,
    &.active {
        color: var(--link);
        background-color: #fafafa;
    }
`
