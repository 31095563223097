import { useFloating, autoPlacement, offset, FloatingPortal, FloatingOverlay } from "@floating-ui/react"
import classNames from "classnames"
import { useClickOutside } from "core/hooks"
import { ReactNode, useState } from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"

type Props = {
    dropdown?: ReactNode
    children: ReactNode
    active: boolean
}

const HeaderMenu = ({ active, dropdown, children }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: ["bottom-start"],
            }),
        ],
    })

    const handleClickOutside = () => {
        setIsOpen(false)
    }
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)
    return (
        <>
            <MenuItemsWrap
                onClick={() => setIsOpen((s) => !s)}
                ref={refs.setReference}
                className="menu-items-wrap text-13 flex items-center"
            >
                <div
                    className={classNames("menu-item font-semibold", {
                        active: active || isOpen,
                    })}
                >
                    {children}
                    {dropdown ? (
                        <span className="icon-chevron-thin-down icon ml-[6px] relative bottom-[2px] text-[5px]" />
                    ) : null}
                </div>
            </MenuItemsWrap>
            {isOpen && !!dropdown && (
                 <FloatingPortal>
                    {/* <FloatingOverlay
                            className='z-20'
                            onClick={handleClickOverlay}
                        >
                            
                        </FloatingOverlay> */}
                    <Dropdown ref={refs.setFloating} style={floatingStyles}>
                        {dropdown}
                    </Dropdown>
                 </FloatingPortal>
            )}
        </>
    )
}

export default HeaderMenu

const MenuItemsWrap = styled.div`
    position: relative;
    color: #fff;
    &:hover {
        color: #fff;
    }
    .menu-item {
        padding: 0 12px;
        height: 54px;
        padding-top: 18px;
        /* border-bottom: 2px solid transparent; */
        user-select: none;
        cursor: pointer;
        &.active {
            background-color: #253342;
            /* border-bottom: 2px solid var(--brand); */
            .icon {
                color: var(--brand);
            }
        }
        &:hover {
            background-color: #253342;
            .icon {
                color: var(--brand);
            }
        }
    }
`

const Right = styled.div``

const Dropdown = styled.div`
    min-width: 220px;
    padding: 12px 0;
    position: absolute;
    z-index: 11;
    top: 100%;
    left: 0;
    background-color: #253342;
    font-size: 13px;
    .dropdown-menu {
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 18px;
        color: white;
        font-weight: 600;
        &:hover {
            background-color: #33475b;
        }
    }
`
