import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"

const DepositChangeCount2 = ({
    onClick,
    onMouseEnter,
}: {
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseEnter: (e: any) => void
}) => {
    const { deposit, currentEvent } = useSnapshot(eventDetailStore)
    const depositChange = (deposit || 0) != (currentEvent.deposit || 0)

    if (currentEvent.id == 0 || !depositChange) return null
    return (
        <>
            <div
                onMouseOver={(e) => e.stopPropagation()}
                onMouseOut={(e) => e.stopPropagation()}
                onMouseEnter={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onMouseEnter && onMouseEnter(e)
                }}
                onMouseLeave={(e) => e.stopPropagation()}
                onClick={onClick}
                className="text-[orangered] text-13 font-normal cursor-pointer hover:underline absolute right-[-28px] top-0"
            >
                +1
            </div>
        </>
    )
}

export default DepositChangeCount2
