import DialogAddChecklist from "modules/dialogs/DialogAddChecklist"
import PlusButton from "modules/ui/PlusButton"
import UndoButton from "modules/ui/UndoButton"
import { useState } from "react"
import { todoStore } from "stores/todoStore"
import ChecklistMoreButton from "./ChecklistMoreButton"
import DialogSelectTodo from "modules/dialogs/DialogSelectTodo"

const ChecklistTopActions = () => {
    // const [loading, setLoading] = useState(false)

    const [isOpen, setIsOpen] = useState(false)

    const onReset = () => {
        todoStore.resetTodos()
    }

    // const onSave = async () => {
    //     setLoading(true)
    //     await eventDetailStore.saveChecklistToDB()
    //     setLoading(false)
    // }
    return (
        <div className="flex items-center gap-4">
            <PlusButton
                onClick={() => setIsOpen(true)}
                size={24}
                tooltip="Thêm công việc"
            />
            <UndoButton onClick={onReset} size={15} tooltip="Hủy thay đổi" />

            <ChecklistMoreButton />

            {/* <span className="icon-checkmark text-[11px]" /> */}
            {/* <IconActionButton
                onClick={onSave}
                loading={loading}
                iconClass="icon-save"
                size={13}
                tooltip="Lưu"
            /> */}
            <DialogSelectTodo isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    )
}

export default ChecklistTopActions
