import { AxiosResponse } from "axios"
import { EventPayload } from "models/event_payload"
import ScheduleEvent, { EventItem, EventLite } from "models/events/event"
import EventMenu from "models/events/eventMenu"
import { CommonResponse } from "models/response"
import { Timeline } from "models/timeline"
import { nanoid } from "nanoid"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { attachmentStore } from "stores/attachmentStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { paymentStore } from "stores/paymentStore"
import { rootStore } from "stores/rootStore"
import { todoStore } from "stores/todoStore"
import DateUtils from "utils/DateUtils"
import EventUtils from "utils/EventUtils"
import { calculateEventMenusChangeCount } from "utils/OrderUtils"
import { useSnapshot } from "valtio"
import API from "./api"
import { getDraft } from "./draftService"
import {
    calculateOrderNamesChange,
    calculateOrderTableCountChanges,
    encodeTimelines,
    formatGuestEstimate,
    getQuillContent
} from "./utils"

const searchEvent = async (q: string) => {
    var res = await API.get<AxiosResponse<ScheduleEvent[]>>(
        `/events/query-simple?q=${q}`
    )
    return res.data.data.map((e) => new EventItem(e))
}
const getEventMenus = async (eventId: number) => {
    var res = await API.get<AxiosResponse<EventMenu[]>>(
        `/event-menus/${eventId}`
    )
    return res.data.data
}
const getEventsByStartDate = async (d: Date) => {
    var res = await API.get<AxiosResponse<ScheduleEvent[]>>(
        `/events?startDate=${d.toYMDNumber()}`
    )
    return res.data.data.map((e) => new EventItem(e))
}

export const useTimelineChangeCount = () => {
    const { currentEvent, editingAppointments, isFetchedEvent } =
        useSnapshot(eventDetailStore)
    const [count, setCount] = useState(0)
    useEffect(() => {
        var changeCount =
            encodeTimelines(editingAppointments) !=
            encodeTimelines(currentEvent.parseTimelines())
                ? 1
                : 0

        setCount(isFetchedEvent ? changeCount : 0)
    }, [currentEvent.timeline, editingAppointments, isFetchedEvent])
    return count
}

export const useChecklistNoteChangeCount = () => {
    const { checklistNote, isFetchedEvent } = useSnapshot(eventDetailStore)
    if (!isFetchedEvent) return 0
    const changed =
        getQuillContent(checklistNote) !=
        getQuillContent(eventDetailStore.currentEvent.checklistNote || "")
    return changed ? 1 : 0
}

export const useOrderNoteChangeCount = () => {
    const { orderNote, isFetchedEvent } = useSnapshot(eventDetailStore)
    if (!isFetchedEvent) return 0
    const changed =
        getQuillContent(orderNote) !=
        getQuillContent(eventDetailStore.currentEvent.orderNote || "")
    return changed ? 1 : 0
}

export const useOrderNameChangeCount = () => {
    const { currentEvent, isFetchedEvent } = useSnapshot(eventDetailStore)
    const { orderNames } = useSnapshot(eventMenuStore)
    if (currentEvent.id == 0 || !isFetchedEvent) return 0

    const newOrderNames = [...orderNames]
    const oldOrderNames = [...currentEvent.orderNames]

    const orderNameChanges = calculateOrderNamesChange(
        oldOrderNames,
        newOrderNames
    )

    const { addedItems, updatedItems, removedItems } = orderNameChanges.items

    const orderNameChangeCount =
        addedItems.length + updatedItems.length + removedItems.length
    if (orderNameChangeCount == 1) {
        console.log({ newOrderNames, oldOrderNames, currentEvent })
    }
    return orderNameChangeCount
}

export const useMenuChangeCount = () => {
    const { currentEvent, isFetchedEvent } = useSnapshot(eventDetailStore)
    const { editingMenus } = useSnapshot(eventMenuStore)
    if (currentEvent.id == 0 || !isFetchedEvent) return 0

    const count = calculateEventMenusChangeCount(
        currentEvent.menus,
        editingMenus
    )
    return count
}

export const useOrderChangeCount = () => {
    const { isFetchedEvent: isFetchedEvent } = useSnapshot(eventDetailStore)
    const menuChangeCount = useMenuChangeCount()
    const orderNameChangeCount = useOrderNameChangeCount()
    return !isFetchedEvent ? 0 : orderNameChangeCount + menuChangeCount
}

export const useTableCountChanges = () => {
    const { tableSetup, currentEvent, isFetchedEvent } =
        useSnapshot(eventDetailStore)
    const { orderNames } = useSnapshot(eventMenuStore)

    if (!isFetchedEvent || currentEvent.id == 0) {
        return {
            changed: false,
            changedItems: [],
        }
    }

    const newOrderNames = [...orderNames]
    const oldOrderNames = [...currentEvent.orderNames]
    const orderNameChanges = calculateOrderNamesChange(
        oldOrderNames,
        newOrderNames
    )

    const tableSetupChangeDetails = `${currentEvent.tableSetup}$${tableSetup}`
    const changes = calculateOrderTableCountChanges({
        orderNameChangeDetails: orderNameChanges.details,
        tableSetupChangeDetails,
    })
    return changes
}

export const useGeneralChangeCount = () => {
    const {
        title,
        status,
        deposit,
        tableReserve,
        isFetchedEvent,
        customerId,
        guestFrom,
        guestTo,
        eventNote,
        currentEvent,
    } = useSnapshot(eventDetailStore)
    const dateChange = useDateChanges()
    const timelineChange = useTimelineChangeCount()
    const titleChange = title !== currentEvent.title ? 1 : 0
    const statusChange = status !== currentEvent.status ? 1 : 0
    const depositChange = (deposit || 0) !== (currentEvent.deposit || 0) ? 1 : 0
    const tableCountChanges = useTableCountChanges()
    const tableReserveChange =
        (tableReserve || 0) !== (currentEvent.tableReserve || 0) ? 1 : 0

    const guestChange =
        formatGuestEstimate(guestFrom, guestTo) !=
        formatGuestEstimate(currentEvent.guestFrom, currentEvent.guestTo)
            ? 1
            : 0
    const customerChange =
        (customerId || 0) !== (currentEvent.customerId || 0) ? 1 : 0
    const noteChange = eventNote !== currentEvent.note ? 1 : 0

    const totalChangeCount =
        dateChange +
        timelineChange +
        titleChange +
        statusChange +
        depositChange +
        guestChange +
        customerChange +
        (tableCountChanges.changed ? 1 : 0) +
        tableReserveChange +
        noteChange

    return !isFetchedEvent ? 0 : totalChangeCount
}

export const useDateChanges = () => {
    const { currentEvent, selectedDate } = useSnapshot(eventDetailStore)
    const [count, setCount] = useState(0)
    useEffect(() => {
        var changeCount = DateUtils.isSameDay(
            selectedDate,
            currentEvent.startDate
        )
            ? 0
            : 1
        setCount(changeCount)
    }, [currentEvent.startDate, selectedDate])
    return count
}

export const useEventDetailFull = (eventId: number) => {
    const [loading, setLoading] = useState(false)
    const [event, setEvent] = useState<EventItem>(EventItem.generateEmpty())
    const { refreshCount } = useSnapshot(eventDetailStore)

    useEffect(() => {
        ;(async () => {
            if (eventId) {
                console.log("call get event detail full in useEventDetailFull")
                setLoading(true)

                var ev = await getEventDetailFull(eventId)
                setLoading(false)

                var eventItem = new EventItem(ev)
                setEvent(new EventItem(ev))

                var draft = await getDraft(ev.id)
                if (draft) {
                    eventDetailStore.initEventDraft(draft)
                    eventDetailStore.currentEvent = eventItem
                } else {
                    eventDetailStore.initEventBasicInfo(eventItem)
                }

                eventMenuStore.initMenusAndOrderNames(
                    (draft?.menus || eventItem.menus || []).map((e) => ({
                        ...e,
                    })),
                    draft?.orderNames || ev.orderNames || []
                )
                //init todos
                todoStore.initTodosAndChecklists(
                    (draft?.todos || eventItem.todos || []).map((e) => ({
                        ...e,
                    })),
                    ev.checklistNames || []
                )
                //init payments
                paymentStore.initEventPaymentsAndSections(
                    (draft?.payments || eventItem.payments || []).map((e) => ({
                        ...e,
                        free: !!e.free,
                    }))
                )
                //init attachments
                attachmentStore.attachments = (eventItem.attachments || []).map(
                    (e) => ({
                        ...e,
                    })
                )

                eventDetailStore.isFetchedEvent = true
            }
        })()
    }, [eventId, refreshCount])
    return {
        loading,
        event,
    }
}

export const useCurrentEvent = () => {
    //làm 2 việc: init giá trị cho currentEvent và init giá trị cho currentAppointments
    let { eventId, mode } = useParams()

    eventDetailStore.editMode = !!mode || !eventId
    const { events } = useSnapshot(rootStore)
    const [event, setEvent] = useState<EventItem>(EventItem.generateEmpty())
    useEffect(() => {
        if (events.length && eventId) {
            var found = events.find((e) => e.id === Number(eventId))
            // if (found) {
            //     var ev = new EventItem({found})
            //     eventDetailStore.currentEvent = ev
            //     eventDetailStore.initEventBasicInfo(ev)
            //     setEvent(ev)
            // }
        }
    }, [events.length, eventId])
    return { event }
}

const saveOrderToDB = async (): Promise<CommonResponse> => {
    var event = eventDetailStore.currentEvent
    var menus = eventMenuStore.editingMenus
    var payload = {
        id: event.id,
        menus,
        eventTitle: event.title,
        userName: rootStore.currentUser.displayName,
    }

    var response = await API.put<CommonResponse>(`/events/orders`, payload)
    return response.data
}
const saveOrderNoteToDB = async ({
    orderNote,
    plainText,
}: {
    orderNote: string
    plainText: string
}): Promise<CommonResponse> => {
    var event = eventDetailStore.currentEvent
    var payload = {
        id: event.id,
        orderNote: orderNote,
        plainText: plainText,
        eventTitle: event.title,
        userName: rootStore.currentUser.displayName,
    }

    var response = await API.put<CommonResponse>(`/events/orderNote`, payload)
    return response.data
}
const saveEventNoteToDB = async (): Promise<CommonResponse> => {
    var event = eventDetailStore.currentEvent
    var payload = {
        id: event.id,
        note: eventDetailStore.eventNote,
        eventTitle: event.title,
        userName: rootStore.currentUser.displayName,
    }

    var response = await API.put<CommonResponse>(`/events/eventNote`, payload)
    return response.data
}
const saveChecklistToDB = async (): Promise<CommonResponse> => {
    var event = eventDetailStore.currentEvent
    var todos = todoStore.editingTodos
    var payload = {
        id: event.id,
        todos,
        eventTitle: event.title,
        userName: rootStore.currentUser.displayName,
    }

    var response = await API.put<CommonResponse>(`/event-todos`, payload)
    return response.data
}

const saveTimelineToDB = async (): Promise<CommonResponse> => {
    var timelines: Timeline[] = eventDetailStore.editingAppointments
    var { timeline, startTime } = EventUtils.calculateTimeline(
        timelines,
        eventDetailStore.selectedDate
    )

    //update lại timeline, startTime và startDate
    eventDetailStore.currentEvent.startTime = startTime
    eventDetailStore.currentEvent.timeline = timeline
    eventDetailStore.currentEvent.startDate =
        eventDetailStore.currentEvent.getStartTime()

    var placeName =
        rootStore.places.find((e) => e.id === timelines[0].placeId)?.name || ""

    var payload = {
        id: eventDetailStore.currentEvent.id,
        timeline,
        startTime,
        eventTitle: eventDetailStore.currentEvent.title,
        userName: rootStore.currentUser.displayName,
        placeName,
    }

    var response = await API.put<CommonResponse>(`/event-timeline`, payload)
    return response.data
}

const createEvent = async (payload: EventPayload): Promise<CommonResponse> => {
    var response = await API.post("/events", payload)
    return response.data
}
const updateEvent = async (payload: EventPayload): Promise<CommonResponse> => {
    var response = await API.put("/events", payload)
    return response.data
}

export type CancelEventPayload = {
    eventId: number
    cancelNote: string
    eventTitle: string
    userName: string
}

const cancelEvent = async (
    payload: CancelEventPayload
): Promise<CommonResponse> => {
    var response = await API.put("/events/cancel", payload)
    return response.data
}

const updateLayoutAttachment = async ({
    eventId,
    layoutId,
    removedLayoutId,
    eventTitle,
    userName,
}: {
    eventId: number
    layoutId: string
    removedLayoutId?: string
    eventTitle: string
    userName: string
}): Promise<CommonResponse> => {
    var payload = {
        id: eventId,
        layoutId: layoutId,
        removedLayoutId: removedLayoutId,
        eventTitle: eventTitle,
        userName: userName,
    }
    var response = await API.put("/events/layoutId", payload)
    return response.data
}

//response.error = false có nghĩa là chưa có order name đó, có thể thêm
const checkOrderExists = async (
    orderName: string,
    eventId: number
): Promise<CommonResponse> => {
    //trường hợp tạo mới (eventId = 0) cũng sẽ đc check trên backend
    var payload = {
        eventId,
        orderName,
    }
    var response = await API.post<CommonResponse>(
        "/events/check-order-name",
        payload
    )

    return response.data
}

const deleteOrder = async ({
    orderName,
    eventId,
    eventTitle,
    userName,
}: {
    orderName: string
    eventId: number
    userName: string
    eventTitle: string
}): Promise<CommonResponse> => {
    //trường hợp tạo mới (eventId = 0) cũng sẽ đc check trên backend
    var payload = {
        eventId,
        orderName,
        userName,
        eventTitle,
    }
    var response = await API.post<CommonResponse>(
        "/events/delete-order",
        payload
    )

    return response.data
}

const getEventLiteByMonth = async ({
    month,
    year,
}: {
    month: number
    year: number
}) => {
    const res = await API.get<AxiosResponse<EventLite[]>>(
        `/events/by-month?month=${month}&year=${year}`
    )
    return res.data.data
}

const queryEvent = async ({
    searchTerm,
    date,
    dateCreated,
    eventTypes,
    statuses,
    customers,
    places,
    sortBy,
    sortDir,
    limit,
    offset,
}: {
    searchTerm: string
    date: string
    dateCreated: string
    eventTypes: string[]
    statuses: string[]
    customers: string[]
    places: string[]
    sortBy: string
    sortDir: string
    limit: number
    offset: number
}) => {
    var dateQuery = date ? `date=${date}` : ""
    var dateCreatedQuery = dateCreated ? `dateCreated=${dateCreated}` : ""
    var eventTypeQuery = eventTypes.map((e) => `eventTypes[]=${e}`).join("&")
    var statusQuery = statuses.map((e) => `statuses[]=${e}`).join("&")
    var customerQuery = customers.map((e) => `customers[]=${e}`).join("&")
    var placeQuery = places.map((e) => `places[]=${e}`).join("&")
    var sortByQuery = sortBy ? `orderBy=${sortBy}` : ""
    var sortDirQuery = sortDir ? `sortDirection=${sortDir}` : ""
    var limitQuery = `limit=${limit || 20}`
    var offsetQuery = offset ? `offset=${offset || 0}` : ""
    var searchTermQuery = searchTerm.trim() ? `searchTerm=${searchTerm}` : ""

    var phrases = [
        searchTermQuery,
        dateQuery,
        dateCreatedQuery,
        eventTypeQuery,
        placeQuery,
        statusQuery,
        customerQuery,
        sortByQuery,
        sortDirQuery,
        limitQuery,
        offsetQuery,
    ]
        .filter((e) => e)
        .join("&")

    // if (!phrases) {
    //     return []
    // }
    const res = await API.get<
        AxiosResponse<{ items: ScheduleEvent[]; count: number }>
    >(`/events/query?${phrases}`)
    return {
        data: res.data.data.items,
        count: res.data.data.count,
    }
}

export const getEventDetailFull = async (eventId: number) => {
    var response = await API.get<AxiosResponse<ScheduleEvent>>(
        `/events/details-full?eventId=${eventId}`
    )
    var item = response.data.data

    item.checklistNote = item.checklistNote || ""

    item.menus = (item.menus || []).map((e) => ({ ...e, key: nanoid() }))
    item.todos = (item.todos || []).map((e) => ({ ...e, key: nanoid() }))
    item.payments = (item.payments || []).map((e) => ({
        ...e,
        free: !!e.free,
        key: nanoid(),
    }))
    item.isCancel = !!item.isCancel
    return response.data.data
}

const EventService = {
    queryEvent,
    getEventsByStartDate,
    createEvent,
    updateEvent,
    saveTimelineToDB,
    saveOrderToDB,
    saveEventNoteToDB,
    saveOrderNoteToDB,
    saveChecklistToDB,
    updateLayoutAttachment,
    checkOrderExists,
    deleteOrder,
    cancelEvent,
    searchEvent,
    getEventMenus,
    getEventLiteByMonth,
}

export default EventService
