import { ChevronRightButton } from "core/styledComponents"
import ChecklistTableHeader from "modules/checklist/ChecklistTable/ChecklistTableHeader"
import ChecklistTableTop from "modules/checklist/ChecklistTable/ChecklistTableTop"
import TodoTableBody from "modules/checklist/ChecklistTable/TodoTableBody"
import DialogSelectTodo from "modules/dialogs/DialogSelectTodo"
import { useState } from "react"
import { rootStore } from "stores/rootStore"
import { todoStore } from "stores/todoStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import { ChecklistHeader } from "./ChecklistHeader/ChecklistHeader"

const ChecklistTabRight = () => {
    const { editingTodos, currentChecklist } = useSnapshot(todoStore)
    const { checklistTemplates } = useSnapshot(rootStore)
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <Wrap className="px-5 py-4 rounded-md card-shadow mt-5">
                <ChecklistHeader />
                <ChecklistTableTop />
                <div className="flex items-center">
                    <div
                        className="text-sm my-2 flex items-center  ml-auto cursor-pointer"
                        onClick={() => setIsOpen(true)}
                    >
                        <span className="text-[#BABABA]">Template:&nbsp;</span>{" "}
                        <span className="text-link font-medium hover:underline">
                            {editingTodos.filter(
                                (e) => e.name && e.checklist == currentChecklist
                            )?.[0]?.template ||
                                checklistTemplates[0]?.name ||
                                ""}
                        </span>
                        <ChevronRightButton />
                    </div>
                </div>
                <ChecklistTableHeader />
                <TodoTableBody />
            </Wrap>
            <DialogSelectTodo isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default ChecklistTabRight

const Wrap = styled.div`
    background-color: #fff;
`
