import classNames from "classnames"
import get from "get-value"
import React, { useCallback, useContext, useRef, useState } from "react"
import { NumericFormat } from "react-number-format"
import { styled } from "styled-components"
import { moveLeft, moveDown, moveRight, moveUp, moveDownAtIndex } from "./utils"
import { cellBorderColor } from "./config"
import { CellContext, ICellContext } from "./context"

interface Props<T> {
    data: T
    colKey: string
    onUpdateValue: (data: T, colKey: string, value: string) => void
    onDelete: (data: T, colKey: string) => void
    onMoveDownAtLastRow?: () => void
    onTabAtLastRowCell?: () => void
}
const NumericCell = <T extends object>({
    data,
    colKey,
    onDelete,
    onUpdateValue,
    onMoveDownAtLastRow,
    onTabAtLastRowCell,
}: React.PropsWithChildren<Props<T>>) => {
    const [showCursor, setShowCursor] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const { setData, setColKey } = useContext<ICellContext<T>>(CellContext)
    const [showExpression, setShowExpression] = useState(false)
    const [expression, setExpression] = useState("")
    const [expressionHasError, setExpressionHasError] = useState(false)

    const inputRef = useRef<HTMLInputElement>()
    const expressionInputRef = useRef<HTMLInputElement>(null)

    const onMouseDown = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    const handleChangeFormular = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExpression(e.target.value)
    }

    const handleExpInputBlur = () => {
        setShowExpression(false)
        setExpressionHasError(false)
    }

    const handleExpKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            expressionInputRef.current?.blur()
            inputRef.current?.focus()
        }
        if (e.key === "Enter" || e.key === "Tab") {
            const exp = expressionInputRef.current?.value || "0"
            try {
                //trong js eval ** sẽ thành hàm mũ nhưng ở đây ta coi là lỗi
                if (exp.includes("**")) {
                    throw Error("contains **")
                }
                let value = eval(exp)
                setExpressionHasError(false)
                if (typeof value === "number") {
                    onUpdateValue(data, colKey, `${Math.round(value)}`)
                    setInputValue("")
                    setShowCursor(false)
                } else {
                    console.log("expression value is not number")
                }
                expressionInputRef.current?.blur()
                inputRef.current?.focus()
                setTimeout(() => {
                    moveDown(inputRef.current)
                })
            } catch (error) {
                e.preventDefault()
                expressionInputRef.current?.focus()
                setExpressionHasError(true)
            }
        }
    }

    const handleFocus = () => {
        setInputValue("")
        setShowCursor(false)
        setData(data)
        setColKey(colKey)
    }

    const handleBlur = () => {
        setData(null)
        if (showCursor && !showExpression) {
            saveCellContent()
        }
    }

    const editCellContent = useCallback((content: string) => {
        setInputValue(content)
        if (inputRef.current) {
            inputRef.current.focus()
            //di chuyển caret xuống cuối cùng
            var currency = get(data, colKey).formatCurrency()
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.selectionStart = currency.length
                    inputRef.current.selectionEnd = currency.length
                    setShowCursor(true)
                    inputRef.current.select()
                }
            }, 20)
        }
    }, [])

    const saveCellContent = () => {
        const value = (inputRef.current?.value || "").replaceAll(",", "")
        onUpdateValue(data, colKey, value)
        setInputValue("")
        setShowCursor(false)
    }

    const handleDoubleClick = () => {
        if (inputRef.current && !showCursor) {
            setInputValue(`${get(data, colKey)}`)
            inputRef.current.focus()
            //di chuyển caret xuống cuối cùng
            var currency = get(data, colKey).formatCurrency()
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.selectionStart = currency.length
                    inputRef.current.selectionEnd = currency.length
                    setShowCursor(true)
                    inputRef.current.select()
                }
            }, 20)
        }
    }

    const deleteCellContent = () => {
        setInputValue("")
        onDelete(data, colKey)
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replaceAll(",", "")
        setInputValue(value)
        setShowCursor(true)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            setShowCursor(false)
            setInputValue("")
        } else if (e.key === "Delete" || e.key === "Backspace") {
            if (!showCursor) {
                setInputValue("0")
                deleteCellContent()
            }
        } else if (e.key === "Enter") {
            e.preventDefault()
            if (inputRef.current && showCursor) {
                saveCellContent()
                const canMove = moveDown(inputRef.current)

                setTimeout(() => {
                    setShowCursor(false)
                    if (!canMove) {
                        onMoveDownAtLastRow && onMoveDownAtLastRow()
                    }
                })
            } else {
                e.stopPropagation()
                editCellContent(`${get(data, colKey)}`)
            }
        } else if (e.key === "ArrowLeft") {
            if (!showCursor) {
                moveLeft(inputRef.current)
            }
        } else if (e.key === "ArrowDown") {
            if (showCursor) {
                let value = (inputRef.current?.value || "").replaceAll(",", "")
                onUpdateValue(data, colKey, value)
            }
            moveDown(inputRef.current)
        } else if (e.key === "ArrowUp") {
            if (showCursor) {
                let value = (inputRef.current?.value || "").replaceAll(",", "")
                onUpdateValue(data, colKey, value)
            }
            moveUp(inputRef.current)
        } else if (e.key === "ArrowRight") {
            if (!showCursor) {
                moveRight(inputRef.current)
            }
        } else if (e.key === "Tab") {
            e.preventDefault()
            if (inputRef.current && showCursor) {
                saveCellContent()
            }
            const canMove = moveRight(inputRef.current)
            if (!canMove) {
                if (document.activeElement) {
                    const canMoveDown = moveDownAtIndex(
                        document.activeElement,
                        1
                    )
                    if (!canMoveDown) {
                        onTabAtLastRowCell && onTabAtLastRowCell()
                    }
                }
            }
        } else {
            if (["*", "+", "-", "/"].includes(e.key)) {
                setExpression(`${inputValue}${e.key}`)
                setShowExpression(true)
                setTimeout(() => {
                    expressionInputRef.current?.focus()
                }, 10)
            }
        }
    }

    return (
        <Wrap
            className={classNames("relative justify-end items-center")}
            onMouseDown={onMouseDown}
        >
            {get(data, colKey).formatCurrency()}
            <NumericFormat
                onFocus={handleFocus}
                onBlur={handleBlur}
                onDoubleClick={handleDoubleClick}
                className={classNames({
                    "show-cursor": showCursor,
                    "pointer-events-none opacity-0": showExpression,
                })}
                value={inputValue}
                thousandSeparator=","
                getInputRef={inputRef}
                onKeyDown={handleKeyDown}
                onInput={handleInput}
            />
            {showExpression && (
                <FormularInput
                    className={expressionHasError ? "error" : ""}
                    ref={expressionInputRef}
                    value={expression}
                    onBlur={handleExpInputBlur}
                    onKeyDown={handleExpKeyDown}
                    onChange={handleChangeFormular}
                />
            )}
        </Wrap>
    )
}

export default React.memo(NumericCell) as typeof NumericCell

const Wrap = styled.div`
    outline: none;
    display: flex;
    align-items: center;
    padding: 8px;
    white-space: pre-wrap;
    min-width: 0;
    font-size: 14px;
    border-right: 1px solid ${cellBorderColor};
    &:last-child {
        padding: 4px 0;
    }
    &:focus-within {
        outline: 2px solid royalblue;
        background-color: #fff;
        z-index: 1;
    }

    input {
        text-align: right;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: transparent;
        padding: 8px;
        outline: none;
        background-color: transparent;
        resize: none;
        caret-color: transparent;
        &.show-cursor {
            background-color: white;
            color: var(--text-primary);
            caret-color: var(--text-primary);
        }
    }
`

const FormularInput = styled.input`
    background-color: white !important;
    z-index: 10;
    color: black !important;
    outline: 2px solid royalblue !important;
    caret-color: var(--text-primary) !important;
    &.error {
        outline: 2px solid red !important;
    }
`
