// icon:edit | Feathericons https://feathericons.com/ | Cole Bemis
import * as React from "react"

function IconPrint(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            viewBox="0 0 27 26"
            {...props}
        >
            <path
                d="M4.748 5.183h2.14V3.3c0-.887.459-1.325 1.332-1.325h10.34c.883 0 1.333.438 1.333 1.325v1.864h2.14V3.463c0-2.38-1.184-3.463-3.475-3.463H8.223C6.03 0 4.748 1.083 4.748 3.463v1.72zm-1.07 16.952H6.1V20.06H3.675c-.97 0-1.469-.506-1.469-1.47V7.64c0-.964.498-1.473 1.47-1.473h19.439c.961 0 1.469.51 1.469 1.473v10.952c0 .963-.508 1.469-1.47 1.469h-2.258v2.075h2.256c2.381 0 3.669-1.24 3.669-3.63V7.722c0-2.38-1.288-3.63-3.669-3.63H3.678C1.385 4.092 0 5.342 0 7.722v10.783c0 2.39 1.287 3.63 3.678 3.63zm3.688 3.596h12.049c1.66 0 2.48-.761 2.48-2.482v-4.81c0-1.709-.82-2.48-2.48-2.48H7.365c-1.6 0-2.48.771-2.48 2.48v4.81c0 1.72.82 2.483 2.48 2.483zm.493-2.022c-.56 0-.851-.28-.851-.851v-4.035c0-.562.291-.84.851-.84h11.072c.57 0 .842.278.842.84v4.035c0 .572-.272.85-.842.85H7.86zm.788-2.881c0 .541.42.971.964.971h7.572c.53 0 .951-.43.951-.971a.95.95 0 00-.95-.951H9.61a.953.953 0 00-.964.95zM7.194 10.213h1.08c.343 0 .55-.208.55-.55V8.59c0-.343-.207-.55-.55-.55h-1.08c-.334 0-.54.207-.54.55v1.073c0 .342.206.55.54.55zm3.777 0h1.073c.343 0 .548-.208.548-.55V8.59c0-.343-.205-.55-.548-.55H10.97c-.343 0-.548.207-.548.55v1.073c0 .342.205.55.548.55zm3.77 0h1.08c.333 0 .54-.208.54-.55V8.59c0-.343-.207-.55-.54-.55h-1.08c-.345 0-.55.207-.55.55v1.073c0 .342.205.55.55.55zm3.768 0h1.08c.343 0 .55-.208.55-.55V8.59c0-.343-.207-.55-.55-.55h-1.08c-.334 0-.539.207-.539.55v1.073c0 .342.206.55.539.55zm-11.315 3.88h1.08c.343 0 .55-.208.55-.55V12.46c0-.343-.207-.55-.55-.55h-1.08c-.334 0-.54.207-.54.55v1.082c0 .343.206.55.54.55zm3.777 0h1.073c.343 0 .548-.208.548-.55V12.46c0-.343-.205-.55-.548-.55H10.97c-.343 0-.548.207-.548.55v1.082c0 .343.205.55.548.55zm3.77 0h1.08c.333 0 .54-.208.54-.55V12.46c0-.343-.207-.55-.54-.55h-1.08c-.345 0-.55.207-.55.55v1.082c0 .343.205.55.55.55zm3.768 0h1.08c.343 0 .55-.208.55-.55V12.46c0-.343-.207-.55-.55-.55h-1.08c-.334 0-.539.207-.539.55v1.082c0 .343.206.55.539.55z"
            ></path>
        </svg>
    )
}

export default IconPrint
