import { AxiosResponse } from "axios"
import { Customer, CustomerPayload, generateEmptyCustomer } from "models/customer"
import { CommonResponse } from "models/response"
import { useEffect, useState } from "react"
import { rootStore } from "stores/rootStore"
import { useSnapshot } from "valtio"
import API from "./api"


export const useCustomer = (customerId: number) => {
    const { customers } = useSnapshot(rootStore)
    const [customer, setCustomer] = useState<Customer>(generateEmptyCustomer())
    useEffect(() => {
        if (customers.length && customerId) {
            var found = customers.find(e => e.id === Number(customerId))
            if (found) {
                setCustomer(found)
            }
        }

    }, [customers, customerId])

    return customer
}

const getLatestCustomers = async () => {

    var updatedTimes = rootStore.customers.map(e => e.updatedAt)
    //sắp xếp giảm dần
    updatedTimes.sort((a, b) => b - a)
    var latestTime = updatedTimes.length > 0 ? updatedTimes[0] : 0
    var response = await API.get<AxiosResponse<Customer[]>>(`/customers/latest?time=${latestTime}`)

    var deletedIds = <number[]>[]
    var updatedItems = <Customer[]>[]
    for (var item of response.data.data) {
        if (item.isDeleted) {
            deletedIds.push(item.id)
        } else {
            updatedItems.push(item)
        }
    }

    var updatedIds = updatedItems.map(e => e.id)
    var listTobeRemoved = [...deletedIds, ...updatedIds]

    //lọc bỏ item bị xóa, hoặc mới update
    rootStore.customers = rootStore.customers.filter(e => !listTobeRemoved.includes(e.id))

    //đẩy item mới update vào danh sách
    for (var item of updatedItems) {
        rootStore.customers.push(item)
    }

}
const createCustomer = async (customer: CustomerPayload): Promise<CommonResponse> => {
    var response = await API.post<CommonResponse>('/customers', customer)
    if (response.data.error == false) {
        await getLatestCustomers()
    }
    return response.data
}
const updateCustomer = async (customer: CustomerPayload): Promise<CommonResponse> => {
    var response = await API.put<CommonResponse>('/customers', customer)

    if (response.data.error == false) {
        await getLatestCustomers()
    }
    return response.data
}

const CustomerService = {
    getLatestCustomers,
    createCustomer,
    updateCustomer
}

export default CustomerService