import { ViewItem } from "models/view"
import { DateFilterValue, defaultViews } from "modules/views/viewService"
import { asyncStorage } from "services/localStorageService"
import { proxy } from "valtio"

const KEY = `EVENT_VIEWS`
const ALL_VIEWS = `${KEY}_ALL_VIEWS`

export const eventFilterStore = proxy({
    sortBy: "",
    sortDir: "",
    dateValue: DateFilterValue.ALL,
    dateCreated: DateFilterValue.ALL,
    customerValues: <string[]>[],
    statusValues: <string[]>[],
    eventTypeValues: <string[]>[],
    placeValues: <string[]>[],
    hiddenViews: [],
    views: <ViewItem[]>[],
    currentViewName: "",
    canSave: true,
    itemCount: 0,
    refreshCount: 0,
    applyView: (viewItem: ViewItem) => {
        eventFilterStore.dateValue = viewItem.date
        eventFilterStore.dateCreated = viewItem.dateCreated
        eventFilterStore.customerValues = viewItem.customers
        eventFilterStore.statusValues = viewItem.statuses
        eventFilterStore.eventTypeValues = viewItem.eventTypes
        eventFilterStore.placeValues = viewItem.places
        eventFilterStore.canSave = viewItem.isDefault ? false : true
    },
    changeCurrentView: (name: string) => {
        eventFilterStore.currentViewName = name
        var currentView = eventFilterStore.views.find((e) => e.name === name)
        if (currentView) {
            eventFilterStore.applyView(currentView)
        }
    },

    initData: async () => {
        if (!eventFilterStore.views.length) {
            var views = (await asyncStorage.getItem<ViewItem[]>(ALL_VIEWS)) || []
            if (!views.length) {
                views = defaultViews
            }

            eventFilterStore.views = views
            eventFilterStore.currentViewName = views[0].name
            eventFilterStore.applyView(views[0])
        }
    },
    createView: async (name: string) => {
        if (eventFilterStore.views.find((e) => e.name === name)) {
            return false
        }
        eventFilterStore.views.push({
            name,
            date: "",
            dateCreated: "",
            customers: [],
            eventTypes: [],
            statuses: [],
            places: [],
            deposit: "",
        })

        const views = eventFilterStore.views.map((e) => ({
            ...e,
            customers: e.customers.map((item) => item),
            eventTypes: e.eventTypes.map((item) => item),
            statuses: e.statuses.map((item) => item),
            places: e.places.map((item) => item),
        }))
        await asyncStorage.setItem(ALL_VIEWS, views)
        return true
    },
    renameCurrentView: async (newName: string) => {
        const currentViewName = eventFilterStore.currentViewName
        var index = eventFilterStore.views.findIndex(
            (e) => e.name == currentViewName
        )

        if (index != -1) {
            eventFilterStore.views[index].name = newName
            eventFilterStore.currentViewName = newName
        }

        var views = (await asyncStorage.getItem<ViewItem[]>(ALL_VIEWS)) || []
        if (!views.length) {
            views = defaultViews
        }
        var _index = views.findIndex((e) => e.name == currentViewName)
        if (_index != -1) {
            views[index].name = newName
            await asyncStorage.setItem(ALL_VIEWS, views)
        }
    },
    saveCurrentView: async () => {
        const currentViewName = eventFilterStore.currentViewName
        const otherViews = eventFilterStore.views
            .filter((item) => item.name !== currentViewName)
            .map((e) => ({
                ...e,
                customers: e.customers.map((item) => item),
                eventTypes: e.eventTypes.map((item) => item),
                statuses: e.statuses.map((item) => item),
                places: e.places.map((item) => item),
            }))
        const views = [
            ...otherViews,
            {
                name: currentViewName,
                date: eventFilterStore.dateValue,
                dateCreated: eventFilterStore.dateCreated,
                customers: eventFilterStore.customerValues.map((e) => e),
                eventTypes: eventFilterStore.eventTypeValues.map((e) => e),
                statuses: eventFilterStore.statusValues.map((e) => e),
                places: eventFilterStore.placeValues.map((e) => e),
            },
        ]

        var index = eventFilterStore.views.findIndex(
            (e) => e.name === currentViewName
        )
        if (index != -1) {
            eventFilterStore.views[index] = {
                name: currentViewName,
                date: eventFilterStore.dateValue,
                dateCreated: eventFilterStore.dateCreated,
                customers: eventFilterStore.customerValues.map((e) => e),
                eventTypes: eventFilterStore.eventTypeValues.map((e) => e),
                statuses: eventFilterStore.statusValues.map((e) => e),
                places: eventFilterStore.placeValues.map((e) => e),
                deposit: "",
            }
        }

        await asyncStorage.setItem(ALL_VIEWS, views)
    },
    resetCurrentView: async () => {
        var views = (await asyncStorage.getItem<ViewItem[]>(ALL_VIEWS)) || []
        if (!views.length) {
            views = defaultViews
        }

        const currentViewName = eventFilterStore.currentViewName
        const currentView = views.find((e) => e.name == currentViewName)

        var index = eventFilterStore.views.findIndex(
            (e) => e.name == currentViewName
        )
        if (currentView && index !== -1) {
            eventFilterStore.views[index] = currentView
            eventFilterStore.applyView(currentView)
        }
    },
    toggleViewVisibility: async (name: string, visible?: boolean) => {
        var index = eventFilterStore.views.findIndex((e) => e.name == name)

        var isHidden =
            visible !== undefined
                ? !visible
                : !eventFilterStore.views[index].isHidden
        if (index != -1) {
            eventFilterStore.views[index].isHidden = isHidden
            if (isHidden) {
                var visibleItems = eventFilterStore.views.filter(
                    (e) => !e.isHidden
                )
                if (visibleItems.length) {
                    setTimeout(() => {
                        eventFilterStore.changeCurrentView(visibleItems[0].name)
                    })
                }
            } else {
                eventFilterStore.changeCurrentView(name)
            }
        }

        var views = (await asyncStorage.getItem<ViewItem[]>(ALL_VIEWS)) || []
        if (!views.length) {
            views = defaultViews
        }
        var _index = views.findIndex((e) => e.name == name)
        if (_index != -1) {
            views[index].isHidden =
                visible !== undefined ? !visible : !views[index].isHidden
            await asyncStorage.setItem(ALL_VIEWS, views)
        }
    },
})
