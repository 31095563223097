import { useState } from "react"
import { useDateChanges, useTimelineChangeCount } from "services/eventService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import TitleChange from "./TitleChange/TitleChange"

const TitleChangeCount = () => {
    const { title, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    const changed = title != currentEvent.title

    if (currentEvent.id == 0 || !changed) return null
    return (
        <>
            <span
                onClick={() => setIsOpen(true)}
                className="text-[orangered] text-13 font-normal cursor-pointer hover:underline"
            >
                +1
            </span>
            <TitleChange isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* <EventNoteChange isOpen={isOpen} setIsOpen={setIsOpen} /> */}
        </>
    )
}

export default TitleChangeCount
