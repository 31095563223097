import classNames from "classnames"
import { Customer } from "models/customer"
import { CSSProperties, useCallback } from "react"
import Select, { OptionProps, components } from "react-select"
import { eventDetailStore } from "stores/eventDetailStore"

import { rootStore } from "stores/rootStore"
import { useSnapshot } from "valtio"
import CaretDown from "./CaretDown"
import { ClearIcon } from "./ClearIcon"
import { saveDraft } from "services/draftService"
import { recentListStore } from "modules/recent_list/recentListStore"

const CustomOption = (props: OptionProps<Customer>) => {
    const { children, ...others } = props
    const isSelected = props.isSelected
    return (
        <components.Option {...others}>
            <div>
                <div>{others.data.name}</div>
                <div
                    className={classNames("text-xs ", {
                        "text-gray-400": !isSelected,
                    })}
                >
                    {others.data.addr}
                </div>
            </div>
        </components.Option>
    )
}

const SelectCustomer = () => {
    const { customers } = useSnapshot(rootStore)
    const { customerId, currentEvent } = useSnapshot(eventDetailStore)

    const getOptionValue = useCallback((option: Customer) => {
        return option.id.toString()
    }, [])

    const getOptionLabel = useCallback((option: Customer) => {
        return option.name
    }, [])

    const handleChange = (option: Customer | null) => {
        eventDetailStore.customerId = option?.id || 0
        eventDetailStore.customerName = option?.name || ""
        saveDraft()
    }
    const customer = customers.find((e) => e.id === customerId) || null
    return (
        <>
            <Select
                classNamePrefix={"react-select"}
                value={customer}
                components={{
                    Option: CustomOption,
                    DropdownIndicator: CaretDown,
                    ClearIndicator: ClearIcon,
                }}
                options={customers}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                isMulti={false}
                isClearable
                onChange={handleChange}
                placeholder="Chọn khách hàng"
                noOptionsMessage={() => "Không có kết quả"}
            />
        </>
    )
}

export default SelectCustomer
