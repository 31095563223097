import { AppointmentItem } from "models/events/appointment"
import styled from "styled-components"

type Props = {
    appointment: AppointmentItem
    scrollTop: number
    top: number
    topPinMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    topPinDragStart: (
        e: React.DragEvent<HTMLDivElement>
    ) => void
}
const TopPin = ({ appointment, scrollTop, top, topPinMouseDown, topPinDragStart }: Props) => {
    return (
        <>
            <TopPinWrap
                onDragStart={topPinDragStart}
                onMouseDown={topPinMouseDown}
                style={{
                    transform: `translateY(${-scrollTop + 30 - 8 + top}px)`,
                    backgroundColor: `${appointment.color}3F`,
                }}
            >
                <div
                    style={{
                        backgroundColor: appointment.color,
                    }}
                />
            </TopPinWrap>
        </>
    )
}

export default TopPin

const TopPinWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    position: absolute;
    left: 70px;
    div {
        width: 10px;
        height: 10px;
        border-radius: 96px;
    }
`
