import { ReactNode, useEffect, useState } from "react";
import { TabData, tabsContext } from "./tabContext";

type Props = {
    children: ReactNode;
    onChange?: React.Dispatch<React.SetStateAction<number>>;
};
const TabsProvider = ({ children, onChange }: Props) => {
    const [activeTabs, setActiveTabs] = useState<TabData[]>([]);

    const handleChange = (index: number, name: string) => {
        setActiveTabs(s => [
            ...s.filter((e) => e.name != name),
            {
                index,
                name: name || '',
            },
        ]);
    };
    return (
        <tabsContext.Provider value={{ activeTabs, onChange: handleChange }}>
            {children}
        </tabsContext.Provider>
    );
};

export default TabsProvider;
