import classNames from "classnames"
import IconCaretDown from "common/icons/IconCaretDown"
import SimpleSelect from "core/select/SimpleSelect"
import { EventStatus } from "services/constants"
import { rootStore } from "stores/rootStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"

type Props = {
    id: number
    name: string
    onChange: (s: number) => void
}

//nằm ở view chi tiết tiệc
const SelectTemplate = ({ id, name, onChange }: Props) => {
    const { todos, todoSections, checklistTemplates } = useSnapshot(rootStore)
    return (
        <>
            <SimpleSelect
                value={{
                    label: name,
                    value: id,
                }}
                searchable
                placeholder="Chọn template"
                options={checklistTemplates.map((e) => ({
                    label: e.name,
                    value: e.id,
                }))}
                onChange={(e) => onChange(Number(e?.value) || 0)}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value.toString()}
                // renderNoOption={renderNoOption}
            >
                <div
                    className={classNames(
                        "flex items-center  font-medium text-sm text-link"
                    )}
                >
                    {name}
                    <IconCaretDown
                        className={classNames("text-[8px] text-[#868686] ml-2")}
                    />
                    {/* {(active || isMenuOpen) && (
                        <IconCaretDown
                            className={classNames(
                                "text-[8px] text-[#868686] ml-2"
                            )}
                        />
                    )} */}
                </div>
            </SimpleSelect>
        </>
    )
}

export default SelectTemplate

const Wrap = styled.div``

const Label = styled.div`
    transition: top 0.25s ease-out;
`

const EmptyContainer = styled.div`
    transition: height 0.2s ease-out;
    border-bottom: 1px solid transparent;
    &.is-active {
        border-bottom: 1px solid var(--link);
    }
`
