import { ReactNode, useContext } from "react"
import { tabsContext } from "./tabContext"
import classNames from "classnames"
import type { ElementType } from "react"

const TabPanel = ({
    index,
    group,
    className,
    style,
    destroyOnInactive,
    children,
    ...others
}: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
> & {
    index: number
    group?: string
    destroyOnInactive?: boolean
}) => {
    const { activeTabs } = useContext(tabsContext)
    const isActive = !!activeTabs.find(
        (e) => e.index == index && e.name == group
    )

    const displayNone = !isActive ? { display: "none" } : {}
    if (destroyOnInactive && !isActive) return null
    return (
        <div
            className={classNames(className, { active: isActive })}
            style={{
                ...style,
                ...displayNone,
            }}
            {...others}
        >
            {children}
        </div>
    )
}

export default TabPanel
