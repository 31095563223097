import { MenuSize } from "models/menu_size"
import API from "./api"
import { CommonResponse } from "models/response"

const getMenuSizes = async (menuId: number): Promise<MenuSize[]> => {
    var res = await API.get(`/menu-sizes?menuId=${menuId}`)
    return res.data.data
}

const createMenuSize = async (menuSize: MenuSize): Promise<CommonResponse> => {
    var response = await API.post<CommonResponse>('/menu-sizes', {
        menuId: menuSize.menuId,
        name: menuSize.name,
        amount: menuSize.amount,
        price: menuSize.price
    })
    return response.data
}
const updateMenuSize = async (menuSize: MenuSize): Promise<CommonResponse> => {
    var response = await API.put<CommonResponse>('/menu-sizes', {
        id: menuSize.id,
        name: menuSize.name,
        amount: menuSize.amount,
        price: menuSize.price
    })
    return response.data
}


const MenuSizeService = {
    getMenuSizes,
    createMenuSize,
    updateMenuSize
}

export default MenuSizeService