import { initializeApp } from "firebase/app"
import "firebase/database"
import {
    getMessaging,
    getToken,
    onMessage,
    isSupported,
} from "firebase/messaging"
import "firebase/auth"

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDlsp4lIcKqxCNKbDHyVDUU5eGWWGWMMLk",
    authDomain: "chat-app-71f35.firebaseapp.com",
    projectId: "chat-app-71f35",
    storageBucket: "chat-app-71f35.appspot.com",
    messagingSenderId: "1088213852146",
    appId: "1:1088213852146:web:8e844de4ef7b146aa6156b",
}

export const VAPID_KEY =
    "BMYMVTAMkfXR5tows7AOfCA0YgP93rREtgrcPidBdfNJgQ48mEokmLK9xfcZYqK9eIKW-IfwbOmJ6RlnE434MgU"

export const initFirebase = () => {
    console.log("firebase initialized!")
    initializeApp(firebaseConfig)
}
export const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app);

export const requestPermission = () => {

    console.log("Requesting User Permission......");
    Notification.requestPermission().then((permission) => {

        if (permission === "granted") {

            console.log("Notification User Permission Granted.");
            return getToken(messaging, { vapidKey: `BMYMVTAMkfXR5tows7AOfCA0YgP93rREtgrcPidBdfNJgQ48mEokmLK9xfcZYqK9eIKW-IfwbOmJ6RlnE434MgU` })
                .then((currentToken) => {

                    if (currentToken) {

                        console.log('Client Token: ', currentToken);
                    } else {

                        console.log('Failed to generate the app registration token.');
                    }
                })
                .catch((err) => {

                    console.log('An error occurred when requesting to receive the token.', err);
                });
        } else {

            console.log("User Permission Denied.");
        }
    });

}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log(messaging)
            resolve(payload);
        });
    });

requestPermission();
