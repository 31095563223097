import { useEffect, useState } from "react"
import { Input } from "core/styledComponents"
import { useSnapshot } from "valtio"
import { Button } from "core/button/Button"
import { todoStore } from "stores/todoStore"
import Dialog from "core/dialog/Dialog"
import CloseButton from "core/button/CloseButton"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DialogAddChecklist = ({ isOpen, setIsOpen }: Props) => {
    const { checklistNames } = useSnapshot(todoStore)
    const [name, setName] = useState("")

    useEffect(() => {
        if (!isOpen) {
            setName("")
        }
    }, [isOpen])

    const handleAdd = async () => {
        if (!checklistNames.includes(name)) {
            todoStore.checklistNames.push(name)
            todoStore.currentChecklist = name
        }
        setIsOpen(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleAdd()
        }
    }

    return (
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[25vh] bg-white">
                <div className=" pt-6 pb-6 px-14 flex-col flex items-center relative">
                    <CloseButton
                        className="text-[10px] absolute top-0 right-0 mt-4 mr-4 text-gray-500"
                        onClick={() => setIsOpen(false)}
                    />
                    <div className="text-base font-semibold text-primary mb-5 text-center">
                        Thêm check list
                    </div>
                    <Input
                        className="text-md"
                        placeholder="Tên check list"
                        value={name}
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        onClick={handleAdd}
                        className="mt-4  w-full "
                        disabled={!name}
                    >
                        Xong
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default DialogAddChecklist
