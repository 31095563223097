import { proxy } from 'valtio';

export const dialogStore = proxy<{
    isOpen: any,
    onClose: any,
    showEventPanel: boolean
    showSavePanel: boolean
}>({
    showEventPanel: false,
    showSavePanel: false,
    isOpen: {},
    onClose: () => {}
})

export const openDialog = (name: string) => {
    dialogStore.isOpen[name] = true
}
export const closeDialog = (name: string) => {
    dialogStore.isOpen[name] = false
}


