import { createContext } from "react";

export type TabData = {
    index: number
    name: string
}
export interface TabContext {
    activeTabs: TabData[];
    onChange: (index: number, name: string) => void
}

export const tabsContext = createContext<TabContext>({ activeTabs:[], onChange: (index: number, name: string) => { } });

export const tabHeadersContext = createContext({ name: ''});