import { TodoChangeItem } from "models/events/TodoChangeItem"
import EventTodo from "models/events/eventTodo"
import { equalIgnoreCase } from "services/utils"

export const checkTodosChanged = (
    editingTodos: EventTodo[],
    defaultTodos: EventTodo[]
) => {
    defaultTodos = defaultTodos.filter((e) => e.name)
    editingTodos = editingTodos.filter((e) => e.name)
    if (editingTodos.length != defaultTodos.length) {
        return true
    }
    for (var item of editingTodos) {
        var found = defaultTodos
            .filter((e) => e.name)
            .find(
                (e) =>
                    e.detail === item.detail &&
                    equalIgnoreCase(e.name, item.name) &&
                    e.section == item.section &&
                    equalIgnoreCase(e.template, item.template) &&
                    e.checklist === item.checklist
            )
        if (!found) {
            return true
        }
    }
    return false
}

export const calculateChecklistNameChanges = (
    oldNames: string[],
    newNames: string[]
) => {
    let addedItems: string[] = []
    let removedItems: string[] = []

    for (let item of oldNames) {
        let found = newNames.includes(item)
        if (!found) {
            removedItems.push(item)
        }
    }

    for (let item of newNames) {
        if (!oldNames.includes(item)) {
            addedItems.push(item)
        }
    }
    return {
        addedItems,
        removedItems,
        changeCount: addedItems.length + removedItems.length,
        changed: addedItems.length + removedItems.length > 0,
    }
}

export const calculateTodoChanges = (
    oldItems: readonly EventTodo[],
    newItems: readonly EventTodo[]
) => {
    var checklists: {
        [key: string]: {
            updatedItems: string[]
            addedItems: string[]
            removedItems: string[]
        }
    } = {}

    const _oldItems = oldItems.filter((e) => e.name)
    const _newItems = newItems.filter((e) => e.name)

    //tất cả order đều đc đẩy lên
    let changed = false
    for (var newItem of _newItems) {
        let checklistName = newItem.checklist
        if (!checklists[checklistName]) {
            checklists[checklistName] = {
                updatedItems: [],
                addedItems: [],
                removedItems: [],
            }
        }
        let found = oldItems.find(
            (m) =>
                equalIgnoreCase(m.name, newItem.name) &&
                m.checklist == newItem.checklist &&
                equalIgnoreCase(m.template, newItem.template) &&
                equalIgnoreCase(m.section, newItem.section)
        )
        if (found) {
            var isSame = found.detail == newItem.detail
            if (!isSame) {
                changed = true
                checklists[checklistName].updatedItems.push(newItem.name)
            }
        } else {
            changed = true
            checklists[checklistName].addedItems.push(newItem.name)
        }
    }

    //tìm những món bị bỏ
    for (var oldItem of _oldItems) {
        let checklistName = oldItem.checklist as string
        if (!checklists[checklistName]) {
            checklists[checklistName] = {
                updatedItems: [],
                addedItems: [],
                removedItems: [],
            }
        }
        let found = newItems.find(
            (m) =>
                equalIgnoreCase(m.name, oldItem.name) &&
                m.checklist == oldItem.checklist &&
                equalIgnoreCase(m.template, oldItem.template) &&
                equalIgnoreCase(m.section, oldItem.section)
        )
        if (!found) {
            changed = true
            checklists[checklistName].removedItems.push(oldItem.name)
        }
    }
    const changes: TodoChangeItem[] = []
    let checklistNamesInCurrentItems = [
        ...new Set(newItems.map((e) => e.checklist)),
    ]

    let changeCount = 0
    for (let checklistName in checklists) {
        //xử lý xóa những checklist "bị xóa" bằng cách kiểm tra checklist đó có mặt trong list items hiện tại ko
        if (checklistNamesInCurrentItems.includes(checklistName)) {
            const items = checklists[checklistName]
            changes.push({
                checklistName,
                addedItems: items.addedItems,
                removedItems: items.removedItems,
                updatedItems: items.updatedItems,
            })
            changeCount +=
                items.addedItems.length +
                items.removedItems.length +
                items.updatedItems.length
        }
    }

    const oldNames = oldItems.map((e) => e.checklist)
    const newNames = newItems.map((e) => e.checklist)
    const checklistNameChanges = calculateChecklistNameChanges(
        oldNames,
        newNames
    )

    return {
        changed,
        changes,
        changeCount,
        checklistNameChanges,
    }
}
