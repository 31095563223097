import React, { Dispatch, SetStateAction } from "react"

export interface ICellContext<T> {
    data: T | null
    setData: Dispatch<SetStateAction<T | null>>
    colKey: string
    setColKey: Dispatch<SetStateAction<string>>
}

export const CellContext = React.createContext<ICellContext<any>>({
    data: null,
    setData: () => {},
    colKey: "",
    setColKey: () => {},
})
