import classNames from "classnames"
import { ChecklistChangeCount } from "./ChecklistChangeCount/ChecklistChangeCount"

export const ChecklistHeader = ({ className }: { className?: string }) => {
    return (
        <div className={classNames("text-base flex items-center font-bold")}>
            Check list{" "}
            <ChecklistChangeCount  />
        </div>
    )
}
