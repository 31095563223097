import API from "./api"
import { PlaceItem } from "models/place"
import { useEffect, useState } from "react"
import store from "store2"

const KEY = `PLACE_ITEMS`
const ALL_PLACES = `${KEY}_ALL`

const getAll = async (): Promise<PlaceItem[]> => {
    var res = await API.get(`/places`)
    return res.data.data
}

export const usePlaces = () => {
    const [places, setPlaces] = useState<PlaceItem[]>(
        store.get(ALL_PLACES) || []
    )
    useEffect(() => {
        ;(async () => {
            var _places = await getAll()
            setPlaces(_places)
            store.set(ALL_PLACES, _places)
        })()
    }, [])
    return places
}

// const createMenuSize = async (menuSize: MenuSize): Promise<CommonResponse> => {
//     var response = await api.post<CommonResponse>('/menu-sizes', {
//         menuId: menuSize.menuId,
//         name: menuSize.name,
//         amount: menuSize.amount,
//         price: menuSize.price
//     })
//     return response.data
// }
// const updateMenuSize = async (menuSize: MenuSize): Promise<CommonResponse> => {
//     var response = await api.put<CommonResponse>('/menu-sizes', {
//         id: menuSize.id,
//         name: menuSize.name,
//         amount: menuSize.amount,
//         price: menuSize.price
//     })
//     return response.data
// }

const PlaceService = {
    getAll,
    usePlaces,
}

export default PlaceService
