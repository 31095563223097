import { useCallback } from "react"
import Select from "react-select"
import { eventDetailStore } from "stores/eventDetailStore"

import { EventStatus } from "services/constants"
import { saveDraft } from "services/draftService"
import { useSnapshot } from "valtio"
import CaretDown from "./CaretDown"
import { recentListStore } from "modules/recent_list/recentListStore"

type Option = {
    label: string
    value: number
}

const options: Option[] = [
    {
        label: "Tham khảo",
        value: EventStatus.UNCONFIRM,
    },
    {
        label: "Đã cọc, chưa chốt",
        value: EventStatus.INPROGRESS,
    },
    {
        label: "Đã chốt",
        value: EventStatus.CONFIRMED,
    },
]

const SelectEventStatus2 = () => {
    const { status, currentEvent } = useSnapshot(eventDetailStore)

    const getOptionValue = useCallback((option: Option) => {
        return option.value.toString()
    }, [])

    const handleChange = (option: Option | null) => {
        eventDetailStore.status = option?.value || 0
        // saveStatusDraft(option?.value || 0, currentEvent.id)
        saveDraft()
    }
    const value = options.find((e) => e.value === status) || null
    return (
        <>
            <Select
                classNamePrefix={"react-select"}
                value={value}
                components={{
                    DropdownIndicator: CaretDown,
                }}
                options={options}
                getOptionValue={getOptionValue}
                isMulti={false}
                onChange={handleChange}
                placeholder=""
                noOptionsMessage={() => "Không có kết quả"}
            />
        </>
    )
}

export default SelectEventStatus2
