import classNames from "classnames"
import IconCaretDown from "common/icons/IconCaretDown"
import SimpleSelect from "core/select/SimpleSelect"
import CustomerChange from "modules/EventDetail/GeneralTab/CustomerChangeCount/CustomerChange/CustomerChange"
import { getStatusText } from "modules/event_activity/helper"
import { useState } from "react"
import { EventStatus } from "services/constants"
import { styled } from "styled-components"

type Props = {
    status: number
    onChange: (s: number) => void
}

const options = [
    {
        label: "Tham khảo",
        value: EventStatus.UNCONFIRM,
    },
    {
        label: "Đã cọc, chưa chốt",
        value: EventStatus.INPROGRESS,
    },
    {
        label: "Đã chốt",
        value: EventStatus.CONFIRMED,
    },
]

//nằm ở view chi tiết tiệc
const SelectEventStatus2 = ({ status, onChange }: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isOpenChange, setIsOpenChange] = useState(false)
    const [active, setActive] = useState(true)
    // const renderNoOption = (s: string) => (
    //     <div className="text-white bg-link text-13 px-4 cursor-pointer py-2">
    //         Tạo khách hàng "{s}"
    //     </div>
    // )
    // const renderOption = (s: {
    //     label: string
    //     value: number
    //     addr: string
    // }) => (
    //     <div className="text-white bg-link text-13 px-4 cursor-pointer py-2"></div>
    // )
    const handleMenuOpen = (isOpen: boolean) => {
        if (isMenuOpen && !isOpen) {
            setActive(false)
        }
        setIsMenuOpen(isOpen)
    }
    const handleMousEnter = () => {
        if (!isMenuOpen) {
            setActive(true)
        }
    }

    const handleMouseLeave = () => {
        if (!isMenuOpen) {
            setActive(false)
        }
    }
    return (
        <>
            <Wrap
                className={classNames("relative mt-4 w-full")}
                onMouseEnter={handleMousEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Label
                    className={classNames("text-13 text-secondary label mb-1")}
                >
                    Trạng thái
                </Label>
                <SimpleSelect
                    onOpenChange={handleMenuOpen}
                    value={{
                        label: getStatusText(status),
                        value: status,
                    }}
                    searchable
                    placeholder="Chọn trạng thái"
                    options={options}
                    onChange={(e) => onChange(Number(e?.value) || 0)}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value.toString()}
                    // renderNoOption={renderNoOption}
                >
                    <div
                        className={classNames(
                            "flex items-center  font-medium text-sm text-link",
                            {}
                        )}
                    >
                        {getStatusText(status)}
                        {(active || isMenuOpen) && (
                            <IconCaretDown
                                className={classNames(
                                    "text-[8px] text-[#868686] ml-2"
                                )}
                            />
                        )}
                    </div>
                </SimpleSelect>
                {/* <CustomerChangeCount2
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsOpenChange(true)
                    }}
                    onMouseEnter={(e) => {
                        setActive(false)
                    }}
                /> */}
            </Wrap>
        </>
    )
}

export default SelectEventStatus2

const Wrap = styled.div``

const Label = styled.div`
    transition: top 0.25s ease-out;
`

const EmptyContainer = styled.div`
    transition: height 0.2s ease-out;
    border-bottom: 1px solid transparent;
    &.is-active {
        border-bottom: 1px solid var(--link);
    }
`
