import { autoPlacement, offset, useFloating } from "@floating-ui/react"
import classNames from "classnames"
import { useClickOutside } from "core/hooks"
import { ReactNode, useEffect, useRef, useState } from "react"
import { stripAccents } from "services/utils"
import { styled } from "styled-components"

const MENU_ITEM_HEIGHT = 40

export interface Props<T> {
    value?: T
    children: ReactNode
    options: T[]
    getOptionLabel: (option: T) => string
    getOptionValue: (option: T) => string
    onChange: (value?: T) => void
    searchable?: boolean
    placeholder?: string
    renderOption?: (option: T) => ReactNode
    renderNoOption?: (s: string) => ReactNode
    onOpenChange?: (isOpen: boolean) => void
}

const SimpleSelect = <T extends object>({
    options,
    onOpenChange,
    placeholder,
    renderOption,
    renderNoOption,
    getOptionLabel,
    getOptionValue,
    onChange,
    value,
    searchable,
    children,
}: React.PropsWithChildren<Props<T>>) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const menuRef = useRef<HTMLDivElement>(null)
    const [searchText, setSearchText] = useState("")
    const [index, setIndex] = useState(0)
    const [readyToShow, setReadyToShow] = useState(false)

    const { refs, floatingStyles, update } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "bottom-end",
                    "top-start",
                    "top-end",
                ],
            }),
            offset(10),
        ],
    })

    const getCurrentOptions = (text: string) => {
        return options.filter(
            (e) =>
                stripAccents(getOptionLabel(e))
                    .toLowerCase()
                    .startsWith(stripAccents(text.trim()).toLowerCase()) ||
                stripAccents(getOptionLabel(e).toLowerCase()).includes(
                    ` ${stripAccents(text.trim().toLowerCase())}`
                )
        )
    }
    const _options = searchable ? getCurrentOptions(searchText) : options

    const handleContainerClick = () => {
        setShowDropdown((s) => !s)
    }

    const handleClickDropdown = () => {}

    const handleContainerKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault()
            setShowDropdown(true)
        }

        if (e.key == "ArrowDown") {
            e.preventDefault()
            setIndex((s) => s + 1)
        }
        if (e.key == "ArrowUp") {
            e.preventDefault()

            setIndex((s) => s - 1)
        }

        if (e.key === "Enter") {
            e.preventDefault()
            // handleSelectOption(getOptionValue(options[index]))
        }
    }

    const handleSearchInputBlur = () => {
        setTimeout(() => {
            setShowDropdown(false)
        }, 100)
    }
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key == "Escape") {
            setShowDropdown(false)
        }
        if (e.key == "ArrowDown") {
            e.preventDefault()
            var newIndex = index + 1
            if (newIndex > _options.length - 1) {
                newIndex = 0
            }
            setIndex(newIndex)
            if (menuRef.current) {
                if (newIndex == 0) {
                    menuRef.current.scrollTop = 0
                } else {
                    var child = menuRef.current.childNodes.item(
                        newIndex
                    ) as HTMLDivElement
                    var maxScrollTop = child.offsetTop - 240

                    if (menuRef.current.scrollTop < maxScrollTop) {
                        menuRef.current.scrollTop = maxScrollTop
                    }
                }
                setReadyToShow(true)
            }
        }
        if (e.key == "ArrowUp") {
            e.preventDefault()
            var newIndex = index - 1
            if (newIndex < 0) {
                newIndex = _options.length - 1
            }
            setIndex(newIndex)
            if (menuRef.current) {
                if (newIndex == _options.length - 1) {
                    var child = menuRef.current.childNodes.item(
                        newIndex
                    ) as HTMLDivElement
                    menuRef.current.scrollTop = child.offsetTop - 240
                } else {
                    var maxScrollTop = MENU_ITEM_HEIGHT * newIndex
                    if (menuRef.current.scrollTop > maxScrollTop) {
                        menuRef.current.scrollTop = maxScrollTop
                    }
                }
                setReadyToShow(true)
            }
        }

        if (e.key === "Enter") {
            e.preventDefault()
            handleSelectOption(_options[index])
        }
    }

    useEffect(() => {
        const _options = getCurrentOptions(searchText)
        if (showDropdown && !searchText) {
            let _index = value
                ? _options.findIndex(
                      (e) => getOptionValue(e) == getOptionValue(value)
                  )
                : 0
            if (_index == -1) {
                _index = 0
            }
            setIndex(_index)
            setTimeout(() => {
                if (menuRef.current) {
                    var child = menuRef.current.childNodes.item(
                        _index
                    ) as HTMLDivElement
                    menuRef.current.scrollTop = child.offsetTop - 240
                    setReadyToShow(true)
                }
            })
        } else {
            setIndex(0)
            setTimeout(() => {
                if (menuRef.current) {
                    menuRef.current.scrollTop = 0
                    setReadyToShow(true)
                }
            })
        }
        if (!showDropdown) {
            setReadyToShow(false)
        }

        if (!showDropdown) {
            setSearchText("")
            setIndex(0)
        }
        onOpenChange && onOpenChange(showDropdown)
    }, [showDropdown, value, searchText])

    const handleSelectOption = (e: T) => {
        // setValue(e)
        // var domEl = refs.domReference.current as HTMLDivElement
        // console.log(domEl)
        // domEl.focus()
        onChange && onChange(e)
        setShowDropdown(false)
    }
    const handleClickOutside = () => {
        setShowDropdown(false)
        setIndex(0)
        setSearchText("")
        setShowDropdown(false)
    }
    useClickOutside(handleClickOutside, refs.domReference)

    const content = (
        <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="shadow-menu bg-white rounded-lg overflow-hidden z-[100]"
        >
            {searchable && (
                <div className="flex items-center">
                    <InputSearch
                        onKeyDown={handleKeyDown}
                        autoFocus
                        onMouseDown={(e) => e.stopPropagation()}
                        onBlur={handleSearchInputBlur}
                        className="outline-none flex-1 px-4"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={placeholder}
                    />
                </div>
            )}

            <DropdownMenu
                className={classNames(" py-2 overflow-y-auto", {
                    "opacity-0": !readyToShow,
                })}
                ref={menuRef}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={handleClickDropdown}
            >
                {_options.map((e, _index) => (
                    <div
                        className={classNames("menu-item", {
                            selected: _index == index,
                        })}
                        onMouseDown={() => handleSelectOption(e)}
                        key={getOptionValue(e)}
                    >
                        {renderOption ? renderOption(e) : getOptionLabel(e)}
                    </div>
                ))}
                {_options.length == 0 &&
                    (renderNoOption ? (
                        renderNoOption(searchText)
                    ) : (
                        <div
                            onMouseDown={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                            }}
                            onClick={(e) => e.stopPropagation()}
                            className="menu-item text-gray-400 cursor-default bg-white"
                        >
                            Không tìm thấy kết quả
                        </div>
                    ))}
            </DropdownMenu>
        </div>
    )
    return (
        <>
            <Wrap
                tabIndex={0}
                ref={refs.setReference}
                onBlur={() => {
                    if (!searchable) {
                        setTimeout(() => setShowDropdown(false), 0)
                    }
                }}
                className={classNames(
                    "relative inline-flex w-full justify-start select-none cursor-pointer"
                )}
                onClick={handleContainerClick}
                onKeyDown={handleContainerKeyDown}
            >
                {children}
            </Wrap>
            {showDropdown && <>{content}</>}
        </>
    )
}

export default SimpleSelect

const Wrap = styled.div`
    outline: none;
`

const InputSearch = styled.input`
    font-size: 14px;
    border-bottom: 1px solid #ececec;
    width: 100%;
    padding: 8px 0;
`

const DropdownMenu = styled.div`
    background-color: #fff;
    max-height: 250px;

    min-width: 240px;
    .menu-item {
        padding: 0 16px;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        display: flex;
        align-items: center;
        height: ${MENU_ITEM_HEIGHT}px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.selected {
            background-color: var(--link);
            color: #fff;
        }
    }
`
