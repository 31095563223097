import * as Popover from "@radix-ui/react-popover"
import classNames from "classnames"
import IconCopy from "common/icons/IconCopy"
import IconDotsVertical from "common/icons/IconDotsVertical"
import IconEdit from "common/icons/IconEdit"
import IconPlus from "common/icons/IconPlus"
import IconRemoteCopy from "common/icons/IconRemoteCopy"
import IconTrash from "common/icons/IconTrash"
import { DropdownMenu } from "core/styledComponents"
import EventMenu from "models/events/eventMenu"
import DialogAddOrder from "modules/EventDetail/OrderTab/order_more_button/dialog_add_order/DialogAddOrder"
import DialogRenameOrder from "modules/EventDetail/OrderTab/order_more_button/dialog_rename_order/DialogRenameOrder"
import { useState } from "react"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import DialogSearchEvent from "./dialog_search_event/DialogSearchEvent"

const OrderMoreButton = () => {
    const [open, setOpen] = useState(false)
    const [isOpenRename, setIsOpenRename] = useState(false)
    const [isOpenSearchEvent, setIsOpenSearchEvent] = useState(false)
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const { currentOrderName, orderNames } = useSnapshot(eventMenuStore)

    const handleDelete = async () => {
        if (orderNames.length > 1) {
            var index = orderNames.indexOf(currentOrderName)
            eventMenuStore.deleteOrder(index)
            eventDetailStore.deleteOrder(index)
            saveDraft()
        }
    }

    const handleDuplicate = () => {
        eventMenuStore.duplicateOrder()
        saveDraft()
        setOpen(false)
    }

    const handleCopyOrder = () => {
        setIsOpenSearchEvent(true)
        setOpen(false)
    }

    const onSelectOrder = (orderName: string, eventMenus: EventMenu[]) => {
        setIsOpenSearchEvent(false)
        eventMenuStore.addOrderWithMenus(orderName, eventMenus)
    }

    return (
        <>
            <Popover.Root open={open} onOpenChange={setOpen}>
                <Popover.Trigger className="" asChild>
                    <Div
                        className={classNames(
                            "flex items-center justify-center",
                            { active: open }
                        )}
                    >
                        <IconDotsVertical width={20} height={20} />
                    </Div>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        align="end"
                        className="card-shadow bg-white p-1 outline-none rounded-lg z-30"
                        sideOffset={5}
                    >
                        <DropdownMenu onClick={handleDuplicate}>
                            Nhân bản
                            <IconCopy className="ml-auto text-xl" />
                        </DropdownMenu>
                        <DropdownMenu onClick={handleCopyOrder}>
                            Sao chép từ sự kiện
                            <IconRemoteCopy className="ml-auto text-xl" />
                        </DropdownMenu>
                        <DropdownMenu onClick={() => setIsOpenAdd(true)}>
                            Thêm thực đơn
                            <IconPlus className="ml-auto text-2xl" />
                        </DropdownMenu>
                        <DropdownMenu onClick={() => setIsOpenRename(true)}>
                            Đổi tên thực đơn
                            <IconEdit
                                width={14}
                                height={14}
                                className="ml-auto mr-1"
                            />
                        </DropdownMenu>
                        {orderNames.length > 1 && (
                            <DropdownMenu
                                className="text-red-500"
                                onClick={handleDelete}
                                disabled={orderNames.length < 1}
                            >
                                Xóa thực đơn
                                <IconTrash
                                    className="ml-auto mr-1"
                                    width={16}
                                    height={16}
                                />
                            </DropdownMenu>
                        )}
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
            <DialogAddOrder isOpen={isOpenAdd} setIsOpen={setIsOpenAdd} />
            <DialogRenameOrder
                isOpen={isOpenRename}
                setIsOpen={setIsOpenRename}
            />
            <DialogSearchEvent
                isOpen={isOpenSearchEvent}
                setIsOpen={setIsOpenSearchEvent}
                onDone={onSelectOrder}
            />
        </>
    )
}

export default OrderMoreButton

const Div = styled.div`
    width: 38px;
    height: 38px;
    &:hover,
    &.active {
        color: var(--text-link);
        background-color: #fafafa;
    }
`
