import React, { useRef, useEffect } from "react"

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useClickOutside(
    onClickOutside: () => void,
    ref: React.RefObject<Element>,
    ref2?: React.RefObject<Element>
) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            const condition1 =
                ref && ref.current && ref.current.contains(event.target as Node)
            const condition2 =
                ref2 &&
                ref2.current &&
                ref2.current.contains(event.target as Node)
            if (!condition1 && !condition2) {
                onClickOutside()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, ref2])
}
