import { useCallback, useEffect, useRef, useState } from "react"
import { Input } from "core/styledComponents"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import CloseButton from "core/button/CloseButton"
import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import styled from "styled-components"
import { useClickOutside } from "core/hooks"
import EventService from "services/eventService"
import { EventItem } from "models/events/event"
import IconClose from "common/icons/IconClose"
import EventMenu from "models/events/eventMenu"
import Checkbox from "core/Checkbox"
import { parseOrderName } from "services/orderUtils"
import DialogTitle from "core/dialog/DialogTitle"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    menus: EventMenu[]
    // onDone: (orderName: string, eventMenus: EventMenu[]) => void
}

const MenuListPopup = ({ isOpen, setIsOpen, menus }: Props) => {
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px]  rounded-lg  w-[600px] mt-[100px] pb-3 text-sm bg-white tex">
                    <DialogTitle>Danh sách món</DialogTitle>
                    <Table>
                        <thead>
                            <tr className="font-medium">
                                <td>#</td>
                                <td>Tên món</td>
                                <td>Định lượng</td>
                                <td>Đơn giá</td>
                            </tr>
                        </thead>
                        <tbody>
                            {menus.map((e, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td className="name">{e.menu}</td>
                                    <td>{e.amount}</td>
                                    <td>{e.price.formatCurrency()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <hr />
                    <div className="flex items-center justify-center pt-3">
                        <Button
                            outline
                            onClick={() => setIsOpen(false)}
                            className="w-24"
                        >
                            Đóng
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default MenuListPopup

const Table = styled.table`
    margin: 10px 20px;
    margin-top: 16px;
    width: calc(100% - 40px);
    td {
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        padding: 8px 16px;
    }
    td.name {
        min-width: 200px;
    }
    tbody td {
        font-size: 14px;
    }
    thead td {
        text-align: center;
        background-color: #f3f3f3;
    }

    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
`
