import classNames from "classnames"
import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { todoStore } from "stores/todoStore"
import {
    calculateChecklistNameChanges,
    calculateTodoChanges,
} from "utils/TodoUtils"
import { useSnapshot } from "valtio"
import PaymentChanges from "./PaymentChanges"
import { paymentStore } from "stores/paymentStore"
import {
    usePaymentChangeCount,
    usePaymentNameChangeCount,
} from "services/paymentService"

export const PaymentChangeCount = ({ className }: { className?: string }) => {
    const [isOpen, setIsOpen] = useState(false)

    const paymentChangeCount = usePaymentChangeCount()
    const paymentNameChangeCount = usePaymentNameChangeCount()
    const changeCount = paymentChangeCount + paymentNameChangeCount
    return (
        <>
            {changeCount > 0 && (
                <span
                    onClick={() => setIsOpen(true)}
                    className={classNames(
                        "text-[orangered] font-normal text-13 ml-3 hover:underline cursor-pointer",
                        className
                    )}
                >
                    +{changeCount}
                </span>
            )}
            {changeCount > 0 && (
                <PaymentChanges isOpen={isOpen} setIsOpen={setIsOpen} />
            )}
        </>
    )
}
