import IconChevronLeft from "common/icons/IconChevronLeft"
import DatePicker from "core/datepicker/DatePicker"
import { useCallback } from "react"
import { useNavigate } from "react-router"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import EventDetailActions from "../EventDetailActions"
import EventDetailLeftSkeleton from "./EventDetailLeftSkeleton/EventDetailLeftSkeleton"
import EventTitle from "./EventTitle/EventTitle"
import SelectCustomer from "./SelectCustomer/SelectCustomer"
import SelectDeposit from "./SelectDeposit/SelectDeposit"
import SelectGuestEstimate from "./SelectGuestEstimate/SelectGuestEstimate"
import SelectTableReserve from "./SelectTableReserve/SelectTableReserve"
import SelectEventStatus2 from "./SelectEventStatus/SelectEventStatus2"
import StatusChangeCount from "../GeneralTab/StatusChangeCount/StatusChangeCount"

const EventDetailLeft = () => {
    const {
        currentEvent,
        title,
        tableReserve,
        guestFrom,
        guestTo,
        tableSetup,
        deposit,
        customerId,
        customerName,
        status,
        selectedDate,
        isFetchedEvent,
        cancelNote,
    } = useSnapshot(eventDetailStore)

    const onSelectDate = useCallback((d: Date) => {
        eventDetailStore.selectedDate = new Date(d.getTime())
        saveDraft()
    }, [])
    const handleChangeStatus = (newStatus: number) => {
        eventDetailStore.status = newStatus
    }
    const navigate = useNavigate()
    const handleSelectGuestEstimate = (guestFrom: number, guestTo: number) => {
        eventDetailStore.guestFrom = guestFrom
        eventDetailStore.guestTo = guestTo
        // saveGuestDraft(guestFrom, guestTo, currentEvent.id)
        saveDraft()
    }

    const handleSelectCustomer = (newCusId: number, name: string) => {
        eventDetailStore.customerId = newCusId
        eventDetailStore.customerName = name
        saveDraft()
    }

    const handleDoneDeposit = (_deposit: number) => {
        eventDetailStore.deposit = _deposit
        // saveDepositDraft(_deposit, currentEvent.id)
        saveDraft()
    }

    // const handleSelectTable = (tableSetup: string) => {
    //     eventDetailStore.tableSetup = TableSetupUtils.parseTableSetup(
    //         tableSetup
    //     )
    //         .map((e) => `${e.count}b${e.index}`)
    //         .join(".")
    // }
    const handleSelectTableReserve = (tableReserve: number) => {
        eventDetailStore.tableReserve = tableReserve
        saveDraft()
    }

    if (!isFetchedEvent && (!currentEvent || currentEvent.id == 0))
        return (
            <div className="px-5 pt-5">
                <EventDetailLeftSkeleton />
            </div>
        )

    return (
        <>
            <div>
                <div className="flex items-center pl-4 pt-5 text-sm">
                    <IconChevronLeft
                        width={18}
                        height={18}
                        className="text-link"
                    />
                    <div
                        className="text-link font-semibold pl-2 cursor-pointer hover:underline"
                        onClick={() => navigate(`/`)}
                    >
                        Sự kiện
                    </div>
                    <div className="ml-auto mr-5">
                        <EventDetailActions />
                    </div>
                </div>

                <EventTitle />
                <div className="px-5 flex items-center">
                    <div className="text-sm">Ngày:</div>
                    <div className="flex-1">&nbsp;</div>
                    <DatePicker
                        selectedDate={selectedDate}
                        onSelect={onSelectDate}
                    />
                </div>
                {/* <div className="mt-4 px-5 flex items-center">
                    <div className="text-sm  flex-none">
                        Trạng thái:
                    </div>
                    <div className="flex-1">&nbsp;</div>
                    <div className="flex-none">
                        <SelectEventStatus
                            onChange={(value) => {
                                eventDetailStore.status = value
                            }}
                            status={status}
                        />
                    </div>
                </div> */}

                <hr className="mb-5 mt-6 mx-5" />
                <div className="text-sm ml-8 font-semibold">
                    Thông tin chung
                </div>
                <div className="mx-10">
                    <div className="relative">
                        <SelectEventStatus2
                            status={status}
                            onChange={handleChangeStatus}
                        />
                        <StatusChangeCount className=" right-[-28px] top-0"/>
                    </div>
                </div>
                <div className="mx-10">
                    <SelectCustomer
                        customerId={customerId}
                        customerName={customerName || ""}
                        addr={""}
                        onChange={handleSelectCustomer}
                    />
                </div>
                <div className="mx-10">
                    <SelectGuestEstimate
                        guestFrom={guestFrom}
                        guestTo={guestTo}
                        onDone={handleSelectGuestEstimate}
                    />
                </div>
                <div className="mx-10">
                    <SelectDeposit
                        deposit={deposit}
                        onDone={handleDoneDeposit}
                    />
                </div>
                {/* <div className="mx-10">
                    <SelectTable
                        tableSetup={tableSetup}
                        onDone={handleSelectTable}
                    />
                </div> */}
                <div className="mx-10">
                    <SelectTableReserve
                        tableReserve={tableReserve}
                        onDone={handleSelectTableReserve}
                    />
                </div>

                {!!currentEvent.isCancel && (
                    <div className="mt-4 mx-10 ">
                        <div className="text-13 text-secondary w-[120px] flex-shrink-0">
                            Lý do hủy
                        </div>
                        <div className="text-sm font-normal mt-1">
                            {cancelNote}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default EventDetailLeft
