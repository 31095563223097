import { AxiosResponse } from "axios"
import { EventActivityItem } from "models/eventActivity"
import { useEffect, useState } from "react"
import API from "./api"

export const useEventActivity = (eventId: number) => {
    const [loading, setLoading] = useState(false)
    const [eventActivities, setEventActivities] = useState<EventActivityItem[]>([])
    useEffect(() => {
        ;(async () => {
            setLoading(true)
            var res = await API.get<AxiosResponse<EventActivityItem[]>>(
                `/activities/byEvent/${eventId}`
            )
            setLoading(false)
            setEventActivities(res.data.data)
        })()
    }, [])
    return {
        loading,
        eventActivities,
    }
}
