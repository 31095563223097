import classNames from "classnames"
import { Button } from "core/button/Button"
import {
    ScrollAreaRoot,
    ScrollAreaScrollbar,
    ScrollAreaThumb,
    ScrollAreaViewPort,
} from "core/scrollComponents"
import Tab from "core/tabs/Tab"
import TabHeaders from "core/tabs/TabHeaders"
import TabPanel from "core/tabs/TabPanel"
import TabsProvider from "core/tabs/TabsProvider"
import ChecklistNote from "modules/checklist/ChecklistNote/ChecklistNote"
import EventActivity from "modules/event_activity/EventActivity"
import FeedbackNote from "modules/feedback/FeedbackNote"
import OrderNote from "modules/EventDetail/OrderTab/OrderNote/OrderNote"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import {
    useChecklistNoteChangeCount,
    useEventDetailFull,
    useGeneralChangeCount,
    useOrderChangeCount,
    useOrderNoteChangeCount,
} from "services/eventService"
import { showSuccess } from "services/notificationService"
import { dialogStore } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventFilterStore } from "stores/eventFilterStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import ChecklistTabRight from "../ChecklistTabRight/ChecklistTabRight"
import OrderTab from "../OrderTab/OrderTab"
import PaymentTabRight from "../PaymentTabRight"
import SavePanel from "../SavePanel/SavePanel"
import GeneralTab from "../GeneralTab/GeneralTab"
import GeneralChangeCount from "./GeneralChangeCount/GeneralChangeCount"
import { PaymentChangeCount } from "modules/payment/PaymentChangeCount"
import {
    usePaymentChangeCount,
    usePaymentNameChangeCount,
} from "services/paymentService"
import { ChecklistChangeCount } from "../ChecklistTabRight/ChecklistHeader/ChecklistChangeCount/ChecklistChangeCount"
import {
    useChecklistChangeCount,
    useChecklistNameChangeCount,
} from "services/checklistService"
import { FocusTrap } from "@headlessui/react"

const EventDetailPanel = () => {
    const { showEventPanel } = useSnapshot(dialogStore)
    const { currentEvent, feedback } = useSnapshot(eventDetailStore)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    useEventDetailFull(showEventPanel ? currentEvent.id : 0)

    const handleCreateOrUpdate = async () => {
        setLoading(true)
        if (currentEvent.id === 0) {
            var response = await eventDetailStore.createEvent()
            setLoading(false)
            dialogStore.showEventPanel = false
            if (response.error == false) {
                navigate(`/su-kien/${response.newId}`)
            }
        } else {
            var response = await eventDetailStore.updateEvent()
            setLoading(false)
            eventFilterStore.refreshCount++
            showSuccess()
        }
    }
    const groupName = "event-detail"

    const refreshEventDetailAndEventTable = useCallback(() => {
        eventDetailStore.refreshCount++
        eventFilterStore.refreshCount++
    }, [])

    const orderChangeCount = useOrderChangeCount()
    const orderNoteChangeCount = useOrderNoteChangeCount()

    const paymentChangeCount = usePaymentChangeCount()
    const paymentNameChangeCount = usePaymentNameChangeCount()
    const pmChangeCount = paymentChangeCount + paymentNameChangeCount

    const checklistChangeCount = useChecklistChangeCount()
    const checklistNameChangeCount = useChecklistNameChangeCount()
    const checklistNoteChangeCount = useChecklistNoteChangeCount()
    const checklistTabChangeCount =
        checklistChangeCount +
        checklistNameChangeCount +
        checklistNoteChangeCount

    useEffect(() => {
        //khi đóng panel lại thì cũng phải ẩn save panel đi
        if (!showEventPanel) {
            dialogStore.showSavePanel = false
            eventDetailStore.isFetchedEvent = false
        }
    }, [showEventPanel])

    return (
        <TabsProvider>
            <Backdrop
                className={classNames({ active: showEventPanel })}
                onClick={() => (dialogStore.showEventPanel = false)}
            >
                <Wrap
                    onClick={(e) => e.stopPropagation()}
                    className={classNames({ active: showEventPanel })}
                >
                    <div className="bg-[#33475B] h-[50px] px-8 font-medium text-lg text-white flex items-center">
                        {currentEvent.id === 0
                            ? "Tạo sự kiện"
                            : currentEvent.isCancel
                            ? `[Hủy] ${currentEvent.title}`
                            : currentEvent.title}
                        <Close
                            className="ml-auto "
                            onClick={() => (dialogStore.showEventPanel = false)}
                        >
                            <span className="icon-close text-sm" />
                        </Close>
                    </div>
                    <ScrollAreaRoot type="auto">
                        <ScrollAreaViewPort
                            style={{
                                height: currentEvent.id
                                    ? "calc(100vh - 60px)"
                                    : "calc(100vh - 120px)",
                            }}
                        >
                            <Tabs defaultIndex={0} group={groupName}>
                                <TabItem index={0} data-text="Chi tiết">
                                    <div className="flex items-center">
                                        Thông tin chung{" "}
                                        {currentEvent.id > 0 && (
                                            <GeneralChangeCount />
                                        )}
                                    </div>
                                </TabItem>
                                <TabItem index={1} data-text="Thanh toán">
                                    <div className="flex items-center">
                                        Order{" "}
                                        {orderChangeCount +
                                            orderNoteChangeCount >
                                            0 && (
                                            <span className="text-[#999] text-xs font-medium ml-2">
                                                +
                                                {orderChangeCount +
                                                    orderNoteChangeCount}
                                            </span>
                                        )}
                                    </div>
                                </TabItem>
                                <TabItem index={2} data-text="Thanh toán">
                                    <div className="flex items-center">
                                        Check list{" "}
                                        {checklistTabChangeCount > 0 && (
                                            <span className="text-[#999] text-xs font-medium ml-2">
                                                +{checklistTabChangeCount}
                                            </span>
                                        )}
                                    </div>
                                </TabItem>
                                {currentEvent.id !== 0 && (
                                    <>
                                        <TabItem
                                            index={3}
                                            data-text="Thanh toán"
                                        >
                                            <div className="flex items-center">
                                                Thanh toán
                                                {pmChangeCount > 0 && (
                                                    <span className="text-[#999] text-xs font-medium ml-2">
                                                        +{pmChangeCount}
                                                    </span>
                                                )}
                                            </div>
                                        </TabItem>
                                        <TabItem index={4} data-text="Feedback">
                                            <div className="flex items-center">
                                                Feedback
                                                {currentEvent.feedback !=
                                                    feedback && (
                                                    <span className="text-[#999] text-xs font-medium ml-2">
                                                        +1
                                                    </span>
                                                )}
                                            </div>
                                        </TabItem>
                                        <TabItem
                                            index={5}
                                            data-text="Hoạt động"
                                        >
                                            Hoạt động
                                        </TabItem>
                                    </>
                                )}
                            </Tabs>
                            <TabContent
                                className="mx-4 my-5"
                                group={groupName}
                                index={0}
                            >
                                {showEventPanel && <GeneralTab />}
                                <div className="h-10" />
                                {/* <EventNote />
                                <EventSchedule eventId={currentEvent.id} /> */}
                            </TabContent>
                            <TabContent
                                className="mx-4 my-5"
                                group={groupName}
                                index={1}
                            >
                                <OrderNote />
                                {showEventPanel && <OrderTab />}
                                <div className="h-10" />
                            </TabContent>
                            <TabContent
                                className="mx-4 my-5"
                                group={groupName}
                                index={2}
                            >
                                <ChecklistNote />
                                {showEventPanel && <ChecklistTabRight />}
                            </TabContent>
                            {currentEvent.id !== 0 && showEventPanel && (
                                <>
                                    <TabContent
                                        className="mx-4 my-5"
                                        group={groupName}
                                        index={3}
                                    >
                                        <PaymentTabRight />
                                        <div className="h-10" />
                                    </TabContent>
                                    <TabContent
                                        destroyOnInactive
                                        className="mx-4 my-5"
                                        group={groupName}
                                        index={4}
                                    >
                                        <FeedbackNote />
                                    </TabContent>
                                    <TabContent
                                        destroyOnInactive
                                        className="mx-4 my-5"
                                        group={groupName}
                                        index={5}
                                    >
                                        <EventActivity
                                            eventId={currentEvent.id}
                                        />
                                    </TabContent>
                                </>
                            )}
                            <ScrollAreaScrollbar
                                orientation="vertical"
                                style={{
                                    backgroundColor: "#f5f5f5",
                                }}
                            >
                                <ScrollAreaThumb />
                            </ScrollAreaScrollbar>
                             
                        </ScrollAreaViewPort>
                    </ScrollAreaRoot>
                    {currentEvent.id === 0 && (
                        <div className="bg-[#f9fbfe] border-t border-[#d7d8db] h-14 px-8 font-medium text-lg justify-center gap-10  flex items-center">
                            <Button
                                brand
                                className="px-10"
                                loading={loading}
                                onClick={handleCreateOrUpdate}
                            >
                                {currentEvent.id === 0 ? "Tạo" : "Lưu"}
                            </Button>
                            <Button
                                outline
                                brand
                                className="px-10"
                                disabled={loading}
                                onClick={() => {
                                    dialogStore.showEventPanel = false
                                }}
                            >
                                Hủy
                            </Button>
                        </div>
                    )}
                </Wrap>
            </Backdrop>
            {!!currentEvent.id && showEventPanel && (
                <SavePanel onSuccess={refreshEventDetailAndEventTable} />
            )}
        </TabsProvider>
    )
}

export default EventDetailPanel

const Close = styled.div`
    width: 40px;
    height: 40px;
    z-index: 20;
    border-radius: 88px;
    display: grid;
    place-content: center;
    cursor: pointer;
    &:hover {
        background-color: #6e8194;
    }
`

const Backdrop = styled.div`
    position: fixed;
    z-index: 20;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 0.25s ease-out;
    pointer-events: none;
    &.active {
        pointer-events: all;
        background-color: rgba(0, 0, 0, 0.25);
    }
`

const Wrap = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    width: 1040px;
    background-color: #fff;
    transform: translateX(1040px);
    &.active {
        transform: translateX(0);
    }
    transition: transform 0.25s ease;
    box-shadow: -5px 0px 15px 1px rgba(0, 0, 0, 0.1);
`
const Tabs = styled(TabHeaders)`
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
    box-shadow: 0 1px 4px #cdcdcd;
    height: 54px;
    padding-top: 14px;
    padding-left: 16px;
`

const TabItem = styled(Tab)`
    height: 40px;
    cursor: pointer;
    padding: 0 24px;
    margin: 0 8px;
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    color: #8f9397;
    border: 1px solid transparent;
    border-top: 3px solid transparent;
    min-width: 96px;
    &::after {
        content: attr(data-text);
        content: attr(data-text) / "";
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: bold;
    }
    &:hover {
        color: #676c71;
        background-color: #f0f0f0;
    }
    text-size-adjust: none;
    &.active {
        cursor: default;
        box-shadow: 0 1px 5px #ccddee;
        font-weight: 600;
        background-color: #fff;
        color: black;
        border: 1px solid #dddddd;
        border-top: 3px solid var(--brand);
        border-bottom: none;
        &:hover {
            color: black;
            background-color: #fff;
        }
    }
`

const TabContent = styled(TabPanel)`
    display: none;
    &.active {
        display: block;
    }
`
