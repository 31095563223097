import { Button } from "core/button/Button"
import { Page } from "core/styledComponents"
import { EventItem } from "models/events/event"
import EventDetailPanel from "modules/EventDetail/EventDetailPanel/EventDetailPanel"
import AppHeader from "modules/header/AppHeader"
import EventViews from "modules/views/EventViews"
import { dialogStore } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventFilterStore } from "stores/eventFilterStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { paymentStore } from "stores/paymentStore"
import { rootStore } from "stores/rootStore"
import { todoStore } from "stores/todoStore"
import { useSnapshot } from "valtio"
import EventTable from "./EventTable"
import IconCaretDown from "common/icons/IconCaretDown"
import { useEffect } from "react"

const Events = () => {
    const { itemCount } = useSnapshot(eventFilterStore)
    const { eventTypes, places } = useSnapshot(rootStore)
    const openCreateEvent = () => {
        var emptyEvent = EventItem.generateEmpty()
        var typeId = eventTypes[0]?.id || 0
        var placeId = places[0]?.id || 0
        emptyEvent.timeline = `10:00,120,${typeId},${placeId}`
        eventDetailStore.initEventBasicInfo(emptyEvent)
        todoStore.initTodosAndChecklists([], [])
        eventMenuStore.initMenusAndOrderNames([], [])
        paymentStore.initEventPaymentsAndSections([])

        dialogStore.showEventPanel = true
    }
    useEffect(() => {
        document.title="Âu Cơ Hospitality"
    },[])

    return (
        <>
            <Page>
                <AppHeader />
                <div>
                    <div className="flex items-center my-5 mx-12">
                        <div className="text-xl font-bold text-link">
                            Sự kiện{" "}
                            <IconCaretDown className="text-[10px] ml-1 inline-block text-[#7c98b6]" />
                            <div className="text-xs mt-1 font-semibold text-[#33475B]">
                                {itemCount} sự kiện
                            </div>
                        </div>
                        <div className="ml-auto flex items-center">
                            {/* <DropdownButton 
                                dropdown={
                                    <DropdownMenu>
                                        <MenuItem>Hủy sự kiện</MenuItem>
                                        <MenuItem>Xóa sự kiện</MenuItem>
                                    </DropdownMenu>
                                }
                            >
                                
                                <Button outline={true} brand={true} >Thao tác <CaretDown className='ml-1' /></Button>
                            </DropdownButton> */}

                            <Button className="ml-3" onClick={openCreateEvent}>
                                Tạo sự kiện
                            </Button>
                        </div>
                    </div>
                </div>
                <EventViews />
                <EventTable />
            </Page>
            <EventDetailPanel />
        </>
    )
}

export default Events
