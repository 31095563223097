import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { calculateEventMenusChangeCount } from "utils/OrderUtils"
import { useSnapshot } from "valtio"
import OrderChangeCount from "./OrderChangeCount/OrderChangeCount"
import OrderTopActions from "../OrderTopActions"

const OrderHeader = () => {
    const { currentEvent } = useSnapshot(eventDetailStore)
    const { editingMenus } = useSnapshot(eventMenuStore)
    const changeCount = calculateEventMenusChangeCount(
        currentEvent.menus,
        editingMenus
    )
    return (
        <>
            <div className="text-base flex items-center font-bold ">
                Thực đơn
                <OrderChangeCount />
                <div className="ml-auto">
                    <OrderTopActions />
                </div>
            </div>
        </>
    )
}

export default OrderHeader
