import classNames from "classnames"
import IconCheckmarkThin from "common/icons/IconCheckmarkThin"
import { Button } from "core/button/Button"
import { ReactComponent as Spinner } from "core/svgs/loader.svg"
import { useEffect, useState } from "react"
import { discardDraft } from "services/draftService"
import {
    useChecklistNoteChangeCount,
    useMenuChangeCount,
    useOrderNameChangeCount,
    useOrderNoteChangeCount,
    useTimelineChangeCount,
} from "services/eventService"
import { showSuccess } from "services/notificationService"
import { formatGuestEstimate, isSameDay } from "services/utils"
import { dialogStore } from "stores/dialogStore"
import { draftStore } from "stores/draftStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { paymentStore } from "stores/paymentStore"
import { todoStore } from "stores/todoStore"
import styled from "styled-components"
import { checkTableSetupChange } from "utils/TableSetupUtils"
import { useSnapshot } from "valtio"
import SavePanelLoader from "./Loader/SavePanelLoader"
import {
    usePaymentChangeCount,
    usePaymentNameChangeCount,
} from "services/paymentService"
import {
    useChecklistChangeCount,
    useChecklistNameChangeCount,
} from "services/checklistService"
import { recentListStore } from "modules/recent_list/recentListStore"

const SavePanel = ({ onSuccess }: { onSuccess?: () => void }) => {
    const {
        title,
        customerId,
        status,
        deposit,
        tableReserve,
        tableSetup,
        guestFrom,
        guestTo,
        eventNote,
        checklistNote,
        orderNote,
        selectedDate,
        editingAppointments,
        currentEvent,
        feedback,
        isFetchedEvent,
    } = useSnapshot(eventDetailStore)
    const { showSavePanel } = useSnapshot(dialogStore)

    useEffect(() => {
        draftStore.savingCounter = 0
        return () => {
            draftStore.saving = false
            draftStore.savingCounter = 0
        }
    }, [])

    const [loading, setLoading] = useState(false)

    const handleCancel = () => {
        eventDetailStore.resetData()
        dialogStore.showSavePanel = false
    }

    const handleSave = async () => {
        setLoading(true)
        const eventId = currentEvent.id
        var response = await eventDetailStore.updateEvent()
        if (response.error == false) {
            showSuccess()
            onSuccess && onSuccess()
            discardDraft(eventId)
        }
        setLoading(false)
        eventDetailStore.editingKey = ""
        recentListStore.addItem({
            id: eventId,
            name: title,
            isDraft: false,
        })
    }

    const menuChangeCount = useMenuChangeCount()
    const orderNameChangeCount = useOrderNameChangeCount()
    const orderNoteChangeCount = useOrderNoteChangeCount()
    const checklistNoteChangeCount = useChecklistNoteChangeCount()
    const timelineChangeCount = useTimelineChangeCount()

    const paymentChangeCount = usePaymentChangeCount()
    const paymentNameChangeCount = usePaymentNameChangeCount()
    const checklistChangeCount = useChecklistChangeCount()
    const checklistNameChangeCount = useChecklistNameChangeCount()

    let hasChanged = false

    if (
        title != eventDetailStore.currentEvent.title ||
        !isSameDay(selectedDate, currentEvent.startDate) ||
        status != eventDetailStore.currentEvent.status ||
        (deposit || 0) != (eventDetailStore.currentEvent.deposit || 0) ||
        formatGuestEstimate(guestFrom, guestTo) !=
            formatGuestEstimate(
                eventDetailStore.currentEvent.guestFrom,
                eventDetailStore.currentEvent.guestTo
            ) ||
        (tableReserve || 0) !=
            (eventDetailStore.currentEvent.tableReserve || 0) ||
        checkTableSetupChange(
            tableSetup,
            eventDetailStore.currentEvent.tableSetup
        ) ||
        (customerId || 0) != (eventDetailStore.currentEvent.customerId || 0) ||
        eventNote != (eventDetailStore.currentEvent.note || "") ||
        checklistNoteChangeCount > 0 ||
        orderNoteChangeCount > 0 ||
        feedback != eventDetailStore.currentEvent.feedback
    ) {
        hasChanged = true
    }

    if (
        timelineChangeCount +
            menuChangeCount +
            orderNameChangeCount +
            checklistChangeCount +
            checklistNameChangeCount +
            paymentChangeCount +
            paymentNameChangeCount >
        0
    ) {
        hasChanged = true
    }
    console.log("orderNameChangeCount:" + orderNameChangeCount)
    useEffect(() => {
        dialogStore.showSavePanel = hasChanged && isFetchedEvent
    }, [hasChanged, isFetchedEvent])
    // useEffect(() => {
    //     //check change
    //     let hasChanged = false
    //     if (currentEvent.id == 0) {
    //         dialogStore.showSavePanel = false
    //         return
    //     }
    //     // var change1 =
    //     //     getQuillContent(orderNote) !=
    //     //     getQuillContent(eventDetailStore.currentEvent.orderNote || "")
    //     // var change2 = !isSameDay(selectedDate, currentEvent.startDate)

    //     //check thay đổi ở các field trước khi check thay đổi ở order, checklist, payment, timelines
    //     if (
    //         title != eventDetailStore.currentEvent.title ||
    //         !isSameDay(selectedDate, currentEvent.startDate) ||
    //         status != eventDetailStore.currentEvent.status ||
    //         (deposit || 0) != (eventDetailStore.currentEvent.deposit || 0) ||
    //         formatGuestEstimate(guestFrom, guestTo) !=
    //             formatGuestEstimate(
    //                 eventDetailStore.currentEvent.guestFrom,
    //                 eventDetailStore.currentEvent.guestTo
    //             ) ||
    //         (tableReserve || 0) !=
    //             (eventDetailStore.currentEvent.tableReserve || 0) ||
    //         checkTableSetupChange(
    //             tableSetup,
    //             eventDetailStore.currentEvent.tableSetup
    //         ) ||
    //         (customerId || 0) !=
    //             (eventDetailStore.currentEvent.customerId || 0) ||
    //         eventNote != (eventDetailStore.currentEvent.note || "") ||
    //         checklistNoteChangeCount > 0 ||
    //         orderNoteChangeCount > 0 ||
    //         feedback != eventDetailStore.currentEvent.feedback
    //     ) {
    //         hasChanged = true
    //     } else {
    //         if (
    //             menuChangeCount > 0 ||
    //             orderNameChangeCount > 0 ||
    //             timelineChangeCount > 0 ||
    //             paymentChangeCount > 0 ||
    //             paymentNameChangeCount > 0
    //         ) {
    //             hasChanged = true
    //         }

    //         if (
    //             editingTodos.filter((e) => e.name).length !=
    //             eventDetailStore.currentEvent.todos.filter((e) => e.name).length
    //         ) {
    //             hasChanged = true
    //         } else {
    //             for (let item of eventDetailStore.currentEvent.todos) {
    //                 let found = editingTodos.find(
    //                     (e) =>
    //                         e.detail == item.detail &&
    //                         e.name == item.name &&
    //                         e.section == item.section &&
    //                         e.checklist == item.checklist
    //                 )
    //                 if (!found) {
    //                     hasChanged = true
    //                     break
    //                 }
    //             }
    //         }
    //     }
    //     dialogStore.showSavePanel = hasChanged && !isFetchingEvent
    // }, [
    //     editingMenus,
    //     editingTodos,
    //     editingPayments,
    //     editingAppointments,
    //     title,
    //     status,
    //     deposit,
    //     tableReserve,
    //     tableSetup,
    //     guestFrom,
    //     guestTo,
    //     eventNote,
    //     checklistNote,
    //     orderNote,
    //     selectedDate,
    //     customerId,
    //     currentEvent,
    //     feedback,
    //     orderNames,
    //     isFetchingEvent,
    // ])

    // const generalChangeCount = useGeneralChangeCount()

    // const totalChangeCount =
    //     generalChangeCount +
    //     menuChangeCount +
    //     orderNameChangeCount +
    //     orderNoteChangeCount
    return (
        <>
            <SavePanelWrap
                className={classNames("flex items-center justify-center", {
                    active: showSavePanel,
                })}
            >
                <div className="flex justify-center gap-3">
                    <Button brand loading={loading} onClick={handleSave}>
                        Lưu
                    </Button>
                    <Button outline onClick={handleCancel}>
                        Hủy
                    </Button>
                </div>
                {/* <SavePanelLoader className="absolute right-[60px] top-2 "/> */}
                {/* {totalChangeCount > 0 && (
                    <Button
                        className="border-none text-link absolute font-normal left-[calc(50%+100px)] top-2 bg-transparent hover:underline"
                        outline
                    >
                        ({totalChangeCount} thay đổi)
                    </Button>
                )} */}
            </SavePanelWrap>
        </>
    )
}

export default SavePanel

const SavePanelWrap = styled.div`
    position: fixed;
    left: 0;
    z-index: 20;
    height: 48px;
    width: 100vw;
    background-color: #f4f6f8;
    border-top: 1px solid #d9d9d9;
    bottom: -68px;
    transition: bottom 0.25s ease;
    &.active {
        bottom: 0;
    }
    .spinner path {
        stroke: gray;
    }
`
