import API from "services/api"
import { asyncStorage } from "../../../services/localStorageService"
import { ResultItem } from "../models/types"
import { AxiosResponse } from "axios"

const KEY = `${
    import.meta.env.MODE == "development" ? "dev" : "prod"
}_global_search_recent_visited_events`

export const getRecentItems = async () => {
    const value =
        (await asyncStorage.getItem<ResultItem[]>(KEY)) || <ResultItem[]>[]
    return value
}

export const saveRecentItems = async (items: ResultItem[]) => {
    const escapeProxy = items.map((e) => ({ ...e }))
    await asyncStorage.setItem(KEY, escapeProxy)
    console.log("save items")
}

export const searchEvent = async (q: string) => {
    if (q.trim().length < 2) return []
    var res = await API.get<AxiosResponse<ResultItem[]>>(
        `/events/search-title?limit=10&q=${q}`
    )
    return res.data.data
}
