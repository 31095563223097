import classNames from "classnames"
import { CSSProperties, useCallback } from "react"
import Select, {
    ClearIndicatorProps,
} from "react-select"


import { PlaceItem } from "models/place"
import IconCaretDown from "common/icons/IconCaretDown"
import CaretDown from "./CaretDown"
import { ClearIcon } from "./ClearIcon"

type CaretDownProps = {
    className?: string
}

type Props = {
    className?: string
    placeId: number
    isClearable?: boolean
    places: readonly PlaceItem[]
    onChange: (placeId: number) => void
    onFocus?: () => void
}
const SelectPlace = ({
    className,
    placeId,
    onChange,
    isClearable,
    onFocus,
    places,
}: Props) => {
    const getOptionValue = useCallback((option: PlaceItem) => {
        return option.id.toString()
    }, [])

    const getOptionLabel = useCallback((option: PlaceItem) => {
        return option.name
    }, [])

    const handleChange = (option: PlaceItem | null) => {
        onChange(option?.id || 0)
    }
    const value = places.find((e) => e.id === placeId) || null
    return (
        <>
            <Select
                classNamePrefix={"react-select"}
                className={classNames(className)}
                components={{
                    DropdownIndicator: CaretDown,
                    ClearIndicator: ClearIcon,
                }}
                value={value}
                options={places}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                isMulti={false}
                onFocus={onFocus}
                isClearable={isClearable}
                onChange={handleChange}
                placeholder="Chọn sảnh"
                noOptionsMessage={() => "Không có kết quả"}
            />
        </>
    )
}

export default SelectPlace
