const getOsInfo = () => {
    var OSName = "Unknown"
    let platform = window.navigator.platform
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
        OSName = "Windows 10"
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
        OSName = "Windows 8.1"
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
        OSName = "Windows 8"
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
        OSName = "Windows 7"
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
        OSName = "Windows Vista"
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
        OSName = "Windows XP"
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
        OSName = "Windows 2000"
    let macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"]
    let iosPlatforms = ["iPhone", "iPad", "iPod"]

    if (macosPlatforms.indexOf(platform) !== -1) {
        OSName = "Mac OS"
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        OSName = "iOS"
    }

    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX"
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux"
    return OSName
}

const getBrowserInfo = () => {
    var ua = navigator.userAgent
    var tem
    var M =
        ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || []
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return "IE " + (tem[1] || "")
    }
    if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera")
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"]
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    return M.join(" ")
}

const getDeviceInfo = () => {
    return `${getBrowserInfo()} - ${getOsInfo()}`
}

const DeviceUtils = {
    getBrowserInfo,
    getOsInfo,
    getDeviceInfo
}

export default DeviceUtils
