import classNames from "classnames"
import { useState } from "react"
import {
    useChecklistChangeCount,
    useChecklistNameChangeCount,
} from "services/checklistService"
import ChecklistChanges from "../ChecklistChanges/ChecklistChanges"

export const ChecklistChangeCount = ({ className }: { className?: string }) => {
    const [isOpen, setIsOpen] = useState(false)

    const changeCount = useChecklistChangeCount()
    const checklistNameChangeCount = useChecklistNameChangeCount()
    const totalChangeCount = changeCount + checklistNameChangeCount
    return (
        <>
            {totalChangeCount > 0 && (
                <span
                    onClick={() => setIsOpen(true)}
                    className={classNames(
                        "text-[orangered] font-normal text-13 ml-3 hover:underline cursor-pointer",
                        className
                    )}
                >
                    +{totalChangeCount}
                </span>
            )}
            {totalChangeCount > 0 && (
                <ChecklistChanges isOpen={isOpen} setIsOpen={setIsOpen} />
            )}
        </>
    )
}
