import classNames from "classnames"
import EventMenu from "models/events/eventMenu"
import React from "react"
import { ReactNode } from "react"
import { styled } from "styled-components"

type Props = {
    children: ReactNode
    menu: EventMenu
}

const RowIndexCell = ({ menu, children }: Props) => {
    return (
        <Div className={classNames("justify-center text-[#777777]", {})}>
            {children}
        </Div>
    )
}

export default React.memo(RowIndexCell)

const Div = styled.div`
    box-sizing: border-box;
    min-width: 0;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px;
    white-space: pre-wrap;
    min-width: 0;
    font-size: 13px;
    border-right: 1px solid #f1f1f1;
    &:last-child {
        padding: 4px 0;
    }
    /* border-left: 4px solid transparent;
    &.active {
        border-left: 4px solid orangered;
    } */
`
