import classNames from "classnames"
import IconCaretDown from "common/icons/IconCaretDown"
import EmptyContent from "core/EmptyContent"
import SimpleSelect from "core/select/SimpleSelect"
import { useRef, useState } from "react"
import { rootStore } from "stores/rootStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import CustomerChangeCount2 from "./CustomerChangeCount2"
import CustomerChange from "modules/EventDetail/GeneralTab/CustomerChangeCount/CustomerChange/CustomerChange"

type Props = {
    customerId: number
    customerName: string
    addr: string
    onChange: (s: number, name: string) => void
}

const SelectCustomer = ({
    customerId,
    customerName,
    addr,
    onChange,
}: Props) => {
    const { customers } = useSnapshot(rootStore)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isOpenChange, setIsOpenChange] = useState(false)
    const [active, setActive] = useState(!!customerId)
    // const renderNoOption = (s: string) => (
    //     <div className="text-white bg-link text-13 px-4 cursor-pointer py-2">
    //         Tạo khách hàng "{s}"
    //     </div>
    // )
    // const renderOption = (s: {
    //     label: string
    //     value: number
    //     addr: string
    // }) => (
    //     <div className="text-white bg-link text-13 px-4 cursor-pointer py-2"></div>
    // )
    const handleMenuOpen = (isOpen: boolean) => {
        if (isMenuOpen && !isOpen) {
            setActive(false)
        }
        setIsMenuOpen(isOpen)
    }
    const handleMousEnter = () => {
        if (!isMenuOpen) {
            setActive(true)
        }
    }

    const handleMouseLeave = () => {
        if (!isMenuOpen) {
            setActive(false)
        }
    }
    return (
        <>
            <Wrap
                className={classNames("relative mt-4 w-full")}
                onMouseEnter={handleMousEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Label
                    className={classNames("text-13 text-secondary label mb-1")}
                >
                    Khách hàng
                </Label>
                <SimpleSelect
                    onOpenChange={handleMenuOpen}
                    value={
                        customerId
                            ? {
                                  label: customerName,
                                  value: customerId,
                                  addr: addr,
                              }
                            : undefined
                    }
                    searchable
                    placeholder="Tìm kiếm khách hàng"
                    options={customers
                        .map((e) => ({
                            label: e.name,
                            value: e.id,
                            addr: e.addr,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                    onChange={(e) =>
                        onChange(Number(e?.value) || 0, e?.label || "")
                    }
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value.toString()}
                    // renderNoOption={renderNoOption}
                >
                    {customerId ? (
                        <div
                            className={classNames(
                                "flex items-center  font-medium text-sm",
                                {}
                            )}
                        >
                            {customerName}
                            {(active || isMenuOpen) && (
                                <IconCaretDown
                                    className={classNames(
                                        "text-[8px] text-[#868686] ml-2"
                                    )}
                                />
                            )}
                        </div>
                    ) : (
                        <>
                            <EmptyContainer
                                className={classNames(
                                    "flex items-center font-semibold text-sm cursor-pointer w-full",
                                    { "is-active": active || isMenuOpen }
                                )}
                            >
                                &nbsp;
                                {!active && !isMenuOpen && (
                                    <EmptyContent className="pt-1 text-[#555]" />
                                )}
                                &nbsp;
                                {(active || isMenuOpen) && (
                                    <IconCaretDown className="text-[8px] text-[#868686] ml-2" />
                                )}
                            </EmptyContainer>
                        </>
                    )}
                </SimpleSelect>
                <CustomerChangeCount2
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsOpenChange(true)
                    }}
                    onMouseEnter={(e) => {
                        setActive(false)
                    }}
                />
            </Wrap>
            <CustomerChange isOpen={isOpenChange} setIsOpen={setIsOpenChange} />
        </>
    )
}

export default SelectCustomer

const Wrap = styled.div``

const Label = styled.div`
    transition: top 0.25s ease-out;
`

const EmptyContainer = styled.div`
    transition: height 0.2s ease-out;
    border-bottom: 1px solid transparent;
    &.is-active {
        border-bottom: 1px solid var(--link);
    }
`
