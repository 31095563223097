import {
    FloatingOverlay,
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import { useState } from "react"
import styled from "styled-components"
import { defaultDateValues, defaultDateValues2 } from "./viewConstants"
import classNames from "classnames"
import MonthSelect from "./components/MonthSelect"
import { DateFilterValue } from "./viewService"
import { useClickOutside } from "core/hooks"
import IconChevronRight from "common/icons/IconChevronRight"
import IconCaretDown from "common/icons/IconCaretDown"
import IconCheckmarkThin from "common/icons/IconCheckmarkThin"

type Props = {
    label: string
    value: string
    onSelect: (value: string) => void
}

//filter  dành cho ví dụ (thời gian tạo, thời gian update ...) (trong quá khứ)
const DateInThePastFilter = ({ value, label, onSelect }: Props) => {
    const [showDropdown, setShowDropdown] = useState(false)

    const { refs, floatingStyles, update } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [autoPlacement(), offset(10)],
    })
    const handleSelectMonth = (d: Date) => {
        onSelect(`${d.getMonth() + 1}.${d.getFullYear()}`)
    }
    const handleClickDropdown = () => {}
    const isMonth = /^\d{1,2}\.\d{4}$/.test(value)
    const isNextDay = /^\d+$/.test(value)
    const isLastDay = /^-\d+$/.test(value)

    const handleClickOutside = () => {
        setShowDropdown(false)
    }
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)

    let _value = value || DateFilterValue.ALL
    return (
        <FilterWrap
            ref={refs.setReference}
            onClick={() => setShowDropdown((s) => !s)}
            className={classNames(
                "inline-flex select-none whitespace-nowrap cursor-pointer items-center px-3.5 py-1 rounded-[3px] font-semibold text-sm text-link",
                {
                    active: _value != DateFilterValue.ALL,
                }
            )}
        >
            {label}
            <IconCaretDown className="text-[8px]  ml-2 text-[#768893]" />
            {showDropdown && (
                <FloatingPortal>
                    <DropdownMenu
                        className="shadow-menu rounded-lg py-1 z-[99]"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        onClick={handleClickDropdown}
                    >
                        {defaultDateValues2.map((e, index) => (
                            <div
                                key={`${index}-${e.value}`}
                                className={classNames("menu-item", {
                                    "font-medium text-link": _value == e.value,
                                })}
                                onClick={() => onSelect(e.value)}
                            >
                                {_value == e.value ? (
                                    <IconCheckmarkThin className="text-[14px] text-link mr-2" />
                                ) : (
                                    <span className="w-[22px]">&nbsp;</span>
                                )}
                                {e.label}
                            </div>
                        ))}

                        <hr className="mx-4 my-3" />
                        <div className="menu-item">
                            <span className="w-[20px]" />
                            Chọn ngày
                            <IconChevronRight className=" ml-auto text-base text-gray-400"/>
                        </div>
                    </DropdownMenu>
                </FloatingPortal>
            )}
        </FilterWrap>
    )
}

export default DateInThePastFilter

const FilterWrap = styled.div`
    &.active {
        background-color: #eef8ff;
    }
`

const DropdownMenu = styled.div`
    min-width: 240px;
    padding: 4px;
    background-color: #fff;
    div {
        font-size: 14px;
    }

    .menu-item {
        padding: 0 12px;
        display: flex;
        user-select: none;
        align-items: center;
        height: 40px;
        border-radius: 4px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
    }

    .category-item {
        padding: 8px 16px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
    }
`

const MonthMenu = styled.div`
    &:hover {
        .month-select {
            display: block;
        }
    }
`
const LastDayMenu = styled.div`
    &:hover {
        .last-day-select {
            display: block;
        }
    }
`
const NextDayMenu = styled.div`
    &:hover {
        .next-day-select {
            display: block;
        }
    }
`
const MonthSelectWrap = styled.div`
    position: absolute;
    display: none;
    z-index: 10;
    left: calc(100% - 12px);
    width: 250px;
    padding: 4px;
    border-radius: 4px;
    top: -160px;
    background-color: #fff;
    div {
        padding: 12px 16px;
        &:hover {
            background-color: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;
        }
    }
`
const LastDayWrap = styled.div`
    position: absolute;
    display: none;
    z-index: 10;
    left: calc(100% - 12px);
    width: 200px;
    padding: 4px;
    border-radius: 4px;
    top: -60px;
    background-color: #fff;
    div {
        padding: 12px 16px;
        &:hover {
            background-color: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;
        }
    }
`
const NextDayWrap = styled.div`
    position: absolute;
    display: none;
    z-index: 10;
    left: calc(100% - 12px);
    width: 200px;
    padding: 4px;
    border-radius: 4px;
    top: -60px;
    background-color: #fff;
    div {
        padding: 12px 16px;
        &:hover {
            background-color: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;
        }
    }
`
