import classNames from "classnames"
import IconClose from "common/icons/IconClose"
import styled from "styled-components"

type Props = {
    className?: string
    onClick?: () => void
}
const CloseButton = ({ className, onClick }: Props) => {
    return (
        <CloseBtn className={classNames(className)}>
            <IconClose
                className="icon-close text-[17px] text-[#aaa]"
                onClick={onClick}
            />
        </CloseBtn>
    )
}

export default CloseButton

const CloseBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    padding: 4px;
    font-size: 11px;
    &:hover {
        background-color: #eee;
    }
`
