import "App.scss"
import { rootStore } from "stores/rootStore"
import { styled } from "styled-components"

import { useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { Outlet } from "react-router-dom"
import { ToastContainer, Zoom } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import API from "services/api"
import { SIDEBAR_WIDTH } from "services/constants"
import CustomerService from "services/customerService"
import "override.scss"
import { nanoid } from "nanoid"
import { Axios, AxiosResponse } from "axios"
import { EventType } from "models/eventType"
import { parseColor } from "services/utils"
import Notification from "modules/common/Notification"
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth"
// import CellProvider from 'core/cells/CellProvider';

function Layout() {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const unsub = onAuthStateChanged(getAuth(), async (user) => {
            console.log("1")
            if (user) {
                //force refresh
                // var idToken = await getIdToken(user, true)

                window.addEventListener(
                    "focus",
                    function () {
                        console.log("focus ")
                    },
                    false
                )
                API.get("/users/me").then((res) => {
                    rootStore.currentUser = res.data.data
                    setLoading(false)
                })

                API.get<AxiosResponse<EventType[]>>("/event-types").then(
                    (res) => {
                        rootStore.eventTypes = res.data.data.map((e) => ({
                            ...e,
                            color: parseColor(e.color),
                        }))
                    }
                )

                API.get("/places").then((res) => {
                    rootStore.places = res.data.data
                })

                API.get("/menus").then((res) => {
                    rootStore.menus = res.data.data
                })

                API.get("/checklist-templates").then((res) => {
                    rootStore.checklistTemplates = res.data.data
                })

                API.get("/todo-sections").then((res) => {
                    rootStore.todoSections = res.data.data
                })

                API.get("/todos").then((res) => {
                    //@ts-ignore
                    rootStore.todos = res.data.data.map((e) => ({
                        ...e,
                        key: nanoid(),
                    }))
                })
                API.get("/payment-types").then((res) => {
                    rootStore.paymentCategories = res.data.data
                })
                API.get("/payment-items").then((res) => {
                    //@ts-ignore
                    rootStore.paymentItems = res.data.data.map((e) => ({
                        ...e,
                        key: nanoid(),
                    }))
                })

                API.get("/menu-types").then((res) => {
                    rootStore.menuTypes = res.data.data
                })
                API.get("/units").then((res) => {
                    rootStore.units = res.data.data
                })

                API.get("/customer-groups").then((res) => {
                    rootStore.customerGroups = res.data.data
                })
                CustomerService.getLatestCustomers()
            } else {
                window.location.href = "/login"
            }
            unsub()
        })

        return () => {
            console.log("..... out")
        }
    }, [])

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Outlet />
                    <ToastContainer
                        position="top-center"
                        autoClose={4000}
                        transition={Zoom}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                    <Notification />
                </>
            )}
        </>
    )
}

export default Layout

const Main = styled.div`
    width: calc(100vw - ${SIDEBAR_WIDTH}px);
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${SIDEBAR_WIDTH}px;
    min-height: 0;
    background-color: #fff;
`

// const TopHeader = styled.div`
//   height: 60px;
//   border-bottom: 1px solid #eee;
// `
const Loader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
`
