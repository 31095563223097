import classNames from "classnames"
import { useEffect, useRef } from "react"
import { eventFilterStore } from "stores/eventFilterStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"

export const HEADER_HEIGHT = 32

type Props = {
    scrollLeft: number
}

const EventTableHeader = ({ scrollLeft }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const { sortBy, sortDir } = useSnapshot(eventFilterStore)

    const toggleSort = (_sortBy: string) => {
        if (_sortBy !== sortBy) {
            eventFilterStore.sortDir = "desc"
        } else {
            if (sortDir.toUpperCase() == "") {
                eventFilterStore.sortDir = "desc"
            } else if (sortDir.toUpperCase() == "DESC") {
                eventFilterStore.sortDir = "asc"
            } else if (sortDir.toUpperCase() == "ASC") {
                eventFilterStore.sortDir = "desc"
            }
        }
        eventFilterStore.sortBy = _sortBy
    }

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = scrollLeft
        }
    }, [scrollLeft])
    return (
        <Wrap className="shadow-bottom" ref={containerRef}>
            <div className="cell">&nbsp;</div>
            <div className="justify-center cell">TÊN SỰ KIỆN</div>
            <div className="justify-center cell">KHÁCH HÀNG</div>
            <div className="justify-center cell">GHI CHÚ</div>
            <div className="justify-center cell">TRẠNG THÁI</div>
            <div
                className={classNames("justify-center cell sortable", {
                    active: sortBy == "startTime",
                })}
                onClick={() => toggleSort("startTime")}
            >
                NGÀY DIỄN RA
                <Sorter isTarget={sortBy == "startTime"} sortDir={sortDir} />
            </div>
            <div className="cell justify-center">GIỜ BẮT ĐẦU</div>
            <div className="cell justify-center">ĐỊA ĐIỂM</div>
            <div className="cell justify-center">LOẠI HÌNH</div>
            <div className="cell justify-center">BÀN DỰ KIẾN</div>
            <div
                className={classNames("justify-center cell sortable", {
                    active: sortBy == "guestFrom",
                })}
                onClick={() => toggleSort("guestFrom")}
            >
                KHÁCH DỰ KIẾN
                <Sorter isTarget={sortBy == "guestFrom"} sortDir={sortDir} />
            </div>
            <div
                className={classNames("justify-center uppercase cell sortable", {
                    active: sortBy == "deposit",
                })}
                onClick={() => toggleSort("deposit")}
            >
                ĐẶT CỌC
                <Sorter isTarget={sortBy == "deposit"} sortDir={sortDir} />
            </div>
            <div
                className={classNames("justify-center uppercase cell sortable", {
                    active: sortBy == "menuCount",
                })}
                onClick={() => toggleSort("menuCount")}
            >
                Tổng số món
                <Sorter isTarget={sortBy == "menuCount"} sortDir={sortDir} />
            </div>
            <div
                className={classNames("justify-center uppercase cell sortable", {
                    active: sortBy == "totalPrice",
                })}
                onClick={() => toggleSort("totalPrice")}
            >
                Tổng đơn giá
                <Sorter isTarget={sortBy == "totalPrice"} sortDir={sortDir} />
            </div>
            <div className={classNames("justify-center uppercase cell")}>Check list</div>
            <div
                className={classNames("justify-center uppercase cell sortable", {
                    active: sortBy == "totalPayment",
                })}
                onClick={() => toggleSort("totalPayment")}
            >
                Tổng thanh toán
                <Sorter isTarget={sortBy == "totalPayment"} sortDir={sortDir} />
            </div>
        </Wrap>
    )
}

export default EventTableHeader

const Sorter = ({
    isTarget,
    sortDir,
}: {
    isTarget: boolean
    sortDir: string
}) => {
    return (
        <SorterWrap>
            <TriangleUp
                className={classNames("triangle-up", {
                    active: isTarget && sortDir == "asc",
                    target: isTarget && sortDir == "desc",
                })}
            />
            <TriangleDown
                className={classNames("triangle-down", {
                    active: isTarget && sortDir == "desc",
                    target: isTarget && sortDir == "asc",
                })}
            />
        </SorterWrap>
    )
}

const Wrap = styled.div`
    height: ${HEADER_HEIGHT}px;
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 9;
    overflow: hidden;

    border-right: 1px solid #e9e9e9;
    flex-shrink: 0;
    z-index: 8;
    padding-right: 16px;
    font-size: 12px;
    font-weight: bold;
    display: grid;
    grid-template-columns: 40px 320px 140px 170px 120px 150px 140px 140px 170px 140px 140px 140px 140px 140px 140px minmax(
            140px,
            1fr
        );
    div.cell {
        height: 100%;
        display: flex;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-shrink: 0;
        align-items: center;
        color: #343434;
        padding: 8px 8px;
        border-right: 1px solid #eaeaea;
        user-select: none;
        &.sortable {
            cursor: pointer;
            position: relative;
        }
        &:hover {
            &.sortable {
                background-color: #eaf3ff;
                color: #52647a;
            }

            .triangle-down {
                border-top: 5px solid var(--link);

                &.target {
                    border-top: 5px solid #aaa;
                }
            }
            .triangle-up {
                &.target {
                    border-bottom: 5px solid #aaa;
                }
            }
        }
        &.active {
            background-color: #e4ebf2;
            color: #52647a;
        }
    }
`

const SorterWrap = styled.div`
    position: absolute;
    right: 10px;
    top: 8px;
    z-index: 10;
    width: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
`

const TriangleDown = styled.div`
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #ccc;
    &.active {
        border-top: 5px solid var(--link);
    }
`

const TriangleUp = styled.div`
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid #ccc;
    &.active {
        border-bottom: 5px solid var(--link);
    }
`
