import classNames from "classnames"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import { Input } from "core/styledComponents"
import { useEffect, useState } from "react"
import { eventFilterStore } from "stores/eventFilterStore"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RenameViewDialog = ({ isOpen, setIsOpen }: Props) => {
    const { currentViewName, views } = useSnapshot(eventFilterStore)
    const [defaultName, setDefaultName] = useState("")
    const [name, setName] = useState(currentViewName)
    useEffect(() => {
        if (!isOpen) {
            setName(currentViewName)
            setDefaultName(currentViewName)
        }
    }, [isOpen, currentViewName])

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSave()
        }
    }
    const handleSave = async () => {
        setDefaultName(name)
        await eventFilterStore.renameCurrentView(name)
        setIsOpen(false)
    }

    const otherViewNames = views
        .filter((e) => e.name != defaultName)
        .map((e) => e.name)
    const isDuplicateName = otherViewNames.includes(name.trim())
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[25vh] bg-white">
                    <div className="px-8 pt-5 mb-4">
                        <div className="text-md font-semibold mb-5">
                            Đổi tên view
                        </div>
                        <Input
                            autoFocus
                            value={name}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={name}
                            className="h-10 text-sm rounded-lg"
                        />
                        <div
                            className={classNames(
                                "text-xs text-red-500 mt-2 ml-2",
                                {
                                    "opacity-0": !isDuplicateName,
                                }
                            )}
                        >
                            View đã tồn đã tồn tại
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-center gap-4 py-5">
                        <Button
                            disabled={
                                !name.trim() ||
                                views.map((e) => e.name).includes(name.trim())
                            }
                            className="w-[100px]"
                            onClick={handleSave}
                        >
                            Lưu
                        </Button>
                        <Button
                            className="w-[100px]"
                            outline={true}
                            onClick={() => setIsOpen(false)}
                        >
                            Hủy
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default RenameViewDialog
