import { styled } from "styled-components"
import {
    useFloating,
    autoPlacement,
    offset,
    FloatingPortal,
} from "@floating-ui/react"
import { useClickOutside } from "core/hooks"
import classNames from "classnames"
import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import {
    extractOrderName,
    parseMealTableSetup,
    parseMealTableSetupWithDefault2,
} from "utils/TableSetupUtils"
import { useSnapshot } from "valtio"
import SelectTablePopup from "./SelectTablePopup/SelectTablePopup"
import TableCountChanged from "./TableCountChanged"

const TableEstimate = () => {
    const { tableSetup } = useSnapshot(eventDetailStore)
    const mealTableSetups = parseMealTableSetupWithDefault2(tableSetup)
    const { orderNames } = useSnapshot(eventMenuStore)

    if (eventDetailStore.currentEvent.id == 0) return null
    return (
        <>
            {orderNames.length > 0 &&
                orderNames.length === mealTableSetups.length && (
                    <div className="pt-3 text-md bg-white rounded-md  mt-2 shadow mb-3 pb-3">
                        <div
                            className={classNames(
                                "text-base font-semibold pl-5 mt-1 flex items-center"
                            )}
                        >
                            Bàn dự kiến <TableCountChanged />
                        </div>

                        <Table>
                            <thead className=" text-13 text-secondary font-medium">
                                <tr>
                                    <td>Thực đơn</td>
                                    <td>Số lượng</td>
                                </tr>
                            </thead>
                            <tbody>
                                {mealTableSetups.map((e, index) => (
                                    <tr>
                                        <td>
                                            {extractOrderName(
                                                orderNames[index]
                                            ) || "Mặc định"}
                                        </td>
                                        <td>
                                            <SelectTablePopup index={index} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
        </>
    )
}

export default TableEstimate

const Table = styled.table`
    margin: 10px 20px;
    margin-top: 16px;
    td {
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        padding: 8px 16px;
        min-width: 150px;
    }
    td:last-child {
        min-width: 200px;
    }
    tbody td {
        font-size: 14px;
    }
    thead td {
        text-align: center;
        background-color: #f3f3f3;
    }

    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
`
