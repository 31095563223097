import styled from "styled-components"
import { useSnapshot } from "valtio"
import { eventMenuStore } from "stores/eventMenuStore"
import classNames from "classnames"
import { eventDetailStore } from "stores/eventDetailStore"
import { paymentStore } from "stores/paymentStore"
import PaymentTopActions from "./PaymentTopActions"

export const ORDER_TABLE_TOP_HEIGHT = 44

const PaymentTableTop = () => {
    const { currentPaymentName: currentSection, paymentNames: currentSectionNames } = useSnapshot(paymentStore)
    return (
        <>
            <PaymentTableTopWrap className="bg-white flex items-center gap-4 px-5 card-shadow">
                {currentSectionNames.map((e) => (
                    <OrderName
                        key={e}
                        onClick={() => (paymentStore.currentPaymentName = e)}
                        className={classNames(
                            "h-7 px-3 flex items-center rounded-full font-medium text-sm cursor-default",
                            {
                                "bg-lightblue text-link active":
                                    e === currentSection,
                            }
                        )}
                    >
                        {e || "Mặc định"}
                    </OrderName>
                ))}
                <div className="ml-auto">
                    <PaymentTopActions />
                </div>
            </PaymentTableTopWrap>
        </>
    )
}

export default PaymentTableTop

const PaymentTableTopWrap = styled.div`
    border-bottom: 1px solid #e9e9e9;
    background-color: #fff;
    box-shadow: 0 1px 5px #eee;
    height: ${ORDER_TABLE_TOP_HEIGHT}px;
`

const OrderName = styled.div`
    &:not(.active) {
        &:hover {
            background-color: #f1f1f1;
        }
    }
`
