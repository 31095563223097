import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import { saveDraft } from "services/draftService"
import { useTimelineChangeCount } from "services/eventService"
import { usePlaces } from "services/placeService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import TimelineChangeHistory from "./TimelineChangeHistory"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const TimelineChangePopup = ({ isOpen, setIsOpen }: Props) => {
    const { currentEvent, editingAppointments } = useSnapshot(eventDetailStore)
    const places = usePlaces()

    const cancelChanges = () => {
        eventDetailStore.selectedDate = new Date(
            currentEvent.startDate.getTime()
        )
        eventDetailStore.editingAppointments = currentEvent.parseTimelines()
        saveDraft()
        // clearTimelineDraft(currentEvent.id)
        // clearDateDraft(currentEvent.id)
        setIsOpen(false)
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg   w-[550px] pb-3 mt-[25vh] bg-white">
                    <DialogTitle>Thay đổi lịch sự kiện</DialogTitle>
                    <div className="px-12 pb-2 pt-5 text-sm">
                        <TimelineChangeHistory
                            newTimelines={[...editingAppointments]}
                            oldTimelines={currentEvent.parseTimelines()}
                            places={places}
                        />
                    </div>
                    <hr />
                    <div className="justify-center flex pt-3">
                        <Button outline onClick={cancelChanges}>
                            Hủy thay đổi
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default TimelineChangePopup
