
import { paymentStore } from "stores/paymentStore"
import PaymentMoreButton from "./PaymentMoreButton"
import SelectPayment from "./SelectPayment"
import PlusButton from "modules/ui/PlusButton"
import UndoButton from "modules/ui/UndoButton"
import { useState } from "react"

const PaymentTopActions = () => {
    // const [loading, setLoading] = useState(false)
    const [isOpenSelectPayment, setIsOpenSelectPayment] = useState(false)
   

    const onReset = () => {
        paymentStore.resetPayments()
    }

    // const onDelete = () => {
    //     eventMenuStore.removeSelectedMenu()
    // }
    // const onSave = async () => {
    //     setLoading(true)
    //     await eventDetailStore.saveOrderToDB()
    //     setLoading(false)
    // }
    return (
        <div className="flex items-center gap-4">
            <UndoButton onClick={onReset} size={15} tooltip="Hủy thay đổi" />
            <PlusButton
                onClick={() => setIsOpenSelectPayment(true)}
                size={26}
                tooltip="Thêm hạng mục"
            />

            {/* <span className="icon-checkmark text-[11px]" /> */}
            {/* <IconActionButton
                onClick={onSave}
                loading={loading}
                iconClass="icon-save"
                size={13}
                tooltip="Lưu"
            /> */}
            <PaymentMoreButton />
            <SelectPayment
                isOpen={isOpenSelectPayment}
                setIsOpen={setIsOpenSelectPayment}
            />
        </div>
    )
}

export default PaymentTopActions
