import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import FeedbackChange from "./FeedbackChange"

const FeedbackChangeCount = () => {
    const { feedback, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)
    if (currentEvent.id == 0) return null
    return (
        <>
            {currentEvent.feedback != feedback && (
                <span
                    onClick={() => setIsOpen(true)}
                    className="text-[orangered] text-13 font-normal cursor-pointer hover:underline"
                >
                    +1
                </span>
            )}
            <FeedbackChange isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default FeedbackChangeCount
