import EventAttachment from 'models/eventAttachment';
import { proxy } from 'valtio';

export const attachmentStore = proxy<{
    attachments: EventAttachment[]
}>({
    attachments: [],
})


