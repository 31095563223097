import { Timeline } from "models/timeline"
import { eventDetailStore } from "stores/eventDetailStore"
import { todoStore } from "stores/todoStore"
import { paymentStore } from "stores/paymentStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { attachmentStore } from "stores/attachmentStore"
import { EventItem } from "models/events/event"
import { encodeTimelines } from "./utils"
import { asyncStorage } from "./localStorageService"
import { draftStore } from "stores/draftStore"
import { recentListStore } from "modules/recent_list/recentListStore"

// const ORDER_NOTE_PREFIX = `ORDER_NOTE_DRAFT`
// const EVENT_NOTE_PREFIX = `EVENT_NOTE_DRAFT`
// const EVENT_TITLE_PREFIX = `EVENT_TITLE_DRAFT`
// const EVENT_DATE_PREFIX = `EVENT_DATE_DRAFT`
// const EVENT_TIMELINE_PREFIX = `EVENT_TIMELINE_DRAFT`

export const saveDraft = async () => {
    console.log("Saving draft...")
    draftStore.savingCounter = draftStore.savingCounter + 1
    draftStore.saving = true
    const {
        title,
        status,
        guestFrom,
        guestTo,
        deposit,
        eventNote,
        orderNote,
        checklistNote,
        tableSetup,
        tableReserve,
        customerId,
        customerName,
        feedback,
        currentEvent,
    } = eventDetailStore
    const { editingTodos, checklistNames } = todoStore
    const { editingPayments } = paymentStore
    const { editingMenus, orderNames } = eventMenuStore
    const { attachments } = attachmentStore

    var timelines: Timeline[] = eventDetailStore.editingAppointments
    timelines.sort((a, b) => a.hour * 60 + a.min - b.hour * 60 - b.min)

    var earliestAppointment = timelines[0]
    var hour = earliestAppointment.hour.padZero()
    var min = earliestAppointment.min.padZero()
    var date = eventDetailStore.selectedDate
    var year = `${date.getFullYear()}`.substring(2, 4)
    var month = (date.getMonth() + 1).padZero()
    var day = date.getDate().padZero()
    var startTime = +`${year}${month}${day}${hour}${min}`
    var timeline = encodeTimelines(timelines)
    const ev: Partial<EventItem> & {} = {
        title,
        // attachments,
        checklistNames: [...checklistNames], // cách này để bỏ proxy bọc ngoài nếu ko sẽ ko lưu được
        status,
        startTime,
        timeline,
        guestFrom,
        guestTo,
        deposit,
        note: eventNote,
        orderNames: [...orderNames],
        orderNote,
        customerId,
        customerName,
        checklistNote,
        tableSetup,
        tableReserve,
        feedback,
        menus: [...editingMenus.map((e) => ({ ...e }))], // cách này để bỏ proxy bọc ngoài
        todos: [...editingTodos.map((e) => ({ ...e }))],
        payments: [...editingPayments.map((e) => ({ ...e }))],
    }

    // store.set(
    //     `${import.meta.env.MODE == "development" ? "dev" : "prod"}_${
    //         currentEvent.id
    //     }`,
    //     ev
    // )
    const key = `${import.meta.env.MODE == "development" ? "dev" : "prod"}_${
        currentEvent.id
    }`

    await asyncStorage.setItem(key, ev)
    draftStore.saving = false
    recentListStore.addItem({
        id: currentEvent.id,
        isDraft: false,
        name: currentEvent.title,
    })
}

// export const getDraft = async (id: number) => {
//     return await asyncStorage.getItem<Partial<EventItem>>(
//         `${import.meta.env.MODE == "development" ? "dev" : "prod"}_${id}`
//     )
// }

export const getDraft = async (id: number) => {
    // const draft = store.get(
    //     `${import.meta.env.MODE == "development" ? "dev" : "prod"}_${id}`
    // )
    // return draft as Partial<EventItem> | undefined

    const key = `${
        import.meta.env.MODE == "development" ? "dev" : "prod"
    }_${id}`
    const value = await asyncStorage.getItem<Partial<EventItem>>(key)
    if (value) return { ...value, id }
}
export const discardDraft = (id: number) => {
    const key = `${
        import.meta.env.MODE == "development" ? "dev" : "prod"
    }_${id}`
    asyncStorage.removeItem(key)
    // store.remove(
    //     `${import.meta.env.MODE == "development" ? "dev" : "prod"}_${id}`
    // )
}

// export const saveOrderNoteDraft = (orderNote: string, eventId: number) => {
//     store.set(`${ORDER_NOTE_PREFIX}_${eventId}`, orderNote)
// }

// export const getOrderNoteDraft = (eventId: number) =>
//     store.get(`${ORDER_NOTE_PREFIX}_${eventId}`)

// export const saveEventNoteDraft = (eventNote: string, eventId: number) => {
//     console.log("save event note draft: ", eventNote)
//     store.set(`${EVENT_NOTE_PREFIX}_${eventId}`, eventNote)
// }

// export const getEventNoteDraft = (eventId: number) =>
//     store.get(`${EVENT_NOTE_PREFIX}_${eventId}`)

// export const clearEventNoteDraft = (eventId: number) => {
//     console.log("clear event note draft")
//     store.remove(`${EVENT_NOTE_PREFIX}_${eventId}`)
// }
// export const saveEventTitleDraft = (title: string, eventId: number) => {
//     store.set(`${EVENT_TITLE_PREFIX}_${eventId}`, title)
// }

// export const getEventTitleDraft = (eventId: number) =>
//     store.get(`${EVENT_TITLE_PREFIX}_${eventId}`)

// export const clearTitleDraft = (eventId: number) => {
//     store.remove(`${EVENT_TITLE_PREFIX}_${eventId}`)
// }

// export const getEventDateDraft = (eventId: number) =>
//     store.get(`${EVENT_DATE_PREFIX}_${eventId}`)

// export const saveEventDate = (ts: number, eventId: number) => {
//     store.set(`${EVENT_DATE_PREFIX}_${eventId}`, ts)
// }

// export const clearDateDraft = (eventId: number) => {
//     store.remove(`${EVENT_DATE_PREFIX}_${eventId}`)
// }

// export const saveEventTimeline = (timelines: Timeline[], eventId: number) => {
//     store.set(`${EVENT_TIMELINE_PREFIX}_${eventId}`, timelines)
// }

// export const getTimelineDraft = (eventId: number) =>
//     store.get(`${EVENT_TIMELINE_PREFIX}_${eventId}`)

// export const clearTimelineDraft = (eventId: number) => {
//     store.remove(`${EVENT_TIMELINE_PREFIX}_${eventId}`)
// }
