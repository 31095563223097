import { MenuSize } from "models/menu_size"
import API from "./api"
import { DateCount } from "core/types"


const countByDate = async (from: number, to: number): Promise<DateCount[]> => {
    //from va to co dang YYMMDD
    var res = await API.get(
        `/events/countByDate?from=${from}&to=${to}`
    )
    return res.data.data
}

const StatisticService = {
    countByDate,
}

export default StatisticService
