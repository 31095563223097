import { ThreeDots } from 'react-loader-spinner'


export default function Spinner() {
    return (
        <ThreeDots
            height="24"
            width="24"
            radius="9"
            color="#ccc"
            ariaLabel="loading"
        />

    );
}