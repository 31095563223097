import { EventType } from "models/eventType"
import { Timeline } from "models/timeline"
import { nanoid } from "nanoid"
import { useEffect, useRef, useState } from "react"
import { parseColor } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import BottomPin from "./BottomPin"
import CheckButton from "./CheckButton"
import Columns from "./Columns"
import CurrentAppointments from "./CurrentAppointments"
import DialogSelectEventType from "./DialogSelectEventType"
import EndTimeLabel from "./EndTimeLabel"
import HorizontalLines from "./HorizontalLines"
import PlacesContainer from "./PlacesContainer"
import Rectangle from "./Rectangle"
import StartTimeLabel from "./StartTimeLabel"
import TopPin from "./TopPin"
import YAxis from "./YAxis"
import RemoveButton from "./RemoveButton"
import { EventItem } from "models/events/event"
import { PlaceItem } from "models/place"

export const YAXIS_WIDTH = 46
export const COL_MARGIN = 4 //mỗi bên 4
export const MIN_WIDTH = 940 //chiều rộng tối thiểu của vùng thao tác, nếu bé hơn sẽ xuất hiện scroll
export const START_HOUR = 7
export const PLACES_CONTAINER_HEIGHT = 40

type Props = {
    defaultBoundaryWidth?: number
    defaultBoundaryTop?: number
    defaultBoundaryLeft?: number
    otherEvents: EventItem[]
    places: PlaceItem[]
    eventTypes: EventType[]
}

//khi điều chỉnh giờ, kéo thả ... thì các thay đổi được cập nhật vào eventDetailStore.editingAppointments
const TimePicker = ({
    defaultBoundaryWidth,
    defaultBoundaryTop,
    defaultBoundaryLeft,
    otherEvents,
    places,
    eventTypes,
}: Props) => {
    const editMode = true
    //editingKey là key của timelineItem được sinh ra ở local để quản lý appointment của event
    const { editingKey, selectedDate, editingAppointments, currentEvent } =
        useSnapshot(eventDetailStore)
    const [otherAppointments, setOtherAppointments] = useState<Timeline[]>([])

    //tọa độ mép trên vùng thao tác
    const [boundaryLeft, setBoundaryLeft] = useState(defaultBoundaryLeft || 0)
    const [boundaryTop, setBoundaryTop] = useState(defaultBoundaryTop || 0)
    const areaRef = useRef<HTMLDivElement>(null)
    const [boundaryWidth, setBoundaryWidth] = useState(
        defaultBoundaryWidth || 0
    )

    const [isOpenSelectEventType, setIsOpenSelectEventType] = useState(false)
    //top là vị trí top của rectangle, với quy ước  0 là giá trị khi ngang với START_HOUR
    const [top, setTop] = useState(0)
    const [bottom, setBottom] = useState(0) //vị trí bottom của rectangle
    const lastTopRef = useRef(0)
    const lastBottomRef = useRef(0)
    const [colIndex, setColIndex] = useState(0)

    //dùng để tính toán vị trí của placeholder khi mousemove (tạm bỏ qua logic này)
    // const [placeholderTop, setPlaceholderTop] = useState(0)
    // const [placeholderColIndex, setPlaceholderColIndex] = useState(0)

    useEffect(() => {
        if (areaRef.current) {
            let observer = new ResizeObserver((mutationsList, observe) => {
                const bound = areaRef.current?.getBoundingClientRect()
                if (bound) {
                    setBoundaryLeft(bound.left)
                    setBoundaryTop(bound.top)
                    setBoundaryWidth(bound.width)
                }
            })

            observer.observe(areaRef.current, {})
        }
    }, [])

    const rectangleMouseDownRef = useRef(false)
    const topPinMouseDownRef = useRef(false)
    const bottomPinMouseDownRef = useRef(false)

    //khi mouse down vào vùng trống để tạo thêm lịch sẽ hiện 1 placeholder
    const [showPlaceholder, setShowPlaceholder] = useState(false)
    //lưu lại giá trị clientY khi mới mouse down
    const lastClientYRef = useRef(0)

    useEffect(() => {
        var items: Timeline[] = []
        for (var ev of otherEvents) {
            var timelineItems = ev.parseTimelines()
            items = [...items, ...timelineItems]
        }

        setOtherAppointments(items)
    }, [otherEvents, selectedDate])

    useEffect(() => {
        window.addEventListener("resize", windowResize)
        //editingKey: key của timelineItem được sinh ra ở local để quản lý appointment của event
        if (editingKey) {
            const editingAppointment = editingAppointments.find(
                (e) => e.key === editingKey
            )
            if (editingAppointment) {
                updateTop(editingAppointment)
                updateBottom(editingAppointment)
                setColIndex(getColIndex(editingAppointment.placeId))
            }
        }
    }, [editingKey])

    function windowResize() {
        const bound = areaRef.current?.getBoundingClientRect()
        if (bound) {
            setBoundaryLeft(bound.left)
            setBoundaryTop(bound.top)
            setBoundaryWidth(bound.width)
        }
    }

    //scrollTop và scrollLeft của vùng thao tác (container)
    const [scrollLeft, setScrollLeft] = useState(0)
    const [scrollTop, setScrollTop] = useState(0)

    const getColIndex = (placeId: number) =>
        places.map((e) => e.id).indexOf(placeId)
    const getColor = (typeID: number) => {
        var color = eventTypes.find((e) => e.id === typeID)?.color || ""
        return parseColor(color)
    }

    // if (!places.length) {
    //     return (
    //         <Wrapper
    //             style={{
    //                 height: `calc(100vh - ${
    //                     OFFSET_TOP - PLACES_CONTAINER_HEIGHT
    //                 }px)`,
    //             }}
    //             className="bg-white flex items-stretch relative"
    //         >
    //             &nbsp;
    //         </Wrapper>
    //     );
    // }

    const updateTop = (e: Timeline) => {
        var _top = (e.hour - START_HOUR) * 60 + e.min
        setTop(_top)
        lastTopRef.current = _top
    }
    const updateBottom = (e: Timeline) => {
        var _top = (e.hour - START_HOUR) * 60 + e.min
        var _bottom = _top + e.duration
        setBottom(_bottom)
        lastBottomRef.current = _bottom
    }

    const rectangleMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (!editMode) {
            return
        }
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = true
            topPinMouseDownRef.current = false
            bottomPinMouseDownRef.current = false
            lastTopRef.current = top
            lastClientYRef.current = e.clientY
        }
        return false
    }
    const topPinMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = false
            bottomPinMouseDownRef.current = false
            topPinMouseDownRef.current = true
            lastTopRef.current = top
            lastBottomRef.current = bottom
            lastClientYRef.current = e.clientY
        }
    }
    const topPinDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = false
            bottomPinMouseDownRef.current = false
            topPinMouseDownRef.current = true
            lastTopRef.current = top
            lastBottomRef.current = bottom
            lastClientYRef.current = e.clientY
        }
    }
    const bottomPinMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = false
            topPinMouseDownRef.current = false
            bottomPinMouseDownRef.current = true
            lastTopRef.current = top
            lastBottomRef.current = bottom
            lastClientYRef.current = e.clientY
        }
    }
    const bottomPinDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = false
            topPinMouseDownRef.current = false
            bottomPinMouseDownRef.current = true
            lastTopRef.current = top
            lastBottomRef.current = bottom
            lastClientYRef.current = e.clientY
        }
    }

    const containerMouseUp = () => {
        if (!editMode) {
            return
        }
        rectangleMouseDownRef.current = false
        topPinMouseDownRef.current = false
        bottomPinMouseDownRef.current = false
        var roundTop = Math.round(top / 15) * 15
        var rountBottom = Math.round(bottom / 15) * 15

        setTop(roundTop)
        setBottom(rountBottom)
        lastTopRef.current = roundTop
        lastBottomRef.current = rountBottom
        // //xử lý làm tròn mouseTop
        // setShowPlaceholder(false)
        if (showPlaceholder && !editingKey) {
            setIsOpenSelectEventType(true)
        }
    }

    const containerMouseMove = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (!editMode) {
            return
        }

        if (rectangleMouseDownRef.current) {
            const height = lastTopRef.current - lastBottomRef.current
            const deltaY = lastClientYRef.current - e.clientY

            const _top = lastTopRef.current - deltaY

            let _left = e.clientX - YAXIS_WIDTH - boundaryLeft + scrollLeft
            let _colIndex = Math.floor(_left / colWidth)
            if (_colIndex >= 0 && _colIndex < places.length) {
                setColIndex(_colIndex)
            }
            setTop(_top)
            setBottom(_top - height)
        } else if (topPinMouseDownRef.current) {
            const deltaY = lastClientYRef.current - e.clientY

            const _top = lastTopRef.current - deltaY
            if (_top + 30 <= bottom) {
                setTop(_top)
            }
        } else if (bottomPinMouseDownRef.current) {
            const deltaY = lastClientYRef.current - e.clientY

            const _bottom = lastBottomRef.current - deltaY
            if (_bottom - 30 >= top) {
                setBottom(_bottom)
            }
        }
    }

    const SCROLL_WIDTH = 20
    const colWidth =
        (boundaryWidth - YAXIS_WIDTH - SCROLL_WIDTH) / places.length

    const editingAppointment = eventDetailStore.editingAppointments.find(
        (e) => e.key === editingKey
    )

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            eventDetailStore.toggleViewMode()
        }
    }

    const containerMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (!editMode) {
            return
        }
        if (e.button !== 0) {
            return
        }
        const boundary = areaRef.current?.getBoundingClientRect()
        const _boundaryLeft = boundary?.left || 0
        const _boundaryTop = boundary?.top || 0
        if (!!editingKey) {
            //TODO: tính toán col index
            const height = lastTopRef.current - lastBottomRef.current
            // const deltaY = lastClientYRef.current - e.clientY

            let _top = e.clientY - _boundaryTop - 30 + scrollTop
            _top = Math.round(_top / 15) * 15
            setTop(_top)
            setBottom(_top - height)
            lastTopRef.current = _top
            lastBottomRef.current = _top - height

            let _left = e.clientX - YAXIS_WIDTH + scrollLeft - _boundaryLeft
            let _colIndex = Math.floor(_left / colWidth)
            if (_colIndex >= 0) {
                setColIndex(_colIndex)
            }
            setShowPlaceholder(false)
        } else {
            //placeholder và current editing appointment dùng chung biến top và colIndex (vì 2 thằng ko xuất hiện đồng thời)
            //top của placeholder phải làm tròn giờ (phút = 0)
            let _top = e.clientY - _boundaryTop - 30 + scrollTop
            _top = Math.floor(_top / 60) * 60

            let _left = e.clientX - YAXIS_WIDTH + scrollLeft - _boundaryLeft
            let _colIndex = Math.floor(_left / colWidth)

            setTop(_top)
            if (_colIndex < 0) {
                _colIndex = 0
            }
            setColIndex(_colIndex)
            setShowPlaceholder(true)
        }
    }

    let startHour = Math.floor(top / 60) + START_HOUR
    let startMin = Math.round((top - (startHour - START_HOUR) * 60) / 15) * 15
    if (startMin == 60) {
        startMin = 0
        startHour += 1
    }

    let endHour = Math.floor(bottom / 60) + START_HOUR
    let endMin = Math.round((bottom - (endHour - START_HOUR) * 60) / 15) * 15
    if (endMin == 60) {
        endMin = 0
        endHour += 1
    }

    useEffect(() => {
        //cập nhật trực tiếp editingAppointment trên store khi các yếu tố thay đổi
        var _editingAppointment = eventDetailStore.editingAppointments.find(
            (e) => e.key === editingKey
        )
        if (_editingAppointment) {
            _editingAppointment.hour = startHour
            _editingAppointment.min = startMin
            _editingAppointment.duration =
                (endHour - startHour) * 60 + endMin - startMin
            _editingAppointment.placeId = places[colIndex].id
        }
    }, [startHour, startMin, endHour, endMin, editingKey, colIndex])

    return (
        <div className="relative">
            <PlacesContainer places={places} />
            <TimePickerAreaWrap
                className="flex flex-col outline-none"
                tabIndex={1}
                ref={areaRef}
                onKeyDown={handleKeyDown}
                onMouseUp={containerMouseUp}
                onMouseMove={containerMouseMove}
                onMouseDown={containerMouseDown}
            >
                <Columns
                    colWidth={colWidth}
                    scrollLeft={scrollLeft}
                    scrollTop={scrollTop}
                />
                <>
                    {boundaryWidth > 0 && (
                        <>
                            {otherAppointments.map((e) => (
                                <TimelineCard
                                    key={e.key}
                                    style={{
                                        width: `${colWidth - 2 * COL_MARGIN}px`,
                                        height: `${e.duration}px`,
                                        transform: `translate(-${scrollLeft}px, ${
                                            -scrollTop +
                                            30 +
                                            (e.hour - START_HOUR) * 60 +
                                            e.min
                                        }px)`,
                                        marginLeft: `${
                                            colWidth * getColIndex(e.placeId) +
                                            COL_MARGIN / 2 +
                                            YAXIS_WIDTH
                                        }px`,
                                        backgroundColor: getColor(e.typeId),
                                    }}
                                >
                                    {
                                        otherEvents.find(
                                            (item) => item.id === e.eventId
                                        )?.title
                                    }
                                </TimelineCard>
                            ))}

                            <CurrentAppointments
                                top={top}
                                bottom={bottom}
                                colIndex={colIndex}
                                colWidth={colWidth}
                                scrollLeft={scrollLeft}
                                scrollTop={scrollTop}
                                setColIndex={setColIndex}
                                updateTop={updateTop}
                                updateBottom={updateBottom}
                            />
                            {editingAppointment != null && (
                                <>
                                    <Rectangle
                                        top={top}
                                        bottom={bottom}
                                        colIndex={colIndex}
                                        colWidth={colWidth}
                                        scrollLeft={scrollLeft}
                                        scrollTop={scrollTop}
                                        rectangleMouseDown={rectangleMouseDown}
                                    />
                                    <CheckButton
                                        top={top}
                                        colIndex={colIndex}
                                        colWidth={colWidth}
                                        scrollLeft={scrollLeft}
                                        scrollTop={scrollTop}
                                    />
                                    {editingAppointments.length > 1 && (
                                        <RemoveButton
                                            bottom={bottom}
                                            colIndex={colIndex}
                                            colWidth={colWidth}
                                            scrollLeft={scrollLeft}
                                            scrollTop={scrollTop}
                                        />
                                    )}
                                    <TopPin
                                        top={top}
                                        colIndex={colIndex}
                                        colWidth={colWidth}
                                        scrollLeft={scrollLeft}
                                        scrollTop={scrollTop}
                                        topPinMouseDown={topPinMouseDown}
                                        topPinDragStart={topPinDragStart}
                                    />
                                    <BottomPin
                                        bottom={bottom}
                                        colIndex={colIndex}
                                        colWidth={colWidth}
                                        scrollLeft={scrollLeft}
                                        scrollTop={scrollTop}
                                        bottomPinMouseDown={bottomPinMouseDown}
                                        bottomPinDragStart={bottomPinDragStart}
                                    />

                                    <StartTimeLabel
                                        top={top}
                                        scrollTop={scrollTop}
                                    />
                                    <EndTimeLabel
                                        bottom={bottom}
                                        scrollTop={scrollTop}
                                    />
                                </>
                            )}

                            {!editingAppointment && showPlaceholder && (
                                <Placeholder
                                    style={{
                                        width: `${colWidth - 2 * COL_MARGIN}px`,
                                        transform: `translate(${-scrollLeft}px, ${
                                            -scrollTop + 30 + top
                                        }px)`,
                                        marginLeft: `${
                                            colWidth * colIndex +
                                            COL_MARGIN / 2 +
                                            YAXIS_WIDTH
                                        }px`,
                                        backgroundColor: `#FFFFFF3F`,
                                    }}
                                />
                            )}
                        </>
                    )}
                    <HorizontalLines />
                    <YAxis top={top} bottom={bottom} scrollTop={scrollTop} />
                </>
            </TimePickerAreaWrap>

            <DialogSelectEventType
                isOpen={isOpenSelectEventType}
                setIsOpen={setIsOpenSelectEventType}
                onClose={() => {
                    setShowPlaceholder(false)
                }}
                onSelect={(e: EventType) => {
                    var key = nanoid()
                    var timelineItem: Timeline = {
                        duration: 60,
                        eventId: currentEvent.id,
                        hour: startHour,
                        min: startMin,
                        placeId: places[colIndex].id,
                        typeId: e.id,
                        key: key,
                    }
                    eventDetailStore.editingAppointments.push(timelineItem)
                    eventDetailStore.editingKey = key
                    updateTop(timelineItem)
                    updateBottom(timelineItem)
                    setShowPlaceholder(false)
                    setIsOpenSelectEventType(false)
                }}
            />
        </div>
    )
}

export default TimePicker

const TimelineCard = styled.div`
    border-radius: 4px;
    background-color: #fff;
    user-select: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    padding: 4px 8px;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
`

const Placeholder = styled.div`
    height: 60px;
    position: absolute;
    border-radius: 4px;
    user-select: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    border: 1px solid #ccc;
`

const TimePickerAreaWrap = styled.div`
    background-color: #fff;
`
