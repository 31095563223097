import styled from "styled-components"
import { MIN_WIDTH, START_HOUR } from "./TimePicker"

const HorizontalLines = () => {
    const times = Array.from(Array(24 - START_HOUR + 1).keys()).map(e => e + START_HOUR)
    return (
        <div className="pointer-events-none" >
            {times.map((e) => (
                <div key={e} className="flex items-center h-[60px] ">
                    <HLine className="flex-1 ml-5" />
                </div>
            ))}
        </div>
    )
}

export default HorizontalLines

const HLine = styled.hr`
    color: #EAEAEB;
`