import { useEffect } from "react"
import SettingSidebar from "./Sidebar/SettingSidebar"
import { Outlet } from "react-router"

const Settings = () => {
  
    return (
        <div className="flex items-stretch bg-[#f4f6f8] h-[100vh]">
            <SettingSidebar />
            <Outlet />
        </div>
    )
}

export default Settings
