import TableSetupChanges from "modules/EventDetail/TimelineAndNoteTab/TableEstimate/TableSetupChanges/TableSetupChanges"
import { useState } from "react"
import {
    calculateOrderNamesChange,
    calculateOrderTableCountChanges,
} from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { useSnapshot } from "valtio"

const TableCountChanged = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { tableSetup, currentEvent } = useSnapshot(eventDetailStore)
    const { orderNames } = useSnapshot(eventMenuStore)

    const newOrderNames = [...orderNames]
    const oldOrderNames = [...currentEvent.orderNames]
    const orderNameChanges = calculateOrderNamesChange(
        oldOrderNames,
        newOrderNames
    )

    const tableSetupChangeDetails = `${currentEvent.tableSetup}$${tableSetup}`
    const changes = calculateOrderTableCountChanges({
        orderNameChangeDetails: orderNameChanges.details,
        tableSetupChangeDetails,
    })
    return (
        <>
            {changes.changed && (
                <div
                    onClick={() => setIsOpen(true)}
                    className=" text-[orangered] font-normal text-13 ml-3 hover:underline cursor-pointer"
                >
                    +1
                </div>
            )}
            <TableSetupChanges isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}
export default TableCountChanged
