import { formatGuestEstimate } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"

const GuestChangeCount = ({
    onClick,
    onMouseEnter,
}: {
    onClick: (e:  React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseEnter: (e: any) => void
}) => {
    const { guestFrom, guestTo, currentEvent } = useSnapshot(eventDetailStore)

    const changed =
        formatGuestEstimate(guestFrom, guestTo) !==
        formatGuestEstimate(currentEvent.guestFrom, currentEvent.guestTo)

    if (currentEvent.id == 0 || !changed) return null
    return (
        <>
            <div
                onMouseOver={(e) => e.stopPropagation()}
                onMouseOut={(e) => e.stopPropagation()}
                onMouseEnter={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onMouseEnter && onMouseEnter(e)
                }}
                onMouseLeave={(e) => e.stopPropagation()}
                onClick={onClick}
                className="text-[orangered] text-13 font-normal cursor-pointer hover:underline absolute right-[-28px] top-0"
            >
                +1
            </div>
        </>
    )
}

export default GuestChangeCount
