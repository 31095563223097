import { useState } from "react"
import { formatGuestEstimate } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import GuestEstimateChange from "./GuestEstimateChange/GuestEstimateChange"

const GuestEstimateChangeCount = () => {
    const { guestFrom, guestTo, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    const changed =
        formatGuestEstimate(guestFrom, guestTo) !==
        formatGuestEstimate(currentEvent.guestFrom, currentEvent.guestTo)

    if (currentEvent.id == 0 || !changed) return null
    return (
        <>
            <div
                onMouseOver={(e) => e.stopPropagation()}
                onMouseOut={(e) => e.stopPropagation()}
                onMouseEnter={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onMouseLeave={(e) => e.stopPropagation()}
                onClick={() => setIsOpen(true)}
                className="text-[orangered] text-13 font-normal cursor-pointer hover:underline absolute right-[-32px] top-2"
            >
                +1
            </div>
            <GuestEstimateChange isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default GuestEstimateChangeCount
