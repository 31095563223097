//trả về true nếu tìm thấy, false nếu ko tìm thấy
export const findTextAreaAndFocusIfFound = (
    row: Element | null | undefined,
    cellIndex: number
) => {
    if (row) {
        var cell = row.childNodes[cellIndex] as HTMLDivElement
        if (cell) {
            var textArea = cell.firstElementChild as HTMLTextAreaElement
            if (textArea && textArea.tagName === "TEXTAREA" || textArea.tagName === "INPUT") {
                textArea.focus()
                return true
            }
        }
    }
    return false
}

export const moveRight = (cellEl: Element | null | undefined) => {
    var nextCell = cellEl?.parentElement?.nextElementSibling
    if (nextCell) {
        var textArea = nextCell.firstElementChild as HTMLTextAreaElement
        if (textArea && textArea.tagName === "TEXTAREA" || textArea.tagName === "INPUT") {
            textArea.focus()
            return true
        }
    }
    return false
}

export const getCellIndex = (cellEl: Element | null | undefined) => {
    var i = 0
    var currentNode = cellEl?.parentElement
    if (currentNode) {
        var child: Element | null = currentNode
        while (child != null) {
            child = child.previousElementSibling
            if (child != null) {
                i += 1
            }
        }
    }
    return i
}

export const moveLeft = (cellEl: Element | null | undefined) => {
    var prevCell = cellEl?.parentElement?.previousElementSibling
    if (prevCell) {
        var textArea = prevCell.firstElementChild as HTMLTextAreaElement
        if (textArea) {
            textArea.focus()
        }
    }
}
export const moveUp = (cellEl: Element | null | undefined) => {
    var index = getCellIndex(cellEl)
    var previousRow =
        cellEl?.parentElement?.parentElement?.previousElementSibling
    var found = findTextAreaAndFocusIfFound(previousRow, index)
    //trường hợp giữa 2 row có 1 div section thì cần nhảy tiếp lên dòng trên, nếu ko thấy thì stop
    if (!found) {
        findTextAreaAndFocusIfFound(previousRow?.previousElementSibling, index)
    }
}

//trả về true nếu can move, false nếu ko thể move
export const moveDown = (cellEl: Element | null | undefined) => {
    var index = getCellIndex(cellEl)
    var nextRow = cellEl?.parentElement?.parentElement?.nextElementSibling

    var found = findTextAreaAndFocusIfFound(nextRow, index)
    //trường hợp giữa 2 row có 1 div section thì cần nhảy tiếp xuống dòng dưới, nếu ko thấy thì stop
    if (!found) {
        return findTextAreaAndFocusIfFound(nextRow?.nextElementSibling, index)
    }
    return true
}
export const moveDownAtIndex = (
    cellEl: Element | null | undefined,
    index: number
) => {
    var nextRow = cellEl?.parentElement?.parentElement?.nextElementSibling
    var found = findTextAreaAndFocusIfFound(nextRow, index)
    //trường hợp giữa 2 row có 1 div section thì cần nhảy tiếp xuống dòng dưới, nếu ko thấy thì stop
    if (!found) {
        return findTextAreaAndFocusIfFound(nextRow?.nextElementSibling, index)
    }
    return true
}
