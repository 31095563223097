import { PaymentChangeCount } from "modules/payment/PaymentChangeCount"
import PaymentTableBody from "modules/payment/PaymentTableBody"
import PaymentTableHeader from "modules/payment/PaymentTableHeader"
import PaymentTableTop from "modules/payment/PaymentTableTop"
import { styled } from "styled-components"

const PaymentTabRight = () => {
    return (
        <Wrap className="px-5 py-4 rounded-md card-shadow">
            <div className="text-base flex items-center font-bold   pb-5">
                Thanh toán <PaymentChangeCount />
            </div>

            <PaymentTableTop />
            <PaymentTableHeader />
            <PaymentTableBody />
        </Wrap>
    )
}

export default PaymentTabRight

const Wrap = styled.div`
    background-color: #fff;
`
