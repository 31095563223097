import {
    calculateOrderNamesChange,
    calculateOrderTableCountChanges,
    getPureOrderName,
} from "services/utils"

type Props = {
    oldOrderNames: string[]
    newOrderNames: string[]
    oldTableSetup: string
    newTableSetup: string
}

const TableSetupChangeHistory = ({
    oldOrderNames,
    newOrderNames,
    oldTableSetup,
    newTableSetup,
}: Props) => {
    const orderNameChanges = calculateOrderNamesChange(
        oldOrderNames,
        newOrderNames
    )

    //chỉ khi orderNames ko thay đổi thì mới có tính năng hủy thay đổi tableSetup

    const tableSetupChangeDetails = `${oldTableSetup}$${newTableSetup}`
    const tableCountChanges = calculateOrderTableCountChanges({
        orderNameChangeDetails: orderNameChanges.details,
        tableSetupChangeDetails,
    })

    let changedItems = tableCountChanges.changedItems
    const removedOrderNames = orderNameChanges.items.removedItems.map((e) =>
        getPureOrderName(e)
    )

    const addedOrderNames = orderNameChanges.items.addedItems.map((e) =>
        getPureOrderName(e)
    )

    return (
        <div className="px-10 py-5 text-sm">
            {changedItems.map((e, index) => (
                <div key={`${e}-${index}`}>
                    {removedOrderNames.includes(
                        getPureOrderName(e.orderName)
                    ) ? (
                        <div className="mb-6 text-sm">
                            <div className="font-medium text-[orangered]">
                                Bỏ
                            </div>
                            <div className="mt-3 pl-3 ">
                                <span className="text-secondary">- </span>
                                <span className="line-through">
                                    {" "}
                                    {getPureOrderName(e.orderName) ||
                                        "Mặc định"}
                                    :
                                </span>
                                <span className="line-through">
                                    {e.oldTableCounts.length == 0 && (
                                        <>
                                            <span className="font-medium">
                                                0
                                            </span>
                                        </>
                                    )}
                                    {e.oldTableCounts.map((tblCount, idx) => (
                                        <span key={idx}>
                                            {idx > 0 && <span> + </span>}
                                            <span className="font-medium">
                                                {" "}
                                                {tblCount.count}
                                            </span>
                                            &nbsp;
                                            <span className="text-grey">
                                                bàn {tblCount.index}
                                            </span>
                                        </span>
                                    ))}
                                </span>
                            </div>
                        </div>
                    ) : addedOrderNames.includes(
                          getPureOrderName(e.orderName)
                      ) ? (
                        <div className="mb-3">
                            <div className="">Thêm&nbsp;</div>
                            <div className="mt-3 pl-3">
                                <span className="text-secondary">+&nbsp;</span>
                                <span>
                                    {getPureOrderName(e.orderName) ||
                                        "Mặc định"}
                                    :
                                </span>
                                {e.newTableCounts.map((tblCount, idx) => (
                                    <span key={idx}>
                                        {idx > 0 && <span> + </span>}
                                        <span className="font-medium">
                                            {" "}
                                            {tblCount.count}
                                        </span>
                                        &nbsp;
                                        <span className="text-grey">
                                            bàn {tblCount.index}
                                        </span>
                                    </span>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="mb-6 text-sm">
                            <div>
                                {getPureOrderName(e.orderName) || "Mặc định"}:
                            </div>
                            <div className="mt-3 px-3 border-l-[3px] border-[#cacfd9]">
                                <span>
                                    {e.oldTableCounts.length == 0 && (
                                        <span className="font-medium">0</span>
                                    )}
                                    {e.oldTableCounts.map((tblCount, idx) => (
                                        <span key={idx}>
                                            {idx > 0 && <span> + </span>}
                                            <span className="font-medium">
                                                {" "}
                                                {tblCount.count}
                                            </span>
                                            &nbsp;
                                            <span className="text-grey">
                                                bàn {tblCount.index}
                                            </span>
                                        </span>
                                    ))}
                                </span>
                                <span className="text-[#aaa] mx-2">→</span>
                                <span>
                                    {e.newTableCounts.length == 0 && (
                                        <>
                                            <span className="font-medium">
                                                0
                                            </span>
                                        </>
                                    )}
                                    {e.newTableCounts.map((tblCount, idx) => (
                                        <span key={idx}>
                                            {idx > 0 && <span> + </span>}
                                            <span className="font-medium">
                                                {" "}
                                                {tblCount.count}
                                            </span>
                                            &nbsp;
                                            <span className="text-grey">
                                                bàn {tblCount.index}
                                            </span>
                                        </span>
                                    ))}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default TableSetupChangeHistory
