import EventNote from "./EventNote/EventNote"
import EventTimeline from "./EventTimeline/EventTimeline"
import TableEstimate from "./TableEstimate/TableEstimate"

const GeneralTabPartial = ({ eventId }: { eventId: number }) => {
    return (
        <div className="mb-20">
            <EventNote />
            <EventTimeline eventId={eventId} />
            <TableEstimate />
        </div>
    )
}

export default GeneralTabPartial
