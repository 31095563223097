import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import TableReserveChange from "./TableReserveChange/TableReserveChange"

const TableReserveChangeCount = () => {
    const { tableReserve, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    const changed = tableReserve != currentEvent.tableReserve

    if (currentEvent.id == 0 || !changed) return null
    return (
        <>
            <span
                onClick={() => setIsOpen(true)}
                className="text-[orangered] text-13 font-normal ml-2 cursor-pointer hover:underline absolute right-[-32px] top-2"
            >
                +1
            </span>
            <TableReserveChange isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default TableReserveChangeCount
