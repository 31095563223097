import { Button } from "core/button/Button"
import { eventFilterStore } from "stores/eventFilterStore"

const ResetViewButton = () => {
    const handleReset = () => {
        eventFilterStore.resetCurrentView()
    }
    return <Button outline onClick={handleReset}>Reset</Button>
}

export default ResetViewButton
