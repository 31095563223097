import { asyncStorage } from "../../../services/localStorageService"
import { Item } from "../models/types"

const KEY = `${
    import.meta.env.MODE == "development" ? "dev" : "prod"
}_recent_items`

export const getRecentItems = async () => {
    const value = (await asyncStorage.getItem<Item[]>(KEY)) || <Item[]>[]
    return value
}

export const saveRecentItems = async (items: Item[]) => {
    const escapeProxy = items.map((e) => ({ ...e }))
    await asyncStorage.setItem(KEY, escapeProxy)
}
