import IconPlus from "common/icons/IconPlus"
import IconTrash from "common/icons/IconTrash"
import { CellContext, ICellContext } from "core/cells/context"
import { moveDown, moveDownAtIndex } from "core/cells/utils"
import EventPayment from "models/events/eventPayment"
import { nanoid } from "nanoid"
import { useContext } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { paymentStore } from "stores/paymentStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"

const PaymentSummary = () => {
    const { editingPayments, currentPaymentName: currentSection } =
        useSnapshot(paymentStore)
    const { editMode } = eventDetailStore
    const { colKey, data } =
        useContext<ICellContext<EventPayment | null>>(CellContext)

    const addRow = () => {
        paymentStore.addRow(data)
    }

    const deleteRow = () => {
        if (data) {
            const index = data
                ? editingPayments.findIndex((e) => e.key === data.key)
                : 0
            //xử lý khi xóa dòng thì focus vào dòng tiếp theo nếu có
            paymentStore.removeItem(data.key)
            if (editingPayments.length) {
                let key = editingPayments[index]
            }
        }
    }

    return (
        <SummaryWrap className="flex items-center sticky bottom-0 pr-3">
            {true && (
                <>
                    <div
                        className="px-4 text-13 hover:text-link cursor-default flex items-center"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={addRow}
                    >
                        <IconPlus width={18} height={18} /> Thêm dòng
                    </div>
                    <div
                        onMouseDown={(ev) => ev.preventDefault()}
                        className="px-4 text-13 hover:text-red-500 cursor-default flex items-center"
                        onClick={deleteRow}
                    >
                        <IconTrash width={13} height={13} className="mr-2" />{" "}
                        Xóa dòng
                    </div>
                </>
            )}
            <div className="ml-auto flex items-center">
                <span className="text-[##aaabb3] mr-3">Tổng:</span>
                <div className=" font-bold text-base">
                    {editingPayments
                        .filter((e) => e.section === currentSection && e.name)
                        .reduce(
                            (acc, cur) =>
                                acc + (cur.free ? 0 : cur.price) * cur.quantity,
                            0
                        )
                        .formatCurrency()}
                </div>
            </div>
        </SummaryWrap>
    )
}

export default PaymentSummary

const SummaryWrap = styled.div`
    font-size: 13px;
    height: 40px;
    background-color: #f3f4f5;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #ededed;
    z-index: 1;
`
