import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import { saveDraft } from "services/draftService"
import {
    calculateOrderNamesChange,
    calculateOrderTableCountChanges,
    getPureOrderName,
} from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import {
    encodeTableSetup,
    parseMealTableSetupWithDefault,
} from "utils/TableSetupUtils"
import { useSnapshot } from "valtio"
import TableSetupChangeHistory from "./TableSetupChangeHistory"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const TableSetupChanges = ({ isOpen, setIsOpen }: Props) => {
    const { tableSetup, currentEvent } = useSnapshot(eventDetailStore)
    const { orderNames } = useSnapshot(eventMenuStore)

    const newOrderNames = [...orderNames]
    const oldOrderNames = [...currentEvent.orderNames]

    const orderNameChanges = calculateOrderNamesChange(
        oldOrderNames,
        newOrderNames
    )

    //chỉ khi orderNames ko thay đổi thì mới có tính năng hủy thay đổi tableSetup

    const tableSetupChangeDetails = `${currentEvent.tableSetup}$${tableSetup}`
    const tableCountChanges = calculateOrderTableCountChanges({
        orderNameChangeDetails: orderNameChanges.details,
        tableSetupChangeDetails,
    })

    let tableCountChanged = tableCountChanges.changed
    let changedItems = tableCountChanges.changedItems
    const changedOrderNames = changedItems.map((e) => e.orderName)

    const cancelChanges = () => {
        //chỉ cancel changes tableCount only
        const mealTableSetups = parseMealTableSetupWithDefault(tableSetup)

        for (let orderName of changedOrderNames) {
            let index = orderNames.findIndex(
                (item) => getPureOrderName(item) == getPureOrderName(orderName)
            )
            let changedItem = changedItems.find(
                (e) =>
                    getPureOrderName(e.orderName) ===
                    getPureOrderName(orderName)
            )
            if (index != -1 && index < mealTableSetups.length) {
                mealTableSetups[index] = changedItem?.oldTableCounts || []
            }
        }
        //reset lại tableCounts của các order thay đổi về ban đầu
        if (tableCountChanged) {
            eventDetailStore.tableSetup = currentEvent.tableSetup || ""
            saveDraft()
        }
        eventDetailStore.tableSetup = encodeTableSetup(mealTableSetups)
        // eventMenuStore.resetEventMenus()

        saveDraft()
        setIsOpen(false)
    }

    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg   w-[500px] pb-3 mt-[25vh] bg-white">
                    <DialogTitle>Thay đổi bàn dự kiến</DialogTitle>
                    {tableCountChanged && (
                        <TableSetupChangeHistory
                            newOrderNames={newOrderNames}
                            oldOrderNames={oldOrderNames}
                            newTableSetup={tableSetup}
                            oldTableSetup={currentEvent.tableSetup}
                        />
                    )}

                    {tableCountChanged &&
                        !orderNameChanges.items.removedItems.length && (
                            <>
                                <hr />
                                <div className="justify-between px-5 flex pt-3">
                                    <Button outline onClick={cancelChanges}>
                                        Hủy thay đổi
                                    </Button>
                                    <Button
                                        onClick={() => setIsOpen(false)}
                                        className="w-24"
                                    >
                                        OK
                                    </Button>
                                </div>
                            </>
                        )}
                </div>
            </Dialog>
        </>
    )
}

export default TableSetupChanges
