import { proxy } from "valtio"
import { Item } from "./models/types"
import { getRecentItems, saveRecentItems } from "./services/recentListService"

export const recentListStore = proxy({
    recentItems: <Item[]>[],
    async loadRecentItems() {
        const items = await getRecentItems()
        this.recentItems = items
    },
    async addItem(item: Item) {
        if (!this.recentItems.length) {
            await this.loadRecentItems()
        }

        //thay thế item cũ nếu có
        this.recentItems = this.recentItems.filter((e) => e.id != item.id)
        this.recentItems.push(item)
        //lưu lại
        saveRecentItems(this.recentItems)
    },
    async removeItem(id: number) {
        let oldLength = this.recentItems.length
        if (!oldLength) {
            await this.loadRecentItems()
        }
        //thay thế item cũ nếu có

        this.recentItems = this.recentItems.filter((e) => e.id != id)
        if (this.recentItems.length != oldLength) {
            saveRecentItems([...this.recentItems])
        }
    },
})
