
type Props = {
    oldDate: Date
    newDate: Date
}

const DateChangeHistory = ({ oldDate, newDate }: Props) => {
    return (
        <div className="px-12 pb-7 pt-5 text-[15px] text-center">
            <span>{oldDate.formatDMY()}</span>{" "}
            <span className="text-[#aaa] mx-2">→</span>
            <span>{newDate.formatDMY()}</span>
        </div>
    )
}

export default DateChangeHistory
