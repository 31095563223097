import styled from "styled-components"
import { useSnapshot } from "valtio"
import { eventMenuStore } from "stores/eventMenuStore"
import classNames from "classnames"
import { parseOrderName } from "services/orderUtils"

export const ORDER_TABLE_TOP_HEIGHT = 44

const OrderTableTop = () => {
    const { currentOrderName, orderNames, editingMenus } =
        useSnapshot(eventMenuStore)
    return (
     
            <OrderNamesWrap className="flex items-center gap-4 ">
                {orderNames.map((e) => (
                    <OrderName
                        key={e}
                        onClick={() => (eventMenuStore.currentOrderName = e)}
                        className={classNames(
                            "h-7 px-3 flex items-center rounded-full font-medium text-sm cursor-default",
                            {
                                "bg-lightblue text-link active":
                                    e === currentOrderName,
                            }
                        )}
                    >
                        {parseOrderName(e).name || "Mặc định"}
                    </OrderName>
                ))}

               
            </OrderNamesWrap>
           
    
    )
}

export default OrderTableTop

const OrderNamesWrap = styled.div`
    background-color: #fff;
    height: ${ORDER_TABLE_TOP_HEIGHT}px;
`

const OrderName = styled.div`
    &:not(.active) {
        &:hover {
            background-color: #f1f1f1;
        }
    }
`
