import DateInput from "core/datepicker/DateInput"
import { Input, TextArea } from "core/styledComponents"
import SelectCustomer from "modules/common/SelectCustomer"
import SelectEventStatus2 from "modules/common/SelectEventStatus2"
import { useEffect, useRef } from "react"
import { saveDraft } from "services/draftService"
import { dialogStore } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import EventNoteChangeCount from "../TimelineAndNoteTab/EventNote/EventNoteChangeCount/EventNoteChangeCount"
import TableEstimateSimple from "../TimelineAndNoteTab/TableEstimate/TableEstimateSimple"
import DateChangeCount from "./DateChangeCount/DateChangeCount"
import InputDeposit from "./InputDeposit/InputDeposit"
import InputGuestEstimate from "./InputGuestEstimate/InputGuestEstimate"
import InputTableReserve from "./InputTableReserve/InputTableReserve"
import SelectPlaceAndTime from "./SelectPlaceAndTime/SelectPlaceAndTime"
import StatusChangeCount from "./StatusChangeCount/StatusChangeCount"
import TimelineChangeCount from "./TimelineChangeCount/TimelineChangeCount"
import TitleChangeCount from "./TitleChangeCount/TitleChangeCount"
import TableCountChanged from "../TimelineAndNoteTab/TableEstimate/TableCountChanged"
import TableReserveChangeCount from "./InputTableReserve/TableReserveChangeCount/TableReserveChangeCount"
import DepositChangeCount from "./InputDeposit/DepositChangeCount/DepositChangeCount"
import CustomerChangeCount from "./CustomerChangeCount/CustomerChangeCount"

const GeneralTab = () => {
    const { title, selectedDate, eventNote, currentEvent, isFetchedEvent } =
        useSnapshot(eventDetailStore)
    const { showEventPanel } = useSnapshot(dialogStore)

    const timerRef = useRef(0)
    const titleRef = useRef<HTMLInputElement>(null)

    const handleSelectDate = (d: Date) => {
        eventDetailStore.selectedDate = d
        saveDraft()
    }

    const handleChangeEventTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = (e.target.value || "").substring(0, 100)
        eventDetailStore.title = newValue
        // saveEventTitleDraft(newValue, currentEvent.id)
        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(() => {
            saveDraft()
        }, 500)
    }
    const handleChangeEventNote = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const newValue = (e.target.value || "").substring(0, 200)
        eventDetailStore.eventNote = newValue
        // saveEventNoteDraft(newValue, currentEvent.id)
        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(() => {
            saveDraft()
        }, 500)
    }

    useEffect(() => {
        if (showEventPanel) {
            titleRef.current?.focus()
        }
    }, [showEventPanel])
    return (
        <div
            className="mx-auto w-[560px] pt-4 text-sm"
            tabIndex={0}
        >
            <div className="relative">
                <div className="font-medium mb-2 relative">
                    Tên sự kiện
                    <span className="text-red-500">*</span>
                    <div className="absolute right-[-32px] top-0">
                        <TitleChangeCount />
                    </div>
                </div>
            </div>
            <Input
                value={title}
                onChange={handleChangeEventTitle}
                ref={titleRef}
                placeholder="Tối đa 100 ký tự"
            />
            <div className="font-medium mb-2 mt-5 relative">
                Ghi chú
                <div className="absolute right-[-32px] top-0">
                    <EventNoteChangeCount />
                </div>
            </div>
            <TextArea
                value={eventNote}
                placeholder="Tối đa 200 ký tự"
                onChange={handleChangeEventNote}
                minRows={3}
            />

            <div className="font-medium  mb-2 mt-5 relative">
                Thời gian
                {currentEvent.id > 0 && (
                    <div className="absolute right-[-32px] top-0">
                        <DateChangeCount />
                    </div>
                )}
            </div>
            <DateInput
                selectedDate={selectedDate}
                onSelect={handleSelectDate}
                dateCounts={[]}
            />

            <div className="relative">
                <SelectPlaceAndTime />
                {currentEvent.id > 0 && <TimelineChangeCount />}
            </div>
            <div className="font-medium mb-2 mt-5 relative ">
                Khách hàng
                <CustomerChangeCount className="absolute right-[-28px] top-0" />
            </div>
            <SelectCustomer />

            <div className="font-medium mb-2 mt-5">Trạng thái</div>
            <div className="relative">
                <SelectEventStatus2 />
                {currentEvent.id > 0 && (
                    <StatusChangeCount className=" right-[-36px] top-2" />
                )}
            </div>
            <div className="font-medium mb-2 mt-5">Khách dự kiến</div>
            <InputGuestEstimate />
            <div className="font-medium mb-2 mt-5">
                <div className="relative">
                    Bàn dự kiến
                    {currentEvent.id > 0 && isFetchedEvent && (
                        <div className="absolute right-[-32px] top-1">
                            <TableCountChanged />
                        </div>
                    )}
                </div>
            </div>
            <TableEstimateSimple />
            <div className="font-medium mb-2 mt-5 relative">
                Bàn dự phòng{" "}
                {currentEvent.id > 0 && <TableReserveChangeCount />}
            </div>
            <InputTableReserve />
            <div className="font-medium mb-2 mt-5 relative">
                Đặt cọc {currentEvent.id > 0 && <DepositChangeCount />}
            </div>
            <InputDeposit />
        </div>
    )
}

export default GeneralTab
