import { parseColor } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import { COL_MARGIN, YAXIS_WIDTH } from "./TimePicker"


type Props = {
    scrollLeft: number
    scrollTop: number
    top: number
    bottom: number
    colIndex: number
    colWidth: number
    rectangleMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}
const Rectangle = ({ scrollLeft, scrollTop, top, bottom, colIndex, colWidth, rectangleMouseDown

}: Props) => {

    const { eventTypes } = useSnapshot(rootStore)
    const { editingKey, editingAppointments, title } = useSnapshot(eventDetailStore)
    const editingAppointment = editingAppointments.find(e => e.key === editingKey)

    const getColor = (typeID: number) => {
        var color = eventTypes.find(e => e.id === typeID)?.color || ''
        return parseColor(color)
    }
    return (
        <>
            {
                !!editingKey && !!editingAppointment && (

                    <TimelineCard
                        className="cursor-move"
                        key={editingKey}
                        style={{
                            width: `${colWidth - 2 * COL_MARGIN}px`,
                            height: `${bottom - top}px`,
                            transform: `translate(${-scrollLeft + colWidth * colIndex + COL_MARGIN / 2 + YAXIS_WIDTH}px, ${-scrollTop + 30 + top}px)`,
                            backgroundColor: `#FFFFFF3F`,
                            border: `1px dashed ${getColor(editingAppointment.typeId)}`,
                            color: `${getColor(editingAppointment.typeId)}`,
                        }}
                        onMouseDown={rectangleMouseDown}
                    >
                        {eventTypes.find(item => item.id === editingAppointment.typeId)?.name}
                        {/* {title || 'Chưa có tiêu đề'} */}
                    </TimelineCard>
                )
            }
        </>
    )
}

export default Rectangle


const TimelineCard = styled.div`
    border-radius: 4px;
    background-color:#fff;
    user-select: none;
    box-shadow: 0 1px 5px rgba(0,0,0,0.3);
    padding: 4px 8px;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
`