import {
    FloatingFocusManager,
    FloatingOverlay,
    useFloating,
    FloatingPortal,
    useDismiss,
} from "@floating-ui/react"
import { ReactNode, useState } from "react"
import { styled } from "styled-components"
import { createContext } from "react"
import React from "react"

export const dialogContext = createContext<{
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}>({
    isOpen: false,
    setIsOpen: () => {},
})

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    children: ReactNode
    closeOnClickBackdrop?: boolean
    escapeToClose?: boolean
}

const Dialog = ({
    isOpen,
    setIsOpen,
    closeOnClickBackdrop = true,
    children,
    escapeToClose= true
}: Props) => {
    const { refs, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
    })

    useDismiss(context, { outsidePress: false, })

    return (
        <dialogContext.Provider
            value={{ isOpen: isOpen, setIsOpen: setIsOpen }}
        >
            <FloatingPortal>
                {isOpen && (
                    <StyledFloatingOverlay
                        ref={refs.setReference}
                        className="z-[100] fade-in "
                        lockScroll
                        onMouseDown={(ev) => {
                            ev.preventDefault()
                            ev.stopPropagation()
                        }}
                        onClick={() =>
                            closeOnClickBackdrop ? setIsOpen(false) : null
                        }
                        style={{ background: "rgba(0, 0, 0, 0.2)" }}
                    >
                        <FloatingFocusManager context={context}>
                            <div
                                className="m-auto w-fit"
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {children}
                            </div>
                        </FloatingFocusManager>
                    </StyledFloatingOverlay>
                )}
            </FloatingPortal>
        </dialogContext.Provider>
    )
}

export default Dialog

const StyledFloatingOverlay = styled(FloatingOverlay)`
    transition: all 0.25s ease;
`
