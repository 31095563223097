import { TableCount } from "models/table_count"
import { removeSpaces, stripAccents } from "services/utils"

const formatTableSetup = (tableSetup: string) => {
    var _tables = parseMealTableSetup(tableSetup)
    var finalTableCounts = sumTableCount(_tables)
    return finalTableCounts.length
        ? finalTableCounts.map((e) => `${e.count} bàn ${e.index}`).join(", ")
        : ""
}

export const sumTableCount = (arr: TableCount[][]) => {
    let result: TableCount[] = []
    for (let tableCounts of arr) {
        for (let tblCount of tableCounts) {
            let found = result.find((e) => e.index === tblCount.index)
            if (found) {
                found.count += tblCount.count
            } else {
                result.push({ count: tblCount.count, index: tblCount.index })
            }
        }
    }
    result.sort((a, b) => a.index - b.index)
    return result.filter((e) => e.count)
}
// export const checkTableSetupChange = (s1: string, s2: string) => {
//     var list1 = parseTableSetup(s1)
//     var list2 = parseTableSetup(s2)
//     list1.sort((a, b) => a.index - b.index)
//     list2.sort((a, b) => a.index - b.index)
//     if (list1.length != list2.length) return true
//     for (let item of list1) {
//         var found = list2.find(
//             (e) => e.count == item.count && e.index == item.index
//         )
//         if (!found) return true
//     }

//     return false
// }

export const extractOrderName = (s: string) =>
    s.includes("^") ? s.split("^")[0] : s

const getTableSetupString = (tables: TableCount[]) => {
    return tables
        .filter((e) => e.index && e.count)
        .map((e) => `${e.count}b${e.index}`)
        .join(".")
}

const validateTableSetupString = (tableSetupString: string) => {
    if (!tableSetupString.trim()) return true
    tableSetupString = stripAccents(removeSpaces(tableSetupString))

    var separator = "."
    if (tableSetupString.includes(",")) {
        separator = ","
    }

    for (var item of tableSetupString.split(separator)) {
        var isValid = /^\d+\s*(b|ban)\s*\d+$/.test(item)
        if (!isValid) {
            return false
        }
    }
    return true
}

export const encodeTableSetup = (mealTableSetups: TableCount[][]) => {
    return mealTableSetups
        .map((meal) => {
            var tblCounts = meal.filter((e) => e.count)
            if (!tblCounts.length) return ""
            return tblCounts.map((e) => `${e.count}b${e.index}`).join(".")
        })
        .join("|")
}

export const useTableSetupChangeCount = () => {}

export const checkTableSetupChange = (oldValue: string, newValue: string) => {
    return (
        encodeTableSetup(parseMealTableSetup(oldValue)) !==
        encodeTableSetup(parseMealTableSetup(newValue))
    )
}
export const parseTableSetup = (tableSetup: string) => {
    if (!tableSetup) return []
    tableSetup = stripAccents(removeSpaces(tableSetup))
    var separator = "."
    if (tableSetup.includes(",")) {
        separator = ","
    }
    var list = <TableCount[]>[]
    for (var item of tableSetup.split(separator)) {
        if (item.includes("ban")) {
            var index = Number(item.split("ban")?.[1]) || 0
            var count = Number(item.split("ban")?.[0]) || 0
            if (index && count)
                list.push({
                    index,
                    count,
                })
        } else {
            var index = Number(item.split("b")?.[1]) || 0
            var count = Number(item.split("b")?.[0]) || 0
            if (index && count)
                list.push({
                    index,
                    count,
                })
        }
    }
    return list
}

export const parseTableCount = (fragment: string) => {
    if (!fragment) return [{ index: 10, count: 0 }]
    fragment = stripAccents(removeSpaces(fragment))
    var separator = "."
    if (fragment.includes(",")) {
        separator = ","
    }
    var list = <TableCount[]>[]
    let totalCount = 0

    for (var item of fragment.split(separator)) {
        if (item.includes("ban")) {
            var index = Number(item.split("ban")?.[1]) || 0
            var count = Number(item.split("ban")?.[0]) || 0
            list.push({
                index,
                count,
            })
            totalCount += count
        } else {
            var index = Number(item.split("b")?.[1]) || 0
            var count = Number(item.split("b")?.[0]) || 0
            list.push({
                index,
                count,
            })
            totalCount += count
        }
    }
    if (totalCount > 0) return list.filter((e) => e.count)
    return list
}

export const parseMealTableSetup = (tableSetup: string) => {
    if (!tableSetup) return [[]]
    const arr = tableSetup.split("|").map((e) => parseTableCount(e))
    return arr
}
export const parseMealTableSetupWithDefault = (tableSetup: string) => {
    if (!tableSetup) return [[]]
    var meals = parseMealTableSetup(tableSetup).map((e) =>
        e.length == 0 ? [{ index: 10, count: 0 }] : e
    )
    return meals
}
export const parseMealTableSetupWithDefault2 = (tableSetup: string) => {
    if (!tableSetup) return [[{ count: 0, index: 10 }]]
    var meals = parseMealTableSetup(tableSetup).map((e) =>
        e.length == 0 ? [{ index: 10, count: 0 }] : e
    )
    return meals
}

const TableSetupUtils = {
    formatTableSetup,
    getTableSetupString,
    validateTableSetupString,
    parseTableSetup,
    sumTableCount,
}

export default TableSetupUtils
