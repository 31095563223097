import { NumericInput } from "core/styledComponents"
import { useEffect, useRef, useState } from "react"
import { NumberFormatValues } from "react-number-format"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"

const InputDeposit = () => {
    const { deposit } = useSnapshot(eventDetailStore)
    const [value, setValue] = useState(deposit)
    useEffect(() => {
        setValue(deposit)
    }, [deposit])

    const timerRef = useRef(0)

    const handleChange = (values: NumberFormatValues) => {
        const _value = values.floatValue
        setValue(_value || 0)
        eventDetailStore.deposit = _value || 0

        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(() => {
            saveDraft()
        }, 500)
    }

    //nếu gọi savedraft ở handleChange thì sẽ bị lỗi vì hàm onValueChange gọi ngay từ đầu
    const handleBlur = () => {
        // saveDepositDraft(deposit, eventDetailStore.currentEvent.id)
        window.clearTimeout(timerRef.current)
    }

    return (
        <NumericInput
            placeholder="0"
            value={value || ""}
            autoFocus
            onBlur={handleBlur}
            allowLeadingZeros
            thousandSeparator=","
            onValueChange={handleChange}
        />
    )
}

export default InputDeposit
