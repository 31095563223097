import styled from "styled-components"
import { cellBorderColor } from "./config"



export const Section = styled.div`
    background-color: #f6f7f8;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid ${cellBorderColor};
    flex-shrink: 0;
    color: #9397a6;
    padding-left: 44px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    height: 40px;
`