import {
    calculateOrderNamesChange,
    extractTableType,
    getPureOrderName,
} from "services/utils"

type Props = {
    newOrderNames: string[]
    oldOrderNames: string[]
}

const OrderNameChangeHistory = ({ oldOrderNames, newOrderNames }: Props) => {
    const orderNameChanges = calculateOrderNamesChange(
        oldOrderNames,
        newOrderNames
    )

    return (
        <div className="px-5 pt-2 pb-5  text-sm">
            <div className="mt-2">
                {orderNameChanges.items.addedItems.length > 0 && (
                    <div className="text-sm mb-5">
                        <div className=" mt-1 mb-1 text-secondary">Thêm thực đơn:&nbsp;</div>

                        {orderNameChanges.items.addedItems.map((e, index) => (
                            <div key={index} className="ml-3">
                                <span className="text-secondary">+&nbsp;</span>
                                <span className="">
                                    {getPureOrderName(e) || "Mặc định"}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
                {orderNameChanges.items.updatedItems.length > 0 && (
                    <div  className="text-sm mb-5">
                        <div className="text-secondary mt-1 mb-1">
                            Cập nhật thực đơn
                        </div>
                        {orderNameChanges.items.updatedItems.map((e, index) => (
                            <div key={index}>
                                <div className="pl-3">
                                    <span className="text-secondary">+</span>{" "}
                                    <span className="">
                                        {getPureOrderName(e) ||
                                            "Thực đơn mặc định"}
                                        :
                                    </span>
                                    &nbsp;
                                    <span className="text-secondary">
                                        suất bàn {extractTableType(e)}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {orderNameChanges.items.removedItems.length > 0 && (
                    <div  className="text-sm mb-5">
                        <div className="mt-1 mb-1 text-red-500">Bỏ thực đơn:&nbsp;</div>
                        {orderNameChanges.items.removedItems.map((e, index) => (
                            <div key={index}>
                                <span className="text-secondary">-&nbsp;</span>
                                <span className="pl-1">
                                    <span className="line-through">
                                        {getPureOrderName(e) || "Mặc định"}
                                    </span>
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderNameChangeHistory
