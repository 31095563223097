import classNames from "classnames"
import { AppointmentItem } from "models/events/appointment"
import { useCallback, useEffect, useRef, useState } from "react"
import { DialogName } from "services/constants"
import { openDialog } from "stores/dialogStore"
import { styled } from "styled-components"
import BottomPin from "./BottomPin"
import EndTimeLabel from "./EndTimeLabel"
import HorizontalLines from "./HorizontalLines"
import StartTimeLabel from "./StartTimeLabel"
import TopPin from "./TopPin"
import YAxis from "./YAxis"
import { getEndHour } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"

export const YAXIS_WIDTH = 46
export const COL_MARGIN = 4 //mỗi bên 4
export const MIN_WIDTH = 940 //chiều rộng tối thiểu của vùng thao tác, nếu bé hơn sẽ xuất hiện scroll
export const START_HOUR = 7
export const PLACES_CONTAINER_HEIGHT = 40

const TimePickerSingle = ({
    activeKey,
    currentAppointments,
    otherAppointments,
    onChange,
}: {
    activeKey: string
    currentAppointments: AppointmentItem[]
    otherAppointments: AppointmentItem[]
    onChange: (
        startHour: number,
        startMin: number,
        endHour: number,
        endMin: number
    ) => void
}) => {
    const editMode = true
    //editingKey là key của timelineItem được sinh ra ở local để quản lý appointment của event

    //tọa độ mép trên vùng thao tác
    const areaRef = useRef<HTMLDivElement>(null)

    //top là vị trí top của rectangle, với quy ước  0 là giá trị khi ngang với START_HOUR
    const [top, setTop] = useState(0)
    const [bottom, setBottom] = useState(0) //vị trí bottom của rectangle
    const lastTopRef = useRef(0)
    const lastBottomRef = useRef(0)

    //dùng để tính toán vị trí của placeholder khi mousemove (tạm bỏ qua logic này)
    // const [placeholderTop, setPlaceholderTop] = useState(0)
    // const [placeholderColIndex, setPlaceholderColIndex] = useState(0)

    const rectangleMouseDownRef = useRef(false)
    const topPinMouseDownRef = useRef(false)
    const bottomPinMouseDownRef = useRef(false)

    //khi mouse down vào vùng trống để tạo thêm lịch sẽ hiện 1 placeholder
    const [showPlaceholder, setShowPlaceholder] = useState(false)
    //lưu lại giá trị clientY khi mới mouse down
    const lastClientYRef = useRef(0)

    //scrollTop và scrollLeft của vùng thao tác (container)
    const [scrollLeft, setScrollLeft] = useState(0)
    const [scrollTop, setScrollTop] = useState(0)

    var activeAppointment = currentAppointments.find((e) => e.key === activeKey)

    const updateTop = (e: AppointmentItem) => {
        var _top = (e.hour - START_HOUR) * 60 + e.min
        setTop(_top)
        lastTopRef.current = _top
    }
    const updateBottom = (e: AppointmentItem) => {
        var _top = (e.hour - START_HOUR) * 60 + e.min
        var _bottom = _top + e.duration
        setBottom(_bottom)
        lastBottomRef.current = _bottom
    }

    useEffect(() => {
        if (activeAppointment) {
            updateTop(activeAppointment)
            updateBottom(activeAppointment)
        }
    }, [
        activeAppointment?.hour || 10,
        activeAppointment?.min || 0,
        activeAppointment?.duration || 120,
        activeKey,
    ])

    const rectangleMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (!editMode) {
            return
        }
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = true
            topPinMouseDownRef.current = false
            bottomPinMouseDownRef.current = false
            lastTopRef.current = top
            lastClientYRef.current = e.clientY
        }

        registerMouseMoveListener()
        return false
    }

    const registerMouseMoveListener = () => {
        document.addEventListener("mousemove", mouseMove)
        document.addEventListener("mouseup", function mouseup(e) {
            document.removeEventListener("mousemove", mouseMove)
        })
    }
    const topPinMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = false
            bottomPinMouseDownRef.current = false
            topPinMouseDownRef.current = true
            lastTopRef.current = top
            lastBottomRef.current = bottom
            lastClientYRef.current = e.clientY

            registerMouseMoveListener()
        }
    }
    const topPinDragStart = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = false
            bottomPinMouseDownRef.current = false
            topPinMouseDownRef.current = true
            lastTopRef.current = top
            lastBottomRef.current = bottom
            lastClientYRef.current = e.clientY

            registerMouseMoveListener()
        }
    }

    const bottomPinMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = false
            topPinMouseDownRef.current = false
            bottomPinMouseDownRef.current = true
            lastTopRef.current = top
            lastBottomRef.current = bottom
            lastClientYRef.current = e.clientY
            registerMouseMoveListener()
        }
    }
    const bottomPinDragStart = (
        e: React.DragEvent<HTMLDivElement>
    ) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.button === 0) {
            rectangleMouseDownRef.current = false
            topPinMouseDownRef.current = false
            bottomPinMouseDownRef.current = true
            lastTopRef.current = top
            lastBottomRef.current = bottom
            lastClientYRef.current = e.clientY
            registerMouseMoveListener()
        }
    }

    const containerMouseUp = () => {
        if (!editMode) {
            return
        }
        rectangleMouseDownRef.current = false
        topPinMouseDownRef.current = false
        bottomPinMouseDownRef.current = false
        var roundTop = Math.round(top / 15) * 15
        var rountBottom = Math.round(bottom / 15) * 15

        setTop(roundTop)
        setBottom(rountBottom)
        lastTopRef.current = roundTop
        lastBottomRef.current = rountBottom
        // //xử lý làm tròn mouseTop
        // setShowPlaceholder(false)
        handleChange(roundTop, rountBottom)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            // eventDetailStore.toggleViewMode()
        }
    }
    function mouseMove(e: MouseEvent) {
        if (rectangleMouseDownRef.current) {
            const height = lastTopRef.current - lastBottomRef.current
            const deltaY = lastClientYRef.current - e.clientY

            const _top = lastTopRef.current - deltaY

            setTop(_top)
            setBottom(_top - height)
        } else if (topPinMouseDownRef.current) {
            const deltaY = lastClientYRef.current - e.clientY

            const _top = lastTopRef.current - deltaY
            if (_top + 30 <= bottom) {
                setTop(_top)
                handleChange(_top, bottom)
            }
        } else if (bottomPinMouseDownRef.current) {
            const deltaY = lastClientYRef.current - e.clientY

            const _bottom = lastBottomRef.current - deltaY
            if (_bottom - 30 >= top) {
                setBottom(_bottom)
            }
        }
    }

    const containerMouseDown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (e.button !== 0) {
            return
        }

        //TODO: tính toán col index
        const height = lastTopRef.current - lastBottomRef.current
        // const deltaY = lastClientYRef.current - e.clientY

        let _top =
            e.clientY - 120 + (areaRef.current?.parentElement?.scrollTop || 0)
        _top = Math.round(_top / 15) * 15
        setTop(_top)
        setBottom(_top - height)
        lastTopRef.current = _top
        lastBottomRef.current = _top - height

        handleChange(_top, _top - height)
    }

    const handleChange = (_top: number, _bottom: number) => {
        let startHour = Math.floor(_top / 60) + START_HOUR
        let startMin =
            Math.round((_top - (startHour - START_HOUR) * 60) / 15) * 15
        if (startMin == 60) {
            startMin = 0
            startHour += 1
        }

        let endHour = Math.floor(_bottom / 60) + START_HOUR
        let endMin =
            Math.round((_bottom - (endHour - START_HOUR) * 60) / 15) * 15
        if (endMin == 60) {
            endMin = 0
            endHour += 1
        }
        onChange(startHour, startMin, endHour, endMin)
    }
    // useEffect(() => {
    //     //cập nhật trực tiếp editingAppointment trên store khi các yếu tố thay đổi
    //     var _editingAppointment = eventDetailStore.editingAppointments.find(
    //         (e) => e.key === editingKey
    //     )
    //     if (_editingAppointment) {
    //         _editingAppointment.hour = startHour
    //         _editingAppointment.min = startMin
    //         _editingAppointment.duration =
    //             (endHour - startHour) * 60 + endMin - startMin
    //         _editingAppointment.placeId = places[colIndex].id
    //     }
    // }, [startHour, startMin, endHour, endMin, editingKey, colIndex])

    return (
        <div className="relative h-[calc(100%-40px)] overflow-y-auto">
            <TimePickerAreaWrap
                className="flex flex-col outline-none"
                tabIndex={1}
                ref={areaRef}
                onKeyDown={handleKeyDown}
                onMouseUp={containerMouseUp}
                // onMouseMove={containerMouseMove}
                onMouseDown={containerMouseDown}
            >
                <>
                    {otherAppointments.map((e) => (
                        <TimelineCard
                            key={e.key}
                            style={{
                                height: `${e.duration}px`,
                                transform: `translateY(${-scrollTop +
                                    30 +
                                    (e.hour - START_HOUR) * 60 +
                                    e.min
                                    }px)`,

                                backgroundColor: e.color,
                            }}
                        >
                            {e.title}
                        </TimelineCard>
                    ))}

                    {currentAppointments
                        .filter((e) => e.key !== activeKey)
                        .map((e) => (
                            <TimelineCard
                                key={e.key}
                                style={{
                                    height: `${e.duration}px`,
                                    transform: `translateY(${-scrollTop +
                                        30 +
                                        (e.hour - START_HOUR) * 60 +
                                        e.min
                                        }px)`,
                                    backgroundColor: `${e.color}3F`,
                                    border: `1px solid ${e.color}`,
                                    pointerEvents: `none`,
                                    color: e.color,
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(ev) => {
                                    // updateTop(e)
                                    // updateBottom(e)
                                    // ev.stopPropagation()
                                    eventDetailStore.editingKey = e.key || ''
                                }}
                            >
                                {e.title || "Chưa có tiêu đề"}
                            </TimelineCard>
                        ))}

                    {!!activeAppointment && (
                        <>
                            <TimelineCard
                                className="cursor-move"
                                key={activeAppointment.key}
                                style={{
                                    height: `${bottom - top}px`,
                                    transform: `translateY(${-scrollTop + 30 + top
                                        }px)`,
                                    backgroundColor: `#FFFFFF3F`,
                                    border: `1px solid ${activeAppointment.color}`,
                                    color: `${activeAppointment.color}`,
                                }}
                                onMouseDown={rectangleMouseDown}
                            >
                                {activeAppointment.title || "Chưa có tiêu đề"}
                            </TimelineCard>

                            <TopPin
                                appointment={activeAppointment}
                                top={top}
                                scrollTop={scrollTop}
                                topPinMouseDown={topPinMouseDown}
                                topPinDragStart={topPinDragStart}

                            />
                            <BottomPin
                                appointment={activeAppointment}
                                bottom={bottom}
                                scrollTop={scrollTop}
                                bottomPinMouseDown={bottomPinMouseDown}
                                bottomPinDragStart={bottomPinDragStart}
                            />

                            <StartTimeLabel top={top} scrollTop={scrollTop} />
                            <EndTimeLabel
                                bottom={bottom}
                                scrollTop={scrollTop}
                            />
                        </>
                    )}

                    <HorizontalLines />
                    <YAxis top={top} bottom={bottom} scrollTop={scrollTop} />
                </>
            </TimePickerAreaWrap>
        </div>
    )
}

export default TimePickerSingle

const TimelineCard = styled.div`
    border-radius: 4px;
    background-color: #fff;
    user-select: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    padding: 4px 8px;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    width: calc(100% - 60px);
    margin-left: 60px;
`

const TimePickerAreaWrap = styled.div`
    background-color: #fff;
`
