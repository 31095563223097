import { Button } from "core/button/Button"
import CloseButton from "core/button/CloseButton"
import Dialog from "core/dialog/Dialog"
import { Input } from "core/styledComponents"
import { useCallback, useEffect, useState } from "react"
import { saveDraft } from "services/draftService"
import { eventMenuStore } from "stores/eventMenuStore"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DialogAddOrder = ({ isOpen, setIsOpen }: Props) => {
    const [name, setName] = useState("")

    const handleAdd = async () => {
        eventMenuStore.addEmptyOrder(name)
        saveDraft()
        setIsOpen(false)
    }

    const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
        if(e.key == 'Enter' && name) {
            handleAdd();
        }
    },[name])

    useEffect(() => {
        setName("")
    }, [isOpen])

    return (
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[25vh] bg-white">
                <div className=" pt-6 pb-6 px-14 flex-col flex items-center relative">
                    <CloseButton
                        onClick={() => setIsOpen(false)}
                        className="absolute top-2 right-3"
                    />
                    <div className="text-base font-semibold text-primary mb-5 text-center">
                        Thêm thực đơn
                    </div>
                    <Input
                        className="text-md"
                        placeholder="Ví dụ: Bữa trưa"
                        autoFocus={true}
                        value={name}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        onClick={handleAdd}
                        className="mt-4  w-full "
                        disabled={!name}
                    >
                        Xong
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default DialogAddOrder
