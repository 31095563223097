import { Timeline } from "models/timeline"
import { TimeplinePlus } from "models/timelinePlus"

const convertToEventStartTime = (date: Date) => {
    const year = date.getFullYear().toString().substring(2, 4)
    const month = (date.getMonth() + 1).padZero()
    const day = date.getDate().padZero()
    const hour = date.getHours().padZero()
    const min = date.getMinutes().padZero()
    return +`${year}${month}${day}${hour}${min}`
}


const parseTimelinePlus = (timeline: string) => {
    if (!timeline || !timeline.trim()) return []

    var list: TimeplinePlus[] = []
    var startTime = 0
    for (var str of timeline.split("|")) {
        var arr = str.split(",")
        if (arr.length < 4) return []

        var hhmm = arr[0]
        var duration = Number(arr[1])
        var typeId = Number(arr[2])
        var placeId = Number(arr[3])
        if (arr.length == 5) {
            startTime = Number(arr[4])
        }

        var hour = +hhmm.split(":")[0]
        var min = +hhmm.split(":")[1]
        list.push({
            hour,
            min,
            placeId,
            duration,
            startTime: 0,
            typeId,
        })
        for (var item of list) {
            item.startTime = startTime
        }
        list.sort((a, b) => a.hour * 60 + a.min - b.hour * 60 - b.min)
    }
    return list
}

const calculateTimeline = (timelines: Timeline[], date: Date) => {
    timelines.sort((a, b) => a.hour * 60 + a.min - b.hour * 60 - b.min)

    var earliestAppointment = timelines[0]
    var hour = earliestAppointment.hour.padZero()
    var min = earliestAppointment.min.padZero()
    var year = `${date.getFullYear()}`.substring(2, 4)
    var month = (date.getMonth() + 1).padZero()
    var day = date.getDate().padZero()
    var startTime = +`${year}${month}${day}${hour}${min}`
    var timeline = timelines
        .map((e) => `${e.hour}:${e.min},${e.duration},${e.typeId},${e.placeId}`)
        .join("|")
    return {
        timeline,
        startTime,
    }
}

const EventUtils = {
    convertToEventStartTime,
    parseTimelinePlus,
    calculateTimeline
}

export default EventUtils
