import styled from "styled-components"
import UserProfileMenu from "./UserProfileMenu"
import { extractModule } from "services/utils"
import classNames from "classnames"
import { NavLink } from "react-router-dom"
import { useCallback, useRef, useState } from "react"
import { useClickOutside } from "core/hooks"
import HeaderMenu from "./HeaderMenu"
import { AppRoutes } from "services/constants"
import IconSetting from "common/icons/IconSetting"
import IconSearch from "common/icons/IconSearch"

import IconHistory from "common/icons/IconHistory"
import RecentList from "modules/recent_list/RecentList"
import GlobalSearch from "modules/global_search/GlobalSearch"

const AppHeader = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenGlobalSearch, setIsOpenGlobalSearch] = useState(false)

    const module = extractModule()
    const isModuleSuKien =
        module === "/" ||
        module.includes("/su-kien") ||
        module.includes("/lich-thang")
    const isModuleBaoCao = module.includes("/bao-cao")
    const isModuleKhachhang = module.includes("/khach-hang")

    const handleClickOutside = useCallback(() => {
        setIsOpen(false)
    }, [])
    const headerRef = useRef<HTMLElement>(null)
    useClickOutside(handleClickOutside, headerRef)

    return (
        <>
            <HeaderWrap className="flex items-center">
                <img src="/images/booking.svg" />
                <span className="text-lg font-medium text-white ml-3 mr-16">
                    Sự kiện
                </span>
                <div className="flex items-center">
                    <HeaderMenu
                        active={isModuleSuKien}
                        dropdown={
                            <>
                                <DropdownMenu
                                    to={`/`}
                                    className="dropdown-menu"
                                >
                                    Danh sách
                                </DropdownMenu>
                                <DropdownMenu
                                    to={`${AppRoutes.EVENT_MONTH_VIEW}`}
                                    className="dropdown-menu"
                                >
                                    Lịch tháng
                                </DropdownMenu>
                            </>
                        }
                    >
                        Sự kiện
                    </HeaderMenu>

                    <MenuItemsWrap
                        to={"/bao-cao"}
                        className="menu-items-wrap  text-13  flex items-center"
                    >
                        <div
                            className={classNames("menu-item font-semibold", {
                                active: isModuleBaoCao,
                            })}
                        >
                            Báo cáo
                        </div>
                    </MenuItemsWrap>
                    <MenuItemsWrap
                        to={"/khach-hang"}
                        className="menu-items-wrap  text-13  flex items-center"
                    >
                        <div
                            className={classNames("menu-item font-semibold", {
                                active: isModuleKhachhang,
                            })}
                        >
                            Khách hàng
                        </div>
                    </MenuItemsWrap>
                </div>

                <Right className="flex items-center ml-auto gap-2">
                    <Menu
                        onClick={() => setIsOpenGlobalSearch(true)}
                        className="flex items-center justify-center"
                    >
                        <IconSearch className="text-base" />
                    </Menu>
                    <Menu
                        onClick={() => setIsOpen(true)}
                        className="flex items-center justify-center"
                    >
                        <IconHistory className="text-base" />
                    </Menu>
                    <NavMenu
                        className="flex items-center justify-center"
                        to={`${AppRoutes.SETTINGS}/ho-so`}
                    >
                        <IconSetting className="text-base" />
                    </NavMenu>
                    <div className=" mr-3">
                        <UserProfileMenu />
                    </div>
                </Right>
            </HeaderWrap>
            <RecentList isOpen={isOpen} setIsOpen={setIsOpen} />
            <GlobalSearch
                isOpen={isOpenGlobalSearch}
                setIsOpen={setIsOpenGlobalSearch}
            />
        </>
    )
}

export default AppHeader

const HeaderWrap = styled.div`
    height: 54px;
    padding-left: 20px;
    background-color: var(--primary-active);
    color: white;
    position: relative;
    z-index: 11;
`

const DropdownMenu = styled(NavLink)`
    &.active {
        background-color: #33475b;
        .icon {
            color: var(--brand);
        }
    }
    &:hover {
        background-color: #33475b;
    }
`

const MenuItemsWrap = styled(NavLink)`
    position: relative;
    color: #fff;
    &:hover {
        color: #fff;
    }
    .menu-item {
        padding: 0 12px;
        height: 54px;
        padding-top: 18px;
        /* border-bottom: 2px solid transparent; */
        cursor: pointer;
        &.active {
            background-color: #253342;
            /* border-bottom: 2px solid var(--brand); */
            .icon {
                color: var(--brand);
            }
        }
        &:hover {
            background-color: #253342;
        }
    }
`

const Right = styled.div``

const Menu = styled.div`
    width: 45px;
    height: 38px;
    color: #fff;
    &:hover {
        color: #fff;
    }
    cursor: pointer;
    &:hover {
        background-color: #253342;
        border-radius: 3px;
    }
`

const NavMenu = styled(NavLink)`
    width: 45px;
    height: 38px;
    color: #fff;
    &:hover {
        color: #fff;
    }
    cursor: pointer;
    &:hover {
        background-color: #253342;
        border-radius: 3px;
    }
`
