import { Page } from "core/styledComponents"
import AppHeader from "modules/header/AppHeader"
import { NavLink } from "react-router-dom"
import { AppRoutes } from "services/constants"

const Report = () => {
    return (
        <Page>
            <AppHeader />
            <div className="flex items-center my-5 mx-12">
                <div className="text-xl font-bold text-link">Báo cáo</div>
            </div>
            <hr />
            <NavLink to={AppRoutes.FEEDBACK_REPORT} className="text-center block text-sm font-medium mt-5 text-link">Feedback khách hàng</NavLink>
        </Page>
    )
}

export default Report
