import styled from "styled-components"
import Drawer from "rc-drawer"
import motionProps from "./motion"
import "./style.css"
import { useSnapshot } from "valtio"
import { recentListStore } from "./recentListStore"
import { NavLink } from "react-router-dom"
import { useEffect } from "react"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RecentList = ({ isOpen, setIsOpen }: Props) => {
    const { recentItems } = useSnapshot(recentListStore)
    useEffect(() => {
        recentListStore.loadRecentItems()
    }, [])
    return (
        <Drawer
            open={isOpen}
            onClose={() => setIsOpen(false)}
            placement="right"
            {...motionProps}
        >
            <Wrap
                className="px-5 py-5 h-full"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <div className="font-semibold text-[15px] pb-5">
                    Sửa gần đây
                </div>
                <div className="text-sm">
                    {[...recentItems].reverse().map((e) => (
                        <NavLink
                            className={
                                "hover:underline hover:text-link flex mb-2 "
                            }
                            to={`/su-kien/${e.id}`}
                            key={e.id}
                            onClick={() => setIsOpen(false)}
                        >
                            {e.name}
                        </NavLink>
                    ))}
                </div>
                {/* <div className="font-semibold text-[15px] pb-5 pt-5">Nháp</div>
                <div className="text-sm">
                    <div>Văn phòng phẩm Deli</div>
                </div> */}
            </Wrap>
        </Drawer>
    )
}

export default RecentList

const Wrap = styled.div``
