import { styled } from "styled-components"

import { EventItem } from "models/events/event"
import React, { UIEvent, useEffect, useId, useRef, useState } from "react"
import {
    ScrollAreaRoot,
    ScrollAreaScrollbar,
    ScrollAreaThumb,
    ScrollAreaViewPort,
} from "core/scrollComponents"
import { useNavigate } from "react-router"
import { eventDetailStore } from "stores/eventDetailStore"
import FeedbackReportHeader from "./FeedbackReportHeader"
import { FeedbackReportItem } from "models/feedbackReportItem"
import { parseDateFromNumber } from "services/utils"
import QuillService from "services/quillService"
type Props = {
    items: FeedbackReportItem[]
}

const FeedbackReportTableBody = ({ items }: Props) => {
    const [scrollLeft, setScrollLeft] = useState(0)
    const scrollAreaRef = useRef<HTMLDivElement>(null)
    const handleScroll = (e: UIEvent<HTMLDivElement>) => {
        setScrollLeft(e.currentTarget.scrollLeft)
    }
    const [hasScrollbar, setHasScrollbar] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (scrollAreaRef.current) {
            let observer = new ResizeObserver((mutationsList, observe) => {
                var hasHorizontalScrollbar =
                    scrollAreaRef.current!.scrollWidth >
                    scrollAreaRef.current!.clientWidth
                setHasScrollbar(hasHorizontalScrollbar)
            })

            observer.observe(scrollAreaRef.current, {})
        }
    }, [])
    return (
        <Wrap>
            <ScrollAreaRoot type="auto">
                <ScrollAreaViewPort
                    ref={scrollAreaRef}
                    style={{
                        backgroundColor: `var(--bg)`,
                        maxHeight: `calc(100vh - 280px)`,
                        paddingBottom: hasScrollbar ? "12px" : undefined,
                    }}
                    onScroll={handleScroll}
                >
                    <FeedbackReportHeader scrollLeft={scrollLeft} />
                    <div className="">
                        {items.map((e) => (
                            <Row key={e.eventId}>
                                <div className="cell">&nbsp;</div>
                                <NameCell className="cell text-link relative font-medium ">
                                    <div className="title">{e.title}</div>
                                </NameCell>
                                <div className="cell">
                                    {e.customerName || "-"}
                                </div>

                                <div className="cell text-center">
                                    {parseDateFromNumber(e.startTime).formatDMY()}
                                </div>
                                <div className="cell text-right">
                                    {QuillService.getPlainText(e.feedback)}
                                </div>
                            </Row>
                        ))}
                    </div>
                    <ScrollAreaScrollbar
                        orientation="vertical"
                        style={{
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        <ScrollAreaThumb />
                    </ScrollAreaScrollbar>
                    <ScrollAreaScrollbar
                        orientation="horizontal"
                        style={{
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        <ScrollAreaThumb />
                    </ScrollAreaScrollbar>
                </ScrollAreaViewPort>
            </ScrollAreaRoot>
        </Wrap>
    )
}

export default React.memo(FeedbackReportTableBody)

const Wrap = styled.div`
    min-height: 0;
    flex-shrink: 0;
    background-color: #fff;
    flex: 1;
    outline: none;
    border: 1px solid #e9e9e9;
`

const Row = styled.div`
    box-sizing: border-box;
    min-height: 28px;
    border-bottom: 1px solid #e9e9e9;
    display: grid;
    padding-right: 16px;
    .cell {
        padding: 8px 12px;
        font-size: 14px;
        border-right: 1px solid #e9e9e9;
    }
    &:first-of-type {
        padding-top: 2px;
    }
    &:last-of-type {
        border-bottom: none;
    }
    .trash-cell {
        opacity: 0;
        &:hover {
            opacity: 1;
        }
    }
    &:nth-child(2n + 1) {
        background-color: #fdfdfc;
    }
    &:hover {
        background-color: #f6faff;
        .trash-cell {
            color: #ccc;
            opacity: 1;
            &:hover {
                color: #ca1f1f;
            }
        }
    }

    &.active {
        background-color: #fff7ec;
    }
    grid-template-columns: 40px 320px 170px 150px minmax(
            140px,
            1fr
        );
`
const PreviewButton = styled.div`
    right: 10px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    background-color: #eeeeee;
    border: 1px solid #b3c4ce;
    border-radius: 3px;
    color: #707b89;
    height: 28px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 0 8px;
    z-index: 8;
    display: none;
    &:hover {
        background-color: #fafafa;
    }
`

const NameCell = styled.div`
    .title {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    &:hover {
        .preview-button {
            display: flex;
        }
    }
`
