import classNames from "classnames"
import { AppointmentItem } from "models/events/appointment"
import { ReactNode } from "react"
import { getEndHour } from "services/utils"
import { styled } from "styled-components"

type Props = {
    renderHeader: () => ReactNode
    className?: string
    currentAppointment: AppointmentItem
    isOpen: boolean
    onChange: (
        startHour: number,
        startMin: number,
        endHour: number,
        endMin: number
    ) => void
    onOpen: () => void
}

const SelectScheduleTime = ({
    className,
    currentAppointment,
    isOpen,
    onOpen,
}: Props) => {
    
    const [endHour, endMin] = getEndHour(
        currentAppointment.hour,
        currentAppointment.min,
        currentAppointment.duration
    )



    return (
        <>
            <TimeDiv
                tabIndex={0}
                onClick={() => {
                    onOpen()
                }}
                className={classNames(className, "relative", )}
            >
                {currentAppointment.hour.padZero()}:
                {currentAppointment.min.padZero()} -
                {endHour.padZero()}:
                {endMin.padZero()}
            </TimeDiv>

            {/* {isOpen && (
                <FloatingPortal>
                    <FloatingOverlay
                        className="z-20"
                        onClick={() => setIsOpen(false)}
                    >
                        <TimePickerWrap
                            className="shadow-menu  rounded p-5 bg-white ] w-[360px]"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {renderHeader()}
                            <TimePickerSingle
                                activeKey={currentAppointment.key || ""}
                                currentAppointments={currentAppointments}
                                otherAppointments={otherAppointments}
                                onChange={onChange}
                            />
                        </TimePickerWrap>
                    </FloatingOverlay>
                </FloatingPortal>
            )} */}
        </>
    )
}

export default SelectScheduleTime


const TimeDiv = styled.div`
    border: 1px solid var(--input-border);
    border-radius: var(--input-border-radius);
    height: 40px;
    background-color: var(--input-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    &.active, &:focus-within {
        border-color: var(--input-border-focus);
        box-shadow: 0 0 4px 1px rgb(40 153 236 / 30%), 0 0 0 1px #2872bb;
    }
`

const TimePickerWrap = styled.div`
    position: fixed;
    top: 40px;
    bottom: 60px;
    left: calc(100vw - 1060px);
    z-index: 20;
`
