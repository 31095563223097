import { formatDeposit, formatGuestEstimate } from "services/utils"
import { Timeline } from "../timeline"
import { EventStatus } from "services/constants"
import { nanoid } from "nanoid"
import EventTodo from "./eventTodo"
import EventMenu from "./eventMenu"
import EventPayment from "./eventPayment"
import EventAttachment from "../eventAttachment"
import EventUtils from "utils/EventUtils"
import TableSetupUtils from "utils/TableSetupUtils"

type ScheduleEvent = {
    id: number
    title: string
    timeline: string
    status: number
    startTime: number
    userId: number
    customerId?: number
    deposit: number
    guestFrom: number
    guestTo: number
    tableSetup: string
    tableReserve: number
    note?: string
    orderNote?: string
    checklistNote?: string
    cancelNote?: string
    deleteNote?: string
    isCancel?: boolean
    feedback?: string
    customerName?: string
    menuCount: number
    todoCount: number
    totalPrice: number
    totalPayment: number
    updatedAt: number

    orderNames?: string[]
    checklistNames?: string[]
    paymentNames?: string[]

    todos?: EventTodo[]
    menus?: EventMenu[]
    payments?: EventPayment[]
    attachments?: EventAttachment[]
}
export class EventItem {
    id: number
    title: string
    timeline: string
    status: number
    startTime: number
    startDate: Date
    userId: number
    deposit: number
    guestFrom: number
    guestTo: number
    tableSetup: string
    tableReserve: number
    note?: string
    orderNote?: string
    checklistNote?: string
    cancelNote?: string
    deleteNote?: string
    isCancel?: boolean
    feedback?: string
    customerId?: number
    customerName?: string

    menuCount: number
    todoCount: number
    totalPrice: number
    totalPayment: number

    todos: EventTodo[]
    menus: EventMenu[]
    payments: EventPayment[]
    attachments: EventAttachment[]

    orderNames: string[]
    checklistNames: string[]
    paymentNames: string[]

    updatedAt: number

    constructor(data: ScheduleEvent) {
        this.id = data.id
        this.title = data.title
        this.note = data.note
        this.startTime = data.startTime
        this.startDate = this.getStartTime()
        this.status = data.status
        this.userId = data.userId
        this.timeline = data.timeline
        this.deposit = data.deposit
        this.guestFrom = data.guestFrom
        this.guestTo = data.guestTo
        this.tableReserve = data.tableReserve
        this.tableSetup = data.tableSetup

        this.orderNote = data.orderNote || ""
        this.checklistNote = data.checklistNote || ""
        this.cancelNote = data.cancelNote || ""
        this.feedback = data.feedback || ""
        this.deleteNote = data.deleteNote || ""
        this.isCancel = data.isCancel || false
        this.customerId = data.customerId
        this.customerName = data.customerName

        this.menuCount = data.menuCount
        this.todoCount = data.todoCount
        this.totalPrice = data.totalPrice
        this.totalPayment = data.totalPayment

        this.todos = data.todos || []
        this.menus = data.menus || []
        this.payments = (data.payments || []).map((e) => ({
            ...e,
            free: !!e.free,
        }))
        this.attachments = data.attachments || []

        this.orderNames = data.orderNames || []
        this.checklistNames = data.checklistNames || []
        this.paymentNames = data.paymentNames || []

        this.updatedAt = data.updatedAt
    }

    getStartTime() {
        // 2305211300
        var year = Number("20" + `${this.startTime}`.substring(0, 2))
        var month = +`${this.startTime}`.substring(2, 4) - 1
        var day = +`${this.startTime}`.substring(4, 6)
        var hour = +`${this.startTime}`.substring(6, 8)
        var min = +`${this.startTime}`.substring(8, 10)
        var date = new Date(year, month, day, hour, min, 0, 0)
        return date
    }

    formatGuest() {
        return formatGuestEstimate(this.guestFrom, this.guestTo)
    }
    formatDeposit() {
        return formatDeposit(this.deposit)
    }

    formatStatus() {
        if (this.isCancel || this.status == EventStatus.CANCEL) return "Đã hủy"
        if (this.status === EventStatus.CONFIRMED) {
            return "Đã chốt"
        }
        if (this.status === EventStatus.INPROGRESS) {
            return "Đã cọc, chưa chốt"
        }

        return "Tham khảo"
    }

    formatTableSetup() {
        return TableSetupUtils.formatTableSetup(this.tableSetup)
    }

    parseTimelines() {
        if (!this.timeline) {
            return []
        }

        var list = <Timeline[]>[]
        for (var str of this.timeline.split("|")) {
            var arr = str.split(",")
            if (arr.length != 4) {
                return []
            }
            var hhmm = arr[0]
            var duration = +arr[1]
            var typeId = +arr[2]
            var placeId = +arr[3]
            var hour = +hhmm.split(":")[0]
            var min = +hhmm.split(":")[1]
            var eventId = this.id

            list.push(
                new Timeline({
                    key: nanoid(), //generate phía client và chỉ dùng phía client
                    duration,
                    eventId,
                    hour,
                    min,
                    placeId,
                    typeId,
                })
            )
        }
        list.sort((a, b) => a.hour * 60 + a.min - b.hour * 60 - b.min)
        return list
    }

    static generateEmpty() {
        return new EventItem({
            id: 0,
            title: "",
            startTime: EventUtils.convertToEventStartTime(new Date()),
            timeline: "",
            status: 0,
            guestFrom: 0,
            guestTo: 0,
            tableReserve: 0,
            tableSetup: "0b10",
            deposit: 0,
            updatedAt: 0,
            userId: 0,
            cancelNote: "",
            checklistNote: "",
            customerId: 0,
            deleteNote: "",
            feedback: "",
            isCancel: false,
            note: "",
            orderNote: "",
            todoCount: 0,
            menuCount: 0,
            totalPayment: 0,
            totalPrice: 0,
        })
    }
}

export default ScheduleEvent

export type EventLite = {
    id: number
    title: string
    timeline: string
    startTime: number
}
