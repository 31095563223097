import classNames from "classnames"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import { TextArea } from "core/styledComponents"
import { useEffect, useRef, useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import styled from "styled-components"
import TextareaAutosize from "react-textarea-autosize"
import { rootStore } from "stores/rootStore"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CancelNoteDialog = ({ isOpen, setIsOpen }: Props) => {
    const [cancelNote, setCancelNote] = useState("")
    useEffect(() => {
        if (!isOpen) {
            setCancelNote("")
        }
    }, [isOpen])

    const textareRef = useRef<HTMLTextAreaElement>(null)
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleCancel()
        }
    }
    const handleCancel = async () => {
        var success = await eventDetailStore.cancelEvent({
            cancelNote,
            eventId: eventDetailStore.currentEvent.id,
            eventTitle: eventDetailStore.currentEvent.title,
            userName: rootStore.currentUser.displayName,
        })
        if (success) {
            setIsOpen(false)
            eventDetailStore.refreshCount++
        }
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[25vh] bg-white">
                    <div className="px-8 pt-5 mb-4">
                        <div className="text-md font-semibold mb-5">
                            Lý do hủy
                        </div>
                        <TextArea
                            minRows={5}
                            value={cancelNote}
                            autoFocus
                            placeholder="Nhập lý do hủy"
                            ref={textareRef}
                            className={classNames("text-sm")}
                            onChange={(
                                e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                                setCancelNote(e.target.value)
                            }}
                        />
                    </div>
                    <hr />
                    <div className="flex justify-center gap-4 py-5">
                        <Button className="w-[150px]" onClick={handleCancel}>
                            Hủy sự kiện
                        </Button>
                        <Button
                            className="w-[100px]"
                            outline={true}
                            onClick={() => setIsOpen(false)}
                        >
                            Đóng
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default CancelNoteDialog

const StyledTextArea = styled(TextareaAutosize)`
    border-color: transparent;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    padding-top: 4px;
    width: 100%;
`
