import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import EventTodo from "models/events/eventTodo"
import { rootStore } from "stores/rootStore"
import { todoStore } from "stores/todoStore"
import React, { useMemo, useState } from "react"
import SelectGroupCell from "core/cells/SelectGroupCell"
import { Todo } from "models/todo"
import { EmptyCell } from "core/cells/EmptyCell"
import { Section } from "core/cells/Section"
import CellProvider from "core/cells/CellProvider"
import ChecklistSummary from "./ChecklistSummary"
import TextCell from "core/cells/TextCell"
import IconTrash from "common/icons/IconTrash"
import {
    containsIgnoreCase,
    equalIgnoreCase,
    uniqueIgnoreCase,
} from "services/utils"
import { saveDraft } from "services/draftService"
import { moveDownAtIndex } from "core/cells/utils"
import { recentListStore } from "modules/recent_list/recentListStore"
import { eventDetailStore } from "stores/eventDetailStore"

const TodoTableBody = () => {
    /**
     *  logic: Toàn bộ todo, section, checklist hiển thị trên bảng TodoTableBody đều lấy từ editingEventTodos,
     *  ko phụ thuộc vào dữ liệu todo/section/checklist của hệ thống
     *
     */
    const { editingTodos, lastTodoName, currentChecklist } =
        useSnapshot(todoStore)
    const { todoSections, checklistTemplates } = useSnapshot(rootStore)
    const { currentEvent } = useSnapshot(eventDetailStore)

    const currentEditingItems: EventTodo[] = useMemo(
        () => editingTodos.filter((e) => e.checklist === currentChecklist),
        [editingTodos, currentChecklist]
    )

    const templateOfEditingTodos =
        currentEditingItems.filter((e) => e.name)?.[0]?.template || ""

    const sectionNames = uniqueIgnoreCase(
        currentEditingItems.map((e) => e.section)
    )

    /**
     *  currentTemplateItem là templateItem lấy ra từ checklistTemplates hệ thống dựa trên so sánh tên ,
     *   Nếu currentTemplateItem NULL thì lấy bằng checklistTemplates[0]
     *   Khi đó currentTemplateItem và template của editingTodos có thể khác nhau (chấp nhân đc)
     *
     *   Từ currentTemplate sẽ ra được list todoSections của hệ thống và ra đc  list todoItems của hệ thống
     *    list todoItems này là list sẽ hiện lên khi user input vào 1 ô todo và hiện lên suggestion dựa trên list todoItems
     *
     */
    const currentTemplateItem = templateOfEditingTodos
        ? checklistTemplates.find(
              (e) =>
                  e.name.trim().toLowerCase() ===
                  templateOfEditingTodos.trim().toLowerCase()
          )
        : checklistTemplates[0]

    /**
     *  sectionIds là id của những todoSection của template hiện tại
     *  sectionIds dùng để lọc list rootStore.todos nhung todo cua template hien tai
     */
    const sectionIds = todoSections
        .filter((e) => e.templateId == currentTemplateItem?.id)
        .map((e) => e.id)
    const getCellLabel = (e: EventTodo) => e.name
    const getOptionValue = (e: Todo) => e.id
    const getTodoLabel = (e: Todo) => e.name

    /**
     *  ham nay trong bang nay se ko dung den
     */
    const getOptionCategory = (e: Todo) =>
        todoSections.find((s) => s.id == e.sectionId)?.name || ""
    const editMode = true

    const selectedTodoNames = editingTodos
        .filter(
            (e) =>
                e.checklist == currentChecklist &&
                (currentTemplateItem
                    ? e.template === currentTemplateItem?.name
                    : true)
        )
        .map((e) => e.name)

    const handleTabAtLastRowCell = () => {
        todoStore.addRow(null)
        setTimeout(() => {
            if (document.activeElement) {
                moveDownAtIndex(document.activeElement, 1)
            }
        })
    }

    return (
        <CellProvider>
            <Wrap className={editMode ? "" : "pointer-events-none"}>
                {sectionNames.map((s) => (
                    <>
                        <Section>{s}</Section>
                        <>
                            {currentEditingItems
                                .filter((item) =>
                                    equalIgnoreCase(item.section, s)
                                )
                                .map((e) => (
                                    <Row key={e.name}>
                                        <EmptyCell />
                                        <SelectGroupCell
                                            data={e}
                                            getCellLabel={getCellLabel}
                                            getOptionLabel={getTodoLabel}
                                            getOptionValue={getOptionValue}
                                            onDelete={(data, colKey) => {
                                                todoStore.deleteCell(
                                                    data,
                                                    colKey,
                                                    saveDraft
                                                )
                                            }}
                                            /**
                                             *  hàm todoStore.changeTodo gọi khi user input vào 1 ô trong bảng,
                                             *  hệ thống show ra 1 popup để user chọn, user click vào thì hàm đc gọi
                                             */
                                            onUpdateValue={({
                                                cellData,
                                                colKey,
                                                option,
                                                category,
                                            }) => {
                                                todoStore.changeTodo({
                                                    category,
                                                    cellData,
                                                    colKey,
                                                    option,
                                                    cb: saveDraft,
                                                })
                                            }}
                                            options={
                                                /**
                                                 *  đoạn này đảm bảo popup show lên chỉ gồm những todos thuộc current template
                                                 *  và ko chứa những todo đã chọn
                                                 */
                                                currentTemplateItem
                                                    ? rootStore.todos.filter(
                                                          (e) =>
                                                              sectionIds.includes(
                                                                  e.sectionId
                                                              ) &&
                                                              !containsIgnoreCase(
                                                                  selectedTodoNames,
                                                                  e.name
                                                              )
                                                      )
                                                    : rootStore.todos
                                            }
                                            colKey="name"
                                            getOptionCategory={
                                                getOptionCategory
                                            }
                                        />
                                        <TextCell
                                            data={e}
                                            colKey="detail"
                                            onDelete={(data, colKey) => {
                                                todoStore.deleteCell(
                                                    data,
                                                    colKey,
                                                    saveDraft
                                                )
                                            }}
                                            onUpdateValue={(
                                                data,
                                                colKey,
                                                value
                                            ) => {
                                                todoStore.updateCell(
                                                    data,
                                                    colKey,
                                                    value,
                                                    saveDraft
                                                )
                                            }}
                                            onTabAtLastRowCell={
                                                handleTabAtLastRowCell
                                            }
                                        />
                                        <div className="trash-cell">
                                            <TrashIcon
                                                onClick={() => {
                                                    todoStore.removeItem(e.key)
                                                    saveDraft()
                                                }}
                                            >
                                                <IconTrash
                                                    width={16}
                                                    height={16}
                                                />
                                            </TrashIcon>
                                        </div>
                                    </Row>
                                ))}
                        </>
                    </>
                ))}

                <div className="h-20">&nbsp;</div>
            </Wrap>
            <ChecklistSummary />
        </CellProvider>
    )
}

export default React.memo(TodoTableBody)

const Wrap = styled.div`
    min-height: 0;
    flex: 1;
    outline: none;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #e9e9e9;
`

const Row = styled.div`
    box-sizing: border-box;
    min-height: 28px;
    border-bottom: 1px solid #e9e9e9;
    font-size: 13px;
    display: grid;
    &:first-of-type {
        padding-top: 2px;
    }
    .trash-cell {
        opacity: 0;
        &:hover {
            opacity: 1;
        }
    }
    grid-template-columns: 56px 1fr 2fr 40px;

    &:hover {
        background-color: #f6faff;
        .trash-cell {
            color: #ccc;
            opacity: 1;
            &:hover {
                color: #ca1f1f;
            }
        }
    }
`

const TrashIcon = styled.span`
    color: #ccc;
    width: 36px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        color: #ca1f1f;
    }
`
