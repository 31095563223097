import IconSearch from "common/icons/IconSearch"
import Dialog from "core/dialog/Dialog"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import { globalSearchStore } from "./stores/globalSearchStore"
import { NavLink } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { searchEvent } from "./services/globalSearchService"
import { ResultItem } from "./models/types"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const GlobalSearch = ({ isOpen, setIsOpen }: Props) => {
    const { recentItems } = useSnapshot(globalSearchStore)
    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState<ResultItem[]>([])
    const [q, setQ] = useState("")

    const timerRef = useRef(0)

    useEffect(() => {
        if (!isOpen) {
            setEvents([])
            setQ("")
        }
    }, [isOpen])

    useEffect(() => {
        globalSearchStore.loadRecentItems()
    }, [])

    const handleClickResult = (e: ResultItem) => {
        globalSearchStore.addItem(e)
        setIsOpen(false)
    }

    const doSearch = (query: string) => {
        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(async () => {
            setLoading(true)
            var data = await searchEvent(query)
            setLoading(false)
            setEvents(data)
        }, 400)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQ(e.target.value)
        doSearch(e.target.value)
    }
    return (
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
            <Wrap className="m-auto scaleIn shadow-menu rounded-lg  w-[600px] mt-10 bg-white">
                <SearchBox className="flex items-center relative h-10 pl-3">
                    <IconSearch className="text-[#9D9D9D]  text-xl" />
                    <Input
                        autoFocus
                        placeholder="Tìm kiếm"
                        className="ml-3 text-sm"
                        onChange={handleChange}
                        value={q}
                    />
                </SearchBox>
                {recentItems.length > 0 && !q && (
                    <>
                        <hr className="" />
                        <div className="pt-3">
                            <div className="text-secondary header font-medium px-6 pb-3 pt-2 text-sm">
                                Gần đây
                            </div>
                            {[...recentItems].reverse().map((e) => (
                                <NavLink
                                    onClick={() => handleClickResult(e)}
                                    to={`/su-kien/${e.id}`}
                                    className="pl-8 pb-3 text-sm flex hover:underline hover:text-link"
                                    key={e.id}
                                >
                                    {e.name}
                                </NavLink>
                            ))}
                        </div>
                    </>
                )}
                {q.length > 0 && (
                    <div>
                        <hr />
                        <div className="py-5">
                            {events.map((e) => (
                                <NavLink
                                    onClick={() => handleClickResult(e)}
                                    to={`/su-kien/${e.id}`}
                                    className="pl-8 py-2 text-sm hover:underline hover:text-link flex"
                                    key={e.id}
                                >
                                    {e.name}
                                </NavLink>
                            ))}
                        </div>
                    </div>
                )}
            </Wrap>
        </Dialog>
    )
}

export default GlobalSearch

const SearchBox = styled.div``
const Wrap = styled.div`
    hr {
        border-top-color: #e7e7e7 !important;
    }
    .header {
        cursor: default;
        user-select: none;
    }
`

const Input = styled.input`
    outline: none;
    width: 100%;
    border: none;
    &::placeholder {
        color: #d0c9c9;
    }
`
