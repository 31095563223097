import { useState } from "react"
import { paymentStore } from "stores/paymentStore"
import { Input } from "core/styledComponents"
import { useSnapshot } from "valtio"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import CloseButton from "core/button/CloseButton"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const AddPaymentSection = ({ isOpen, setIsOpen }: Props) => {
    const { paymentNames: currentSectionNames } = useSnapshot(paymentStore)
    const [name, setName] = useState("")

    const handleAdd = async () => {
        if (!currentSectionNames.includes(name)) {
            paymentStore.paymentNames.push(name)
            paymentStore.currentPaymentName = name
        }
        setIsOpen(false)
    }

    return (
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[500px] mt-[25vh] bg-white">
                <div className=" pt-6 pb-6 px-14 flex-col flex items-center relative">
                    <CloseButton
                        className="absolute top-0 right-0 mt-3 mr-3 text-gray-500"
                        onClick={() => setIsOpen(false)}
                    />
                    <div className="text-base font-semibold text-primary mb-5 text-center">
                        Thêm mục thanh toán
                    </div>
                    <Input
                        className="text-md"
                        placeholder="Tên muc thanh toan"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        onClick={handleAdd}
                        size="lg"
                        className="mt-4 block w-full "
                        disabled={!name}
                    >
                        Xong
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default AddPaymentSection
