import ReactQuill from "core/ReactQuill"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import { recentListStore } from "modules/recent_list/recentListStore"
import { quillFormat, quillModules } from "services/constants"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ChecklistNoteChange = ({ isOpen, setIsOpen }: Props) => {
    const { checklistNote, currentEvent } = useSnapshot(eventDetailStore)
    const cancelChanges = () => {
        eventDetailStore.checklistNote = currentEvent.checklistNote || ""
        // clearOrderNoteDraft(currentEvent.id)
        saveDraft()
        setIsOpen(false)
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg min-h-[250px] pb-3  w-[600px] mt-[25vh] bg-white">
                    <DialogTitle>Thay đổi</DialogTitle>
                    <div className="px-12 pb-7 pt-5">
                        <div className="text-sm  font-normal mb-2">
                            Giá trị cũ:
                        </div>
                        <EditorWrap tabIndex={0}>
                            <ReactQuill
                                theme="snow"
                                value={
                                    currentEvent.checklistNote
                                        ? JSON.parse(currentEvent.checklistNote)
                                        : ""
                                }
                                modules={quillModules}
                                formats={quillFormat}
                                readOnly={true}
                                placeholder=""
                            />
                        </EditorWrap>
                        <div className="text-sm font-normal mb-2 mt-7">
                            Giá trị mới:
                        </div>

                        <EditorWrap tabIndex={0}>
                            <ReactQuill
                                theme="snow"
                                value={
                                    checklistNote
                                        ? JSON.parse(checklistNote)
                                        : ""
                                }
                                modules={quillModules}
                                formats={quillFormat}
                                readOnly={true}
                                placeholder=""
                            />
                        </EditorWrap>
                    </div>
                    <hr />
                    <div className="flex justify-between pt-3 px-5">
                        <Button onClick={cancelChanges} outline>
                            Hủy thay đổi
                        </Button>
                        <Button
                            className="w-24"
                            onClick={() => setIsOpen(false)}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ChecklistNoteChange

const EditorWrap = styled.div`
    border-radius: 4px;
    padding: 8px;
    position: relative;
    outline: 2px solid transparent;
    transition: outline 0.15s ease;
    border: 1px solid transparent;
    &:not(.active) {
        border: 1px solid #f5e8bc;
        background-color: #fff9e5;
    }
    .ql-toolbar {
        display: none;
    }
`
