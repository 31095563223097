import * as Popover from "@radix-ui/react-popover"
import classNames from "classnames"
import DialogAddOrder from "modules/EventDetail/OrderTab/order_more_button/dialog_add_order/DialogAddOrder"
import { useState } from "react"
import { DialogName } from "services/constants"
import { openDialog } from "stores/dialogStore"
import { eventMenuStore } from "stores/eventMenuStore"
import styled from "styled-components"
import { DropdownMenu } from "core/styledComponents"
import { useSnapshot } from "valtio"
import { todoStore } from "stores/todoStore"
import DialogAddChecklist from "modules/dialogs/DialogAddChecklist"
import DialogRenameChecklist from "modules/dialogs/DialogRenameChecklist"
import IconDotsVertical from "common/icons/IconDotsVertical"
import IconPlus from "common/icons/IconPlus"
import IconEdit from "common/icons/IconEdit"
import IconTrash from "common/icons/IconTrash"

const ChecklistMoreButton = () => {
    const [open, setOpen] = useState(false)
    const { currentChecklist, checklistNames } = useSnapshot(todoStore)
    const [isOpenAddChecklist, setIsOpenAddChecklist] = useState(false)
    const [isOpenRename, setIsOpenRename] = useState(false)

    const handleDelete = async () => {
        setOpen(false)
        if (checklistNames.length > 0) {
            todoStore.editingTodos = todoStore.editingTodos.filter(
                (e) => e.checklist !== currentChecklist
            )
            todoStore.checklistNames = checklistNames.filter(
                (e) => e !== currentChecklist
            )
            if (checklistNames.length) {
                todoStore.currentChecklist = checklistNames[0]
            }
        }
    }

    return (
        <>
            <Popover.Root open={open} onOpenChange={setOpen}>
                <Popover.Trigger className="" asChild>
                    <Div
                        className={classNames(
                            "flex items-center justify-center",
                            { active: open }
                        )}
                    >
                        <IconDotsVertical width={20} height={20} />
                    </Div>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        align="end"
                        className="card-shadow bg-white p-1 outline-none rounded-lg z-30"
                        sideOffset={5}
                    >
                        <DropdownMenu
                            onClick={() => setIsOpenAddChecklist(true)}
                        >
                            Thêm check list
                            <IconPlus className="ml-auto text-2xl" />
                        </DropdownMenu>
                        <DropdownMenu onClick={() => setIsOpenRename(true)}>
                            Đổi tên check list
                            <IconEdit
                                width={14}
                                height={14}
                                className="ml-auto mr-1"
                            />
                        </DropdownMenu>
                        {checklistNames.length > 1 && (
                            <DropdownMenu
                                className="text-red-500"
                                onClick={handleDelete}
                                disabled={checklistNames.length < 1}
                            >
                                Xóa check list
                                <IconTrash
                                    className="ml-auto mr-1"
                                    width={16}
                                    height={16}
                                />
                            </DropdownMenu>
                        )}
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
            <DialogAddChecklist
                isOpen={isOpenAddChecklist}
                setIsOpen={setIsOpenAddChecklist}
            />
            <DialogRenameChecklist
                isOpen={isOpenRename}
                setIsOpen={setIsOpenRename}
            />
        </>
    )
}

export default ChecklistMoreButton

const Div = styled.div`
    width: 38px;
    height: 38px;
    &:hover,
    &.active {
        color: var(--text-link);
        background-color: #fafafa;
    }
`
