import { AxiosResponse } from "axios"
import { EventAppointment } from "models/events/appointment"
import API from "./api"
import { nanoid } from "nanoid"
import { parseColor } from "./utils"

const getEventAppointmentsByDate = async (startDate: number) => {
    var response = await API.get<AxiosResponse<EventAppointment[]>>(
        `/appointments?startDate=${startDate}`
    )
    var items = response.data.data.map((e) => ({
        ...e,
        key: nanoid(),
        color: parseColor(e.color),
    }))
    return items
}

const AppointmentService = {
    getEventAppointmentsByDate,
}

export default AppointmentService
