import classNames from "classnames"
import IconPlus from "common/icons/IconPlus"
import IconTrash from "common/icons/IconTrash"
import { CellContext, ICellContext } from "core/cells/context"
import EventTodo from "models/events/eventTodo"
import { useContext } from "react"
import { dialogStore } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { todoStore } from "stores/todoStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"

const ChecklistSummary = () => {
    const { showSavePanel } = useSnapshot(dialogStore)
    const { colKey, data } =
        useContext<ICellContext<EventTodo | null>>(CellContext)

    const addRow = () => {
        todoStore.addRow(data)
    }

    const deleteRow = () => {
        if (data) {
            todoStore.removeItem(data.key)
        }
    }
    return (
        <ChecklistSummaryWrap
            className={classNames(
                " sticky bottom-0 pr-3 ",
                showSavePanel ? "bottom-[48px]" : "bottom-0"
            )}
        >
            {true && (
                <>
                    <div
                        onMouseDown={(e) => e.preventDefault()}
                        className="px-3 text-13 hover:text-link cursor-default flex items-center"
                        onClick={addRow}
                    >
                        <IconPlus width={18} height={18} />
                        Thêm dòng
                    </div>
                    <div
                        className="px-4 text-13 hover:text-red-500 cursor-default flex items-center"
                        onClick={deleteRow}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        <IconTrash width={13} height={13} className="mr-2" />
                        Xóa dòng
                    </div>
                </>
            )}
        </ChecklistSummaryWrap>
    )
}

export default ChecklistSummary

const ChecklistSummaryWrap = styled.div`
    display: flex;
    align-items: center;
    position: sticky;
    bottom: 0;
    transition: bottom 0.2s ease;
    font-size: 13px;
    height: 40px;
    background-color: #f3f4f5;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #ededed;
    z-index: 1;
`
