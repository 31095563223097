export const CLOUD_FRONT_URL = "https://d274z23b05y0oq.cloudfront.net"

export const SIDEBAR_WIDTH = 240

export const EventStatus = {
    CANCEL: 3,
    CONFIRMED: 2,
    INPROGRESS: 1,
    UNCONFIRM: 0,
}

export const EventTabKey = {
    GENERAL: "GENERAL",
    TIMELINE: "TIMELINE",
    ORDER: "ORDER",
    CHECKLIST: "CHECKLIST",
    PAYMENT: "PAYMENT",
    FEEDBACK: "FEEDBACK",
}

export const newEventTabs = [
    {
        label: "Thông tin chung",
        key: EventTabKey.GENERAL,
    },
    {
        label: "Lịch sự kiện",
        key: EventTabKey.TIMELINE,
    },
    {
        label: "Order",
        key: EventTabKey.ORDER,
    },
    {
        label: "Check list",
        key: EventTabKey.CHECKLIST,
    },
]

export const AppRoutes = {
    NEW_EVENT: "su-kien-moi",
    REPORT: "/bao-cao",
    FEEDBACK_REPORT: "/bao-cao/report-khach-hang",
    CUSTOMERS: "/khach-hang",
    EVENT_DETAIL: "/su-kien",
    EVENT_MONTH_VIEW: "/lich-thang",
    SETTINGS: "/cai-dat",
}

export const eventDetailTabs = [
    {
        label: "Thông tin chung",
        key: EventTabKey.GENERAL,
    },
    {
        label: "Lịch sự kiện",
        key: EventTabKey.TIMELINE,
    },
    {
        label: "Order",
        key: EventTabKey.ORDER,
    },
    {
        label: "Check list",
        key: EventTabKey.CHECKLIST,
    },
    {
        label: "Thanh toán",
        key: EventTabKey.PAYMENT,
    },
    {
        label: "Feedback",
        key: EventTabKey.FEEDBACK,
    },
]

export const DialogName = {
    CUSTOMER_DETAIL: "CUSTOMER_DETAIL",
    NEW_CUSTOMER: "NEW_CUSTOMER",
    SELECT_TABLE: "SELECT_TABLE",
    CHOOSE_EVENT_TYPE: "CHOOSE_EVENT_TYPE",
    ADD_MENU_SIZE: "ADD_MENU_SIZE",
    ADD_ORDER: "ADD_ORDER",
    ADD_PAYMENT_SECTION: "ADD_PAYMENT_SECTION",
    DELETE_ORDER: "DELETE_ORDER",
    SELECT_MENU: "SELECT_MENU",
    SELECT_TODO: "SELECT_TODO",
    SELECT_PAYMENT: "SELECT_PAYMENT",
    SELECT_EVENT_DATE: "SELECT_EVENT_DATE",
    UPDATE_MENU_SIZE: "UPDATE_MENU_SIZE",
    EDIT_TIMELINE: "EDIT_TIMELINE",
}

export const ActivityType = {
    NEW_EVENT: 1,
    UPDATE_EVENT_STATUS: 2,
    UPDATE_EVENT_ORDER: 3,
    UPDATE_TABLE_LAYOUT: 4,
    UPDATE_EVENT_CHECKLIST: 5,
    UPDATE_EVENT_DEPOSIT: 6,
    UPDATE_GUEST_ESTIMATE: 7,
    UPDATE_TABLE_SETUP: 8,
    UPDATE_TABLE_RESERVE: 9,
    UPDATE_EVENT_PAYMENT: 10,
    UPDATE_ORDER_NOTE: 11,
    UPDATE_CHECKLIST_NOTE: 12,
    UPDATE_EVENT_FEEDBACK: 13,
    UPDATE_EVENT_TIMELINE: 14,
    UPDATE_EVENT_TITLE: 15,
    REMOVE_EVENT: 16,
    CANCEL_EVENT: 17,
    RECOVER_EVENT: 18,
    REMOVE_ORDER: 19,
    UPDATE_EVENT_NOTE: 20,
    RENAME_PAYMENT_SECTION: 21,
    REMOVE_PAYMENT_SECTION: 22,
    UPDATE_EVENT_CUSTOMER: 23,
    UPDATE_EVENT_ORDER_TABLE: 24, //thêm, xóa, đổi tên thực đơn, thay đổi loại bàn
    UPDATE_EVENT_CHECKLIST_NAME: 25, //thêm, xóa, đổi tên checklist
    UPDATE_EVENT_PAYMENT_NAME: 26,
}

export const quillModules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic"],
        [{ color: [] }, { background: [] }],
        ["clean"],
    ],
}

export const quillFormat = [
    "header",
    "bold",
    "italic",
    "color",
    "background",
    "list",
    "bullet",
]

export const AttachmentType = {
    LAYOUT: 1,
}
