import { eventDetailStore } from "stores/eventDetailStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import { START_HOUR, YAXIS_WIDTH } from "./TimePickerSingle"

type Props = {
    top: number
    bottom: number
    scrollTop: number
}

const YAxis = ({ top, bottom, scrollTop }: Props) => {
    const { editingKey } = useSnapshot(eventDetailStore)

    let startHour = Math.floor(top / 60) + START_HOUR
    let startMin = Math.round((top - (startHour - START_HOUR) * 60) / 15) * 15
    if (startMin == 60) {
        startMin = 0
        startHour += 1
    }

    let endHour = Math.floor(bottom / 60) + START_HOUR
    let endMin = Math.round((bottom - (endHour - START_HOUR) * 60) / 15) * 15
    if (endMin == 60) {
        endMin = 0
        endHour += 1
    }

    const times = Array.from(Array(24 - START_HOUR + 1).keys()).map(
        (e) => e + START_HOUR
    )
    return (
        <YAxisWrap
            style={{
                width: `${YAXIS_WIDTH}px`,
                transform: `translateY(-${scrollTop}px)`,
            }}
        >
            {times.map((e) => (
                <TimeLabel
                    key={e}
                    className="flex items-center text-xs h-[60px] cursor-default pointer-events-none"
                >
                    {(!!editingKey && e == startHour && !startMin) ||
                    (e == endHour && !endMin)
                        ? ""
                        : e.padZero() + ":00"}
                </TimeLabel>
            ))}
        </YAxisWrap>
    )
}

export default YAxis

const YAxisWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 6px;
    background-color: #fff;
    z-index: 1;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
`

const TimeLabel = styled.span`
    color: #777777;
`
