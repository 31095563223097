import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import DateChangeHistory from "./DateChangeHistory"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DateChangePopup = ({ isOpen, setIsOpen }: Props) => {
    const { selectedDate, currentEvent } = useSnapshot(eventDetailStore)

    const cancelChanges = () => {
        eventDetailStore.selectedDate = new Date(
            currentEvent.startDate.getTime()
        )
        saveDraft()
        setIsOpen(false)
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg   w-[600px] pb-5 mt-[25vh] bg-white">
                    <DialogTitle>Thay đổi ngày</DialogTitle>
                    <DateChangeHistory
                        oldDate={currentEvent.startDate}
                        newDate={selectedDate}
                    />

                    <div className="justify-center flex ">
                        <Button outline onClick={cancelChanges}>
                            Hủy thay đổi
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default DateChangePopup
