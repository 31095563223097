import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import StatusChange from "./StatusChange/StatusChange"
import classNames from "classnames"

const StatusChangeCount = ({ className}: { className?: string}) => {
    const { status, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)

    const changed = status != currentEvent.status

    if (currentEvent.id == 0 || !changed) return null
    return (
        <>
            <span
                onClick={() => setIsOpen(true)}
                className={classNames("text-[orangered] text-13 font-normal ml-2 cursor-pointer hover:underline absolute", className)}
            >
                +1
            </span>
            <StatusChange isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* <DepositChange isOpen={isOpen} setIsOpen={setIsOpen} /> */}
        </>
    )
}

export default StatusChangeCount
