import { styled } from "styled-components"
import { useSnapshot } from "valtio"

import IconTrash from "common/icons/IconTrash"
import CellProvider from "core/cells/CellProvider"
import { Section } from "core/cells/Section"
import SelectCellAsync from "core/cells/SelectCellAsync"
import SelectGroupCell from "core/cells/SelectGroupCell"
import TextCell from "core/cells/TextCell"
import EventMenu from "models/events/eventMenu"
import { MenuItem } from "models/menu_item"
import { MenuSize } from "models/menu_size"
import React, { useMemo } from "react"
import { saveDraft } from "services/draftService"
import MenuSizeService from "services/menuSizeService"
import { parseOrderName } from "services/orderUtils"
import { eventMenuStore } from "stores/eventMenuStore"
import { rootStore } from "stores/rootStore"
import NumericCell from "../../../core/cells/NumericCell"
import OrderSummary from "./OrderSummary"
import RowIndexCell from "./components/RowIndexCell"
import { moveDownAtIndex } from "core/cells/utils"

const OrderTableBody = () => {
    const { editingMenus, lastActiveKey, currentOrderName } =
        useSnapshot(eventMenuStore)

    const currentEditingItems: EventMenu[] = useMemo(
        () =>
            editingMenus.filter(
                (e) => e.orderName === parseOrderName(currentOrderName).name
            ),
        [editingMenus, currentOrderName]
    )
    const groupNames = currentEditingItems.map((e) => e.groupName).unique()

    const getCellLabel = (e: EventMenu) => e.size
    const getOptionLabel = (e: MenuSize) => e.name
    const getOptionValue = (e: MenuSize) => e.id
    const getMenuSize = async (e: EventMenu) => {
        var _menu = rootStore.menus.find((item) => item.name === e.menu)
        var menuSizes = await MenuSizeService.getMenuSizes(_menu?.id || 0)
        return menuSizes
    }

    const getMenuName = (e: MenuItem) => e.name
    const getEventMenuName = (e: EventMenu) => e.menu
    const getMenuValue = (e: MenuItem) => e.id
    const getMenuTypeName = (e: MenuItem) =>
        rootStore.menuTypes.find((item) => item.id === e.typeId)?.name || ""

    const selectedMenuNames = editingMenus
        .filter((e) => e.orderName == parseOrderName(currentOrderName).name)
        .map((e) => e.menu)

    const addNewRowAtLast = () => {
        eventMenuStore.addRow(null)
        setTimeout(() => {
            moveDownAtIndex(document.activeElement, 1)
        }, 0)
    }

    const handleTabAtLastRowCell = () => {
        eventMenuStore.addRow(null)
        setTimeout(() => {
            if (document.activeElement) {
                moveDownAtIndex(document.activeElement, 1)
            }
        })
    }

    return (
        <CellProvider>
            <OrderTableBodyWrap>
                {groupNames.map((s, idx) => (
                    <>
                        <Section>{s || "Chưa phân loại"}</Section>
                        <>
                            {currentEditingItems
                                .filter((item) => item.groupName === s)
                                .map((e, index) => (
                                    <Row key={e.key}>
                                        <RowIndexCell menu={e}>
                                            {index + 1}
                                        </RowIndexCell>
                                        <SelectGroupCell
                                            data={e}
                                            getCellLabel={getEventMenuName}
                                            getOptionLabel={getMenuName}
                                            getOptionValue={getMenuValue}
                                            onDelete={(data, colKey) => {}}
                                            onUpdateValue={({
                                                cellData,
                                                colKey,
                                                option,
                                                category,
                                            }) => {
                                                eventMenuStore.changeMenu({
                                                    category,
                                                    cellData,
                                                    colKey,
                                                    option,
                                                    cb: saveDraft,
                                                })
                                            }}
                                            options={rootStore.menus.filter(
                                                (e) =>
                                                    !selectedMenuNames.includes(
                                                        e.name
                                                    )
                                            )}
                                            colKey="name"
                                            getOptionCategory={getMenuTypeName}
                                        />

                                        <TextCell
                                            data={e}
                                            colKey="amount"
                                            onDelete={(data, colKey) => {
                                                eventMenuStore.deleteCell(
                                                    data,
                                                    colKey,
                                                    saveDraft
                                                )
                                            }}
                                            onUpdateValue={(
                                                data,
                                                colKey,
                                                value
                                            ) => {
                                                eventMenuStore.updateCell(
                                                    data,
                                                    colKey,
                                                    value,
                                                    saveDraft
                                                )
                                            }}
                                        />
                                        <SelectCellAsync
                                            data={e}
                                            colKey="size"
                                            getCellLabel={getCellLabel}
                                            getOptionLabel={getOptionLabel}
                                            getOptionValue={getOptionValue}
                                            onDelete={(data, colKey) => {
                                                eventMenuStore.deleteSize(
                                                    data,
                                                    saveDraft
                                                )
                                            }}
                                            onUpdateValue={(data, option) => {
                                                eventMenuStore.updateSize(
                                                    data,
                                                    option,
                                                    saveDraft
                                                )
                                            }}
                                            getOptions={getMenuSize}
                                        />
                                        <TextCell
                                            data={e}
                                            colKey="note"
                                            onDelete={(data, colKey) => {
                                                eventMenuStore.deleteCell(
                                                    data,
                                                    colKey,
                                                    saveDraft
                                                )
                                            }}
                                            onUpdateValue={(
                                                data,
                                                colKey,
                                                value
                                            ) => {
                                                eventMenuStore.updateCell(
                                                    data,
                                                    colKey,
                                                    value,
                                                    saveDraft
                                                )
                                            }}
                                        />

                                        <NumericCell
                                            data={e}
                                            colKey="price"
                                            onDelete={(data, colKey) => {
                                                eventMenuStore.deleteCell(
                                                    data,
                                                    colKey,
                                                    saveDraft
                                                )
                                            }}
                                            onUpdateValue={(
                                                data,
                                                colKey,
                                                value
                                            ) => {
                                                eventMenuStore.updateCell(
                                                    data,
                                                    colKey,
                                                    value,
                                                    saveDraft
                                                )
                                            }}
                                            onTabAtLastRowCell={
                                                handleTabAtLastRowCell
                                            }
                                            onMoveDownAtLastRow={
                                                addNewRowAtLast
                                            }
                                        />
                                        <div className="trash-cell">
                                            <TrashIcon
                                                onClick={() => {
                                                    eventMenuStore.removeItem(
                                                        e.key
                                                    )
                                                    saveDraft()
                                                }}
                                            >
                                                <IconTrash
                                                    width={16}
                                                    height={16}
                                                />
                                            </TrashIcon>
                                        </div>
                                    </Row>
                                ))}
                        </>
                    </>
                ))}
            </OrderTableBodyWrap>
            <OrderSummary />
        </CellProvider>
    )
}

export default React.memo(OrderTableBody)

const OrderTableBodyWrap = styled.div`
    min-height: 0;
    background-color: #fff;
    flex: 1;
    outline: none;
    display: flex;
    flex-direction: column;
    border: 1px solid #e9e9e9;
`

const Row = styled.div`
    box-sizing: border-box;
    min-height: 28px;
    border-bottom: 1px solid #e9e9e9;
    display: grid;
    /* padding-right: 16px; */
    &:first-of-type {
        padding-top: 2px;
    }
    &:last-child {
        border-bottom: none;
    }
    .trash-cell {
        opacity: 0;
        &:hover {
            opacity: 1;
        }
    }
    &:nth-child(2n + 1) {
        background-color: #fdfdfc;
    }
    & > div:nth-child(2) {
        background-color: #f5f5f5;
    }
    &:hover {
        background-color: #f6faff;
        .trash-cell {
            color: #ccc;
            opacity: 1;
            &:hover {
                color: #ca1f1f;
            }
        }
    }

    &.active {
        background-color: #fff7ec;
    }
    grid-template-columns: 36px 5fr 5fr 72px 5fr 120px 40px;
`

const TrashIcon = styled.span`
    width: 36px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`
