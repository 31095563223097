import IconClose from "common/icons/IconClose"
import { ReactNode, useCallback, useContext } from "react"
import { styled } from "styled-components"
import { dialogContext } from "./Dialog"

type Props = {
    children: String | ReactNode
}

const DialogTitle = ({ children }: Props) => {
    const closeModal = useCallback(() => {
        setIsOpen(false)
    }, [])

    const { isOpen, setIsOpen } = useContext(dialogContext)

    return (
        <>
            <div className="text-[18px] relative font-semibold leading-6 text-gray-900  px-6 py-3 border-b border-td">
                {children}
                <CloseButton>
                    <IconClose
                        className="icon-close text-[17px] text-[#aaa]"
                        onClick={closeModal}
                    />
                </CloseButton>
            </div>
        </>
    )
}

export default DialogTitle

const CloseButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 18px;
    border-radius: 99px;
    top: 14px;
    padding: 4px;
    font-size: 11px;
    /* color: #aaa; */
    &:hover {
        background-color: #eee;
    }
`
