import { PlaceItem } from "models/place"
import { Timeline } from "models/timeline"

type Props = {
    oldTimelines: Timeline[]
    newTimelines: Timeline[]
    places: PlaceItem[]
}

const TimelineChangeHistory = ({
    oldTimelines,
    newTimelines,
    places,
}: Props) => {
    return (
        <>
            <div className="mt-1 pb-3">
                <div className=" flex-wrap flex items-center justify-center">
                    {oldTimelines.map((e, index) => (
                        <span key={index}>
                            {index > 0 ? ", " : ""}&nbsp;
                            <span className="text-[#596671]">
                                {e.hour.padZero()}h:
                                {e.min.padZero()}
                            </span>
                            <span className="text-[#596671]">
                                &nbsp;sảnh&nbsp;
                            </span>
                            <span className="font-medium ">
                                {places.find((p) => p.id === e.placeId)?.name}
                            </span>
                        </span>
                    ))}
                </div>
                <div className="flex flex-wrap mt-3 justify-center">
                    <span className="text-gray-400  mx-2">→</span>
                    {newTimelines.map((e, index) => (
                        <span key={index}>
                            {index > 0 ? ", " : ""}&nbsp;
                            <span className="text-[#596671]">
                                {e.hour.padZero()}h:
                                {e.min.padZero()}
                            </span>
                            <span className="text-[#596671]">
                                &nbsp;sảnh&nbsp;
                            </span>
                            <span className="font-medium ">
                                {places.find((p) => p.id === e.placeId)?.name}
                            </span>
                        </span>
                    ))}
                </div>
            </div>
        </>
    )
}

export default TimelineChangeHistory
