import { useState } from "react"
import { eventDetailStore } from "stores/eventDetailStore"
import { useSnapshot } from "valtio"
import OrderNoteChange from "../OrderNoteChange/OrderNoteChange"
import { getQuillContent } from "services/utils"

const OrderNoteChangeCount = () => {
    const { orderNote, currentEvent } = useSnapshot(eventDetailStore)
    const [isOpen, setIsOpen] = useState(false)
    if (currentEvent.id == 0) return null

    const changed =
        getQuillContent(orderNote) !=
        getQuillContent(eventDetailStore.currentEvent.orderNote || "")
    return (
        <>
            {changed && (
                <span
                    onClick={() => setIsOpen(true)}
                    className="text-[orangered] text-13 font-normal ml-2 cursor-pointer hover:underline"
                >
                    +1
                </span>
            )}
            <OrderNoteChange isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

export default OrderNoteChangeCount
