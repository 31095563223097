import { Button } from "core/button/Button"
import { styled } from "styled-components"
import {
    useFloating,
    autoPlacement,
    offset,
    FloatingPortal,
} from "@floating-ui/react"
import { useState } from "react"
import { useClickOutside } from "core/hooks"
import { eventFilterStore } from "stores/eventFilterStore"

type Props = {
    canSave: boolean
    warningText: string
}

const SaveViewButton = ({ canSave, warningText }: Props) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleOpen = () => setIsOpen((s) => !s)

    const handleSaveView = () => {
        setIsOpen((s) => !s)
        eventFilterStore.saveCurrentView()
    }

    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: ["bottom-end"],
            }),
            offset(20),
        ],
    })

    const handleClickOutside = () => {
        setIsOpen(false)
    }
    useClickOutside(handleClickOutside, refs.floating, refs.domReference)
    return (
        <>
            <Button
                outline
                ref={refs.setReference}
                onClick={toggleOpen}
            >
                <span className="text-[10px] icon-save mr-2" /> Lưu view
            </Button>

            {isOpen && (
                <FloatingPortal>
                    <DropdownWrap
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className="shadow-menu bg-white rounded-lg px-5 py-4  z-20"
                    >
                        <div className="text-13 mb-5">{warningText}</div>
                        <div className="flex items-center gap-3">
                            <Button
                                disabled={!canSave}
                                onClick={handleSaveView}
                            >
                                Lưu
                            </Button>
                            <Button outline
                                onClick={() => eventFilterStore.resetCurrentView()}
                            >Reset</Button>
                        </div>
                        <Arrow />
                    </DropdownWrap>
                </FloatingPortal>
            )}
        </>
    )
}

export default SaveViewButton

const DropdownWrap = styled.div`
    width: 270px;
`

const Arrow = styled.div`
    background-color: #fff;
    position: absolute;
    top: 5px;
    right: 20px;
    width: 12px;
    height: 12px;
    border-top: 1px solid #dddee1;
    border-left: 1px solid #dddee1;
    transform: translateX(-10px) translateY(-100%) rotate(45deg);
`
