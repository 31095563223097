import localforage from "localforage"
import store from "store2"

console.log("host name:" + window.location.hostname)

export const asyncStorage = localforage.createInstance({
    name: window.location.hostname,
})

export const localStore = store.namespace(window.location.hostname)
