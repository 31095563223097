import classNames from "classnames"
import { useContext, useRef, useState } from "react"
import { styled } from "styled-components"
import Checkbox from "core/Checkbox"
import { moveRight, moveDown, moveLeft, moveUp } from "./utils"
import { cellBorderColor } from "./config"
import { CellContext, ICellContext } from "./context"

// type Props = {
//     data: any,
//     colKey: string
//     getCellValue: (data: any) => boolean
//     onToggleValue: (data: any, colKey: string) => void
//     onDelete: (data: any, colKey: string) => void

// }

export interface Props<T> {
    data: T
    colKey: string
    getCellValue: (data: T) => boolean
    onToggleValue: (data: T, colKey: string) => void
    onDelete: (data: T, colKey: string) => void
}

const BooleanCell = <T extends object>({
    data,
    colKey,
    getCellValue,
    onDelete,
    onToggleValue,
}: React.PropsWithChildren<Props<T>>) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const [isFocus, setIsFocus] = useState(false)
    const handleFocus = () => {
        setIsFocus(true)
        setData(data)
        setColKey(colKey)
    }

    const { setData, setColKey } = useContext<ICellContext<T>>(CellContext)

    /**
     *      Khi textarea bị blur thì ẩn dropdown và ẩn highlight ô bị blur
     */
    const handleBlur = () => {
        setIsFocus(false)
        setData(null)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.key === "Escape") {
        } else if (e.key === "Enter") {
            e.preventDefault()
            toggleSelect()
        } else if (e.code === "Space") {
            toggleSelect()
        } else if (e.key === "Delete" || e.key === "Backspace") {
            onDelete(data, colKey)
        } else if (e.key === "ArrowRight") {
            moveRight(textAreaRef.current)
        } else if (e.key === "ArrowLeft") {
            moveLeft(textAreaRef.current)
        } else if (e.key === "ArrowDown") {
            moveDown(textAreaRef.current)
        } else if (e.key === "ArrowUp") {
            moveUp(textAreaRef.current)
        } else if (e.key === "Tab") {
            e.preventDefault()
            moveRight(textAreaRef.current)
        }
    }

    const toggleSelect = () => {
        onToggleValue(data, colKey)
    }

    const handleMouseDown = () => {
        if (isFocus) {
            toggleSelect()
        }
    }

    return (
        <>
            <Wrap
                className={classNames("relative flex justify-center")}
                onMouseDown={handleMouseDown}
            >
                <textarea
                    ref={textAreaRef}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                />
                <Checkbox className="checkbox" value={getCellValue(data)} />
            </Wrap>
        </>
    )
}

export default BooleanCell

const Wrap = styled.div`
    outline: none;
    display: flex;
    align-items: center;
    border-right: 1px solid ${cellBorderColor};
    &:focus-within {
        outline: 2px solid royalblue;
        background-color: #fff;
        z-index: 1;
    }

    textarea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: transparent;
        padding: 4px 8px;
        outline: none;
        background-color: transparent;
        resize: none;
        caret-color: transparent;
        cursor: default;
    }
`
