import { AxiosResponse } from "axios"
import { CommonListResponse } from "common/types"
import { FeedbackReportItem } from "models/feedbackReportItem"
import API from "./api"


export type FeedbackReportResponse = {
    items: FeedbackReportItem[]
    count: number
}

const getFeedbackReport = async (date: string): Promise<CommonListResponse<FeedbackReportItem>> => {
    var response = await API.get<AxiosResponse<CommonListResponse<FeedbackReportItem>>>(`/customer-report/feedback?date=${date}`)
    return response.data.data
}


const CustomerReportService = {
    getFeedbackReport
}

export default CustomerReportService