import { Input } from "core/styledComponents"
import { useEffect, useRef, useState } from "react"
import { saveGuestDraft } from "services/guestEstimageDraft"
import { eventDetailStore } from "stores/eventDetailStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import GuestEstimateChangeCount from "./GuestEstimateChangeCount/GuestEstimateChangeCount"
import { saveDraft } from "services/draftService"

type Props = {
    guestFrom: number
    guestTo: number
    setGuestFrom: React.Dispatch<React.SetStateAction<number>>
    setGuestTo: React.Dispatch<React.SetStateAction<number>>
}

const InputGuestEstimate = () => {
    const { guestFrom, guestTo, currentEvent } = useSnapshot(eventDetailStore)
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")

    const timerRef = useRef(0)

    const [fromPlaceholder, setFromPlaceholder] = useState("")
    const [toPlaceholder, setToPlaceholder] = useState("")

    useEffect(() => {
        if (guestFrom === 0) {
            setFrom("")
            setFromPlaceholder("0")
        } else {
            setFrom(`${guestFrom}` || "")
        }
        if (guestTo === 0) {
            setTo("")
        } else {
            setTo(`${guestTo}`)
        }
    }, [guestFrom, guestTo])

    const handleChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        eventDetailStore.guestFrom = Number(val) || 0
        setToPlaceholder(`${Number(val) || 0}`)
        if (eventDetailStore.guestFrom > guestTo) {
            setTo("")
            eventDetailStore.guestTo = 0
        }

        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(() => {
            saveDraft()
        }, 500)
    }

    const handleChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        eventDetailStore.guestTo = Number(val) || 0
        // saveGuestDraft(
        //     eventDetailStore.guestFrom,
        //     eventDetailStore.guestTo,
        //     currentEvent.id
        // )

        window.clearTimeout(timerRef.current)
        timerRef.current = window.setTimeout(() => {
            saveDraft()
        }, 500)
    }

    const handleBlur = () => {
        if (guestFrom >= guestTo) {
            eventDetailStore.guestTo = 0
            setToPlaceholder(`${guestFrom}`)

            // saveGuestDraft(
            //     eventDetailStore.guestFrom,
            //     eventDetailStore.guestTo,
            //     currentEvent.id
            // )
            saveDraft()
        }
    }

    return (
        <Wrap>
            <div className="text-sm absolute left-[16px] text-[#C5C5C5]">
                Từ
            </div>
            <Input
                className="from flex-1"
                placeholder={fromPlaceholder}
                value={from}
                onBlur={handleBlur}
                onChange={handleChangeFrom}
            />

            <div className="text-sm absolute right-[140px] text-[#C5C5C5]">
                Đến
            </div>
            <Input
                className="to flex-1"
                onChange={handleChangeTo}
                placeholder={toPlaceholder}
                value={to}
                onBlur={handleBlur}
            />
            <GuestEstimateChangeCount />
        </Wrap>
    )
}

export default InputGuestEstimate

const Wrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 24px;
    font-size: 14px;

    .placeholder {
        position: absolute;
    }

    input {
        text-align: right;
        padding-right: 16px;
    }
`
