import { FloatingPortal } from "@floating-ui/react"
import { AppointmentItem, EventAppointment } from "models/events/appointment"
import { Timeline } from "models/timeline"
import SelectEventType from "modules/EventDetail/GeneralTab/SelectPlaceAndTime/SelectEventType/SelectEventType"
import SelectPlace from "modules/common/SelectPlace"
import TimePickerSingle from "modules/common/timepicker/TimePickerSingle"
import { nanoid } from "nanoid"
import { useEffect, useRef, useState } from "react"
import AppointmentService from "services/appointmentService"
import { dialogStore } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import SelectScheduleTime from "./SelectScheduleTime/SelectScheduleTime"
import { rootStore } from "stores/rootStore"
import PlusButton from "modules/ui/PlusButton"
import CloseButton from "core/button/CloseButton"

const SelectPlaceAndTime = () => {
    const {
        editingAppointments,
        currentEvent,
        title,
        selectedDate,
        editingKey,
    } = useSnapshot(eventDetailStore)
    const { showEventPanel } = useSnapshot(dialogStore)
    const [showTimePicker, setShowTimePicker] = useState(false)
    const { places, eventTypes } = useSnapshot(rootStore)
    // const places = usePlaces()
    // const eventTypes = useEventTypes()

    const [otherAppointments, setOtherAppointments] = useState<
        EventAppointment[]
    >([])
    const [activePlaceId, setActivePlaceId] = useState(0)
    const activePlaceIdRef = useRef(0)
    const [activeKey, setActiveKey] = useState("")

    useEffect(() => {
        if (!showEventPanel) {
            setShowTimePicker(false)
        }
    }, [showEventPanel])

    useEffect(() => {
        activePlaceIdRef.current = activePlaceId
    }, [activePlaceId])

    useEffect(() => {
        ;(async () => {
            var startTime = selectedDate.toYMDNumber()
            var data = await AppointmentService.getEventAppointmentsByDate(
                startTime
            )
            setOtherAppointments(
                data.filter((e) => e.eventId !== currentEvent.id)
            )
            if (activePlaceIdRef.current) {
                setPlaceAppointments(
                    data
                        .filter(
                            (item) =>
                                item.eventId !== currentEvent.id &&
                                item.placeId === activePlaceIdRef.current
                        )
                        .map((item) => ({
                            color:
                                eventTypes.find(
                                    (type) => type.id === item.typeId
                                )?.color || "#fff",
                            duration: item.duration,
                            hour: item.hour,
                            min: item.min,
                            title: item.title,
                            key: item.key,
                        }))
                )
            }
        })()
    }, [selectedDate.toYMDNumber()])

    const handleChangePlace = (e: Timeline, placeId: number) => {
        eventDetailStore.editingKey = e.key || ""

        setActivePlaceId(placeId)
        setActiveKey(e.key || "")
        const activeAppointment = eventDetailStore.editingAppointments.find(
            (item) => item.key === e.key
        )
        if (activeAppointment) {
            activeAppointment.placeId = placeId
        }

        const index = eventDetailStore.editingAppointments.findIndex(
            (item) => item.key === e.key
        )
        if (index != -1) {
            eventDetailStore.editingAppointments[index].placeId = placeId
        }

        setCurrentAppointments(
            eventDetailStore.editingAppointments
                .filter((item) => item.placeId === placeId)
                .map((item) => ({
                    color:
                        eventTypes.find((type) => type.id === item.typeId)
                            ?.color || "#fff",
                    duration: item.duration,
                    hour: item.hour,
                    min: item.min,
                    title: title,
                    key: item.key,
                }))
        )
        setPlaceAppointments(
            otherAppointments
                .filter((item) => item.placeId === placeId)
                .map((item) => ({
                    color:
                        eventTypes.find((type) => type.id === item.typeId)
                            ?.color || "#fff",
                    duration: item.duration,
                    hour: item.hour,
                    min: item.min,
                    title: item.title,
                    key: item.key,
                }))
        )
    }
    const handleChangeType = (e: Timeline, typeId: number) => {
        const index = editingAppointments.findIndex(
            (item) => item.key === e.key
        )
        if (index != -1) {
            eventDetailStore.editingAppointments[index].typeId = typeId
        }

        setCurrentAppointments(
            eventDetailStore.editingAppointments
                .filter((item) => item.placeId === activePlaceIdRef.current)
                .map((item) => ({
                    color:
                        eventTypes.find((type) => type.id === item.typeId)
                            ?.color || "#fff",
                    duration: item.duration,
                    hour: item.hour,
                    min: item.min,
                    title: title,
                    key: item.key,
                }))
        )
    }
    const handleChangeTime = (
        startHour: number,
        startMin: number,
        endHour: number,
        endMin: number
    ) => {
        // const activeAppointment = eventDetailStore.editingAppointments.find(
        //     (e) => e.key === editingKey
        // )
        // if (activeAppointment) {
        //     activeAppointment.hour = startHour
        //     activeAppointment.min = startMin
        //     activeAppointment.duration =
        //         endHour * 60 + endMin - startHour * 60 - startMin
        // }
        eventDetailStore.editingAppointments =
            eventDetailStore.editingAppointments.map((e) => {
                if (e.key === editingKey)
                    return {
                        ...e,
                        hour: startHour,
                        min: startMin,
                        duration:
                            endHour * 60 + endMin - startHour * 60 - startMin,
                    }
                return e
            })
    }
    const handleOpenSelectTime = (key: string, placeId: number) => {
        setShowTimePicker(true)
        eventDetailStore.editingKey = key
        setActiveKey(key)
        setActivePlaceId(placeId)
        updateAppointments(placeId)
    }

    const handleAddAppointment = () => {
        var placeId = places[0]?.id || 0
        var timeline = new Timeline({
            duration: 120,
            placeId,
            typeId: eventTypes[0]?.id || 0,
            hour: 10,
            min: 0,
            eventId: 0,
            key: nanoid(),
        })
        eventDetailStore.editingAppointments.push(timeline)
        handleChangePlace(timeline, placeId)
    }

    const updateAppointments = (placeId: number) => {
        setCurrentAppointments(
            eventDetailStore.editingAppointments
                .filter((item) => item.placeId === placeId)
                .map((item) => ({
                    color:
                        eventTypes.find((type) => type.id === item.typeId)
                            ?.color || "#fff",
                    duration: item.duration,
                    hour: item.hour,
                    min: item.min,
                    title: title,
                    key: item.key,
                }))
        )
        setPlaceAppointments(
            otherAppointments
                .filter((item) => item.placeId === placeId)
                .map((item) => ({
                    color:
                        eventTypes.find((type) => type.id === item.typeId)
                            ?.color || "#fff",
                    duration: item.duration,
                    hour: item.hour,
                    min: item.min,
                    title: item.title,
                    key: item.key,
                }))
        )
    }

    const [currentAppointments, setCurrentAppointments] = useState<
        AppointmentItem[]
    >([])

    //appointments của place đó
    const [placeAppointments, setPlaceAppointments] = useState<
        AppointmentItem[]
    >([])

    const renderHeader = () => (
        <div className="bg-[#fff] flex items-center justify-center font-medium h-8">
            {places.find((item) => item.id === activePlaceId)?.name || ""}
        </div>
    )

    return (
        <>
            <div className="font-medium mb-2 mt-5 flex items-center">
                Địa điểm <span className="text-red-500">*</span>
                {/* <div className="flex items-center justify-center ml-auto w-5 h-5">
                                        <span className="icon-plus text-xs text-link" />
                                    </div> */}
                <PlusButton
                    size={24}
                    className="ml-auto  text-link"
                    onClick={handleAddAppointment}
                    tooltip={"Thêm địa điểm"}
                />
            </div>
            <Wrap>
                {editingAppointments.map((e, index) => (
                    <div
                        className="flex items-center gap-4 mb-3 relative"
                        key={e.key}
                    >
                        <SelectPlace
                            places={places}
                            className="flex-[4]"
                            placeId={e.placeId}
                            onFocus={() =>
                                handleOpenSelectTime(e.key || "", e.placeId)
                            }
                            onChange={(placeId) =>
                                handleChangePlace(e, placeId)
                            }
                        />
                        <SelectScheduleTime
                            isOpen={e.key === activeKey}
                            renderHeader={() => (
                                <div className="bg-[#fff] flex items-center justify-center font-medium h-8">
                                    {places.find(
                                        (item) => item.id === e.placeId
                                    )?.name || ""}
                                </div>
                            )}
                            onOpen={() =>
                                handleOpenSelectTime(e.key || "", e.placeId)
                            }
                            onChange={handleChangeTime}
                            className="flex-[3]"
                            currentAppointment={{
                                color:
                                    eventTypes.find(
                                        (type) => type.id === e.typeId
                                    )?.color || "#fff",
                                duration: e.duration,
                                hour: e.hour,
                                min: e.min,
                                title: title,
                                key: e.key,
                            }}
                        />
                        <SelectEventType
                            className="flex-[5]"
                            typeId={e.typeId}
                            onFocus={() =>
                                handleOpenSelectTime(e.key || "", e.placeId)
                            }
                            onChange={(typeId) => handleChangeType(e, typeId)}
                        />
                        {index > 0 && (
                            <span
                                className="icon-delete absolute text-red-500 left-[-26px] cursor-pointer"
                                onClick={() => {
                                    eventDetailStore.editingAppointments =
                                        eventDetailStore.editingAppointments.filter(
                                            (item) => item.key !== e.key
                                        )
                                    var nextActive =
                                        eventDetailStore.editingAppointments[0]
                                    if (nextActive) {
                                        setActiveKey(nextActive.key || "")
                                        eventDetailStore.editingKey =
                                            nextActive.key || ""
                                        setActivePlaceId(nextActive.placeId)
                                        updateAppointments(nextActive.placeId)
                                    }
                                }}
                            />
                        )}
                    </div>
                ))}
            </Wrap>
            {showTimePicker && (
                <FloatingPortal>
                    <TimePickerWrap
                        className="shadow-menu   rounded p-5 bg-white ] w-[360px]"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <CloseButton
                            onClick={() => setShowTimePicker(false)}
                            className={"absolute top-3 right-3"}
                        />

                        {renderHeader()}
                        <TimePickerSingle
                            activeKey={activeKey}
                            currentAppointments={currentAppointments}
                            otherAppointments={placeAppointments}
                            onChange={handleChangeTime}
                        />
                    </TimePickerWrap>
                </FloatingPortal>
            )}
        </>
    )
}

export default SelectPlaceAndTime

const Wrap = styled.div``

const TimePickerWrap = styled.div`
    position: fixed;
    top: 40px;
    bottom: 40px;
    left: 10px;
    z-index: 20;
`
const Close = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 99px;

    right: 12px;
    top: 12px;
    color: #ccc;
    &:hover {
        color: #aaa;
        background-color: #ededed;
    }
`
