import classNames from "classnames"
import IconPlus from "common/icons/IconPlus"
import IconTrash from "common/icons/IconTrash"
import { CellContext, ICellContext } from "core/cells/context"
import { moveDownAtIndex } from "core/cells/utils"
import EventMenu from "models/events/eventMenu"
import { nanoid } from "nanoid"
import { useContext } from "react"
import { saveDraft } from "services/draftService"
import { parseOrderName } from "services/orderUtils"
import { dialogStore } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import { OrderUnitPrice } from "./OrderUnitPrice/OrderUnitPrice"

const OrderSummary = () => {
    // const { editMode } = useSnapshot(eventDetailStore)
    const { showSavePanel } = useSnapshot(dialogStore)
    const { editingMenus, currentOrderName } = useSnapshot(eventMenuStore)
    const { colKey, data } =
        useContext<ICellContext<EventMenu | null>>(CellContext)

    const addRow = () => {
        eventMenuStore.addRow(data)
    }

    const deleteRow = () => {
        if (data) {
            eventMenuStore.removeItem(data.key)
            saveDraft()
        }
    }

    return (
        <OrderSummaryWrap
            className={classNames(
                " sticky bottom-0 pr-3 py-1",
                showSavePanel ? "bottom-[40px]" : "bottom-0"
            )}
        >
            {true && (
                <>
                    <div
                        onMouseDown={(e) => e.preventDefault()}
                        className="px-3 text-13 hover:text-link cursor-default flex items-center"
                        onClick={addRow}
                    >
                        <IconPlus width={18} height={18} />
                        Thêm dòng
                    </div>
                    <div
                        className="px-4 text-13 hover:text-red-500 cursor-default flex items-center"
                        onClick={deleteRow}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        <IconTrash width={13} height={13} className="mr-2" />
                        Xóa dòng
                    </div>
                </>
            )}
            <div className="ml-auto">
                <div className="flex items-center">
                    <span className="text-[##aaabb3] mr-3 w-12">Tổng:</span>
                    <div className=" font-bold text-base w-24 text-right mr-8">
                        {editingMenus
                            .filter(
                                (e) =>
                                    e.orderName ===
                                    parseOrderName(currentOrderName).name
                            )
                            .reduce((acc, cur) => acc + cur.price, 0)
                            .formatCurrency()}
                    </div>
                </div>
                <div className="flex items-center mt-1">
                    <span className="text-[##aaabb3] mr-3 w-12">1 Suất: </span>
                    <OrderUnitPrice />
                </div>
            </div>
        </OrderSummaryWrap>
    )
}

export default OrderSummary

const OrderSummaryWrap = styled.div`
    display: flex;
    align-items: start;
    justify-content: end;
    position: sticky;
    bottom: 0;
    transition: bottom 0.2s ease;
    font-size: 13px;
    height: 64px;
    background-color: #f3f4f5;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #ededed;
    z-index: 1;
`
