import classNames from "classnames"
import IconChevronLeft from "common/icons/IconChevronLeft"
import IconChevronRight from "common/icons/IconChevronRight"
import styled from "styled-components"

type Props = {
    offset: number
    itemCount: number
    limit: number
    onNextClick: () => void
    onPrevClick: () => void
    className?: string
}

const Paginator = ({
    offset,
    itemCount,
    limit,
    onNextClick,
    onPrevClick,
    className,
}: Props) => {
    return (
        <div
            className={classNames(
                "flex items-center text-sm font-medium text-[#333]",
                className
            )}
        >
            {offset + 1}-{Math.min(itemCount, offset + limit)} /{itemCount}
            <PaginationWrap className="flex items-center ml-5">
                <PaginatorLeft
                    className={classNames({
                        "pointer-events-none opacity-[0.4]": offset == 0,
                    })}
                    onClick={onPrevClick}
                >
                    <IconChevronLeft
                     
                        className="icon-left text-xl"
                    />
                </PaginatorLeft>
                <PaginatorRight
                    onClick={onNextClick}
                    className={classNames({
                        "pointer-events-none opacity-[0.4]":
                            offset + limit >= itemCount,
                    })}
                >
                    <IconChevronRight
                        className="icon-right  text-xl"
                    />
                </PaginatorRight>
            </PaginationWrap>
        </div>
    )
}

export default Paginator

const PaginationWrap = styled.div`
    border: 1px solid #e7e7e7;
    border-radius: 4px;
`
const PaginatorLeft = styled.div`
    width: 36px;
    border-right: 1px solid #ddd;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    background-color: #fff;
    cursor: pointer;
    .icon-left {
        stroke: #bebebe;
    }
    &:hover {
        background-color: #fafafa;
        span {
            color: #aaa;
        }
    }
`
const PaginatorRight = styled.div`
    width: 36px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon-right {
        color: #bebebe;
    }
    border-radius: 0 4px 4px 0;
    background-color: #fff;
    &:hover {
        background-color: #fafafa;
        span {
            color: #aaa;
        }
    }
`
