import Tab from "core/tabs/Tab"
import TabHeaders from "core/tabs/TabHeaders"
import TabPanel from "core/tabs/TabPanel"
import OrderNote from "modules/EventDetail/OrderTab/OrderNote/OrderNote"
import ChecklistNote from "modules/checklist/ChecklistNote/ChecklistNote"
import EventActivity from "modules/event_activity/EventActivity"
import FeedbackNote from "modules/feedback/FeedbackNote"
import {
    useChecklistChangeCount,
    useChecklistNameChangeCount,
} from "services/checklistService"
import {
    useChecklistNoteChangeCount,
    useOrderChangeCount,
    useOrderNoteChangeCount
} from "services/eventService"
import {
    usePaymentChangeCount,
    usePaymentNameChangeCount,
} from "services/paymentService"
import { extractEventId } from "services/utils"
import { styled } from "styled-components"
import ChecklistTabRight from "../ChecklistTabRight/ChecklistTabRight"
import GeneralChangeCount from "../EventDetailPanel/GeneralChangeCount/GeneralChangeCount"
import OrderTab from "../OrderTab/OrderTab"
import PaymentTabRight from "../PaymentTabRight"
import GeneralTabPartial from "../TimelineAndNoteTab/GeneralTabPartial"

const EventDetailRight = () => {
    const groupName = "event-detail"
    var eventId = extractEventId()
    const orderChangeCount = useOrderChangeCount()
    const orderNoteChangeCount = useOrderNoteChangeCount()
    const checklistChangeCount = useChecklistChangeCount()
    const checklistNameChangeCount = useChecklistNameChangeCount()
    const checklistNoteChange = useChecklistNoteChangeCount()
    const checklistTabChangeCount =
        checklistNoteChange + checklistChangeCount + checklistNameChangeCount

    const orderTabChangeCount = orderNoteChangeCount + orderChangeCount

    const paymentChangeCount = usePaymentChangeCount()
    const paymentNameChangeCount = usePaymentNameChangeCount()

    const paymentTabChangeCount = paymentChangeCount + paymentNameChangeCount
    return (
        <>
            <Tabs defaultIndex={0} group={groupName}>
                <TabItem index={0} data-text="Chi tiết">
                    <div className="flex items-center">
                        Thông tin chung <GeneralChangeCount />
                    </div>
                </TabItem>
                <TabItem index={1} data-text="Thanh toán">
                    <div className="flex items-center">
                        Order
                        {orderTabChangeCount > 0 && (
                            <span className="text-[#999] text-xs font-medium ml-2">
                                +{orderTabChangeCount}
                            </span>
                        )}
                    </div>
                </TabItem>
                <TabItem index={2} data-text="Thanh toán">
                    <div className="flex items-center">
                        Check list{" "}
                        {checklistTabChangeCount > 0 && (
                            <span className="text-[#999] text-xs font-medium ml-2">
                                +{checklistTabChangeCount}
                            </span>
                        )}
                    </div>
                </TabItem>
                <TabItem index={3} data-text="Thanh toán">
                    <div className="flex items-center">
                        Thanh toán
                        {paymentTabChangeCount > 0 && (
                            <span className="text-[#999] text-xs font-medium ml-2">
                                +{paymentTabChangeCount}
                            </span>
                        )}
                    </div>
                </TabItem>
                <TabItem index={4} data-text="Feedback">
                    Feedback
                </TabItem>
                <TabItem index={5} data-text="Hoạt động">
                    Lịch sử hoạt động
                </TabItem>
            </Tabs>
            <TabPanel className="mx-4 my-5" group={groupName} index={0}>
                <GeneralTabPartial eventId={eventId} />
            </TabPanel>

            <TabPanel className="mx-4 my-5" group={groupName} index={1}>
                <OrderNote />
                <OrderTab />
                <div className="h-10" />
            </TabPanel>
            <TabPanel className="mx-4 my-5" group={groupName} index={2}>
                <ChecklistNote />
                <ChecklistTabRight />
            </TabPanel>
            <TabPanel className="mx-4 my-5" group={groupName} index={3}>
                <PaymentTabRight />
                <div className="h-10" />
            </TabPanel>
            <TabPanel
                destroyOnInactive
                className="mx-4 my-5"
                group={groupName}
                index={4}
            >
                <FeedbackNote />
            </TabPanel>
            <TabPanel
                destroyOnInactive
                className="mx-4 my-5"
                group={groupName}
                index={5}
            >
                <EventActivity eventId={eventId} />
            </TabPanel>
        </>
    )
}

export default EventDetailRight

const Tabs = styled(TabHeaders)`
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
    box-shadow: 0 1px 4px #cdcdcd;
    /* height: 54px; */
    padding-top: 14px;
    padding-left: 16px;
`

const TabItem = styled(Tab)`
    height: 40px;
    cursor: pointer;
    padding: 0 24px;
    margin: 0 8px;
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    color: #8f9397;
    border: 1px solid transparent;
    border-top: 3px solid transparent;
    min-width: 96px;
    &::after {
        content: attr(data-text);
        content: attr(data-text) / "";
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: bold;
    }
    &:hover {
        color: #676c71;
        background-color: #f0f0f0;
    }
    text-size-adjust: none;
    &.active {
        cursor: default;
        box-shadow: 0 1px 5px #ccddee;
        font-weight: 600;
        background-color: #fff;
        color: black;
        border: 1px solid #dddddd;
        border-top: 3px solid var(--brand);
        border-bottom: none;
        &:hover {
            color: black;
            background-color: #fff;
        }
    }
`
