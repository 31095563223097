import { forwardRef } from "react"
import {
    ScrollAreaRoot,
    ScrollAreaScrollbar,
    ScrollAreaThumb,
    ScrollAreaViewPort,
} from "core/scrollComponents"
import classNames from "classnames"
import { ScrollAreaProps } from "@radix-ui/react-scroll-area"

type ContainerProps = React.ComponentPropsWithoutRef<"div"> &
    Pick<ScrollAreaProps, "type">

export type Ref = HTMLDivElement
const Container = forwardRef<Ref, ContainerProps>(
    ({ children, className, type, ...others }: ContainerProps, ref) => {
        return (
            <ScrollAreaRoot
                className={classNames(className)}
                type={type}
                ref={ref}
            >
                <ScrollAreaViewPort>
                    {children}
                    <ScrollAreaScrollbar
                        orientation="vertical"
                        style={{
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        <ScrollAreaThumb />
                    </ScrollAreaScrollbar>
                </ScrollAreaViewPort>
            </ScrollAreaRoot>
        )
    }
)

export default Container
