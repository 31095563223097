import EventTodo from "models/events/eventTodo"
import { proxy } from "valtio"
import { eventDetailStore } from "./eventDetailStore"
import { rootStore } from "./rootStore"
import { Todo } from "models/todo"
import { uniqueIgnoreCase } from "services/utils"
import { nanoid } from "nanoid"
import { moveDownAtIndex } from "core/cells/utils"

export const todoStore = proxy({
    currentChecklist: "",
    checklistNames: [""],
    editingTodos: <EventTodo[]>[],

    activeTodoName: "",
    lastTodoName: "",
    activeColumn: "",
    lastActiveColumn: "",

    selectCell: (name: string, colKey: string) => {
        todoStore.activeTodoName = name
        todoStore.lastTodoName = name
        todoStore.activeColumn = colKey
        todoStore.lastActiveColumn = colKey
    },
    resetTodos: () => {
        todoStore.editingTodos = eventDetailStore.currentEvent.todos.map(
            (e) => ({ ...e })
        )
        todoStore.initChecklistNames()
    },
    initEmptyTodos: () => {
        todoStore.editingTodos = []
    },
    initTodosAndChecklists: (
        eventTodos: EventTodo[],
        checklistNames: string[]
    ) => {
        todoStore.editingTodos = eventTodos
        if (!checklistNames.length) {
            checklistNames = [""]
        }

        todoStore.checklistNames = checklistNames.map((e) => e)
        todoStore.currentChecklist = todoStore.checklistNames[0]
    },
    initChecklistNames: () => {
        var checklists = eventDetailStore.currentEvent.checklistNames
            .map((e) => e)
            .unique()
        if (!checklists.length) {
            checklists = [""]
        }
        todoStore.checklistNames = checklists
        todoStore.currentChecklist = checklists[0]
    },
    deleteCell: (data: EventTodo, colKey: string, cb?: () => void) => {
        var currentItems = todoStore.editingTodos.filter(
            (e) => e.checklist === todoStore.currentChecklist
        )
        var item = currentItems.find(
            (e) =>
                e.key === data.key && e.checklist === todoStore.currentChecklist
        )
        if (item) {
            if (colKey === "detail") {
                item.detail = ""
                cb && cb()
            }
        }
    },
    updateCell: (
        data: EventTodo,
        colKey: string,
        value: string,
        cb?: () => void
    ) => {
        var found = todoStore.editingTodos.find((e) => e.key === data.key)
        if (found) {
            if (colKey == "detail") {
                found.detail = value
                cb && cb()
            }
        }
    },
    addRow(data: EventTodo | null) {
        const currentEditingItems = this.editingTodos.filter(
            (e) => e.checklist === this.currentChecklist
        )
        const categories = uniqueIgnoreCase(
            currentEditingItems.map((e) => e.section)
        )

        const index = data
            ? currentEditingItems.findIndex((e) => e.key === data.key)
            : 0
        const template = this.editingTodos?.[0]?.template ?? ""
        if (data) {
            this.editingTodos.splice(index + 1, 0, {
                section: data.section,
                eventId: eventDetailStore.currentEvent.id,
                id: 0,
                checklist: this.currentChecklist,
                detail: "",
                name: "",
                template,
                key: nanoid(),
            })
            setTimeout(() => {
                moveDownAtIndex(document.activeElement, 1)
            })
        } else {
            this.editingTodos.push({
                section: categories[categories.length - 1] || "",
                eventId: eventDetailStore.currentEvent.id,
                id: 0,
                checklist: this.currentChecklist,
                detail: "",
                name: "",
                template,
                key: nanoid(),
            })
        }
    },
    //@ts-ignore
    changeTodo: ({
        category,
        cellData,
        colKey,
        option,
        cb,
    }: {
        cellData: EventTodo
        colKey: string
        option: Todo
        category: string
        cb?: () => void
    }) => {
        var index = todoStore.editingTodos.findIndex(
            (e) => e.key === cellData.key
        )
        var section = rootStore.todoSections.find(
            (s) => s.id == option.sectionId
        )
        var template = rootStore.checklistTemplates.find(
            (t) => t.id === section?.templateId
        )
        if (section && template && index != -1) {
            // giữ key cũ
            var key = todoStore.editingTodos[index].key
            todoStore.editingTodos[index] = {
                detail: "",
                eventId: eventDetailStore.currentEvent.id,
                id: 0,
                name: option.name,
                section: section.name,
                checklist: todoStore.currentChecklist,
                template: template.name,
                key,
            }
            cb && cb()
        }
    },
    removeItem: (key: string) => {
        var removeItem = todoStore.editingTodos.find(
            (e) => e.key === key && e.checklist === todoStore.currentChecklist
        )
        todoStore.editingTodos = todoStore.editingTodos.filter(
            (e) => e != removeItem
        )
    },
})
