import { EventPayload } from "models/event_payload"
import { EventItem } from "models/events/event"
import { CommonResponse } from "models/response"
import { Timeline } from "models/timeline"
import { EventStatus, EventTabKey } from "services/constants"
import { discardDraft } from "services/draftService"
import EventService, { CancelEventPayload } from "services/eventService"
import { showSuccess } from "services/notificationService"
import {
    encodeTimelines,
    formatGuestEstimate,
    parseStartTime,
    parseTimelines,
} from "services/utils"
import DateUtils from "utils/DateUtils"
import DeviceUtils from "utils/DeviceUtils"
import EventUtils from "utils/EventUtils"
import OrderUtils from "utils/OrderUtils"
import PaymentUtils from "utils/PaymentUtils"
import { proxy } from "valtio"
import { attachmentStore } from "./attachmentStore"
import { eventMenuStore } from "./eventMenuStore"
import { paymentStore } from "./paymentStore"
import { rootStore } from "./rootStore"
import { todoStore } from "./todoStore"
import { checkTodosChanged } from "utils/TodoUtils"
import { recentListStore } from "modules/recent_list/recentListStore"

export const eventDetailStore = proxy({
    isSaving: false,
    isFetchedEvent: false,
    refreshCount: 0,
    currentEvent: EventItem.generateEmpty(),
    editingAppointments: <Timeline[]>[],
    title: "",
    status: EventStatus.UNCONFIRM,
    customerId: 0,
    customerName: "",
    deposit: 0,
    guestFrom: 0,
    guestTo: 0,
    eventNote: "",
    orderNote: "",
    checklistNote: "",
    cancelNote: "",
    feedback: "",
    tableSetup: "",
    tableReserve: 0,

    editMode: false,
    currentTab: EventTabKey.GENERAL, //key của tab hiện tại của trang EventDetail
    editingKey: "",

    selectedDate: new Date(),
    viewDate: new Date(),
    getDateChanges() {
        return DateUtils.isSameDay(
            this.selectedDate,
            this.currentEvent.startDate
        )
            ? 0
            : 1
    },
    getTimelineChanges() {
        return encodeTimelines(this.editingAppointments) !=
            encodeTimelines(this.currentEvent.parseTimelines())
            ? 0
            : 1
    },
    getDetailLeftChanges: () => {
        var changes: string[] = []
        var currentEvent = eventDetailStore.currentEvent

        if (eventDetailStore.customerId != currentEvent.customerId) {
            changes.push("Khách hàng")
        }
        if (eventDetailStore.title != currentEvent.title) {
            changes.push("Tiêu đề")
        }
        if (eventDetailStore.tableSetup != currentEvent.tableSetup) {
            changes.push("Bàn dự kiến")
        }
        if (eventDetailStore.tableReserve != currentEvent.tableReserve) {
            changes.push("Bàn dự phòng")
        }
        if (eventDetailStore.status != currentEvent.status) {
            changes.push("Trạng thái")
        }
        if (eventDetailStore.deposit != currentEvent.deposit) {
            changes.push("Đặt cọc")
        }
        if (
            formatGuestEstimate(
                eventDetailStore.guestFrom,
                eventDetailStore.guestTo
            ) !=
            formatGuestEstimate(currentEvent.guestFrom, currentEvent.guestTo)
        ) {
            changes.push("Khách dự kiến")
        }

        return changes
    },

    saveTimelineToDB: async () => {
        const response = await EventService.saveTimelineToDB()
        if (response.error == false) {
            showSuccess()
            const index = rootStore.events.findIndex(
                (e) => e.id === eventDetailStore.currentEvent.id
            )
            if (index != -1) {
                var startTime = EventUtils.convertToEventStartTime(
                    eventDetailStore.selectedDate
                )
                var status = eventDetailStore.status
                var guestFrom = eventDetailStore.guestFrom
                var guestTo = eventDetailStore.guestTo
                var tableSetup = eventDetailStore.tableSetup
                var tableReserve = eventDetailStore.tableReserve
                var customerId = eventDetailStore.customerId
                var deposit = eventDetailStore.deposit

                //update event in rootStore
                var ev = rootStore.events[index]
                ev.startTime = startTime
                ev.startDate = new Date(eventDetailStore.selectedDate)
                ev.status = status
                ev.guestFrom = guestFrom
                ev.guestTo = guestTo
                ev.tableSetup = tableSetup
                ev.tableReserve = tableReserve
                ev.deposit = deposit
                ev.customerId = customerId

                //update currentEvent
                eventDetailStore.currentEvent.startTime = startTime
                eventDetailStore.currentEvent.startDate = new Date(
                    eventDetailStore.selectedDate
                )
                eventDetailStore.currentEvent.status = status
                eventDetailStore.currentEvent.guestFrom = guestFrom
                eventDetailStore.currentEvent.guestTo = guestTo
                eventDetailStore.currentEvent.tableSetup = tableSetup
                eventDetailStore.currentEvent.tableReserve = tableReserve
                eventDetailStore.currentEvent.deposit = deposit
                eventDetailStore.currentEvent.customerId = customerId
            }
            eventDetailStore.editingKey = ""
        } else {
        }
        return response
    },
    saveOrderToDB: async () => {
        const response = await EventService.saveOrderToDB()
        if (response.error == false) {
            showSuccess()
            eventDetailStore.currentEvent.menus =
                eventMenuStore.editingMenus.map((e) => ({ ...e }))
        }
    },
    saveChecklistToDB: async () => {
        const response = await EventService.saveChecklistToDB()
        if (response.error == false) {
            showSuccess()
            eventDetailStore.currentEvent.todos = todoStore.editingTodos.map(
                (e) => ({ ...e })
            )
        }
    },
    saveOrderNoteToDB: async ({
        note,
        plainText,
    }: {
        note: string
        plainText: string
    }): Promise<CommonResponse> => {
        const response = await EventService.saveOrderNoteToDB({
            orderNote: note,
            plainText,
        })
        if (response.error == false) {
            eventDetailStore.currentEvent.orderNote = note
            showSuccess()
        }
        return response
    },
    saveEventNoteToDB: async (): Promise<CommonResponse> => {
        const response = await EventService.saveEventNoteToDB()
        if (response.error == false) {
            eventDetailStore.currentEvent.note = eventDetailStore.eventNote
            showSuccess()
        }
        return response
    },
    toggleViewMode: () => {
        eventDetailStore.editingKey = ""
    },
    addEmptyTableSetup(type?: number) {
        let s = type && type != 10 ? `0b${type}` : ""
        this.tableSetup = `${this.tableSetup}|${s}`
    },
    removeCurrentAppointment: () => {
        //xóa editingAppointment
        eventDetailStore.editingAppointments =
            eventDetailStore.editingAppointments.filter(
                (e) => e.key != eventDetailStore.editingKey
            )
        eventDetailStore.editingKey = ""
    },
    resetTimeline: () => {
        eventDetailStore.editingAppointments =
            eventDetailStore.currentEvent.parseTimelines()
        eventDetailStore.selectedDate = new Date(
            eventDetailStore.currentEvent.startDate
        )
        eventDetailStore.editingKey = ""
    },
    initEventDraft(draft: Partial<EventItem>) {
        this.title = draft.title || ""
        eventDetailStore.status = draft.status || EventStatus.UNCONFIRM
        eventDetailStore.eventNote = draft.note || ""
        eventDetailStore.orderNote = draft.orderNote || ""
        eventDetailStore.checklistNote = draft.checklistNote || ""
        eventDetailStore.tableSetup = draft.tableSetup || ""
        eventDetailStore.tableReserve = draft.tableReserve || 0
        eventDetailStore.editingAppointments = parseTimelines(
            draft.timeline || "",
            draft.id || 0
        )
        eventDetailStore.selectedDate = parseStartTime(draft.startTime || 0)
        eventDetailStore.deposit = draft.deposit || 0
        eventDetailStore.guestFrom = draft.guestFrom || 0
        eventDetailStore.guestTo = draft.guestTo || 0
        eventDetailStore.customerId = draft.customerId || 0
        eventDetailStore.customerName = draft.customerName || ""
        eventDetailStore.feedback = draft.feedback || ""
        eventDetailStore.editingKey = ""
    },
    initEventBasicInfo: (eventItem: EventItem) => {
        eventDetailStore.currentEvent = eventItem

        eventDetailStore.title = eventItem.title
        eventDetailStore.selectedDate = eventItem.startDate
        eventDetailStore.guestFrom = eventItem.guestFrom
        eventDetailStore.guestTo = eventItem.guestTo

        eventDetailStore.status = eventItem.status
        eventDetailStore.deposit = eventItem.deposit
        eventDetailStore.customerId = eventItem.customerId || 0
        eventDetailStore.customerName = eventItem.customerName || ""
        eventDetailStore.editingAppointments = eventItem.parseTimelines()
        eventDetailStore.tableReserve = eventItem.tableReserve
        eventDetailStore.tableSetup = eventItem.tableSetup
        eventDetailStore.eventNote = eventItem.note || ""

        eventDetailStore.editingKey = ""
        //init orderNote, checklistNote, & feedback

        eventDetailStore.orderNote = eventItem.orderNote || ""
        eventDetailStore.checklistNote = eventItem.checklistNote || ""
        eventDetailStore.cancelNote = eventItem.cancelNote || ""
        eventDetailStore.feedback = eventItem.feedback || ""

        todoStore.initEmptyTodos()
        paymentStore.initEmpty()
        eventMenuStore.initEmpty()
    },
    createEvent: async () => {
        const {
            title,
            status,
            guestFrom,
            guestTo,
            deposit,
            eventNote,
            orderNote,
            checklistNote,
            tableSetup,
            tableReserve,
            customerId,
        } = eventDetailStore
        const { editingTodos, checklistNames } = todoStore
        const { editingPayments, paymentNames } = paymentStore
        const { editingMenus, orderNames } = eventMenuStore
        const { attachments } = attachmentStore

        var timelines: Timeline[] = eventDetailStore.editingAppointments
        timelines.sort((a, b) => a.hour * 60 + a.min - b.hour * 60 - b.min)

        var earliestAppointment = timelines[0]
        var hour = earliestAppointment.hour.padZero()
        var min = earliestAppointment.min.padZero()
        var date = eventDetailStore.selectedDate
        var year = `${date.getFullYear()}`.substring(2, 4)
        var month = (date.getMonth() + 1).padZero()
        var day = date.getDate().padZero()
        var startTime = +`${year}${month}${day}${hour}${min}`
        var timeline = encodeTimelines(timelines)

        const payload: EventPayload = {
            event: {
                id: 0,
                title,
                startTime,
                timeline,
                status,
                guestFrom,
                guestTo,
                tableSetup,
                tableReserve,
                deposit,
                note: eventNote,
                orderNote,
                checklistNote,
                customerId,
            },
            menus: editingMenus,
            todos: editingTodos,
            payments: editingPayments,
            attachments,
            userName: rootStore.currentUser.displayName,
            deviceName: DeviceUtils.getDeviceInfo(),
            orderNames,
            checklistNames,
            paymentNames,
        }
        var response = await EventService.createEvent(payload)

        if (response.error == false) {
            // var newEventItem = new EventItem({
            //     deposit,
            //     guestFrom,
            //     guestTo,
            //     id: response.newId,
            //     startTime,
            //     status,
            //     tableReserve,
            //     tableSetup,
            //     timeline,
            //     title,
            //     updatedAt: 0,
            //     userId: 0,
            //     cancelNote: "",
            //     checklistNote,
            //     customerId,
            //     deleteNote: "",
            //     feedback: "",
            //     isCancel: false,
            //     note: eventNote,
            //     orderNote,
            //     template: currentTemplate,
            // })
            showSuccess()
            recentListStore.addItem({
                id: response.newId,
                name: title,
                isDraft: false,
            })
        }

        return response
    },
    updateEvent: async () => {
        eventDetailStore.isSaving = true
        const {
            title,
            status,
            guestFrom,
            guestTo,
            deposit,
            eventNote,
            orderNote,
            checklistNote,
            tableSetup,
            tableReserve,
            customerId,
            feedback,
            currentEvent,
        } = eventDetailStore
        const { editingTodos, checklistNames } = todoStore
        const { editingPayments, paymentNames } = paymentStore
        const { editingMenus, orderNames } = eventMenuStore
        const { attachments } = attachmentStore

        var timelines: Timeline[] = eventDetailStore.editingAppointments
        timelines.sort((a, b) => a.hour * 60 + a.min - b.hour * 60 - b.min)

        var earliestAppointment = timelines[0]
        var hour = earliestAppointment.hour.padZero()
        var min = earliestAppointment.min.padZero()
        var date = eventDetailStore.selectedDate
        var year = `${date.getFullYear()}`.substring(2, 4)
        var month = (date.getMonth() + 1).padZero()
        var day = date.getDate().padZero()
        var startTime = +`${year}${month}${day}${hour}${min}`
        var timeline = encodeTimelines(timelines)

        const orderChanged = OrderUtils.checkIsOrderChanged(
            editingMenus,
            currentEvent.menus
        )
        const todoChanged = checkTodosChanged(editingTodos, currentEvent.todos)

        const editingPaymentsNotBlank = editingPayments.filter((e) => e.name)
        const editingMenusNotBlank = editingMenus.filter((e) => e.menu)
        const editingTodosNotBlank = editingTodos.filter((e) => e.name)

        const paymentChanged = PaymentUtils.calculatePaymentChange(
            editingPaymentsNotBlank,
            currentEvent.payments
        )

        const payload: EventPayload = {
            event: {
                id: currentEvent.id,
                title,
                startTime,
                timeline,
                status,
                guestFrom,
                guestTo,
                tableSetup,
                tableReserve,
                deposit,
                note: eventNote,
                orderNote,
                checklistNote,
                customerId,
                feedback,
            },
            menus: orderChanged ? editingMenusNotBlank : undefined,
            todos: todoChanged ? editingTodosNotBlank : undefined,
            payments: paymentChanged ? editingPaymentsNotBlank : undefined,
            attachments,
            userName: rootStore.currentUser.displayName || "",
            deviceName: DeviceUtils.getDeviceInfo(),
            orderNames,
            checklistNames,
            paymentNames,
        }

        var response = await EventService.updateEvent(payload)
        eventDetailStore.isSaving = false

        if (response.error == false) {
            //apply changes từ các biến edit vào currentEvent
            eventDetailStore.currentEvent.menus =
                eventMenuStore.editingMenus.map((e) => ({ ...e }))
            eventDetailStore.currentEvent.todos = todoStore.editingTodos.map(
                (e) => ({ ...e })
            )
            eventDetailStore.currentEvent.payments =
                paymentStore.editingPayments.map((e) => ({ ...e }))

            eventDetailStore.currentEvent.title = title
            eventDetailStore.currentEvent.startTime = startTime
            eventDetailStore.currentEvent.startDate =
                eventDetailStore.selectedDate
            eventDetailStore.currentEvent.timeline = timeline
            eventDetailStore.currentEvent.status = status
            eventDetailStore.currentEvent.customerId = customerId
            eventDetailStore.currentEvent.guestFrom = guestFrom
            eventDetailStore.currentEvent.guestTo = guestTo
            eventDetailStore.currentEvent.tableSetup = tableSetup
            eventDetailStore.currentEvent.tableReserve = tableReserve
            eventDetailStore.currentEvent.deposit = deposit
            eventDetailStore.currentEvent.note = eventNote
            eventDetailStore.currentEvent.orderNote = orderNote
            eventDetailStore.currentEvent.checklistNote = checklistNote
            eventDetailStore.currentEvent.feedback = feedback
            eventDetailStore.currentEvent.orderNames = orderNames
            eventDetailStore.currentEvent.checklistNames = checklistNames
            eventDetailStore.currentEvent.paymentNames = paymentNames

            //update events in rootStore
            // const found = rootStore.events.find((e) => e.id === currentEvent.id)
            // if (found) {
            //     found.title = title
            //     found.startTime = startTime
            //     found.timeline = timeline
            //     found.status = status
            //     found.template = currentTemplate
            //     found.guestFrom = guestFrom
            //     found.guestTo = guestTo
            //     found.tableSetup = tableSetup
            //     found.tableReserve = tableReserve
            //     found.deposit = deposit
            //     found.note = eventNote
            //     found.customerId = customerId
            //     found.startDate = eventDetailStore.selectedDate
            // }
        }

        return response
    },
    cancelEvent: async (payload: CancelEventPayload) => {
        var response = await EventService.cancelEvent(payload)
        return response
    },

    deleteOrder(orderIndex: number) {
        const list = this.tableSetup.split("|")
        if (list.length > 1) {
            list.splice(orderIndex, 1)
            this.tableSetup = list.join("|")
        }
    },
    resetData: () => {
        eventDetailStore.selectedDate = new Date(
            eventDetailStore.currentEvent.startDate
        )
        eventDetailStore.resetTimeline()
        eventMenuStore.resetEventMenus()
        todoStore.resetTodos()
        paymentStore.resetPayments()
        //reset basic info
        eventDetailStore.title = eventDetailStore.currentEvent.title
        eventDetailStore.status = eventDetailStore.currentEvent.status
        eventDetailStore.deposit = eventDetailStore.currentEvent.deposit

        eventDetailStore.guestFrom = eventDetailStore.currentEvent.guestFrom
        eventDetailStore.guestTo = eventDetailStore.currentEvent.guestTo
        eventDetailStore.tableSetup = eventDetailStore.currentEvent.tableSetup
        eventDetailStore.tableReserve =
            eventDetailStore.currentEvent.tableReserve

        eventDetailStore.eventNote = eventDetailStore.currentEvent.note || ""

        eventDetailStore.checklistNote =
            eventDetailStore.currentEvent.checklistNote || ""
        eventDetailStore.orderNote =
            eventDetailStore.currentEvent.orderNote || ""
        eventDetailStore.feedback = eventDetailStore.currentEvent.feedback || ""

        eventDetailStore.customerId =
            eventDetailStore.currentEvent.customerId || 0
        eventDetailStore.customerName =
            eventDetailStore.currentEvent.customerName || ""

        //clear draft data
        // clearOrderNoteDraft(eventDetailStore.currentEvent.id)
        // clearTitleDraft(eventDetailStore.currentEvent.id)
        // clearEventNoteDraft(eventDetailStore.currentEvent.id)
        // clearGuestDraft(eventDetailStore.currentEvent.id)
        discardDraft(eventDetailStore.currentEvent.id)
    },
})
