import { EventType } from "models/eventType"
import { parseColor } from "services/utils"
import { rootStore } from "stores/rootStore"
import { styled } from "styled-components"
import { useSnapshot } from "valtio"
import DialogTitle from "core/dialog/DialogTitle"
import Dialog from "core/dialog/Dialog"
import { useEffect } from "react"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onSelect: (e: EventType) => void
    onClose: () => void
}

const DialogSelectEventType = ({
    isOpen,
    setIsOpen,
    onSelect,
    onClose,
}: Props) => {
    const { eventTypes } = useSnapshot(rootStore)
    useEffect(() => {
        if (!isOpen) {
            onClose()
        }
    }, [isOpen])
    return (
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[600px] mt-[5vh] bg-white">
                <DialogTitle>
                    <div className="text-[14px]">Thêm lịch</div>
                </DialogTitle>
                <div className="py-1">
                    <Wrapper>
                        {eventTypes.map((e) => (
                            <div
                                key={e.id}
                                onClick={() => {
                                    onSelect(e)
                                }}
                                className="flex items-center gap-3 py-3 px-8 item"
                            >
                                <div
                                    className="w-4 h-4 rounded "
                                    style={{
                                        backgroundColor: parseColor(e.color),
                                    }}
                                />
                                <div className="text-md cursor-default hover:underline">
                                    {e.name}
                                </div>
                            </div>
                        ))}
                    </Wrapper>
                </div>
            </div>
        </Dialog>
    )
}

export default DialogSelectEventType

const Wrapper = styled.div`
    .item {
        &:hover {
            background-color: var(--bg);
        }
    }
`
