import { isSameDay } from "date-fns"
import { EventActivityItem } from "models/eventActivity"
import { EventType } from "models/eventType"
import { PlaceItem } from "models/place"
import { TableCount } from "models/table_count"
import { Timeline } from "models/timeline"
import DateChangeHistory from "modules/EventDetail/GeneralTab/DateChangeCount/DateChange/DateChangeHistory"
import TimelineChangeHistory from "modules/EventDetail/GeneralTab/TimelineChangeCount/TimelineChange/TimelineChangeHistory"
import TableSetupChangeHistory from "modules/EventDetail/TimelineAndNoteTab/TableEstimate/TableSetupChanges/TableSetupChangeHistory"
import { ReactNode } from "react"
import { ActivityType } from "services/constants"
import {
    encodeTimelines,
    getPureOrderName,
    parseDateFromNumber,
} from "services/utils"
import EventUtils from "utils/EventUtils"
import {
    encodeTableSetup,
    parseMealTableSetup,
    parseTableCount,
} from "utils/TableSetupUtils"
import { getStatusText } from "./helper"
import OrderNameChangeHistory from "modules/EventDetail/OrderTab/OrderHeader/OrderChanges/OrderNameChangeHistory"

const ActivityDetail = ({
    activity,
    places,
    eventTypes,
}: {
    activity: EventActivityItem
    places: PlaceItem[]
    eventTypes: EventType[]
}) => {
    const buildDetailHeader = (activityItem: EventActivityItem) => {
        const mapper = {
            [ActivityType.NEW_EVENT]: "tạo sự kiện",
            [ActivityType.UPDATE_TABLE_RESERVE]: "cập nhật bàn dự kiến",
            [ActivityType.UPDATE_TABLE_SETUP]: "cập nhật số lượng bàn",
            [ActivityType.UPDATE_EVENT_FEEDBACK]: "cập nhật feedback",
            [ActivityType.UPDATE_EVENT_DEPOSIT]: "cập nhật tiền cọc",
            [ActivityType.UPDATE_EVENT_ORDER]: "cập nhật thực đơn",
            [ActivityType.UPDATE_EVENT_ORDER_TABLE]: "cập nhật thực đơn",
            [ActivityType.UPDATE_EVENT_STATUS]: "cập nhật trạng thái sự kiện",
            [ActivityType.UPDATE_EVENT_PAYMENT]: "cập nhật thanh toán",
            [ActivityType.UPDATE_GUEST_ESTIMATE]: "cập nhật khách dự kiến",
            [ActivityType.UPDATE_EVENT_CHECKLIST]: "cập nhật check list",
            [ActivityType.UPDATE_EVENT_TIMELINE]: "cập nhật lịch sự kiện",
            [ActivityType.REMOVE_EVENT]: "xóa sự kiện",
            [ActivityType.CANCEL_EVENT]: "hủy sự kiện",
            [ActivityType.UPDATE_EVENT_NOTE]: "cập nhật ghi chú",
            [ActivityType.UPDATE_CHECKLIST_NOTE]: "cập nhật ghi chú check list",
            [ActivityType.UPDATE_ORDER_NOTE]: "cập nhật ghi chú order",
        }
        return (
            <div className="flex items-center">
                <span className="font-medium text-link">
                    {activity.displayName}
                </span>
                &nbsp;{" "}
                <span className="text-[#86898b]">
                    {mapper[activityItem.type]}
                </span>
            </div>
        )
    }

    const buildAddedItems = (addedItems: string) => {
        if (!addedItems.trim()) return null
        let arr = addedItems.split("|")
        let hienThiSoLuong = false
        if (arr.length == 1 && Number(arr[0]) >= 0) {
            hienThiSoLuong = true
        }

        if (hienThiSoLuong) {
            let count = Number(arr[0]) || 0
            if (count > 0) {
                return (
                    <div>
                        <div className="text-secondary">Thêm</div>
                        <div className="ml-3 mt-2">
                            <span>+</span>&nbsp;
                            <span>{count} hạng mục</span>
                        </div>
                    </div>
                )
            }
        } else if (arr.length) {
            return (
                <div>
                    <div className="mb-1 mt-1 text-secondary">Thêm</div>
                    {arr.map((e, idx) => (
                        <div className="ml-3" key={idx}>
                            <span className="text-[#a6a6af]">+&nbsp;</span>
                            <span>{e}</span>
                        </div>
                    ))}
                </div>
            )
        }
    }

    const buildUpdatedItems = (updatedItems: string) => {
        if (!updatedItems) return null
        let arr = updatedItems.split("|")
        let hienThiSoLuong = false
        if (arr.length === 1 && Number(arr[0]) >= 0) {
            hienThiSoLuong = true
        }

        if (hienThiSoLuong) {
            let count = Number(arr[0]) || 0

            if (count > 0) {
                return (
                    <div>
                        <div>Cập nhật</div>
                        <div className="ml-5">
                            <span>-</span>&nbsp;
                            <span>{count} hạng mục</span>
                        </div>
                    </div>
                )
            }
        } else if (arr.length) {
            return (
                <div>
                    <div className="mb-2 mt-1 text-[#596671]">Cập nhật</div>
                    {arr.map((e, idx) => (
                        <div className="ml-5" key={idx}>
                            <span className="text-[#a6a6af]">-</span>
                            <span className="">&nbsp;{e}</span>
                        </div>
                    ))}
                </div>
            )
        }
    }

    const buildSLMam = (tableCounts: TableCount[]) => {
        var count = 0
        var list: ReactNode[] = []
        for (var item of tableCounts) {
            list.push(
                <div>
                    <div>
                        {item.count} <span className="">bàn</span>{" "}
                        <span>{item.index}</span>
                    </div>
                </div>
            )
            if (count < tableCounts.length - 1) {
                list.push(<span>, </span>)
            }
        }

        return list
    }
    const buildRemovedItems = (removedItems: string) => {
        if (!removedItems) return null
        var arr = removedItems.split("|")
        var hienThiSoLuong = false
        if (arr.length === 1 && Number(arr[0]) >= 0) {
            hienThiSoLuong = true
        }

        if (hienThiSoLuong) {
            var count = Number(arr[0]) || 0

            if (count > 0) {
                return (
                    <div>
                        <div>Bỏ</div>
                        <div className="ml-5 text-red-500 ml-3">
                            <span>-</span>&nbsp;
                            <span>{count} hạng mục</span>
                        </div>
                    </div>
                )
            }
        } else if (arr.length) {
            return (
                <div>
                    <div className="text-red-500">Bỏ</div>
                    {arr.map((e, idx) => (
                        <div className="ml-5 " key={idx}>
                            <span>-</span>&nbsp;
                            <span className="line-through">{e}</span>
                        </div>
                    ))}
                </div>
            )
        }
    }
    const buildDetail = (activityItem: EventActivityItem) => {
        if (activityItem.type === ActivityType.NEW_EVENT) {
            return (
                <div>
                    <div className="flex items-center">
                        {buildDetailHeader(activityItem)}
                    </div>
                </div>
            )
        }
        if (activityItem.type === ActivityType.UPDATE_EVENT_STATUS) {
            var arr = activityItem.detail.split(",")

            if (arr.length === 2) {
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center gap-2 mt-2">
                            <div>{getStatusText(Number(arr[0]))}</div>
                            <span className="text-gray-500">→</span>
                            <div>{getStatusText(Number(arr[1]))}</div>
                        </div>
                    </div>
                )
            }
            if (/^\d+$/.test(activityItem.detail)) {
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center gap-2 mt-2">
                            <div>
                                {getStatusText(Number(activityItem.detail))}
                            </div>
                        </div>
                    </div>
                )
            }
        }

        if (activityItem.type === ActivityType.UPDATE_EVENT_DEPOSIT) {
            var arr = activityItem.detail.split(",")
            if (arr.length === 2) {
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center mt-2">
                            <div>{Number(arr[0]).formatCurrency()}</div>
                            <span className="text-gray-400  mx-2">→</span>
                            <div>{Number(arr[1]).formatCurrency()}</div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center mt-2">
                            <div>
                                {Number(activityItem.detail).formatCurrency()}
                            </div>
                        </div>
                    </div>
                )
            }
        }

        if (activityItem.type === ActivityType.UPDATE_TABLE_SETUP) {
            var detail = activityItem.detail || ""
            var extra = activityItem.extra || ""
            if (detail.includes(",")) {
                //dữ liệu cũ
                var arr = detail.split(",")
                if (arr.length == 2) {
                    var oldValue = parseTableCount(arr[0])
                    var newValue = parseTableCount(arr[1])
                    return (
                        <div>
                            <div className="flex items-center">
                                {buildDetailHeader(activityItem)}
                            </div>
                            <div className="flex items-center mt-2">
                                {buildSLMam(oldValue)}&nbsp;
                                <span className="text-gray-400">→</span>
                                &nbsp;
                                {buildSLMam(newValue)}
                            </div>
                        </div>
                    )
                }
            } else {
                if (detail.includes("$") && extra.includes("$")) {
                    let oldOrderNames = extra.split("$")[0].split("|")
                    let newOrderNames = extra.split("$")[1].split("|")
                    let oldTableSetup = encodeTableSetup(
                        parseMealTableSetup(detail.split("$")[0])
                    )
                    let newTableSetup = encodeTableSetup(
                        parseMealTableSetup(detail.split("$")[1])
                    )
                    return (
                        <div>
                            <div className="flex items-center">
                                {buildDetailHeader(activityItem)}
                            </div>
                            <TableSetupChangeHistory
                                newOrderNames={newOrderNames}
                                newTableSetup={newTableSetup}
                                oldOrderNames={oldOrderNames}
                                oldTableSetup={oldTableSetup}
                            />
                        </div>
                    )
                } else {
                    //sự kiện mới tạo
                    let newOrderNames = extra.split("|")
                    let mealTableSetups = parseMealTableSetup(detail)
                    if (newOrderNames.length === mealTableSetups.length) {
                        return (
                            <div>
                                <div className="flex items-center">
                                    {buildDetailHeader(activityItem)}
                                </div>
                                <div className="mt-3">
                                    {mealTableSetups.map(
                                        (tableCounts, index) => (
                                            <div className="mb-3">
                                                <div className="">
                                                    {getPureOrderName(
                                                        newOrderNames[index]
                                                    ) || "Thực đơn mặc định"}
                                                    :
                                                </div>
                                                <div className="ml-5 mt-2">
                                                    {tableCounts.map(
                                                        (tblCount, idx) => (
                                                            <span key={idx}>
                                                                {idx > 0 && (
                                                                    <span>
                                                                        {" "}
                                                                        +{" "}
                                                                    </span>
                                                                )}
                                                                <span className="font-medium">
                                                                    {" "}
                                                                    {
                                                                        tblCount.count
                                                                    }
                                                                </span>
                                                                &nbsp;
                                                                <span className="text-grey">
                                                                    bàn{" "}
                                                                    {
                                                                        tblCount.index
                                                                    }
                                                                </span>
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )
                    }
                }
            }
        }
        if (activityItem.type === ActivityType.UPDATE_TABLE_RESERVE) {
            var detail = activityItem.detail || ""
            var arr = detail.split(",")
            if (arr.length == 2) {
                let oldValue = arr[0]
                let newValue = arr[1]
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center mt-2">
                            <div>
                                {oldValue} <span>bàn</span>
                                <span className="text-gray-400 mx-2">→</span>
                                {newValue} <span>bàn</span>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center mt-2">
                            <div>
                                <span className="font-medium">{detail}</span>{" "}
                                <span className="text-secondary">bàn</span>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        if (activityItem.type === ActivityType.UPDATE_EVENT_ORDER_TABLE) {
            let oldOrderNames = activityItem.detail.split("$")[0].split("|")
            let newOrderNames = activityItem.detail.split("$")[1].split("|")
            return (
                <div>
                    <div className="flex items-center">
                        {buildDetailHeader(activityItem)}
                    </div>
                    <OrderNameChangeHistory
                        oldOrderNames={oldOrderNames}
                        newOrderNames={newOrderNames}
                    />
                </div>
            )
        }

        if (activityItem.type === ActivityType.UPDATE_GUEST_ESTIMATE) {
            var arr = activityItem.detail.split(",")
            if (arr.length === 2) {
                let oldValue = arr[0]
                let newValue = arr[1]
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center mt-2">
                            <div>
                                {oldValue}{" "}
                                <span className="text-[#aaa]">khách</span>
                            </div>
                            <span className="text-gray-400 mx-2">→</span>
                            <div>
                                {newValue}{" "}
                                <span className="text-[#aaa]">khách</span>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center mt-2">
                            <div>
                                {activityItem.detail}&nbsp;
                                <span className="text-[#aaa]">khách</span>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        if (activityItem.type === ActivityType.UPDATE_EVENT_ORDER) {
            let detail = activityItem.detail || ""
            let extra = activityItem.extra

            if (!extra || !extra.includes("|")) {
                let arr = detail.split("$")
                if (arr.length === 3) {
                    let addedItems = arr[0]
                    let removedItems = arr[1]
                    let updatedItems = arr[2]

                    return (
                        <div>
                            <div className="flex items-center">
                                {buildDetailHeader(activityItem)}
                            </div>
                            <div className="mt-2">
                                <div>{buildAddedItems(addedItems)}</div>
                                <div>{buildRemovedItems(removedItems)}</div>
                                <div>{buildUpdatedItems(updatedItems)}</div>
                            </div>
                        </div>
                    )
                }
            } else {
                let list = detail.split("\n")
                let orderNames = extra.split("|")

                if (!orderNames.length || !list.length) return null

                let index = -1
                let widgets: ReactNode[] = []
                for (let item of list) {
                    index++
                    let arr = item.split("$")
                    if (arr.length === 3) {
                        let addedItems = arr[0]
                        let removedItems = arr[1]
                        let updatedItems = arr[2]
                        if (
                            addedItems.length ||
                            removedItems.length ||
                            updatedItems.length
                        ) {
                            if (
                                addedItems != "0" ||
                                removedItems != "0" ||
                                updatedItems != "0"
                            ) {
                                widgets.push(
                                    <div className="mb-5 mt-2">
                                        <div className="font-medium mb-3">
                                            {getPureOrderName(
                                                orderNames[index]
                                            ) || "Thực đơn mặc định"}
                                        </div>
                                        <div className="ml-3">
                                            <div>
                                                {buildAddedItems(addedItems)}
                                            </div>
                                            <div>
                                                {buildRemovedItems(
                                                    removedItems
                                                )}
                                            </div>
                                            <div>
                                                {buildUpdatedItems(
                                                    updatedItems
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    }
                }
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center mt-2">
                            <div>{widgets}</div>
                        </div>
                    </div>
                )
            }
        }

        if (activityItem.type === ActivityType.UPDATE_EVENT_CHECKLIST) {
            let detail = activityItem.detail || ""
            let arr = detail.split("$")
            if (arr.length === 3) {
                let addedItems = arr[0]
                let removedItems = arr[1]
                let updatedItems = arr[2]

                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div className="flex items-center mt-2">
                            {buildAddedItems(addedItems)}
                        </div>
                        <div className="mt-2 flex items-center">
                            {buildRemovedItems(removedItems)}
                        </div>
                        <div className="mt-2 flex items-center">
                            {buildUpdatedItems(updatedItems)}
                        </div>
                    </div>
                )
            }
        }
        if (activityItem.type === ActivityType.UPDATE_EVENT_PAYMENT) {
            let detail = activityItem.detail || ""
            let extra = activityItem.extra

            if (!extra || !extra.includes("|")) {
                var arr = detail.split("$")
                if (arr.length === 3) {
                    let addedItems = arr[0]
                    let removedItems = arr[1]
                    let updatedItems = arr[2]

                    return (
                        <div>
                            <div className="flex items-center">
                                {buildDetailHeader(activityItem)}
                            </div>
                            <div>
                                <div className="mt-2">
                                    {buildAddedItems(addedItems)}
                                </div>
                                <div className="mt-2">
                                    {buildRemovedItems(removedItems)}
                                </div>
                                <div className="mt-2">
                                    {buildUpdatedItems(updatedItems)}
                                </div>
                            </div>
                        </div>
                    )
                }
            } else {
                let list = detail.split("\n")
                let orderNames = extra.split("|")

                if (!orderNames.length) return null

                let index = -1
                let widgets: ReactNode[] = []
                for (let item of list) {
                    index++
                    let arr = item.split("$")
                    if (arr.length === 3) {
                        let addedItems = arr[0]
                        let removedItems = arr[1]
                        let updatedItems = arr[2]
                        if (
                            addedItems.length ||
                            removedItems.length ||
                            updatedItems.length
                        ) {
                            if (
                                addedItems != "0" ||
                                removedItems != "0" ||
                                updatedItems != "0"
                            ) {
                                widgets.push(
                                    <div>
                                        <div>
                                            {orderNames[index] ||
                                                "Thanh toán mặc định"}
                                        </div>
                                        <div>
                                            {buildAddedItems(addedItems)}
                                            {buildRemovedItems(removedItems)}
                                            {buildUpdatedItems(updatedItems)}
                                        </div>
                                    </div>
                                )
                            }
                        }
                    }
                }
                return (
                    <div>
                        <div className="flex items-center">
                            {buildDetailHeader(activityItem)}
                        </div>
                        <div>{widgets}</div>
                    </div>
                )
            }
        }
        if (
            [
                ActivityType.UPDATE_EVENT_NOTE,
                ActivityType.CANCEL_EVENT,
                ActivityType.REMOVE_EVENT,
                ActivityType.UPDATE_CHECKLIST_NOTE,
                ActivityType.UPDATE_ORDER_NOTE,
                ActivityType.UPDATE_EVENT_FEEDBACK,
            ].includes(activityItem.type)
        ) {
            return (
                <div>
                    <div className="flex items-center">
                        {buildDetailHeader(activityItem)}
                    </div>
                    <div className="flex items-center mt-2 max-w-xl">
                        <div>{activityItem.detail}</div>
                    </div>
                </div>
            )
        }
        if (activityItem.type === ActivityType.UPDATE_EVENT_TIMELINE) {
            var arr = activityItem.detail.split("$")
            if (arr.length === 2) {
                //"8:0,120,1,2|11:0,405,1,6|11:0,165,3,4,2309050800"
                var oldList = EventUtils.parseTimelinePlus(arr[0])
                var newList = EventUtils.parseTimelinePlus(arr[1])

                const oldDate = parseDateFromNumber(oldList[0].startTime)
                const newDate = parseDateFromNumber(newList[0].startTime)

                const oldTimelines = oldList.map(
                    (e) =>
                        new Timeline({
                            duration: e.duration,
                            eventId: 0,
                            hour: e.hour,
                            min: e.min,
                            placeId: e.placeId,
                            typeId: e.typeId,
                        })
                )
                const newTimelines = newList.map(
                    (e) =>
                        new Timeline({
                            duration: e.duration,
                            eventId: 0,
                            hour: e.hour,
                            min: e.min,
                            placeId: e.placeId,
                            typeId: e.typeId,
                        })
                )

                const dateChanged = !isSameDay(oldDate, newDate)
                const timelineChanged =
                    encodeTimelines(oldTimelines) !=
                    encodeTimelines(newTimelines)

                return (
                    <div>
                        <div className="flex items-center">
                            <span className="font-medium text-link">
                                {activity.displayName}
                            </span>
                            &nbsp;{" "}
                            <span className="text-[#596671]">
                                cập nhật lịch sự kiện
                            </span>
                        </div>
                        <div>
                            {dateChanged && (
                                <DateChangeHistory
                                    oldDate={oldDate}
                                    newDate={newDate}
                                />
                            )}
                            {timelineChanged && (
                                <div className="pt-3">
                                    <TimelineChangeHistory
                                        oldTimelines={oldTimelines}
                                        newTimelines={newTimelines}
                                        places={places}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        }

        return null
    }
    return (
        <div className="px-6 py-5" key={activity.id}>
            <div className="flex items-stretch text-sm">
                <div className="w-16 justify-center text-sm">
                    {new Date(activity.updatedAt).formatHHmm()}
                </div>
                {/* <Separator /> */}
                {buildDetail(activity)}
            </div>
        </div>
    )
}

export default ActivityDetail
