import ErrorPage from "ErrorPage"
import Layout from "Layout.tsx"
import Login from "modules/auth/Login"
import "core/prototypes"
import EventDetail from "modules/EventDetail/EventDetail"
import Settings from "modules/settings/Settings"
import ReactDOM from "react-dom/client"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { initFirebase } from "services/firebase"
import * as timeago from "timeago.js"
import vi from "timeago.js/lib/lang/vi"

import "./index.scss"
import { AppRoutes } from "services/constants"
import Events from "modules/events/Events"
import { eventDetailLoader } from "modules/EventDetail/eventDetailLoader"
import Report from "modules/report/Report"
import FeedbackReport from "modules/report/feedback_report/FeedbackReport"
import EventsMonthView from "modules/events/EventsMonthView"
import Customers from "modules/customer/Customers"
import Profile from "modules/settings/Profile/Profile"
import SettingNotification from "modules/settings/Notification/SettingNotification"
import SettingPermission from "modules/settings/Permission/SettingPermission"
import LoginAndPassword from "modules/settings/LoginAndPassword/LoginAndPassword"
import NoInternetPage from "modules/error_page/NoInternetPage"

timeago.register("vi", vi)

//phải để lên trước hàm createBrowserRouter nếu ko sẽ lỗi
initFirebase()
const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: ``,
                element: <Events />,
            },
            {
                path: `${AppRoutes.EVENT_MONTH_VIEW}`,
                element: <EventsMonthView />,
            },
            {
                path: `${AppRoutes.EVENT_DETAIL}/:eventId`,
                element: <EventDetail />,
                loader: eventDetailLoader,
            },
            {
                path: AppRoutes.REPORT,
                element: <Report />,
            },
            {
                path: AppRoutes.FEEDBACK_REPORT,
                element: <FeedbackReport />,
            },
            {
                path: AppRoutes.CUSTOMERS,
                element: <Customers />,
            },
            {
                path: `${AppRoutes.SETTINGS}`,
                element: <Settings />,
                children: [
                    {
                        path: `${AppRoutes.SETTINGS}/ho-so`,
                        element: <Profile />,
                    },
                    {
                        path: `${AppRoutes.SETTINGS}/thong-bao`,
                        element: <SettingNotification />,
                    },
                    {
                        path: `${AppRoutes.SETTINGS}/phan-quyen`,
                        element: <SettingPermission />,
                    },
                    {
                        path: `${AppRoutes.SETTINGS}/mat-khau-dang-nhap`,
                        element: <LoginAndPassword />,
                    },
                ],
            },
        ],
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/no-connection",
        element: <NoInternetPage />,
    },
])

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <RouterProvider router={router} />
)
