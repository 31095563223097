// icon:caret-down | Ant Design Icons https://ant.design/components/icon/ | Ant Design
import * as React from "react"

function IconCaretDown(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            viewBox="0 0 10 5"
            {...props}
        >
            <path  d="M5 5L0 0h10L5 5z"></path>
        </svg>
    ) 
}

export default IconCaretDown
