import { getMessaging, onMessage } from "firebase/messaging"
import React, { useState, useEffect } from "react"
import { requestPermission, onMessageListener } from "services/firebase"
import { showSuccess } from "services/notificationService"

function Notification() {
    const [notification, setNotification] = useState({ title: "", body: "" })

    useEffect(() => {
        requestPermission()
        const messaging = getMessaging()
        const unsubscribe = onMessage(messaging, (payload) => {
            console.log(messaging)
            showSuccess(
                `${payload?.notification?.title || 'Thong bao'}: ${payload?.notification?.body || ''}`
            )
        })
        // const unsubscribe = onMessageListener().then((payload: any) => {
        //     console.log(payload)
        //     setNotification({
        //         title: payload?.notification?.title,
        //         body: payload?.notification?.body,
        //     });
        //     showSuccess(`${payload?.notification?.title}: ${payload?.notification?.body}`)
        //     //   // toast notification
        //     //   toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
        //     //     duration: 60000, // Display the notification for 1 minute
        //     //     position: 'top-right', // Display the notification at the top right section of the browser page
        //     //   });
        // });

        // return () => {
        //     console.log("unsubscribe...")
        //     unsubscribe.catch((err) => console.log("failed: ", err))
        // }
    }, [])

    return null;
}

export default Notification
