import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import DialogTitle from "core/dialog/DialogTitle"
import { recentListStore } from "modules/recent_list/recentListStore"
import { saveDraft } from "services/draftService"
import { eventDetailStore } from "stores/eventDetailStore"
import { eventMenuStore } from "stores/eventMenuStore"
import { todoStore } from "stores/todoStore"
import {
    calculateChecklistNameChanges,
    calculateTodoChanges,
} from "utils/TodoUtils"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ChecklistChanges = ({ isOpen, setIsOpen }: Props) => {
    const { currentEvent } = useSnapshot(eventDetailStore)
    const { editingTodos, checklistNames } = useSnapshot(todoStore)

    const oldChecklistNames = [...currentEvent.checklistNames]
    const newChecklistNames = [...checklistNames]

    const checklistNameChanges = calculateChecklistNameChanges(
        oldChecklistNames,
        newChecklistNames
    )
    const todoChanges = calculateTodoChanges(currentEvent.todos, editingTodos)

    const handleCancelChanges = () => {
        todoStore.resetTodos()

        saveDraft()
        recentListStore.addItem({
            id: currentEvent.id,
            isDraft: true,
            name: currentEvent.title,
        })
        setIsOpen(false)
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu rounded-lg min-h-[180px]  w-[520px] mt-[100px] mb-[60px] pb-3 bg-white">
                    <DialogTitle>Thay đổi</DialogTitle>
                    <>
                        {checklistNameChanges.addedItems.length > 0 && (
                            <div className="px-5 pt-5  text-sm">
                                <span className="font-medium">
                                    Thêm check list:{" "}
                                </span>
                                {checklistNameChanges.addedItems.map(
                                    (e, index) => (
                                        <span key={index}>
                                            {index > 0 && <span>, </span>}
                                            <span className="text-secondary font-medium">
                                                {e}
                                            </span>
                                        </span>
                                    )
                                )}
                            </div>
                        )}
                        {checklistNameChanges.removedItems.length > 0 && (
                            <div className="px-5 pt-5  text-sm">
                                <span className="font-medium">
                                    Bỏ check list:{" "}
                                </span>
                                {checklistNameChanges.removedItems.map(
                                    (e, index) => (
                                        <span key={index}>
                                            {index > 0 && <span>, </span>}
                                            <span className="text-secondary font-medium">
                                                {e || "Mặc định"}
                                            </span>
                                        </span>
                                    )
                                )}
                            </div>
                        )}
                    </>
                    {todoChanges.changed && (
                        <div className="px-5 pt-6">
                            {todoChanges.changes.map((e, index) => (
                                <div key={index}>
                                    {e.addedItems.length +
                                        e.updatedItems.length +
                                        e.removedItems.length >
                                        0 && (
                                        <div className="text-sm  mb-4 font-medium">
                                            {e.checklistName ||
                                                "Check list mặc định"}
                                        </div>
                                    )}
                                    <div className="border-l-[3px] border-[#ccc]">
                                        {e.addedItems.length > 0 && (
                                            <div className="mb-3">
                                                <div className="text-sm font-normal mb-2 ml-4 text-secondary">
                                                    Thêm
                                                </div>
                                                <div className="ml-6">
                                                    {e.addedItems.map(
                                                        (item) => (
                                                            <div
                                                                key={item}
                                                                className=" text-sm font-normal text-primary"
                                                            >
                                                                + {item}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {e.updatedItems.length > 0 && (
                                            <div className="mb-3">
                                                <div className="text-sm text-secondary  font-normal mb-2 ml-4">
                                                    Cập nhật
                                                </div>
                                                <div className="ml-6">
                                                    {e.updatedItems.map(
                                                        (item) => (
                                                            <div
                                                                key={item}
                                                                className=" text-sm font-normal"
                                                            >
                                                                - {item}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {e.removedItems.length > 0 && (
                                            <div className="mb-3">
                                                <div className="text-red-500 text-sm font-normal mb-2 ml-4">
                                                    Bỏ
                                                </div>
                                                <div className="ml-6">
                                                    {e.removedItems.map(
                                                        (item) => (
                                                            <div
                                                                key={item}
                                                                className="text-sm font-normal"
                                                            >
                                                                -{" "}
                                                                <span className="line-through ">
                                                                    {item}
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <hr className="mt-5" />
                    <div className="flex justify-between px-5 pt-3 gap-8">
                        <Button
                            outline
                            onClick={handleCancelChanges}
                            className="text-red-500 font-normal shadow-none"
                        >
                            Hủy thay đổi
                        </Button>
                        <Button
                            className="w-28"
                            onClick={() => setIsOpen(false)}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ChecklistChanges
