import { parseColor } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import { COL_MARGIN, START_HOUR, YAXIS_WIDTH } from "./TimePicker"
import { Timeline } from "models/timeline"
import classNames from "classnames"

type Props = {
    scrollLeft: number
    scrollTop: number
    top: number
    bottom: number
    colIndex: number
    setColIndex: React.Dispatch<React.SetStateAction<number>>
    colWidth: number
    updateTop: (e: Timeline) => void
    updateBottom: (e: Timeline) => void
}
const CurrentAppointments = ({
    scrollLeft,
    scrollTop,
    setColIndex,
    colWidth,
    updateTop,
    updateBottom,
}: Props) => {
    const { eventTypes, places } = useSnapshot(rootStore)
    const { editingKey, editingAppointments, title } =
        useSnapshot(eventDetailStore)
    const currentAppointments = editingAppointments.filter(
        (e) => e.key != editingKey
    )
    const getColor = (typeID: number) => {
        var color = eventTypes.find((e) => e.id === typeID)?.color || ""
        return parseColor(color)
    }
    let editMode = true
    const getColIndex = (placeId: number) =>
        places.map((e) => e.id).indexOf(placeId)
    return (
        <>
            {currentAppointments.map((e) => (
                <TimelineCard
                    className={classNames(
                        editMode ? "cursor-pointer" : "cursor-default"
                    )}
                    key={e.key}
                    style={{
                        width: `${colWidth - 2 * COL_MARGIN}px`,
                        height: `${e.duration}px`,
                        transform: `translate(${
                            -scrollLeft +
                            colWidth * getColIndex(e.placeId) +
                            COL_MARGIN / 2 +
                            YAXIS_WIDTH
                        }px, ${
                            -scrollTop + 30 + (e.hour - START_HOUR) * 60 + e.min
                        }px)`,
                        backgroundColor: `${getColor(e.typeId)}3F`,
                        border: `1px dashed ${getColor(e.typeId)}`,
                        color: `${getColor(e.typeId)}`,
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={() => {
                        if (!editMode) return
                        updateTop(e)
                        updateBottom(e)
                        eventDetailStore.editingKey = e.key || ""
                        setColIndex(getColIndex(e.placeId))
                    }}
                ></TimelineCard>
            ))}
        </>
    )
}

export default CurrentAppointments

const TimelineCard = styled.div`
    border-radius: 4px;
    background-color: #fff;
    user-select: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    padding: 4px 8px;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
`
