import {
    FloatingOverlay,
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import { useCallback, useEffect, useState } from "react"
import { styled } from "styled-components"
import DatePickerInner from "./DatePickerInner"
import { DateCount } from "core/types"
import { endOfMonth, startOfMonth } from "date-fns"
import { getMondayInThePast, getSundayInFuture } from "services/utils"
import StatisticService from "services/statisticService"
import DateUtils from "utils/DateUtils"
import IconDate from "common/icons/IconDate"
import IconCaretDown from "common/icons/IconCaretDown"

type Props = {
    selectedDate: Date
    onSelect: (d: Date) => void
}

const DatePicker = ({ selectedDate, onSelect }: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [eventDates, setEventDates] = useState<DateCount[]>([])
    const [monthView, setMonthView] = useState(new Date())

    const { refs, floatingStyles, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "top-start",
                    "top-end",
                    "right",
                    "right-start",
                ],
            }),
            offset(10),
        ],
    })
    useEffect(() => {
        ;(async () => {
            let firstDayCurrentMonth = startOfMonth(monthView)
            let lastDayOfMonth = endOfMonth(monthView)
            let from = getMondayInThePast(firstDayCurrentMonth).toYMDNumber()
            let to = DateUtils.getSundayInFuture(lastDayOfMonth).toYMDNumber()

            var data = await StatisticService.countByDate(from, to)
            setEventDates(data)
        })()
    }, [monthView.toYMDNumber()])

    const handleClose = useCallback(() => {
        setIsOpen(false)
    }, [])
    const toggleOpen = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()
            setIsOpen((s) => !s)
        },
        []
    )

    const handleSelect = useCallback(
        (d: Date) => {
            setIsOpen(false)
            onSelect(d)
        },
        [onSelect]
    )

    const handleViewMonthChange = useCallback((d: Date) => {
        setMonthView(d)
    }, [])

    return (
        <>
            <Wrapper
                className="inline-flex items-center"
                ref={refs.setReference}
                onClick={toggleOpen}
            >
                <IconDate/>
                <div className="text-[#818181] ml-3 text-sm select-none">
                    {selectedDate?.formatDMY()}
                </div>
                <IconCaretDown className="text-[8px] ml-2 caret-down" />
            </Wrapper>
            {isOpen && (
                <FloatingPortal>
                    <FloatingOverlay className="z-20" onClick={handleClose}>
                        <div
                            className="shadow-menu z-10 bg-white rounded-[3px] p-3"
                            onClick={(e) => e.stopPropagation()}
                            ref={refs.setFloating}
                            style={floatingStyles}
                        >
                            <DatePickerInner
                                selectedDate={selectedDate}
                                onSelect={handleSelect}
                                dateCounts={eventDates}
                                onViewMonthChange={handleViewMonthChange}
                            />
                        </div>
                    </FloatingOverlay>
                </FloatingPortal>
            )}
        </>
    )
}

export default DatePicker

const Wrapper = styled.div`
    .caret-down {
        opacity: 0;
    }

    &:hover {
        cursor: pointer;
        .caret-down {
            opacity: 1;
        }
    }
`
