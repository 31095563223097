import styled from "styled-components"
import Spinner from "core/loader/Spinner"
import classNames from "classnames"
import Tooltip from "modules/common/Tooltip"
import { forwardRef } from "react"
import IconPlus from "common/icons/IconPlus"

type Props = {
    disabled?: boolean
    className?: string
    iconClassName?: string
    tooltip: string
    size?: number
    onClick?: () => void
}
export type Ref = HTMLSpanElement
export const PlusButton = forwardRef<Ref, Props>(
    (
        { className, iconClassName, size, tooltip, disabled, onClick, ...others }: Props,
        ref
    ) => {
        let _size = size || 12
        return (
            <Tooltip content={tooltip}>
                <Icon ref={ref} className={classNames(className)}>
                    <IconPlus
                        width={_size}
                        height={_size}
                        onClick={onClick}
                        className={classNames(iconClassName, {
                            "pointer-events-none	": disabled,
                        })}
                    />
                </Icon>
            </Tooltip>
        )
    }
)

export default PlusButton

const Icon = styled.span`
    cursor: default;
    display: flex;
    align-items: center;
    width: 38px;
    height: 38px;
    padding: 6px;
    border-radius: 4px;
    justify-content: center;

    color: #333;
    &:hover {
        color: var(--link);
        background-color: #f5f5f5;
    }

    &.loading {
        pointer-events: none;
    }
`
