import classNames from "classnames"
import { Button } from "core/button/Button"
import Dialog from "core/dialog/Dialog"
import { Input } from "core/styledComponents"
import { useEffect, useState } from "react"
import { eventFilterStore } from "stores/eventFilterStore"
import { useSnapshot } from "valtio"

type Props = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddViewDialog = ({ isOpen, setIsOpen }: Props) => {
    const [name, setName] = useState("")
    useEffect(() => {
        if (!isOpen) {
            setName("")
        }
    }, [isOpen])

    const { views } = useSnapshot(eventFilterStore)
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleAdd()
        }
    }
    const handleAdd = async () => {
        var success = await eventFilterStore.createView(name)
        if (success) {
            setIsOpen(false)
            eventFilterStore.changeCurrentView(name)
        }
    }
    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[400px] mt-[25vh] bg-white">
                    <div className="px-8 pt-5 mb-4">
                        <div className="text-md font-semibold mb-5">
                            Tạo view
                        </div>
                        <Input
                            autoFocus
                            value={name}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Tên view"
                            className="h-10 text-sm rounded-lg"
                        />
                        <div
                            className={classNames(
                                "text-xs text-red-500 mt-2 ml-2",
                                {
                                    "opacity-0": !views
                                        .map((e) => e.name)
                                        .includes(name.trim()),
                                }
                            )}
                        >
                            View đã tồn đã tồn tại
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-center gap-4 py-5">
                        <Button
                            disabled={
                                !name.trim() ||
                                views.map((e) => e.name).includes(name.trim())
                            }
                            className="w-[100px]"
                            onClick={handleAdd}
                        >
                            Tạo
                        </Button>
                        <Button
                            className="w-[100px]"
                            outline={true}
                            onClick={() => setIsOpen(false)}
                        >
                            Hủy
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AddViewDialog
