// import { useState } from "react"
import IconPrint from "common/icons/IconPrint";
import { Button } from "core/button/Button";
import Dialog from "core/dialog/Dialog";
import { Input, TextArea } from "core/styledComponents";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import { eventDetailStore } from "stores/eventDetailStore";
import { eventMenuStore } from "stores/eventMenuStore";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import "./bolditalic.js";
import "./font.js";
import "./italic.js";
import "./normal.js";
type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function toDataURL(url: string, callback: (arg: any) => void) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
}

const PAGE_WIDTH = 126;
const MARGIN = 18;

const DialogPrintMenu = ({ isOpen, setIsOpen }: Props) => {
    const [subtitle, setSubtitle] = useState("HAPPY WEDDING");
    const [title, setTitle] = useState("");
    const [base64, setBase64] = useState("");

    const { editingMenus, currentOrderName } = useSnapshot(eventMenuStore);
    const { selectedDate } = useSnapshot(eventDetailStore);

    useEffect(() => {
        toDataURL("/images/menu-template.jpg", function (dataUrl: string) {
            console.log("RESULT:", dataUrl);
            setBase64(dataUrl);
        });
    }, []);

    const doc = new jsPDF({
        orientation: "landscape",
    });

    const handlePrint = () => {
        doc.addImage(base64, "JPEG", 10, 10, 277, 190);
        drawSubtitle();
        drawTitle();
        drawDate();

        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(16);
        const w3 = doc.getTextWidth("MENU");

        let top = 30;
        doc.text("MENU", PAGE_WIDTH * 1.5 + 2 * MARGIN - w3 / 2, top);
        top += 10;

        let lineheight = 1.8;
        while (true) {
            let newTop = tryMeasureHeight(top, lineheight);
            if (newTop < 180) {
                break;
            } else {
                lineheight = lineheight - 0.05;
            }
        }
        printAll(top, lineheight);
        doc.output("dataurlnewwindow");
    };

    const tryMeasureHeight = (top: number, lineHeight: number) => {
        var _menuTypes = ["KHAI VỊ", "MÓN CHÍNH", "TRÁNG MIỆNG"];
        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(16);
        const headingDimension = doc.getTextDimensions("test");
        const LH = 0.3;
        for (let type of _menuTypes) {
            top = measureHeightMenuGroup(type, top, lineHeight);
            top += LH * headingDimension.h;
        }

        top = measureHeightDrink(top, lineHeight);
        return top;
    };

    const printAll = (top: number, lineHeight: number) => {
        var _menuTypes = ["KHAI VỊ", "MÓN CHÍNH", "TRÁNG MIỆNG"];
        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(16);
        const headingDimension = doc.getTextDimensions("test");
        const LH = 0.3;
        for (let type of _menuTypes) {
            top = printMenuGroup(type, top, lineHeight);
            top += LH * headingDimension.h;
        }

        top = printDrink(top, lineHeight);
        console.log("top:", top);
        drawWelcomeText(top);
    };

    const drawWelcomeText = (top: number) => {
        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(10);
        const text = "CHÚC QUÝ KHÁCH NGON MIỆNG!";
        const w = doc.getTextWidth(text);
        doc.text(
            "CHÚC QUÝ KHÁCH NGON MIỆNG!",
            PAGE_WIDTH * 1.5 + 2 * MARGIN - w / 2,
            top + 10
        );
    };
    const drawTitle = () => {
        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(16);
        const w = doc.getTextWidth(title);
        doc.text(title, PAGE_WIDTH / 2 + MARGIN - w / 2, subtitle ? 80 : 70);
    };
    const drawSubtitle = () => {
        if (subtitle) {
            doc.setFontSize(14);
            doc.setFont("Times New Roman", "bolditalic");
            const w = doc.getTextWidth(subtitle);
            doc.text(subtitle, PAGE_WIDTH / 2 + MARGIN - w / 2, 70);
        }
    };

    const drawDate = () => {
        doc.setFont("Times New Roman", "italic");
        doc.setFontSize(12);
        const text = `Ngày ${selectedDate.getDate()}, tháng ${
            selectedDate.getMonth() + 1
        }, năm ${selectedDate.getFullYear()}`;
        const w = doc.getTextWidth(text);
        doc.text(text, PAGE_WIDTH / 2 + MARGIN - w / 2, 90);
    };

    const printMenuGroup = (type: string, top: number, lineHeight: number) => {
        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(10);
        const d1 = doc.getTextDimensions(type);

        var _menuTypes = ["KHAI VỊ", "MÓN CHÍNH", "TRÁNG MIỆNG"];
        let index = _menuTypes.indexOf(type);
        doc.text(`${index + 1}. ${type}`, PAGE_WIDTH + 2 * MARGIN + 10, top);
        top += d1.h * lineHeight;

        doc.setFont("Times New Roman", "normal");
        doc.setFontSize(12);
        let menus = editingMenus
            .filter(
                (m) =>
                    m.groupName &&
                    m.groupName.toLowerCase().includes(type.toLowerCase()) &&
                    m.orderName.toLowerCase() == currentOrderName.toLowerCase()
            )
            .map((e) => e.menu);
        for (let menu of menus) {
            const { w, h } = doc.getTextDimensions(menu);
            doc.text(menu, PAGE_WIDTH * 1.5 + 2 * MARGIN - w / 2, top);
            top += h * lineHeight;
        }
        return top;
    };
    const measureHeightMenuGroup = (
        type: string,
        top: number,
        lineHeight: number
    ) => {
        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(10);
        const d1 = doc.getTextDimensions(type);

        top += d1.h * lineHeight;

        doc.setFont("Times New Roman", "normal");
        doc.setFontSize(12);
        let menus = editingMenus
            .filter(
                (m) =>
                    m.groupName &&
                    m.groupName.toLowerCase().includes(type.toLowerCase())
            )
            .map((e) => e.menu);
        for (let menu of menus) {
            const { w, h } = doc.getTextDimensions(menu);
            top += h * lineHeight;
        }
        return top;
    };
    const measureHeightDrink = (top: number, lineHeight: number) => {
        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(10);
        const { h } = doc.getTextDimensions("ĐỒ UỐNG");
        top += h * lineHeight;
        let menus = editingMenus
            .filter(
                (m) =>
                    m.groupName &&
                    m.groupName.toLowerCase().includes("đồ uống") &&
                    m.orderName.toLowerCase() === currentOrderName.toLowerCase()
            )
            .map((e) => e.menu);

        return optimizeDraw(menus, top, false);
    };
    const printDrink = (top: number, lineHeight: number) => {
        doc.setFont("Times New Roman", "bold");
        doc.setFontSize(10);
        doc.text(`4. ĐỒ UỐNG`, PAGE_WIDTH + 2 * MARGIN + 10, top);
        const { h } = doc.getTextDimensions("ĐỒ UỐNG");
        top += h * lineHeight;
        let menus = editingMenus
            .filter(
                (m) =>
                    m.groupName && m.groupName.toLowerCase().includes("đồ uống")
            )
            .map((e) => e.menu);

        return optimizeDraw(menus, top, true);
    };

    const optimizeDraw = (
        menus: string[],
        top: number,
        shouldDrawText: boolean
    ): number => {
        if (!menus.length) {
            return top;
        }
        doc.setFont("Times New Roman", "normal");
        doc.setFontSize(12);

        let len = menus.length;

        let pick = len + 1;
        const MAX_LINE_WIDTH = 100;
        const LINE_HEIGHT = 1.6;

        while (true) {
            pick = pick - 1;
            let text = menus.filter((m, index) => index < pick).join(", ");
            let dimension = doc.getTextDimensions(text);

            if (dimension.w < MAX_LINE_WIDTH) {
                if (shouldDrawText) {
                    doc.text(
                        text,
                        PAGE_WIDTH * 1.5 + 2 * MARGIN - dimension.w / 2,
                        top
                    );
                }
                top += dimension.h * LINE_HEIGHT;
                break;
            }
        }

        return optimizeDraw(
            menus.filter((e, index) => index >= pick),
            top,
            shouldDrawText
        );
    };

    return (
        <>
            <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="m-auto scaleIn shadow-menu min-h-[100px] rounded-lg  w-[600px] mt-[5vh] bg-white">
                    <>
                        <div className="px-6 pt-3 ">
                            <div className="text-xl font-semibold mb-3">
                                In menu
                            </div>
                        </div>
                        <hr />

                        <div className="flex items-center gap-10 mx-10 mt-5">
                            <div className="flex-1 text-sm text-right">
                                Tiêu đề phụ
                            </div>

                            <div className="flex-[3] text-sm">
                                <Input
                                    placeholder="Ví dụ: Happy wedding"
                                    value={subtitle}
                                    onChange={(e) =>
                                        setSubtitle(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-10 mx-10 mt-5">
                            <div className="flex-1 text-sm text-right">
                                Tiêu đề chính
                            </div>

                            <div className="flex-[3] text-sm">
                                <TextArea
                                    minRows={3}
                                    placeholder="Ví dụ: Tiệc tất niên công ty ABC"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                        </div>

                        <hr className="mt-8" />
                        <div className="flex justify-center gap-4 py-5">
                            <Button className="w-[100px]" onClick={handlePrint}>
                                <IconPrint className="text-xs mx-2" />
                                In
                            </Button>
                            <Button
                                className="w-[100px]"
                                outline={true}
                                onClick={() => setIsOpen(false)}
                            >
                                Đóng
                            </Button>
                        </div>
                    </>
                </div>
            </Dialog>
        </>
    );
};

export default DialogPrintMenu;

const PrintDiv = styled.div`
    display: none;

    @media print {
        display: block;
    }

    img {
        width: 90%;
        margin: auto;
    }
    .page-1 {
        position: absolute;
        top: 0;
        left: 1.92cm;
        width: 12.2cm;
        height: 100vh;
    }
    .page-2 {
        position: absolute;
        top: 0;
        left: 15.55cm;
        width: 12.15cm;
        height: 100vh;
    }
    * {
        font-family: "Times New Roman", sans-serif;
    }
`;
