import { toast } from "react-toastify"

export const showSuccess = (message?: string) => {
    toast(
        <div className="flex items-center px-2">
            <div className="flex items-center justify-center w-[36px] h-[36px] rounded-full bg-[#476DF5]">
                <span className="icon-checkmark-fat text-[14px] text-white" />
            </div>
            <div className=" text-primary text-[14px] font-medium  ml-4">
                {message || "Cập nhật thành công!"}
            </div>
        </div>
    )
}
const error = (message: string) => {
    toast(
        <div className="flex items-center px-2">
            <div className="flex items-center justify-center w-[36px] h-[36px] rounded-full bg-[#476DF5]">
                <span className="icon-checkmark-fat text-[14px] text-white" />
            </div>
            <div className=" text-primary text-[14px] font-medium  ml-4">
                {message}
            </div>
        </div>
    )
}

const NotificationService = {
    success: showSuccess,
    error,
}

export default NotificationService
