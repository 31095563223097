import { addMonths, subMonths } from "date-fns"
import { styled } from "styled-components"

type Props = {
    onSelect: (d: Date) => void
}
const MonthSelect = ({ onSelect }: Props) => {
    const today = new Date()
    const list: Date[] = [
        subMonths(today, 3),
        subMonths(today, 2),
        subMonths(today, 1),
        today,
        addMonths(today, 1),
        addMonths(today, 2),
        addMonths(today, 3),
    ]

    return (
        <>
            {list.map((e) => (
                <div
                    key={e.toDateString()}
                    onClick={() => onSelect(e)}
                >{`Tháng ${e.getMonth() + 1} ${
                    e.getFullYear() === today.getFullYear()
                        ? ""
                        : ` - ${e.getFullYear()}`
                }`}</div>
            ))}
        </>
    )
}

export default MonthSelect

const Wrap = styled.div``
