import { styled } from "styled-components"
import { useSnapshot } from "valtio"

import classNames from "classnames"
import IconTrash from "common/icons/IconTrash"
import BooleanCell from "core/cells/BooleanCell"
import CellProvider from "core/cells/CellProvider"
import { EmptyCell } from "core/cells/EmptyCell"
import NumericCell from "core/cells/NumericCell"
import { Section } from "core/cells/Section"
import SelectCell from "core/cells/SelectCell"
import SelectGroupCell from "core/cells/SelectGroupCell"
import TextCell from "core/cells/TextCell"
import { cellBorderColor } from "core/cells/config"
import { moveDownAtIndex } from "core/cells/utils"
import EventPayment from "models/events/eventPayment"
import { PaymentItem } from "models/payment_item"
import { UnitItem } from "models/unit"
import React, { useCallback, useMemo } from "react"
import { saveDraft } from "services/draftService"
import { equalIgnoreCase, uniqueIgnoreCase } from "services/utils"
import { paymentStore } from "stores/paymentStore"
import { rootStore } from "stores/rootStore"
import PaymentSummary from "./PaymentSummary"

const PaymentTableBody = () => {
    const {
        editingPayments,
        lastActiveKey,
        currentPaymentName: currentSection,
    } = useSnapshot(paymentStore)
    const currentEditingPayments = useMemo(
        () => editingPayments.filter((e) => e.section === currentSection),
        [editingPayments, currentSection]
    )
    const categories = uniqueIgnoreCase(
        currentEditingPayments.map((e) => e.category || "")
    )
    const getCellLabel = useCallback((e: EventPayment) => e.unit, [])
    const getOptionLabel = useCallback((e: UnitItem) => e.name, [])
    const getOptionValue = useCallback((e: UnitItem) => e.id, [])

    const getPaymentName = useCallback((e: PaymentItem) => e.name, [])
    const getEventPaymentName = useCallback((e: EventPayment) => e.name, [])
    const getPaymentValue = useCallback((e: PaymentItem) => e.id, [])
    const getPaymentCategoryName = useCallback(
        (e: PaymentItem) =>
            rootStore.paymentCategories.find((item) => item.id === e.typeId)
                ?.name || "",
        [rootStore.paymentCategories]
    )

    const getIsDisCount = useCallback((e: EventPayment) => !!e.free, [])
    const editMode = true

    const addNewRowAtLast = () => {
        paymentStore.addRow(null)
        setTimeout(() => {
            moveDownAtIndex(document.activeElement, 1)
        }, 0)
    }

    const handleTabAtLastRowCell = () => {
        paymentStore.addRow(null)
        setTimeout(() => {
            if (document.activeElement) {
                moveDownAtIndex(document.activeElement, 1)
            }
        })
    }
    return (
        <CellProvider>
            <TableBodyWrap className={""}>
                {categories.map((s) => (
                    <>
                        <Section>
                            {s || "Chưa phân loại"} - {currentSection}
                        </Section>
                        <>
                            {currentEditingPayments
                                .filter((item) =>
                                    equalIgnoreCase(item.category, s)
                                )
                                .map((e) => (
                                    <Row
                                        key={e.key}
                                        className={classNames({
                                            active: e.key === lastActiveKey,
                                        })}
                                    >
                                        <EmptyCell />
                                        <SelectGroupCell
                                            data={e}
                                            getCellLabel={getEventPaymentName}
                                            getOptionLabel={getPaymentName}
                                            getOptionValue={getPaymentValue}
                                            onDelete={(data, colKey) => {}}
                                            onUpdateValue={({
                                                cellData,
                                                colKey,
                                                option,
                                                category,
                                            }) => {
                                                paymentStore.changePayment({
                                                    category,
                                                    cellData,
                                                    colKey,
                                                    option,
                                                    cb: saveDraft,
                                                })
                                            }}
                                            options={rootStore.paymentItems}
                                            colKey="name"
                                            getOptionCategory={
                                                getPaymentCategoryName
                                            }
                                        />

                                        <SelectCell
                                            data={e}
                                            colKey="unit"
                                            getCellLabel={getCellLabel}
                                            getOptionLabel={getOptionLabel}
                                            getOptionValue={getOptionValue}
                                            onDelete={paymentStore.deleteCell}
                                            onUpdateValue={(
                                                data: EventPayment,
                                                option: UnitItem
                                            ) => {
                                                paymentStore.updateUnit(
                                                    data,
                                                    option,
                                                    saveDraft
                                                )
                                            }}
                                            options={rootStore.units}
                                        />
                                        <TextCell
                                            data={e}
                                            colKey="note"
                                            onDelete={(data, colKey) => {
                                                paymentStore.deleteCell(
                                                    data,
                                                    colKey,
                                                    saveDraft
                                                )
                                            }}
                                            onUpdateValue={(
                                                data,
                                                colKey,
                                                value
                                            ) => {
                                                paymentStore.updateTextCell(
                                                    data,
                                                    colKey,
                                                    value,
                                                    saveDraft
                                                )
                                            }}
                                        />
                                        <NumericCell
                                            data={e}
                                            colKey="quantity"
                                            onDelete={paymentStore.deleteCell}
                                            onUpdateValue={
                                                paymentStore.updateTextCell
                                            }
                                        />

                                        {editMode ? (
                                            <BooleanCell
                                                data={e}
                                                colKey="free"
                                                onDelete={
                                                    paymentStore.deleteCell
                                                }
                                                onToggleValue={(
                                                    data: EventPayment,
                                                    colKey: string
                                                ) => {
                                                    paymentStore.updateBooleanCell(
                                                        data,
                                                        colKey,
                                                        saveDraft
                                                    )
                                                }}
                                                getCellValue={getIsDisCount}
                                            />
                                        ) : (
                                            <>
                                                {!!e.free ? (
                                                    <div className="flex items-center justify-center border-r border-[#f1f1f1]">
                                                        <Free className="flex items-center justify-center text-orange-500 text-xs rouned">
                                                            Miễn phí
                                                        </Free>
                                                    </div>
                                                ) : (
                                                    <div className="border-r border-[#f1f1f1]">
                                                        &nbsp;
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {/* <PaymentPriceCell payment={e} /> */}
                                        <NumericCell
                                            data={e}
                                            colKey="price"
                                            onDelete={(data, colKey) => {
                                                paymentStore.deleteCell(
                                                    data,
                                                    colKey,
                                                    saveDraft
                                                )
                                            }}
                                            onUpdateValue={(
                                                data,
                                                colKey,
                                                value
                                            ) => {
                                                paymentStore.updateTextCell(
                                                    data,
                                                    colKey,
                                                    value,
                                                    saveDraft
                                                )
                                            }}
                                            onTabAtLastRowCell={
                                                handleTabAtLastRowCell
                                            }
                                            onMoveDownAtLastRow={
                                                addNewRowAtLast
                                            }
                                        />
                                        <div className="trash-cell">
                                            <TrashIcon
                                                onClick={() => {
                                                    paymentStore.removeItem(
                                                        e.key
                                                    )
                                                    saveDraft()
                                                }}
                                            >
                                                <IconTrash
                                                    width={16}
                                                    height={16}
                                                />
                                            </TrashIcon>
                                        </div>
                                    </Row>
                                ))}
                        </>
                    </>
                ))}
                <PaymentSummary />
                <div className="h-20">&nbsp;</div>
            </TableBodyWrap>
        </CellProvider>
    )
}

export default React.memo(PaymentTableBody)

const TableBodyWrap = styled.div`
    min-height: 0;
    background-color: #fff;
    flex: 1;
    outline: none;
    display: flex;
    flex-direction: column;
    border: 1px solid ${cellBorderColor};
`
const Row = styled.div`
    box-sizing: border-box;
    min-height: 28px;
    border-bottom: 1px solid ${cellBorderColor};
    font-size: 13px;
    display: grid;
    /* padding-right: 16px; */
    &:first-of-type {
        padding-top: 2px;
    }
    &:nth-child(2n + 1) {
        background-color: #fdfdfc;
    }
    grid-template-columns: 36px minmax(200px, 2fr) 100px 3fr 100px 90px 120px 40px;
    .trash-cell {
        opacity: 0;
    }
    &:hover {
        background-color: #f6faff;
        .trash-cell {
            opacity: 1;
        }
    }
    &.active {
        background-color: #fff7ec;
    }
    & > div:nth-child(2) {
        background-color: #f5f5f5;
    }
`

const TrashIcon = styled.span`
    color: #ccc;
    width: 36px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        color: #ca1f1f;
    }
`

const Free = styled.div`
    border: 1px solid orange;
    border-radius: 5px;
    width: 68px;
    height: 20px;
    white-space: nowrap;
`
