import DialogSelectMenu from "modules/dialogs/DialogSelectMenu"
// import { useState } from "react"
import { useState } from "react"
import { DialogName } from "services/constants"
import { openDialog } from "stores/dialogStore"
import styled from "styled-components"
import OrderMoreButton from "./order_more_button/OrderMoreButton"
import DialogPrintMenu from "./components/DialogPrintMenu"
import DialogPrintOrder from "./components/DialogPrintOrder"
import PrintActionButton from "./components/PrintActionButton"
import { eventMenuStore } from "stores/eventMenuStore"
import UndoButton from "modules/ui/UndoButton"
import BookButton from "./components/BookButton"

// import { eventDetailStore } from "stores/eventDetailStore"

const OrderTopActions = () => {
    // const [loading, setLoading] = useState(false)
    const [isOpenSelectMenu, setIsOpenSelectMenu] = useState(false)

    const onReset = () => {
        eventMenuStore.resetEventMenus()
    }

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenPrintMenu, setIsOpenPrintMenu] = useState(false)
    return (
        <>
            <div className="flex items-center gap-4">
                <PrintActionButton
                    onClickPrintMenu={() => setIsOpenPrintMenu(true)}
                    onClickPrintOrder={() => setIsOpen(true)}
                />

                <UndoButton
                    onClick={onReset}
                    size={15}
                    tooltip="Hủy thay đổi"
                />
                <BookButton
                    onClick={() => setIsOpenSelectMenu(true)}
                    size={16}
                    tooltip="Chọn thực đơn"
                />

                <OrderMoreButton />
                <DialogPrintOrder isOpen={isOpen} setIsOpen={setIsOpen} />
                <DialogPrintMenu
                    isOpen={isOpenPrintMenu}
                    setIsOpen={setIsOpenPrintMenu}
                />
            </div>
            <DialogSelectMenu
                isOpen={isOpenSelectMenu}
                setIsOpen={setIsOpenSelectMenu}
            />
        </>
    )
}

export default OrderTopActions

const PrintWrap = styled.div`
    display: none;
    @media print {
        display: block;
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999999;
`
