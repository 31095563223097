import styled from "styled-components"
import { START_HOUR } from "./TimePicker"

type Props = {
    top: number
    scrollTop: number
}

const StartTimeLabel = ({ scrollTop, top }: Props) => {
    let startHour = Math.floor(top / 60) + START_HOUR
    let startMin = Math.round((top - (startHour - START_HOUR) * 60) / 15) * 15
    if (startMin == 60) {
        startMin = 0
        startHour += 1
    }
    return (
        <StartTimeLabelWrap
            style={{
                transform: `translateY(${-scrollTop + 70 + Math.round(top / 15) * 15 - 9}px)`,
            }}
        >{`${startHour.padZero()}:${startMin.padZero()}`}</StartTimeLabelWrap>
    )
}

export default StartTimeLabel

const StartTimeLabelWrap = styled.div`
    font-size: 12px;
    cursor: default;
    user-select: none;
    color: var(--link);
    position: absolute;
    top: 0;
    z-index: 2;
    left: 6px;
`