import * as ScrollArea from '@radix-ui/react-scroll-area';
import styled from 'styled-components';


export const ScrollAreaRoot = styled(ScrollArea.Root)`
    overflow: hidden;
    background-color: #fff;
`
export const ScrollAreaViewPort = styled(ScrollArea.Viewport)`
    width: 100%;
    height: 100%;
    border-radius: inherit;
`
export const ScrollAreaScrollbar = styled(ScrollArea.Scrollbar)`
    display: flex;
    z-index: 20;
    user-select: none;
    touch-action: none;
    padding: 2px;
    background-color: #fff;
    transition: background-color 0.16s ease-out;
   
    &[data-orientation='vertical'] {
        width: 14px;
        border-left: 1px solid #E8E8E8;
    }
    &[data-orientation='horizontal'] {
        height: 14px;
        flex-direction: column;
        border-top: 1px solid #E8E8E8;
    }
`

export const ScrollAreaThumb = styled(ScrollArea.Thumb)`
    flex: 1;
    background-color: #d5d5d5;
    border-radius: 10px;
    position: relative;
    &:hover {
        background-color: #aaa;
    }
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        min-width: 44px;
        min-height: 44px;
    }
`
export const ScrollAreaCorner = styled(ScrollArea.Corner)`
    background-color: #fafafa;
`