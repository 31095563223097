import { FilterValue } from "./filterTypes"
import { DateFilterValue } from "./viewService"

export const defaultDateValues: FilterValue[] = [
    {
        label: "Tất cả",
        value: DateFilterValue.ALL,
    },
    {
        label: "Hôm nay",
        value: DateFilterValue.TODAY,
    },
    {
        label: "Hôm qua",
        value: DateFilterValue.YESTERDAY,
    },
    {
        label: "Sau ngày hôm nay",
        value: DateFilterValue.AFTER_TODAY,
    },
    {
        label: "Tháng này",
        value: DateFilterValue.THIS_MONTH,
    },
    {
        label: "Tháng trước",
        value: DateFilterValue.LAST_MONTH,
    },
]
export const defaultDateValues2: FilterValue[] = [
    {
        label: "Tất cả",
        value: DateFilterValue.ALL,
    },
    {
        label: "Hôm nay",
        value: DateFilterValue.TODAY,
    },
    {
        label: "Hôm qua",
        value: DateFilterValue.YESTERDAY,
    },
]
