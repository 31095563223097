import React from "react"

function IconRemoteCopy(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            width="1em"
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M7 0a2 2 0 00-2 2v1a1 1 0 002 0V2h1a1 1 0 000-2H7zm5 0a1 1 0 100 2h1a1 1 0 100-2h-1zm5 0a1 1 0 100 2h1v1a1 1 0 002 0V2a2 2 0 00-2-2h-1zm3 7a1 1 0 00-2 0v1a1 1 0 002 0V7zm0 5a1 1 0 00-2 0v1h-1a1 1 0 000 2h1a2 2 0 002-2v-1zM2 5a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2V7a2 2 0 00-2-2H2zm0 2h11v11H2V7z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default IconRemoteCopy
