import Container from "core/Container/Container"
import { NavLink } from "react-router-dom"
import { AppRoutes, CLOUD_FRONT_URL } from "services/constants"
import { rootStore } from "stores/rootStore"
import { useSnapshot } from "valtio"
import SettingMenu from "./SettingMenu"

const SettingSidebar = () => {
    const { currentUser } = useSnapshot(rootStore)
    return (
        <Container className="w-[320px] bg-white h-full min-w-0 flex flex-col card-shadow">
            <NavLink className="flex items-center mx-5 mt-5" to={"/"}>
                <span className="icon-arrow-left text-link text-[10px] mr-3" />
                <div className="text-link text-13 font-medium">Quay lại</div>
            </NavLink>

            <div className="flex items-center justify-center px-5 mt-5">
                <div className="w-28 rounded h-12 flex items-center overflow-hidden">
                    <img
                        className="w-full object-contain"
                        src="/images/logo.jpg"
                    />
                </div>
            </div>
            <div className="text-xl font-semibold mt-3 text-center text-primary">
                Âu Cơ Hospitality
            </div>

            <hr className="mt-5 mx-5" />
            <div className="flex items-center px-5 mb-3 mt-7">
                <img
                    className="rounded-full w-8 h-8 object-cover"
                    src={
                        currentUser?.photoID
                            ? `${CLOUD_FRONT_URL}/${currentUser.photoID}`
                            : "/images/avatar-default.webp"
                    }
                />
                <div className="text-base font-semibold ml-3">
                    {currentUser.displayName}
                </div>
                <NavLink className={"text-link text-13 ml-auto"} to={`/login`}>
                    Đăng xuất
                </NavLink>
            </div>
            <SettingMenu to={`${AppRoutes.SETTINGS}/ho-so`}>Hồ sơ</SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/thong-bao`}>
                Cài đặt thông báo
            </SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/mat-khau-dang-nhap`}>
                Mật khẩu & đăng nhập
            </SettingMenu>
            <div className="text-xs pl-16 font-semibold uppercase mt-8">
                Phân quyền & truy cập
            </div>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Quản lý tài khoản
            </SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Phân quyền
            </SettingMenu>
            <div className="text-base pl-16 font-semibold  mt-8">
                Hệ thống
            </div>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Sảnh
            </SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Thực đơn
            </SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Check list
            </SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Hạng mục thanh toán
            </SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Nhóm khách hàng
            </SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Loại sự kiện
            </SettingMenu>
            <SettingMenu to={`${AppRoutes.SETTINGS}/phan-quyen`}>
                Đơn vị tính
            </SettingMenu>
        </Container>
    )
}

export default SettingSidebar
