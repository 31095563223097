import {
    FloatingOverlay,
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import classNames from "classnames"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { styled } from "styled-components"
import { moveDown, moveLeft, moveRight, moveUp } from "./utils"
import { cellBorderColor } from "./config"
import { stripAccents } from "../utils"
import { CellContext, ICellContext } from "./context"
import React from "react"

export interface Props<T, K> {
    data: T
    colKey: string
    options: K[]
    getCellLabel: (data: T) => string
    getOptionLabel: (option: K) => string
    getOptionValue: (option: K) => string | number
    getOptionCategory: (option: K) => string
    onUpdateValue: ({
        cellData,
        colKey,
        option,
        category,
    }: {
        cellData: T
        colKey: string
        option: K
        category: string
    }) => void
    onDelete: (data: T, colKey: string) => void
}

const MENU_ITEM_HEIGHT = 32

const SelectGroupCell = <T extends object, K extends object>({
    data,
    colKey,
    onDelete,
    options,
    getCellLabel,
    getOptionCategory,
    getOptionLabel,
    getOptionValue,
    onUpdateValue,
}: React.PropsWithChildren<Props<T, K>>) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [showCursor, setShowCursor] = useState(false)
    const [inputValue, setInputValue] = useState("")

    const { setData, setColKey } = useContext<ICellContext<T>>(CellContext)

    //index của selected item khi menu xổ ra, khi inputValue thay đổi, reset index về 0
    const [currentIndex, setCurrentIndex] = useState(0)

    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const handleFocus = () => {
        setInputValue("")
        setShowCursor(false)
        setData(data)
        setColKey(colKey)
    }

    /**
     *      Khi textarea bị blur thì ẩn dropdown và ẩn highlight ô bị blur
     */
    const handleBlur = () => {
        if (!showDropdown) {
            setInputValue("")
            setShowDropdown(false)
            setShowCursor(false)
        }
        setData(null)
    }

    const handleClickOverlay = () => {
        setInputValue("")
        setShowDropdown(false)
        setShowCursor(false)
        setTimeout(() => {
            textAreaRef.current?.focus()
        }, 40)
    }

    const handleClickDropdown = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation()
        textAreaRef.current?.focus()
    }

    const getFilterResults = useCallback(() => {
        var seen: { [key: string]: K[] } = {}
        const searchText = stripAccents(inputValue).toLowerCase()
        const filteredItems = options.filter(
            (e) =>
                //lọc lấy những item có name match với searchText theo đk startsWith
                stripAccents(getOptionLabel(e))
                    .toLowerCase()
                    .startsWith(searchText) ||
                stripAccents(getOptionLabel(e))
                    .toLowerCase()
                    .includes(` ${stripAccents(searchText).toLowerCase()}`)
        )
        for (let item of filteredItems) {
            var category = getOptionCategory(item)
            if (!seen[category]) {
                seen[category] = []
            }
            seen[category].push(item)
        }

        let finalResult = []

        let flatItems: K[] = []

        for (let category in seen) {
            finalResult.push({
                category,
                items: seen[category],
            })
            flatItems = [...flatItems, ...seen[category]]
        }
        return { items: flatItems, groups: finalResult }
    }, [options, getCellLabel(data), inputValue])

    const { items, groups } = getFilterResults()

    /**
     *      Khi menu unit xổ ra, ấn lên xuống thì cập nhật textarea value, (chưa cập nhật vào store)
     */
    const goDownAndUpdate = () => {
        let { items } = getFilterResults()

        setCurrentIndex((idx) => {
            let nextIndex = idx + 1
            if (nextIndex > items.length - 1) {
                nextIndex = 0
            }
            return nextIndex
        })
    }

    /**
     *      Khi menu unit xổ ra, ấn lên xuống thì cũng nhật textarea value, (chưa cập nhật vào store)
     */
    const goUpAndUpdate = () => {
        let { items } = getFilterResults()
        setCurrentIndex((idx) => {
            let nextIndex = idx - 1
            if (nextIndex < 0) {
                nextIndex = items.length - 1
            }
            return nextIndex
        })
    }

    const handleClickMenuItem = (item: any, category: string) => {
        selectItem(item, category), setInputValue("")
        setCurrentIndex(0)
        setTimeout(() => {
            textAreaRef.current?.focus()
        })
    }

    const selectItem = (item: any, category: string) => {
        setShowDropdown(false)
        onUpdateValue({
            cellData: data,
            colKey,
            category,
            option: item,
        })
    }

    const editCell = () => {
        textAreaRef.current?.focus()
        setInputValue(`${getCellLabel(data)}`)
        setShowCursor(true)
        setTimeout(() => {
            textAreaRef.current?.select()
        }, 20)
    }

    //khi double click vào ô thì hiện dropdown
    const onDoubleClick = () => {
        if (!showDropdown) {
            editCell()
        }
    }

    //lấy ra selected item (trong danh sách menu xổ ra) và group của nó
    const findCurrentOption = () => {
        var index = -1
        for (var group of groups) {
            for (var item of group.items) {
                index++
                if (index === currentIndex) {
                    return { option: item, group }
                }
            }
        }
        return { option: null, group: null }
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        //khi handleChange textarea thì chưa set ngay thay đổi vào store (chỉ set khi blur hoặc user ấn enter)
        const value = e.target.value
        setShowDropdown(true)
        setInputValue(value)
        setShowCursor(true)
        //reset lại index
        setCurrentIndex(0)
    }

    const cellIsEmpty = () => getCellLabel(data) === ""

    const deleteRowIfCellIsEmpty = () => {
        if (cellIsEmpty()) {
            onDelete(data, colKey)
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation()
        if (e.key === "Escape") {
            setShowCursor(false)
            setShowDropdown(false)
            setInputValue("")
        } else if (e.key === "Enter") {
            e.preventDefault()
            if (!showDropdown) {
                editCell()
            } else {
                const { option, group } = findCurrentOption()
                if (option) {
                    onUpdateValue({
                        cellData: data,
                        colKey,
                        option,
                        category: group?.category || "",
                    })
                }
                setShowDropdown(false)
                setShowCursor(false)
                setInputValue("")
                setCurrentIndex(0)
            }
        } else if (e.key === "Delete" || e.key === "Backspace") {
            if (!showDropdown) {
                if (cellIsEmpty() && e.key === "Delete") {
                    deleteRowIfCellIsEmpty()
                } else {
                    setShowDropdown(true)
                    setInputValue("")
                    setShowCursor(true)
                    //reset lại index
                    setCurrentIndex(0)
                }
            }
        } else if (e.key === "ArrowRight") {
            if (!showDropdown) {
                moveRight(textAreaRef.current)
            }
        } else if (e.key === "ArrowLeft") {
            if (!showDropdown) {
                moveLeft(textAreaRef.current)
            }
        } else if (e.key === "ArrowDown") {
            if (showDropdown) {
                e.preventDefault()
                goDownAndUpdate()
            } else {
                moveDown(textAreaRef.current)
            }
        } else if (e.key === "ArrowUp") {
            if (showDropdown) {
                e.preventDefault()
                goUpAndUpdate()
            } else {
                moveUp(textAreaRef.current)
            }
        } else if (e.key === "Tab") {
            e.preventDefault()
            moveRight(textAreaRef.current)
        }
    }
    const { refs, floatingStyles, update } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        middleware: [autoPlacement(), offset(10)],
    })

    useEffect(() => {
        update()
    }, [items.length])

    useEffect(() => {
        if (!showDropdown) {
            setCurrentIndex(0)
        }
    }, [showDropdown])

    return (
        <>
            <Wrap
                ref={refs.setReference}
                onDoubleClick={onDoubleClick}
                className={classNames("relative flex justify-start")}
            >
                {getCellLabel(data)}

                <textarea
                    ref={textAreaRef}
                    value={inputValue}
                    onBlur={handleBlur}
                    className={classNames({ "show-cursor": showCursor })}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                />
            </Wrap>
            {showDropdown && (
                <FloatingPortal>
                    <FloatingOverlay
                        className="z-20"
                        onClick={handleClickOverlay}
                    >
                        <DropdownMenu
                            className="shadow-menu rounded-lg py-2 z-[99]"
                            ref={refs.setFloating}
                            style={floatingStyles}
                            onClick={handleClickDropdown}
                        >
                            {groups.map((e) => (
                                <div key={e.category}>
                                    <div
                                        key={`category-${e}`}
                                        className="text-[11px] uppercase pl-3 py-1 text-gray-300"
                                    >
                                        {e.category}
                                    </div>
                                    <>
                                        {e.items.map((item: K) => (
                                            <div
                                                onClick={(ev) => {
                                                    ev.stopPropagation()
                                                    handleClickMenuItem(
                                                        item,
                                                        e.category
                                                    )
                                                }}
                                                className={classNames(
                                                    "menu-item",
                                                    {
                                                        active:
                                                            items.indexOf(
                                                                item
                                                            ) === currentIndex,
                                                    }
                                                )}
                                                key={getOptionValue(item)}
                                            >
                                                {getOptionLabel(item)}
                                            </div>
                                        ))}
                                    </>
                                </div>
                            ))}
                            {groups.length === 0 && (
                                <div className={classNames("text-[#ccc] px-4")}>
                                    Không tìm thấy kết quả
                                </div>
                            )}
                        </DropdownMenu>
                    </FloatingOverlay>
                </FloatingPortal>
            )}
        </>
    )
}

export default React.memo(SelectGroupCell) as typeof SelectGroupCell

const Wrap = styled.div`
    outline: none;
    display: flex;
    align-items: center;
    padding: 8px;
    white-space: pre-wrap;
    min-width: 0;
    font-size: 14px;
    border-right: 1px solid ${cellBorderColor};
    &:last-child {
        padding: 4px 0;
    }
    &:focus-within {
        outline: 2px solid royalblue;
        background-color: #fff !important;
        z-index: 1;
    }

    textarea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: transparent;
        padding: 8px;
        outline: none;
        background-color: transparent;
        resize: none;
        caret-color: transparent;
        cursor: default;
        &.show-cursor {
            background-color: white;
            color: var(--text-primary);
            caret-color: var(--text-primary);
        }
    }
`

const DropdownMenu = styled.div`
    min-width: 200px;
    background-color: #fff;
    overflow: hidden;
    div {
        font-size: 14px;
    }

    .menu-item {
        padding: 0 16px;
        display: flex;
        align-items: center;
        height: ${MENU_ITEM_HEIGHT}px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
    }

    .category-item {
        padding: 8px 16px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
    }
`
