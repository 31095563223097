import { parseColor } from "services/utils"
import { eventDetailStore } from "stores/eventDetailStore"
import { rootStore } from "stores/rootStore"
import styled from "styled-components"
import { useSnapshot } from "valtio"
import { COL_MARGIN, YAXIS_WIDTH } from "./TimePicker"


type Props = {
    scrollLeft: number
    scrollTop: number
    bottom: number
    colIndex: number
    colWidth: number
    bottomPinMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    bottomPinDragStart: (
        e: React.DragEvent<HTMLDivElement>
    ) => void
}
const BottomPin = ({ scrollLeft, scrollTop, bottom, colIndex, colWidth, bottomPinMouseDown, bottomPinDragStart

}: Props) => {

    const { eventTypes } = useSnapshot(rootStore)
    const { editingKey } = useSnapshot(eventDetailStore)
    const editingAppointment = eventDetailStore.editingAppointments.find(e => e.key === editingKey)
    const getColor = (typeID: number) => {
        var color = eventTypes.find(e => e.id === typeID)?.color || ''
        return parseColor(color)
    }
    return (
        <>
            {
                !!editingAppointment && (

                    <BottomPinWrap
                        onMouseDown={bottomPinMouseDown}
                        onDragStart={bottomPinDragStart}
                        //colWidth*0.8 là dịch sang bên phải 0.8 bề rộng của colWidth
                        style={{
                            transform: `translate(${-scrollLeft + colWidth * 0.8 - COL_MARGIN * 2 + colWidth * colIndex + COL_MARGIN / 2 + YAXIS_WIDTH}px, ${-scrollTop + 30 - 8 + bottom}px)`,
                            backgroundColor: `${getColor(editingAppointment.typeId)}3F`,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: `${getColor(editingAppointment.typeId)}`,
                            }}
                        />
                    </BottomPinWrap>
                )
            }
        </>
    )
}

export default BottomPin


const BottomPinWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    position: absolute;
    div {
        width: 10px;
        height: 10px;
        border-radius: 96px;
    }

`