import { styled } from "styled-components"

import classNames from "classnames"
import {
    ScrollAreaRoot,
    ScrollAreaScrollbar,
    ScrollAreaThumb,
    ScrollAreaViewPort,
} from "core/scrollComponents"
import { EventType } from "models/eventType"
import { EventItem } from "models/events/event"
import { PlaceItem } from "models/place"
import React, { UIEvent, useCallback, useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import { dialogStore } from "stores/dialogStore"
import { eventDetailStore } from "stores/eventDetailStore"
import EventTableHeader from "./EventTableHeader"
import { parseTimelines } from "services/utils"
type Props = {
    events: EventItem[]
    places: readonly PlaceItem[]
    eventTypes: readonly EventType[]
}

const EventTableBody = ({ events, places, eventTypes }: Props) => {
    const [scrollLeft, setScrollLeft] = useState(0)
    const scrollAreaRef = useRef<HTMLDivElement>(null)
    const handleScroll = (e: UIEvent<HTMLDivElement>) => {
        setScrollLeft(e.currentTarget.scrollLeft)
    }
    const [hasScrollbar, setHasScrollbar] = useState(false)

    const initDataBeforeNavigate = (e: EventItem) => {
        eventDetailStore.currentEvent = e
        eventDetailStore.title = e.title
        eventDetailStore.editingAppointments = e.parseTimelines()
        eventDetailStore.selectedDate = e.startDate
        eventDetailStore.customerId = e.customerId || 0
        eventDetailStore.customerName = e.customerName || ""
        eventDetailStore.status = e.status
        eventDetailStore.guestFrom = e.guestFrom
        eventDetailStore.guestTo = e.guestTo
        eventDetailStore.tableSetup = e.tableSetup
        eventDetailStore.tableReserve = e.tableReserve
        eventDetailStore.deposit = e.deposit
        eventDetailStore.eventNote = e.note || ""
    }

    const renderPlaces = useCallback(
        (e: EventItem) => {
            const timelines = e.parseTimelines()
            return timelines
                .map(
                    (item) =>
                        places.find((p) => p.id == item.placeId)?.name || ""
                )
                .join(", ")
        },
        [places]
    )
    const renderTypes = useCallback(
        (e: EventItem) => {
            const timelines = e.parseTimelines()
            return timelines
                .map(
                    (item) =>
                        eventTypes.find((p) => p.id == item.typeId)?.name || ""
                )
                .join(", ")
        },
        [eventTypes]
    )

    const previewEvent = (eventItem: EventItem) => {
        const isSameId =
            eventItem.id == eventDetailStore.currentEvent.id &&
            eventItem.id !== 0

        eventDetailStore.initEventBasicInfo(eventItem)
        dialogStore.showEventPanel = true
        if (isSameId) {
            //xử lý trường hợp ví dụ vào view 1 event có id = 7, xong ra, vào view lại đúng event này thì cần refresh lại dữ liệu
            //vì mỗi lần ra thì dữ liệu bị reset
            eventDetailStore.refreshCount++
        }
    }

    useEffect(() => {
        if (scrollAreaRef.current) {
            let observer = new ResizeObserver((mutationsList, observe) => {
                var hasHorizontalScrollbar =
                    scrollAreaRef.current!.scrollWidth >
                    scrollAreaRef.current!.clientWidth
                setHasScrollbar(hasHorizontalScrollbar)
            })

            observer.observe(scrollAreaRef.current, {})
        }
    }, [])
    return (
        <Wrap>
            <ScrollAreaRoot type="auto">
                <ScrollAreaViewPort
                    ref={scrollAreaRef}
                    style={{
                        backgroundColor: `var(--bg)`,
                        maxHeight: `calc(100vh - 380px)`,
                        paddingBottom: hasScrollbar ? "12px" : undefined,
                    }}
                    onScroll={handleScroll}
                >
                    <EventTableHeader scrollLeft={scrollLeft} />
                    <div className="">
                        {events.map((e) => (
                            <Row key={e.id}>
                                <div className="cell">&nbsp;</div>
                                <NameCell
                                    onMouseDown={() =>
                                        initDataBeforeNavigate(e)
                                    }
                                    to={`/su-kien/${e.id}`}
                                    className={classNames(
                                        "cell  relative font-medium ",
                                        e.isCancel
                                            ? "text-red-500"
                                            : "text-link"
                                    )}
                                >
                                    <div className="title">
                                        {e.isCancel ? "[Hủy] " : ""}
                                        {e.title || "Chưa có tiêu đề"}
                                    </div>
                                    <PreviewButton
                                        className="absolute preview-button"
                                        onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                        }}
                                        onClick={(ev) => {
                                            ev.preventDefault()
                                            ev.stopPropagation()
                                            previewEvent(e)
                                        }}
                                    >
                                        Xem nhanh
                                    </PreviewButton>
                                </NameCell>
                                <div className="cell">
                                    {e.customerName || "-"}
                                </div>
                                <div className="cell">{e.note || ""}</div>
                                <div className="cell text-center">
                                    {e.formatStatus()}
                                </div>
                                <div className="cell text-center">
                                    {e.startDate.formatDMY()}
                                </div>

                                <div className="cell text-center">
                                    {e.startDate.getHours().padZero()}:
                                    {e.startDate.getMinutes().padZero()}
                                </div>
                                <div className="cell ">{renderPlaces(e)}</div>
                                <div className="cell ">{renderTypes(e)}</div>
                                <div className="cell">
                                    {e.formatTableSetup()}
                                </div>
                                <div className="cell">{e.formatGuest()}</div>
                                <div className="cell text-center">
                                    {e.formatDeposit()}
                                </div>
                                <div className="cell text-center">
                                    {e.menuCount}
                                </div>
                                <div className="cell text-right">
                                    {e.totalPrice?.formatCurrency()}
                                </div>
                                <div className="cell text-center">
                                    {e.todoCount}
                                </div>
                                <div className="cell text-right">
                                    {e.totalPayment?.formatCurrency()}
                                </div>
                            </Row>
                        ))}
                    </div>
                    <ScrollAreaScrollbar
                        orientation="vertical"
                        style={{
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        <ScrollAreaThumb />
                    </ScrollAreaScrollbar>
                    <ScrollAreaScrollbar
                        orientation="horizontal"
                        style={{
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        <ScrollAreaThumb />
                    </ScrollAreaScrollbar>
                </ScrollAreaViewPort>
            </ScrollAreaRoot>
        </Wrap>
    )
}

export default React.memo(EventTableBody)

const Wrap = styled.div`
    min-height: 0;
    flex-shrink: 0;
    background-color: #fff;
    flex: 1;
    outline: none;
    border: 1px solid #e9e9e9;
`

const Row = styled.div`
    box-sizing: border-box;
    min-height: 28px;
    border-bottom: 1px solid #e9e9e9;
    display: grid;
    padding-right: 16px;
    .cell {
        padding: 8px 12px;
        font-size: 14px;
        border-right: 1px solid #e9e9e9;
    }
    &:first-of-type {
        padding-top: 2px;
    }
    &:last-of-type {
        border-bottom: none;
    }
    .trash-cell {
        opacity: 0;
        &:hover {
            opacity: 1;
        }
    }
    &:nth-child(2n + 1) {
        background-color: #fdfdfc;
    }
    &:hover {
        background-color: #f6faff;
        .trash-cell {
            color: #ccc;
            opacity: 1;
            &:hover {
                color: #ca1f1f;
            }
        }
    }

    &.active {
        background-color: #fff7ec;
    }
    grid-template-columns: 40px 320px 140px 170px 120px 150px 140px 140px 170px 140px 140px 140px 140px 140px 140px minmax(
            140px,
            1fr
        );
`
const PreviewButton = styled.div`
    right: 10px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    background-color: #eeeeee;
    border: 1px solid #b3c4ce;
    border-radius: 3px;
    color: #707b89;
    height: 28px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 0 8px;
    z-index: 8;
    display: none;
    &:hover {
        background-color: #fafafa;
    }
`

const NameCell = styled(NavLink)`
    .title {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    &:hover {
        .preview-button {
            display: flex;
        }
    }
`
