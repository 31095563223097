import { ReactNode } from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"

type Props = {
    children: ReactNode
    to: string
}

const SettingMenu = ({ children, to }: Props) => {
    return (
        <StyledNavLink className="py-1 my-2 pl-4 ml-12 mr-3 text-sm" to={to}>
            {children}
        </StyledNavLink>
    )
}

export default SettingMenu

const StyledNavLink = styled(NavLink)`
    color: var(--text-secondary);
    display: flex;
    cursor: pointer;
    &.active {
        pointer-events: none;
        /* font-weight: 500; */
        background-color: #f1f7fb;
        border-radius: 4px;
    }
`
