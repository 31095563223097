// int id;
//   String name;
//   String? addr;
//   String? tel;
//   String? note;
//   String? photoID;
//   String? groupName;
//   int? groupID;
//   int updatedAt;

export type Customer = {
    id: number
    name: string
    addr: string
    tel: string
    note: string
    photoID: string
    groupID?: number
    groupName: string
    updatedAt: number
    isDeleted?: boolean
}

export type CustomerPayload = {
    id?: number
    name: string
    addr: string
    tel: string
    note: string
    photoID?: string
    groupID?: number
}

export const generateEmptyCustomer = (): Customer => ({
    id: 0,
    name: '',
    addr: '',
    tel: '',
    note: '',
    photoID: '',
    groupName: '',
    groupID: 0,
    updatedAt: 0
})