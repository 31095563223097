import { format } from "date-fns"
import { ChecklistTemplate } from "models/checklist_template"
import { Customer } from "models/customer"
import { CustomerGroup } from "models/customer_group"
import { EventItem } from "models/events/event"
import { EventType } from "models/eventType"
import { MenuItem } from "models/menu_item"
import { MenuType } from "models/menu_type"
import { PaymentItem } from "models/payment_item"
import { PaymentCategory } from "models/payment_template"
import { PlaceItem } from "models/place"
import { Todo, TodoSection } from "models/todo"
import { UnitItem } from "models/unit"
import User from "models/user"
import { proxy } from "valtio"

const today = new Date()

export const rootStore = proxy({
    events: <EventItem[]>[],
    currentMonth: format(today, "MMM-yyyy"),
    currentUser: User.generateEmpty(),
    eventTypes: <EventType[]>[],
    places: <PlaceItem[]>[],
    units: <UnitItem[]>[],
    menus: <MenuItem[]>[],
    checklistTemplates: <ChecklistTemplate[]>[],
    todos: <Todo[]>[],
    paymentItems: <PaymentItem[]>[],
    paymentCategories: <PaymentCategory[]>[],
    todoSections: <TodoSection[]>[],
    menuTypes: <MenuType[]>[],
    customers: <Customer[]>[],
    customerGroups: <CustomerGroup[]>[],
    selectedDate: new Date(),
})
