import {
    FloatingPortal,
    autoPlacement,
    offset,
    useFloating,
} from "@floating-ui/react"
import { useCallback, useState } from "react"
import styled from "styled-components"
import { useClickOutside } from "core/hooks"
import IconPrint from "common/icons/IconPrint"
import PrintButton from "modules/ui/PrintButton"
// import { DropdownMenu } from "core/styledComponents"

type Props = {
    className?: string
    onClickPrintOrder: () => void
    onClickPrintMenu: () => void
}

const PrintActionButton = ({
    className,
    onClickPrintOrder,
    onClickPrintMenu,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [showCancelDialog, setShowCancelDialog] = useState(false)
    const { refs, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: [
                    "bottom-end",
                    "top-start",
                    "top-end",
                    "right-end",
                ],
            }),
            ,
            offset(10),
        ],
    })

    const handleClickOutside = useCallback(() => {
        setIsOpen(false)
    }, [])
    useClickOutside(handleClickOutside, refs.domReference, refs.floating)
    return (
        <>
            <PrintButton
                ref={refs.setReference}
                onClick={() => setIsOpen((s) => !s)}
                size={13}
                tooltip="In thực đơn"
            />
            {isOpen && (
                <FloatingPortal>
                    <DropdownMenu
                        className="shadow-menu rounded-lg py-2 z-[100]"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        onClick={() => setIsOpen(false)}
                    >
                        <div className="menu-item" onClick={onClickPrintMenu}>
                            <IconPrint width={16} height={16} className="mr-4" />
                            In thực đơn khách
                        </div>
                        <div className="menu-item" onClick={onClickPrintOrder}>
                            <span className="icon-simple-list text-sm" />
                            In thực đơn nhà bếp
                        </div>
                    </DropdownMenu>
                </FloatingPortal>
            )}
        </>
    )
}

export default PrintActionButton

const DropdownMenu = styled.div`
    width: 250px;
    background-color: #fff;
    overflow: hidden;

    .menu-item {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 8px 20px;
        &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
        }
        &.active {
            background-color: var(--link);
            color: #fff;
        }
        span {
            width: 32px;
            display: inline-flex;
        }
    }
`
